import BaseService from './BaseService';

class GeneralConditionService extends BaseService {
    constructor() {
        super('/common/generalConditions');
    }
}

const generalConditionService = new GeneralConditionService();

export default generalConditionService;
