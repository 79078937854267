const API_URL = `${import.meta.env.VITE_API_URL}`;
const CDN_URL = `${import.meta.env.VITE_CDN_URL}`;

const VERSION = '1.0';

const MAX_RESULT = 10;
const COOKIE_DOMAIN = undefined;

const COOKIE_CONFIG = {
    maxAge: 60 * 60 * 24 * 120,
    path: '/',
    domain: COOKIE_DOMAIN,
    secure: false,
    sameSite: 'Lax',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    API_URL,
    CDN_URL,
    MAX_RESULT,
    VERSION,
    COOKIE_CONFIG,
};
