import { Trans } from 'react-i18next';
import { SaleItemPurchaseType } from '../../../util/Enums';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import TourPurchaseUpdateDialog from './TourPurchaseUpdateDialog';

const TourPurchaseListStatusUpdate = ({ purchaseList = [], onUpdateComplete }) => {
    const [status, setStatus] = useState();
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if (purchaseList?.length > 0) {
            setStatus(purchaseList[0].purchasePaymentStatusTypeId + 1);
        } else {
            setStatus(null);
        }
    }, [purchaseList]);

    const handleChange = event => {
        setStatus(parseInt(event.target.value));
    };

    const StatusSelect = () => (
        <FormControl>
            <InputLabel>Statü</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                label="Statü"
                margin="dense"
                size="small"
                style={{ backgroundColor: '#fff' }}
                onChange={handleChange}>
                {Object.values(SaleItemPurchaseType).map(item => (
                    <MenuItem key={item.id} value={item.id}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );

    const StatusUpdateButton = () => (
        <Button onClick={() => setOpenDialog(true)} variant="contained" color="secondary">
            Güncelle
        </Button>
    );

    const onUpdateCallback = () => {
        setOpenDialog(false);
        onUpdateComplete();
    };

    if (purchaseList?.length > 0 && status !== null) {
        return (
            <>
                <div>
                    <Trans
                        i18nKey="purchase.updateStatusPaymentCompleted"
                        values={{
                            count: purchaseList.length,
                        }}
                        components={{
                            strong: <span style={{ fontWeight: 'bold', color: '#b30909' }} />,
                            status: <StatusSelect />,
                            button: <StatusUpdateButton />,
                        }}
                    />
                </div>
                <TourPurchaseUpdateDialog
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                    purchaseList={purchaseList}
                    onDoneCallback={onUpdateCallback}
                    purchasePaymentStatusTypeId={status}
                />
            </>
        );
    } else {
        return null;
    }
};

export default TourPurchaseListStatusUpdate;
