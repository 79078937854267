import Panel from '../ui/Panel';
import Button from '@mui/material/Button';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import Image from './Image';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import ImageUploadDialog from './ImageUploadDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextField } from '@mui/material';

const CoverImageSelectionCard = ({
    title,
    subtitle,
    aspect,
    image,
    setImage,
    fileName,
    folderName,
    canBeCropped = true,
    elevation = 1,
}) => {
    const { t } = useTranslation();
    const [openImageUploadDialog, setOpenImageUploadDialog] = useState(false);

    const onOpenImageUploadDialog = () => {
        setOpenImageUploadDialog(true);
    };

    const onImageSelectDone = images => {
        if (images?.length > 0) {
            setImage(images[0]);
        }
    };

    return (
        <>
            <Panel
                title={title}
                subtitle={subtitle}
                elevation={elevation}
                action={
                    <Button color="secondary" onClick={onOpenImageUploadDialog}>
                        {t('button.selectImage')}
                    </Button>
                }>
                {!image && (
                    <Button onClick={onOpenImageUploadDialog} startIcon={<InsertPhotoIcon />}>
                        {t('button.selectImage')}
                    </Button>
                )}

                <div style={{ textAlign: 'center' }}>
                    {image && (
                        <>
                            <Image
                                src={image.path}
                                style={{ width: '100%', height: '100%', maxWidth: 450 }}
                            />

                            <TextField
                                fullWidth
                                margin="dense"
                                variant="outlined"
                                autoComplete="off"
                                label={t('label.description')}
                                value={image.desc}
                                onChange={e => {
                                    setImage({ ...image, desc: e.target.value });
                                }}
                            />
                        </>
                    )}

                    {image && (
                        <div>
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={() => setImage(null)}
                                startIcon={<DeleteIcon />}>
                                {t('button.delete')}
                            </Button>
                        </div>
                    )}
                </div>
            </Panel>

            <ImageUploadDialog
                multiple={false}
                aspect={aspect}
                canBeCropped={canBeCropped}
                open={openImageUploadDialog}
                onUploadDone={onImageSelectDone}
                onClose={() => setOpenImageUploadDialog(false)}
                fileName={fileName}
                folderName={folderName}
            />
        </>
    );
};

export default CoverImageSelectionCard;
