import Grid from '@mui/material/Grid';
import Panel from '../../../ui/Panel';
import Input from '../../../ui/Input';
import Select from '../../../ui/Select';
import ImageGalleryCard from '../../../components/ImageGalleryCard';
import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CheckBox from '../../../ui/CheckBox';
import Editor from '../../../ui/Editor';
import { HotelStarTypes } from '../../../util/Enums';
import MenuItem from '@mui/material/MenuItem';

const HotelEditInputPanel = ({
    hotel,
    name,
    images,
    setImages,
    fileNameParam,
    folderName,
    description,
    setDescription,
}) => {
    const { t } = useTranslation();
    const [fileName, setFileName] = useState(fileNameParam);
    const {
        register,
        watch,
        control,
        formState: { errors },
    } = useFormContext();

    const watchFileName = watch('name');
    const aspect = 720 / 480;

    useEffect(() => {
        setFileName(watchFileName || '');
    }, [watchFileName]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Panel title={t('title.hotelInfo')}>
                    <Input
                        name="name"
                        defaultValue={hotel?.name || name || ''}
                        label={t('label.name')}
                        error={errors?.name}
                        {...register('name', {
                            required: t('validation.required'),
                        })}
                    />
                    <Input
                        name="value"
                        defaultValue={hotel?.value}
                        label={t('label.value')}
                        error={errors?.value}
                        {...register('value', {
                            required: t('validation.required'),
                        })}
                    />
                    <Select
                        name="hotelStarTypeId"
                        label={t('label.hotelStarType')}
                        defaultValue={hotel?.hotelStarTypeId}
                        rules={{ required: t('validation.required') }}
                        error={errors?.hotelStarTypeId}
                        control={control}>
                        {Object.values(HotelStarTypes).map(item => (
                            <MenuItem key={item.id} value={item.id}>
                                {t(item.label)}
                            </MenuItem>
                        ))}
                    </Select>
                    <Input
                        type="number"
                        name="volume"
                        defaultValue={hotel?.volume}
                        label={t('label.searchVolume')}
                        error={errors?.volume}
                        {...register('volume')}
                    />
                    <CheckBox
                        defaultChecked={hotel?.isDefault}
                        label={t('label.default')}
                        {...register('isDefault')}
                    />
                </Panel>
            </Grid>
            <Grid item xs={12}>
                <Editor
                    title={t('label.description')}
                    value={description}
                    onChange={val => {
                        setDescription(val);
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <ImageGalleryCard
                    {...{ aspect, images, setImages, fileName, folderName, isCover: true }}
                />
            </Grid>
        </Grid>
    );
};

export default HotelEditInputPanel;
