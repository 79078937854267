import BaseService from './BaseService';
import { easyPost } from '../util/Fetch';

class TourPriceService extends BaseService {
    constructor() {
        super('/tours/prices');
    }

    async priceGenerate(data) {
        return await easyPost('/tours/prices/generate', data);
    }

    async generateRow(data) {
        return await easyPost('/tours/prices/generaterow', data);
    }
}

const tourPriceService = new TourPriceService();

export default tourPriceService;
