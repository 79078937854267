import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Input from '../../../ui/Input';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const AdditionalServicesChildRuleEditDialog = ({ rule = null, open, onClose, onDoneCallback }) => {
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    useEffect(() => {
        if (open) {
            reset();
        }
    }, [open, rule, reset]);

    const onSubmit = async data => {
        onDoneCallback &&
            onDoneCallback({
                ...data,
                minAge: parseFloat(data.minAge),
                maxAge: parseFloat(data.maxAge),
                price: parseFloat(data.price),
            });
    };

    return (
        <Dialog open={open} fullWidth maxWidth="sm" scroll="paper">
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>{t('title.tourPeriodChildRuleEditDialog')}</DialogTitle>
                <DialogContent dividers={true}>
                    <Input
                        defaultValue={rule?.minAge}
                        label={t('label.minAge')}
                        error={errors?.minAge}
                        {...register('minAge', {
                            required: t('validation.required'),
                        })}
                    />
                    <Input
                        defaultValue={rule?.maxAge}
                        label={t('label.maxAge')}
                        error={errors?.maxAge}
                        {...register('maxAge', {
                            required: t('validation.required'),
                        })}
                    />
                    <Input
                        defaultValue={rule?.price}
                        label={t('label.price')}
                        error={errors?.price}
                        {...register('price', {
                            required: t('validation.required'),
                        })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        {t('button.cancel')}
                    </Button>
                    <Button color="secondary" onClick={handleSubmit(onSubmit)}>
                        {t('button.done')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default AdditionalServicesChildRuleEditDialog;
