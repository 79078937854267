import React, { useEffect, useState } from 'react';
import Page from '../../../layout/Page';
import { useHistory, useParams } from 'react-router-dom';
import regionService from '../../../service/RegionService';
import Container from '@mui/material/Container';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import PageActions from '../../../components/PageActions';
import { FormProvider, useForm } from 'react-hook-form';
import useMessage from '../../../util/useMessage';
import SubmitButton from '../../../ui/SubmitButton';
import { MessageType } from '../../../util/Enums';
import RegionEditInputPanel from './RegionEditInputPanel';

let MOUNT = true;

const RegionEditPage = ({ title, ...props }) => {
    const param = useParams();
    const regionId = param?.id;

    const { t } = useTranslation();
    const history = useHistory();
    const showMessage = useMessage();

    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [flag, setFlag] = useState(null);
    const [images, setImages] = useState([]);
    const [fileNameParam, setFileNameParam] = useState('');
    const [folderName] = useState('region');
    const [region, setRegion] = useState(null);
    const [tourPrograms, setTourPrograms] = useState([]);

    const methods = useForm();

    useEffect(() => {
        if (regionId) {
            (async () => {
                setPageLoading(true);
                try {
                    const { resultObject } = await regionService.get(regionId);
                    initPageValues(resultObject);
                } catch (e) {}
                setPageLoading(false);
            })();
        } else {
            setPageLoading(false);
        }

        return () => {
            MOUNT = false;
        };
    }, []);

    const initPageValues = _region => {
        setRegion(_region);
        setFileNameParam(_region?.name || '');

        if (_region?.images?.length > 0) {
            const flagImage = _region.images.find(item => item.imageTypeId === 3);
            setFlag(flagImage || null);
            const galleryImages = _region.images.filter(item => item.imageTypeId !== 3);
            setImages(galleryImages || []);
        }

        methods.setValue('tourType', _region.tourType);
        methods.setValue('isRegion', _region.isRegion);
        methods.setValue('isCreateStaticPage', _region.isCreateStaticPage);
        setTourPrograms(_region?.tourPrograms || []);
    };

    const onSubmit = async data => {
        if (loading) return false;
        try {
            setLoading(true);
            const compileImages = flag !== null ? [...images, { ...flag, imageTypeId: 3 }] : images;
            const obj = {
                ...data,
                images: compileImages,
                volume: data?.volume ? parseInt(data.volume) : 0,
                springDegree: data?.springDegree ? parseFloat(data.springDegree) : null,
                summerDegree: data?.summerDegree ? parseFloat(data.summerDegree) : null,
                winterDegree: data?.winterDegree ? parseFloat(data.winterDegree) : null,
                autumnDegree: data?.autumnDegree ? parseFloat(data.autumnDegree) : null,
            };

            delete obj['regionType'];

            const { resultObject } = await regionService.saveOrUpdate(regionId, obj);
            showMessage(t(regionId ? 'message.updated' : 'message.created'));
            history.push(`/settings/regions/edit/${resultObject.regionId || resultObject.id}`);
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const ActionPanel = () => (
        <PageActions>
            {/* {(region?.isCreateStaticPage !== undefined && region.isCreateStaticPage === false) && <Button
                variant="outlined"
                color="primary"
                disabled={loading}
                sx={{float: "left"}}
                startIcon={loading ? null : <NoteAddIcon/>}
                onClick={() => {
                    onSubmit({...methods.getValues(), isCreateStaticPage: true})
                }}>
                {t('button.createLink')}
            </Button>} */}
            <Button
                variant="contained"
                disabled={loading}
                startIcon={loading ? null : <KeyboardBackspaceIcon />}
                onClick={() => {
                    history.goBack();
                }}>
                {t('button.back')}
            </Button>

            <SubmitButton loading={loading} startIcon={<SaveIcon />}>
                {t('button.save')}
            </SubmitButton>
        </PageActions>
    );

    return (
        <Page title={title} loading={pageLoading}>
            <Container maxWidth="lg">
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <ActionPanel />
                        <RegionEditInputPanel
                            {...{
                                region,
                                images,
                                setImages,
                                flag,
                                setFlag,
                                fileNameParam,
                                folderName,
                                tourPrograms,
                                regionId,
                            }}
                        />
                        <ActionPanel />
                    </form>
                </FormProvider>
            </Container>
        </Page>
    );
};

export default RegionEditPage;
