import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from './DataTable';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import FaqEditDialog from './FaqEditDialog';

const FaqEditPanel = ({ faq, setFaq }) => {
    const { t } = useTranslation();

    const [selectedIndex, setSelectedIndex] = useState(null);
    const [openFaqEditDialog, setOpenFaqEditDialog] = useState(false);

    const columns = [
        { name: 'id', label: 'ID', width: 50, execute: item => item || t('label.new') },
        { name: 'title', label: t('label.title') },
    ];

    const onEditAction = value => {
        const temp = [...faq];
        temp[selectedIndex] = { ...temp[selectedIndex], ...value };
        setFaq && setFaq(temp);

        setOpenFaqEditDialog(false);
        setSelectedIndex(null);
    };

    const onAddAction = value => {
        setFaq && setFaq([...faq, value]);

        setOpenFaqEditDialog(false);
        setSelectedIndex(null);
    };

    return (
        <>
            <DataTable
                title={t('title.faq')}
                columns={columns}
                data={faq}
                count={faq?.length || 0}
                onEdit={(item, index) => {
                    setSelectedIndex(index);
                    setOpenFaqEditDialog(true);
                }}
                onDelete={(item, index) => {
                    const newList = faq.filter((item, idx) => idx !== index);
                    setFaq(newList);
                }}
                action={
                    <Button
                        color="secondary"
                        onClick={() => {
                            setSelectedIndex(null);
                            setOpenFaqEditDialog(true);
                        }}>
                        <AddIcon />
                        {t('button.add')}
                    </Button>
                }
            />
            <FaqEditDialog
                item={faq[selectedIndex]}
                open={openFaqEditDialog}
                onAddAction={onAddAction}
                onEditAction={onEditAction}
                onClose={() => {
                    setOpenFaqEditDialog(false);
                    setSelectedIndex(null);
                }}
            />
        </>
    );
};

export default FaqEditPanel;
