import Page from '../../../layout/Page';
import { useTranslation } from 'react-i18next';
import usePagination from '../../../util/usePagination';
import paymentService from '../../../service/PaymentService';
import { DataTable } from '../../../components/DataTable';
import Container from '@mui/material/Container';
import ListPageActions from '../../../components/ListPageActions';
import { format } from 'date-fns';
import {
    BankTypes,
    findEnumById,
    PaymentType,
    ProductGroupTypes,
    SalePaymentStatusType,
} from '../../../util/Enums';
import { YesNoTypeOptions } from '../../../components/YesNoTypeOptions';
import MinMaxSearchCellForecast from '../../../components/datatable/MinMaxSearchCellForecast';
import DateFilterCell from '../../../components/datatable/DateFilterCell';
import { useState } from 'react';
import TourPaymentListStatusUpdate from './TourPaymentListStatusUpdate';
import { formatPrice } from '../../../util/CurrencyUtil';

const TourPaymentListPage = ({ title, ...props }) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState([]);

    const SaleStatusOptions = () =>
        Object.values(SalePaymentStatusType).map(item => (
            <option key={item.id} value={item.id}>
                {t(item.label)}
            </option>
        ));

    const PaymentTypeOptions = () =>
        Object.values(PaymentType).map(item => (
            <option key={item.id} value={item.id}>
                {t(item.label)}
            </option>
        ));

    const ProductGroupOptions = () =>
        Object.values(ProductGroupTypes).map(item => (
            <option key={item.id} value={item.id}>
                {t(item.label)}
            </option>
        ));

    const BankOptions = () =>
        Object.values(BankTypes).map(item => (
            <option key={item.id} value={item.id}>
                {t(item.label)}
            </option>
        ));

    const columns = [
        {
            name: 'saleCode',
            label: t('payment.saleCode'),
            searchable: true,
            saleOfferFormId: true
        },
        {
            name: 'salePaymentStatusTypeId',
            label: t('payment.salePaymentStatusTypeId'),
            type: Number,
            searchable: true,
            searchType: 'select',
            selectOptions: <SaleStatusOptions />,
            execute: item => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {findEnumById(SalePaymentStatusType, item)?.label}
                </span>
            ),
        },
        {
            name: 'salePaymentTypeId',
            label: t('payment.salePaymentTypeId'),
            type: Number,
            searchable: true,
            searchType: 'select',
            selectOptions: <PaymentTypeOptions />,
            execute: item => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {findEnumById(PaymentType, item)?.label}
                </span>
            ),
        },
        {
            name: 'bankId',
            label: t('payment.bankName'),
            execute: item => (
                <span style={{ whiteSpace: 'nowrap' }}>{findEnumById(BankTypes, item)?.label}</span>
            ),
            type: Number,
            searchable: true,
            searchType: 'select',
            selectOptions: <BankOptions />,
        },
        {
            name: 'saleDate',
            label: t('payment.saleDate'),
            execute: item => (item ? format(new Date(item), 'dd.MM.yyyy') : ''),
            searchable: true,
            SearchCellComponent: DateFilterCell,
        },
        {
            name: 'nameSurname',
            label: t('payment.nameSurname'),
            execute: item => <span style={{ whiteSpace: 'nowrap' }}>{item}</span>,
            searchable: true,
        },
        {
            name: 'maturityDate',
            label: t('payment.maturityDate'),
            execute: item => (item ? format(new Date(item), 'dd.MM.yyyy') : ''),
            searchable: true,
            SearchCellComponent: DateFilterCell,
        },

        {
            name: 'purchasePrice',
            label: t('payment.purchasePrice'),
            searchable: true,
            type: Number,
            SearchCellComponent: MinMaxSearchCellForecast,
            execute: item => formatPrice(item),
        },
        {
            name: 'salePrice',
            label: t('payment.salePrice'),
            searchable: true,
            type: Number,
            SearchCellComponent: MinMaxSearchCellForecast,
            execute: item => formatPrice(item),
        },
        {
            name: 'profit',
            label: t('payment.profit'),
            searchable: true,
            type: Number,
            SearchCellComponent: MinMaxSearchCellForecast,
        },
        {
            name: 'checkInDate',
            label: t('payment.checkInDate'),
            execute: item => (item ? format(new Date(item), 'dd.MM.yyyy') : ''),
            searchable: true,
            SearchCellComponent: DateFilterCell,
        },
        {
            name: 'checkOutDate',
            label: t('payment.checkOutDate'),
            execute: item => (item ? format(new Date(item), 'dd.MM.yyyy') : ''),
            searchable: true,
            SearchCellComponent: DateFilterCell,
        },

        {
            name: 'isFinanced',
            label: t('payment.IsFinanced'),
            searchable: true,
            searchType: 'select',
            selectOptions: <YesNoTypeOptions />,
            execute: item => (item === true ? 'EVET' : 'HAYIR'),
            type: Boolean,
        },
    ];

    const payments = usePagination({
        service: paymentService,
        defaultOrderBy: { name: 'maturityDate', orderType: 'desc' },
    });

    const onSelectItem = (item, e) => {
        if (e.target.checked) {
            if (selected.length > 0) {
                if (selected[0].salePaymentStatusTypeId !== item.salePaymentStatusTypeId) {
                    alert('Sadece aynı statüde olan kayıtları seçebilirsiniz!');
                    return;
                }
            }
            setSelected && setSelected([...selected, item]);
        } else {
            const items = selected?.filter(i => i.id !== item.id);
            setSelected && setSelected(items);
        }
    };

    const onUpdateComplete = () => {
        setSelected([]);
        payments.search();
    };

    const ActionButtons = () => (
        <ListPageActions
            left={
                <TourPaymentListStatusUpdate
                    paymentList={selected}
                    onUpdateComplete={onUpdateComplete}
                />
            }>
            <b>Statüler:</b>{' '}
            {Object.values(SalePaymentStatusType)
                .map(item => item.label)
                .join(' -> ')}
        </ListPageActions>
    );

    return (
        <Page title={title}>
            <Container maxWidth={false} sx={{ maxWidth: '1865px' }}>
                <ActionButtons />
                <DataTable
                    {...payments}
                    headerNoWrap
                    title={t('label.paymentList')}
                    columns={columns}
                    data={payments.data?.payments?.map(item => ({
                        id: item.salePaymentId,
                        ...item,
                    }))}
                    selected={selected}
                    setSelected={setSelected}
                    onSelectItem={onSelectItem}
                    action={
                        <table className="summary-table" style={{ marginBottom: '24px' }}>
                            <thead>
                                <tr>
                                    <th>Birim</th>
                                    <th>Ort.Sat.</th>
                                    <th>Ort.Al.</th>
                                    <th>Ort.Kom.</th>
                                    <th>Ort.Kâr</th>
                                    <th>Top.Sat.</th>
                                    <th>Top.Al.</th>
                                </tr>
                            </thead>
                            <tbody>
                                {payments.data?.summary &&
                                    Object.keys(payments.data?.summary).map((key, index) => {
                                        const currency = payments.data.summary[key];
                                        if (currency) {
                                            return (
                                                <tr key={index}>
                                                    <td>{key.toUpperCase()}</td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {currency['averageSalePrice']}
                                                    </td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {currency['averagePurchasePrice']}
                                                    </td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {currency['averageCommissionRate']}
                                                    </td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {currency['averageGrossProfit']}
                                                    </td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {currency['totalSalePrice']}
                                                    </td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {currency['totalPurchasePrice']}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                        return null;
                                    })}
                            </tbody>
                        </table>
                    }
                />
            </Container>
        </Page>
    );
};

export default TourPaymentListPage;
