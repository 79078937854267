import React, { useEffect, useState } from 'react';
import { MessageType } from '../../../util/Enums';
import saleService from '../../../service/SaleService';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useMessage from '../../../util/useMessage';
import { StyledTableCell } from '../../../components/DataTable';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { getRoomTitleBySaleItem } from '../../../util/CommonUtil';
import { formatPrice } from '../../../util/CurrencyUtil';
import Select from '../../../ui/Select';
import MenuItem from '@mui/material/MenuItem';

const TourReservationEditExtraPanel = ({
    stepId,
    sale,
    additionals,
    toggle,
    onToggle,
    isExpanded,
}) => {
    const { t } = useTranslation();
    const showMessage = useMessage();
    const {
        control,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm();
    const [loading, setLoading] = useState(false);
    const [roomList, setRoomList] = useState([]);

    const selections = watch();

    useEffect(() => {
        if (sale && additionals?.length > 0) {
            const filtredList =
                sale.saleItems?.length > 0
                    ? sale.saleItems.filter(item => item.productGroupId === 1)
                    : [];
            const _rooms = filtredList.map((room, roomIndex) => ({
                ...room,
                additionalServices: additionals[roomIndex].additionalServices,
            }));
            setRoomList(_rooms);
            reset({
                rooms: _rooms,
            });
        }
    }, [sale, additionals]);

    const onSubmit = data => {
        if (loading) return false;
        const saleAdditionalServices = [];
        data.rooms.forEach(room => {
            const saleItemReferenceGuid = room.saleItemGuid;
            return room.additionalServices.forEach(service => {
                saleAdditionalServices.push({
                    additionalServiceId: service.id,
                    selectedPersonCount: service.selectedPersonCount,
                    saleItemReferenceGuid,
                });
            });
        });

        setLoading(true);
        saleService
            .setAdditional({
                saleOfferFormId: sale.saleId,
                data: {
                    saleAdditionalServices: saleAdditionalServices,
                },
            })
            .then(() => {
                showMessage(t('message.updated'));
                onToggle(stepId);
            })
            .catch(({ code, message }) => {
                showMessage(`${code} - ${message}`, MessageType.ERROR);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const getTotalPrice = (service, roomIndex, serviceIndex) => {
        const selectedPersonCount =
            selections?.rooms?.[roomIndex]?.additionalServices[serviceIndex]?.selectedPersonCount ||
            1;
        const total = service.salePrice * service.exchangeRate * selectedPersonCount;
        return formatPrice(total);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
                <CardHeader
                    title="Ek Hizmet Bilgileri"
                    titleTypographyProps={{ variant: 'subtitle1' }}
                    action={
                        <>
                            {isExpanded(stepId) && (
                                <Button color="secondary" type="submit">
                                    KAYDET VE İLERLE
                                </Button>
                            )}
                            <Button color="secondary" onClick={() => toggle(stepId)}>
                                {isExpanded(stepId) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </Button>
                        </>
                    }
                />
                {isExpanded(stepId) && (
                    <CardContent>
                        {roomList.length > 0 &&
                            additionals.length > 0 &&
                            roomList.map((room, roomIndex) => (
                                <Card key={roomIndex} sx={{ marginBottom: '10px' }}>
                                    <CardHeader
                                        title={getRoomTitleBySaleItem(roomIndex, room)}
                                        titleTypographyProps={{ variant: 'subtitle2' }}
                                        sx={{ paddingBottom: '0', marginBottom: '-10px' }}
                                    />
                                    <CardContent>
                                        <Grid container spacing={1}>
                                            <TableContainer sx={{ marginTop: '20px' }}>
                                                <Table size="small" className="data-table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell>
                                                                Ekstra Hizmet
                                                            </StyledTableCell>
                                                            <StyledTableCell>Fiyat</StyledTableCell>
                                                            <StyledTableCell sx={{ with: '100px' }}>
                                                                Kişi Sayısı
                                                            </StyledTableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {room.additionalServices?.map(
                                                            (service, serviceIndex) => {
                                                                return (
                                                                    <TableRow key={serviceIndex}>
                                                                        <TableCell>
                                                                            {service.name}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            +{' '}
                                                                            {getTotalPrice(
                                                                                service,
                                                                                roomIndex,
                                                                                serviceIndex,
                                                                            )}{' '}
                                                                            TL
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Select
                                                                                name={`rooms.${roomIndex}.additionalServices.${serviceIndex}.selectedPersonCount`}
                                                                                error={
                                                                                    errors?.rooms?.[
                                                                                        roomIndex
                                                                                    ]
                                                                                        ?.additionalServices[
                                                                                        serviceIndex
                                                                                    ]
                                                                                        ?.selectedPersonCount
                                                                                }
                                                                                disabled={
                                                                                    service.termOfPurchase ===
                                                                                    1
                                                                                }
                                                                                control={control}>
                                                                                {[
                                                                                    ...Array(
                                                                                        service.maxPersonCount +
                                                                                            1,
                                                                                    ).keys(),
                                                                                ].map(key => {
                                                                                    return (
                                                                                        <MenuItem
                                                                                            key={
                                                                                                key
                                                                                            }
                                                                                            value={
                                                                                                key
                                                                                            }>
                                                                                            {key ===
                                                                                            0
                                                                                                ? 'Boş Bırak'
                                                                                                : `${key} Kişi`}
                                                                                        </MenuItem>
                                                                                    );
                                                                                })}
                                                                            </Select>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                );
                                                            },
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            ))}
                    </CardContent>
                )}
                {isExpanded(stepId) && (
                    <CardActions>
                        <Button color="secondary" sx={{ marginLeft: 'auto' }} type="submit">
                            KAYDET VE İLERLE
                        </Button>
                    </CardActions>
                )}
            </Card>
        </form>
    );
};

export default TourReservationEditExtraPanel;
