import Page from '../../../layout/Page';

import { useTranslation } from 'react-i18next';
import usePagination from '../../../util/usePagination';
import exchangeRateService from '../../../service/ExchangeRateService';
import { DataTable } from '../../../components/DataTable';
import Container from '@mui/material/Container';
import ListPageActions from '../../../components/ListPageActions';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import ExchangeRateDeleteDialog from './ExchangeRateDeleteDialog';
import { getDeletedColumn } from '../../../components/YesNoTypeOptions';
import { findEnumById, ExchangeRatesTypes } from '../../../util/Enums';

const ExchangeRateListPage = ({ title, ...props }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [selected, setSelected] = useState(null);
    const [openExchangeRateDeleteDialog, setOpenExchangeRateDeleteDialog] = useState(false);

    const columns = [
        {
            name: 'id',
            label: 'ID',
            width: 50,
            searchable: true,
            type: Number,
        },
        {
            name: 'name',
            label: t('label.name'),
            searchable: true,
        },
        {
            name: 'amountAddedTypeOf',
            label: t('label.unit'),
            execute: item => t(findEnumById(ExchangeRatesTypes, Number(item))?.label),
        },
        {
            name: 'amountAdded',
            label: t('label.ratio'),
            searchable: true,
            type: Number,
        },
        { ...getDeletedColumn(t) },
    ];

    const exchangeRates = usePagination({
        service: exchangeRateService,
    });

    const ActionButtons = () => (
        <ListPageActions>
            <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={() => {
                    history.push(`/settings/exchangeRates/create`);
                }}>
                {t('button.addNew')}
            </Button>
        </ListPageActions>
    );

    return (
        <Page title={title}>
            <Container maxWidth="xl">
                <ActionButtons />
                <DataTable
                    title={t('label.exchangeRateList')}
                    columns={columns}
                    {...exchangeRates}
                    onEdit={item => {
                        history.push(`/settings/exchangeRates/edit/${item.id}`);
                    }}
                    onDelete={item => {
                        setSelected(item);
                        setOpenExchangeRateDeleteDialog(true);
                    }}
                />
                <ActionButtons />
            </Container>

            <ExchangeRateDeleteDialog
                exchangeRate={selected}
                open={openExchangeRateDeleteDialog}
                onClose={() => {
                    setOpenExchangeRateDeleteDialog(false);
                    setSelected(null);
                }}
                onDoneCallback={exchangeRates.search}
            />
        </Page>
    );
};

export default ExchangeRateListPage;
