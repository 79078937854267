import BaseService from './BaseService';
import { easyPost } from '../util/Fetch';

class PurchaseService extends BaseService {
    constructor() {
        super('/finance/purchases');
    }

    async updateList(data) {
        return await easyPost(`${this.path}/update`, data);
    }
}

const purchaseService = new PurchaseService();

export default purchaseService;
