import Page from '../layout/Page';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
    const { t } = useTranslation();

    return <Page title={t('menu.dashboard')}>Dashboard</Page>;
};

export default Dashboard;
