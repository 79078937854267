import { useEffect, useState } from 'react';
import Page from '../../../layout/Page';
import { useHistory, useParams } from 'react-router-dom';
import specialDayService from '../../../service/SpecialDayService';
import Editor from '../../../ui/Editor';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Panel from '../../../ui/Panel';
import Input from '../../../ui/Input';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import PageActions from '../../../components/PageActions';
import { useForm } from 'react-hook-form';
import { DescType, MessageType, SpecialDayType, YesNoType } from '../../../util/Enums';
import useMessage from '../../../util/useMessage';
import SubmitButton from '../../../ui/SubmitButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '../../../ui/Select';
import RegionInput from '../../../ui/RegionInput';
import DateInput from '../../../ui/DateInput';
import { formatDate } from '../../../util/DateUtil';

let MOUNT = true;

const SpecialDayEditPage = ({ title, ...props }) => {
    const param = useParams();
    const specialDayId = param?.id;

    const { t } = useTranslation();
    const history = useHistory();
    const showMessage = useMessage();

    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [specialDay, setSpecialDay] = useState(null);
    const [descriptions, setDescriptions] = useState({});

    const {
        register,
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm();

    const watchRepeated = watch('repeated');

    useEffect(() => {
        if (specialDayId) {
            (async () => {
                setPageLoading(true);
                try {
                    const { resultObject } = await specialDayService.get(specialDayId);
                    initPageValues(resultObject);
                } catch (e) {}
                setPageLoading(false);
            })();
        } else {
            setPageLoading(false);
        }

        return () => {
            MOUNT = false;
        };
    }, [specialDayId]);

    const initPageValues = _specialDay => {
        setSpecialDay(_specialDay);
        const desc = {};
        Object.keys(DescType).forEach(item => (desc[item] = _specialDay[item]));
        setDescriptions(desc);
    };

    const onSubmit = async data => {
        if (loading) return false;

        try {
            setLoading(true);
            const obj = {
                ...data,
                ...descriptions,
                repeated: Boolean(data.repeated),
                updated: Boolean(data.updated),
                volume: Number(data.volume) || 0,
            };
            const { resultObject } = await specialDayService.saveOrUpdate(specialDayId, obj);
            showMessage(t(specialDayId ? 'message.updated' : 'message.created'));

            history.push(
                `/settings/specialDays/edit/${resultObject.specialDayId || resultObject.id}`,
            );
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const ActionPanel = () => (
        <PageActions>
            <Button
                variant="contained"
                disabled={loading}
                startIcon={loading ? null : <KeyboardBackspaceIcon />}
                onClick={() => {
                    history.goBack();
                }}>
                {t('button.back')}
            </Button>

            <SubmitButton loading={loading} startIcon={<SaveIcon />}>
                {t('button.save')}
            </SubmitButton>
        </PageActions>
    );

    return (
        <Page title={title} loading={pageLoading}>
            <Container maxWidth="xl">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ActionPanel />
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Panel title={t('title.specialDayInfos')}>
                                <Select
                                    name="dayType"
                                    rules={{ required: t('validation.required') }}
                                    error={errors?.dayType}
                                    label={t('label.dayType')}
                                    defaultValue={specialDay?.dayType}
                                    control={control}>
                                    {Object.values(SpecialDayType).map(item => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {t(item.label)}
                                        </MenuItem>
                                    ))}
                                </Select>

                                <RegionInput
                                    name="regions"
                                    label={t('label.regions')}
                                    control={control}
                                    error={errors?.regions}
                                    defaultValue={specialDay?.regions}
                                    rules={{
                                        required: t('validation.required'),
                                    }}
                                />

                                <Input
                                    defaultValue={specialDay?.title}
                                    label={t('label.title')}
                                    error={errors?.title}
                                    {...register('title', {
                                        required: t('validation.required'),
                                    })}
                                />
                                <DateInput
                                    name="startDate"
                                    label={t('label.startDate')}
                                    defaultValue={specialDay?.startDate || null}
                                    error={errors?.startDate}
                                    rules={{ required: t('validation.required') }}
                                    control={control}
                                />
                                <DateInput
                                    name="endDate"
                                    label={t('label.endDate')}
                                    defaultValue={specialDay?.endDate || null}
                                    error={errors?.endDate}
                                    rules={{ required: t('validation.required') }}
                                    control={control}
                                />

                                <Input
                                    defaultValue={specialDay?.volume}
                                    label={t('label.searchVolume')}
                                    error={errors?.volume}
                                    {...register('volume', {
                                        required: t('validation.required'),
                                    })}
                                />

                                <Select
                                    name="repeated"
                                    rules={{ required: t('validation.required') }}
                                    error={errors?.repeated}
                                    label={t('label.repeated')}
                                    defaultValue={Number(specialDay?.repeated) || 0}
                                    control={control}>
                                    {Object.values(YesNoType).map(item => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {t(item.label)}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {(watchRepeated === undefined || watchRepeated === 0) && (
                                    <Select
                                        name="updated"
                                        rules={{ required: t('validation.required') }}
                                        error={errors?.updated}
                                        label={t('label.updated')}
                                        defaultValue={Number(specialDay?.updated) || 0}
                                        control={control}>
                                        {Object.values(YesNoType).map(item => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {t(item.label)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            </Panel>
                        </Grid>

                        <Grid item xs={12}>
                            <Editor
                                title={t('label.microDesc')}
                                value={descriptions[DescType.microDesc]}
                                onChange={val => {
                                    setDescriptions({ ...descriptions, microDesc: val });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Editor
                                title={t('label.shortDesc')}
                                value={descriptions[DescType.shortDesc]}
                                onChange={val => {
                                    setDescriptions({ ...descriptions, shortDesc: val });
                                }}
                            />
                        </Grid>
                    </Grid>
                    <ActionPanel />
                </form>
            </Container>
        </Page>
    );
};

export default SpecialDayEditPage;
