const findEnumById = (type, id) => {
    return Object.values(type).find(item => item.id === id);
};

const DescType = {
    shortDesc: 'shortDesc',
    microDesc: 'microDesc',
    description: 'description',
    holidayPlanDesc: 'holidayPlanDesc',
    tourPackageDesc: 'tourPackageDesc',
    headerDesc: 'headerDesc',
    footerDesc: 'footerDesc',
};

const RegionType = {
    FIRST: { id: 'firstId', label: 'label.firstLevel', level: 1 },
    CONTINENT: { id: 'continentId', label: 'label.continent', level: 2 },
    COUNTRY: { id: 'countryId', label: 'label.country', level: 3 },
    STATE: { id: 'stateId', label: 'label.state', level: 4 },
    CITY: { id: 'cityId', label: 'label.city', level: 5 },
};

const TourType = {
    ABROAD: { id: 1, label: 'label.abroad' },
    CULTURE: { id: 2, label: 'label.culture' },
    SHIP: { id: 3, label: 'label.ship' },
    // DOMESTIC: {id: 2, label: 'label.domestic'}
};

const MessageType = {
    INFO: 'info',
    ERROR: 'error',
    WARN: 'warn',
};

const TagType = {
    PLACE: { id: 1, label: 'label.place' },
    ARTICLE: { id: 2, label: 'label.article' },
    FOOD: { id: 3, label: 'label.food' },
    LOCATION: { id: 4, label: 'label.location' },
};

const DynamicPageType = {
    201: {
        id: 201,
        label: 'pageType.regionPlaceList',
    },
    301: {
        id: 301,
        label: 'pageType.regionFoodList',
    },
    401: {
        id: 401,
        label: 'pageType.guideBook',
    },
    402: {
        id: 402,
        label: 'pageType.guideBookList',
    },
    601: {
        id: 601,
        label: 'pageType.regionTourCatalogList',
    },
    602: {
        id: 602,
        label: 'pageType.categoryTourCatalogList',
    },
    603: {
        id: 603,
        label: 'pageType.combinationTourCatalogList',
    },
};

const CategoryType = {
    DEPARTURE: {
        id: 2,
        label: 'label.departure',
        name: 'departure',
        multiple: false,
        required: true,
        programMultiple: false,
    },
    NIGHTS: {
        id: 5,
        label: 'label.night',
        name: 'night',
        number: true,
        multiple: true,
        required: false,
        programMultiple: false,
        programDisabled: true
    },
    TRANSPORTATION: {
        id: 6,
        label: 'label.transportation',
        name: 'transportation',
        multiple: true,
        required: false,
        programMultiple: true,
    },
    VISA: {
        id: 7,
        label: 'label.visa',
        name: 'visa',
        multiple: false,
        required: false,
        filter: true,
    },
    SPECIAL_PERIOD: {
        id: 1,
        label: 'label.specialPeriod',
        name: 'specialPeriod',
        multiple: true,
        required: false,
        filter: true,
        programMultiple: true,
    },
    MONTH: {
        id: 9,
        label: 'label.month',
        name: 'month',
        number: true,
        multiple: true,
        required: false,
        programMultiple: true,
    },
    YEAR: {
        id: 8,
        label: 'label.year',
        name: 'year',
        number: true,
        multiple: true,
        required: false,
        programMultiple: true,
    },
    CRUISE: {
        id: 3,
        label: 'label.cruise',
        name: 'cruise',
        multiple: true,
        required: false,
        filter: true,
    },
    ACTIVITY: {
        id: 4,
        label: 'label.activity',
        name: 'activity',
        multiple: true,
        required: false,
        filter: true,
    },
    OTHER: {
        id: 10,
        label: 'label.other',
        name: 'other',
        multiple: true,
        required: false,
        filter: true,
        programMultiple: true,
    },
};

const SpecialDayType = {
    FESTIVAL: { id: 1, label: 'label.festival' },
    PUBLIC_HOLIDAY: { id: 2, label: 'label.publicHoliday' },
    PUBLIC_HOLIDAY_IN_TURKEY: { id: 3, label: 'label.publicHolidayInTurkey' },
};

const YesNoType = {
    YES: { id: 1, label: 'label.yes' },
    NO: { id: 0, label: 'label.no' },
};

const PostType = {
    PLACE: { id: 1, label: 'label.place' },
    ARTICLE: { id: 2, label: 'label.article' },
};

const ArticleType = {
    NORMAL: { id: 1, label: 'articleType.normal' },
    WHEN_GO: { id: 2, label: 'articleType.whenToGo', tourContent: true },
    ABOUT: { id: 3, label: 'articleType.about', tourContent: true },
    GIFT: { id: 4, label: 'articleType.gift', tourContent: true },
    VISA: { id: 5, label: 'articleType.visa', tourContent: true },
    ADDITIONAL: { id: 6, label: 'articleType.additional', tourContent: true },
};

const GenerateContentType = [
    { id: 1, label: 'GenerateContentType.whenToGo' },
    { id: 2, label: 'GenerateContentType.about' },
    { id: 3, label: 'GenerateContentType.gift' },
    { id: 4, label: 'GenerateContentType.visa' },
    { id: 5, label: 'GenerateContentType.placesToVisit' },
    { id: 6, label: 'GenerateContentType.whatToEat' },
];

const WordPositionType = {
    PREFIX: { id: 1, label: 'wordPositionType.prefix' },
    SUFFIX: { id: 2, label: 'wordPositionType.suffix' },
};

const SubContentTypes = [
    {
        id: 9,
        articleTypes: [ArticleType.ABOUT, ArticleType.WHEN_GO],
        label: 'subContentType.introduction',
    },
    { id: 1, articleTypes: [ArticleType.WHEN_GO], label: 'subContentType.whichMonthToGo' },
    { id: 2, articleTypes: [ArticleType.WHEN_GO], label: 'subContentType.weather' },
    { id: 3, articleTypes: [ArticleType.WHEN_GO], label: 'subContentType.festival' },
    { id: 4, articleTypes: [ArticleType.WHEN_GO], label: 'subContentType.holiday' },
    { id: 5, articleTypes: [ArticleType.ABOUT], label: 'subContentType.history' },
    { id: 6, articleTypes: [ArticleType.ABOUT], label: 'subContentType.firstToComeToMind' },
    { id: 7, articleTypes: [ArticleType.ABOUT], label: 'subContentType.advices' },
    {
        id: 8,
        articleTypes: [ArticleType.ABOUT],
        label: 'subContentType.thingsToTakeWithoutGoTheTrip',
    },
    {
        id: 10,
        articleTypes: [ArticleType.ABOUT, ArticleType.WHEN_GO],
        label: 'subContentType.moreContent',
    },
];

const IntroSubContentType = {
    id: 9,
    label: 'subContentType.introduction',
};

const PageType = {
    101: {
        id: '101',
        group: '100',
        label: 'pageType.placePost',
        autogen: false,
        region: true,
    },
    102: {
        id: '102',
        group: '100',
        label: 'pageType.articlePost',
        autogen: false,
        region: true,
    },
    103: {
        id: '103',
        group: '100',
        label: 'pageType.whenGoPost',
        autogen: false,
        region: true,
    },
    104: {
        id: '104',
        group: '100',
        label: 'pageType.aboutPost',
        autogen: false,
        region: true,
    },
    105: {
        id: '105',
        group: '100',
        label: 'pageType.giftPost',
        autogen: false,
        region: true,
    },
    106: {
        id: '106',
        group: '100',
        label: 'pageType.visa',
        autogen: false,
        region: true,
    },
    109: {
        id: '109',
        group: '100',
        label: 'pageType.additional',
        autogen: false,
        region: true,
    },
    201: {
        id: '201',
        group: '200',
        label: 'pageType.regionPlaceList',
        autogen: true,
        region: true,
        descriptions: ['headerDesc', 'footerDesc', 'microDesc', 'shortDesc'],
    },
    202: {
        id: '202',
        group: '200',
        label: 'pageType.tagPlaceList',
        autogen: true,
        tag: true,
        descriptions: ['headerDesc', 'footerDesc', 'microDesc', 'shortDesc'],
    },
    203: {
        id: '203',
        group: '200',
        label: 'pageType.combinationPlaceList',
        autogen: true,
        region: true,
        tag: true,
        descriptions: ['headerDesc', 'footerDesc', 'microDesc', 'shortDesc'],
    },

    301: {
        id: '301',
        group: '300',
        label: 'pageType.regionFoodList',
        autogen: true,
        region: true,
        descriptions: ['headerDesc', 'footerDesc', 'microDesc', 'shortDesc'],
    },
    302: {
        id: '302',
        group: '300',
        label: 'pageType.combinationFoodList',
        autogen: true,
        region: true,
        tag: true,
        descriptions: ['headerDesc', 'footerDesc', 'microDesc', 'shortDesc'],
    },

    401: {
        id: '401',
        group: '400',
        label: 'pageType.guideBook',
        autogen: true,
        region: true,
        descriptions: ['description', 'microDesc', 'shortDesc'],
    },
    402: {
        id: '402',
        group: '400',
        label: 'pageType.guideBookList',
        autogen: true,
        region: true,
        descriptions: ['headerDesc', 'footerDesc', 'microDesc', 'shortDesc'],
    },

    501: {
        id: '501',
        group: '500',
        label: 'pageType.tourCatalog',
        autogen: false,
        region: true,
    },

    601: {
        id: '601',
        group: '600',
        label: 'pageType.regionTourCatalogList',
        autogen: true,
        region: true,
        descriptions: ['description'],
        // descriptions: ["headerDesc", "footerDesc", "tourPackageDesc", "holidayPlanDesc", "microDesc", "shortDesc"]
    },
    602: {
        id: '602',
        group: '600',
        label: 'pageType.categoryTourCatalogList',
        autogen: true,
        category: true,
        descriptions: ['description'],
        // descriptions: ["headerDesc", "footerDesc", "tourPackageDesc", "holidayPlanDesc", "microDesc", "shortDesc"]
    },
    603: {
        id: '603',
        group: '600',
        label: 'pageType.combinationTourCatalogList',
        autogen: true,
        region: true,
        category: true,
        descriptions: ['description'],
        // descriptions: ["headerDesc", "footerDesc", "tourPackageDesc", "holidayPlanDesc", "microDesc", "shortDesc"]
    },
    702: {
        id: '702',
        group: '700',
        label: 'pageType.staticPage',
        descriptions: ['description'],
        autogen: false,
        displayFooter: true,
        excludeFaq: true,
    },
};

const PageStatus = {
    AUTOGEN: { id: 1, label: 'pageStatus.autogen' },
    EDITED: { id: 2, label: 'pageStatus.edited' },
};

const PaymentTermTypes = [
    { id: 1, label: 'paymentTermType.NDaysAfterSale' },
    { id: 2, label: 'paymentTermType.NDaysBeforeDepartureDate' },
    { id: 3, label: 'paymentTermType.NDaysAfterDepartureDate' },
];

const PaymentTermDateTypes = [
    { id: 1, label: 'paymentTermDateTypes.salesDate', after: true },
    { id: 2, label: 'paymentTermDateTypes.checkinDate', before: true, after: true },
    { id: 3, label: 'paymentTermDateTypes.checkoutDate', before: true, after: true },
];

const PaymentTermRuleTypes = [
    { id: 1, label: 'paymentTermRuleTypes.before' },
    { id: 2, label: 'paymentTermRuleTypes.after' },
];

const PrivilegeTypes = [
    { id: 1, label: 'privilegeType.menu' },
    { id: 2, label: 'privilegeType.page' },
    { id: 3, label: 'privilegeType.post' },
    { id: 4, label: 'privilegeType.tour' },
    { id: 5, label: 'privilegeType.api' },
];

const GenerateCombinationTypes = [
    { id: 1, label: 'generateCombinationTypes.abroad' },
    { id: 2, label: 'generateCombinationTypes.domestic' },
    { id: 3, label: 'generateCombinationTypes.bothOfThem' },
];

const TermsOfPurchaseTypes = {
    REQUIRED: { id: 1, label: 'termsOfPurchaseTypes.required' },
    OPTIONAL: { id: 0, label: 'termsOfPurchaseTypes.optional' },
};

const ExchangeRatesTypes = {
    QUANTITY: { id: 1, label: 'exchangeRatesTypes.quantity' },
    PERCENT: { id: 2, label: 'exchangeRatesTypes.percent' },
};

const TermsOfParticipationTypes = {
    NUMBER_OF_CONTACT: { id: 1, label: 'TermsOfParticipationTypes.pricePerPerson' },
    NUMBER_OF_ADULT: { id: 0, label: 'TermsOfParticipationTypes.numberOfAdult' },
};

export const ParameterTypes = {
    TRANSPORTATION: { id: 1, label: 'parameterTypes.transportation' },
    ACCOMMODATION: { id: 2, label: 'parameterTypes.accommodation' },
    DEPARTURE_PORT: { id: 3, label: 'parameterTypes.departurePort' },
};

export const AccommodationTypes = {
    AIR: { id: 1, label: 'Uçak' },
    BUS: { id: 2, label: 'Otobüs' },
    HOTEL: { id: 3, label: 'Otel' },
    BOAT: { id: 4, label: 'Gemi' },
    SMALLBOAT: { id: 5, label: 'Tekne' },
    FERRY: { id: 6, label: 'Feribot' },
    TENT: { id: 7, label: 'Bedevi Çadırı' },
    GERCAMP: { id: 8, label: 'Ger Çadır Kampı' },
    TENTCAMP: { id: 9, label: 'Çadır Kampı' },
};

export const Months = {
    JANUARY: { id: 1, label: 'Months.january' },
    FEBRUARY: { id: 2, label: 'Months.february' },
    MARCH: { id: 3, label: 'Months.march' },
    APRIL: { id: 4, label: 'Months.april' },
    MAY: { id: 5, label: 'Months.may' },
    JUNE: { id: 6, label: 'Months.june' },
    JULY: { id: 7, label: 'Months.july' },
    AUGUST: { id: 8, label: 'Months.august' },
    SEPTEMBER: { id: 9, label: 'Months.september' },
    OCTOBER: { id: 10, label: 'Months.october' },
    NOVEMBER: { id: 11, label: 'Months.november' },
    DECEMBER: { id: 12, label: 'Months.december' },
};

export const CommentPlaceTypes = {
    TRIPADVISOR: { id: 1, label: 'Tripadvisor' },
    GOOGLE: { id: 2, label: 'Google' },
    TRUSTPILOT: { id: 3, label: 'Trustpilot' },
    OTHER: { id: 4, label: 'Other' },
};

export const HotelStarTypes = {
    THREE: { id: 1, label: 'label.threeStar' },
    FOUR: { id: 2, label: 'label.fourStar' },
    FIVE: { id: 3, label: 'label.fiveStar' },
    THREEFOUR: { id: 4, label: 'label.threeFourStar' },
    FOURFIVE: { id: 5, label: 'label.fourFiveStar' },
};

export const ReservationAvailableStatusTypes = {
    SENTTOVENDOR: { id: 1, label: 'reservation.offerCreated' },
    AVAILABILITYEXPECTED: { id: 2, label: 'reservation.awaitingAvailability' },
    DENIED: { id: 4, label: 'reservation.denied' },
    COMPLETED: { id: 3, label: 'reservation.finish' },
    SENDTOCUSTOMER: { id: 5, label: 'reservation.sentToCustomer' },
    ENQUIRYFORM: { id: 6, label: 'reservation.enquiryForm' },
};

export const FormTypePlatformTypes = {
    WHATSAPP: { id: 1, label: 'Whatsapp' },
    CHAT: { id: 2, label: 'Chat' },
    PHONE: { id: 3, label: 'Phone' },
    EMAIL: { id: 4, label: 'E-Mail' },
    INSTAGRAM: { id: 5, label: 'Sosyal Medya Form' },
    FACEBOOK: { id: 6, label: 'Sosyal Medya' },
    WEBSITE: { id: 7, label: 'Website' },
    LINKEDIN: { id: 8, label: 'Linkedin' },
};

export const TitleTypes = {
    MR: { id: 1, label: 'Erkek' },
    MRS: { id: 2, label: 'Kadın' },
};

export const AvailableTypes = {
    WAITING: { id: 1, label: 'operation.waiting' },
    APPROVED: { id: 2, label: 'operation.approved' },
    DENIED: { id: 3, label: 'operation.denied' },
    AUTO: { id: 4, label: 'operation.autoConfirmed' },
    AUTODENIED: { id: 5, label: 'operation.autoDenied' },
};

export const DislikeTypes = {
    HIGH: { id: 1, label: 'I found the price to be too high.' },
    ROUTE: { id: 2, label: "I didn't like the route of the yacht." },
    CABIN: { id: 3, label: 'Cabins and number of people are insufficient.' },
    BETTER: { id: 4, label: 'I want a better yacht.' },
};

export const FieldInputs = {
    adultCount: { key: 'adultCount', title: 'Yetişkin', description: '12+ Yaş', defaultValue: 2 },
    childCount: { key: 'childCount', title: 'Çocuk', description: '2-11 Yaş', defaultValue: 0 },
    infantCount: { key: 'infantCount', title: 'Bebek', description: '0-2 Yaş', defaultValue: 0 },
};

export const PassportType = {
    GREEN: { id: 1, label: 'Yeşil' },
    MAROON: { id: 2, label: 'Bordo' },
    GRAY: { id: 3, label: 'Gri' },
    DIPLOMATIC: { id: 4, label: 'Diplomatik' },
    FOREIGN: { id: 5, label: 'Yabancı' },
    RESIDENCE: { id: 6, label: 'Oturum Kartı' },
};

export const SaleTypes = {
    OFFER: { id: 1, label: 'Teklif' },
    PAYMENT: { id: 2, label: 'Ödeme' },
    RESERVATION: { id: 3, label: 'Rezervasyon' },
    CANCEL: { id: 4, label: 'İptal' },
    NOTCONFIRM: { id: 5, label: 'Not Konfirme' },
};
export const SaleStatusTypes = {
    OfferReceived: { id: 1, label: 'Talep Alındı', saleTypeId: 1 },
    OfferCreated: { id: 2, label: 'Görüşme', saleTypeId: 1 },
    SentToCustomer: { id: 3, label: 'Talep İptal', saleTypeId: 1 },
    PaymentPending: { id: 4, label: 'Ödeme Bekleniyor', saleTypeId: 2 },
    PartialPayment: { id: 5, label: 'Kısmi Ödeme', saleTypeId: 2 },
    PaymentCompleted: { id: 6, label: 'Ödeme Tamamlandı', saleTypeId: 3 },
    ToBeSentToVendor: { id: 7, label: 'Tedarikçiye Gönderilecek', saleTypeId: 3 },
    SentToVendor: { id: 8, label: 'Tedarikçiye Gönderildi', saleTypeId: 3 },
    Confirmation: { id: 10, label: 'Konfirme', saleTypeId: 3 },
    CustomerRequest: { id: 12, label: 'Müşteri Talebi', saleTypeId: 4 },
    SupplierRequest: { id: 13, label: 'Tedarikçi Talebi', saleTypeId: 4 },
    Return: { id: 14, label: 'İade', saleTypeId: 4 },
    ReturnConfirm: { id: 15, label: 'Konfirme', saleTypeId: 4 },
    NoteConfirmation: { id: 16, label: 'Not Konfirme Değişiklik Yapıldı', saleTypeId: 5 },
    NoPlaceInAgency: { id: 17, label: 'Acentede Yer Yok', saleTypeId: 5 },
    TourStop: { id: 18, label: 'Tur Kapalı', saleTypeId: 5 },
    NoTriple: { id: 19, label: 'Triple Oda Kabul Etmiyor', saleTypeId: 5 },
    Other: { id: 20, label: 'Diğer', saleTypeId: 5 },
};

export const SaleItemPurchaseType = {
    PaymentPending: { id: 1, label: 'Tahsilat Bekleniyor' },
    BuyForeignCurrency: { id: 2, label: 'Döviz Alınacak' },
    CurrencyReady: { id: 3, label: 'Döviz Hazır' },
    PaymentsWillBeMade: { id: 4, label: 'Ödeme Yapılacak' },
    PaymentMade: { id: 5, label: 'Ödeme Yapıldı' },
};

export const SalePaymentStatusType = {
    Waiting: { id: 1, label: 'Bekleniyor' },
    Completed: { id: 2, label: 'Tamamlandı' },
    Refund: { id: 3, label: 'İade' },
    RefundDone: { id: 4, label: 'İade Yapıldı' },
};

export const PaymentType = {
    Pos: { id: 1, label: 'Kredi Kartı' },
    Remittance: { id: 2, label: 'Havale' },
    Cash: { id: 3, label: 'Nakit' },
};

export const CurrencyTypes = {
    TRY: { id: 1, label: 'TRY' },
    USD: { id: 2, label: 'USD' },
    EUR: { id: 3, label: 'EUR' },
    GBP: { id: 4, label: 'GBP' },
};
export const GuestTypes = {
    ADULT: { id: 1, label: 'Yetişkin' },
    CHILD: { id: 2, label: 'Çocuk' },
    INFANT: { id: 3, label: 'Bebek' },
};
export const GenderTypes = {
    MALE: { id: 1, label: 'Erkek' },
    FEMALE: { id: 2, label: 'Kadın' },
};
export const ReferanceTypes = {
    POST: { id: 1, label: 'Yazı' },
    TOURCATALOG: { id: 2, label: 'Tur Katalog' },
    REGION: { id: 3, label: 'Lokasyon' },
    TOURPROGRAM: { id: 4, label: 'Tur Program' },
    TAGICONIMAGE: { id: 5, label: 'Etiket İcon Fotograf' },
    FOOD: { id: 6, label: 'Yemek' },
    EXTRATOUR: { id: 7, label: 'Ekstra Tur' },
    ADDITIONALSERVICE: { id: 8, label: 'Ek Hizmet Servisi' },
    HOTEL: { id: 9, label: 'Otel' },
    COMMENT: { id: 10, label: 'Yorum' },
    HOMEPAGEPOPULARTOUR: { id: 11, label: 'Ana Sayfa Popüler Tur' },
    PAGE: { id: 12, label: 'Sayfa' },
};

export const ProductGroupTypes = {
    TOUR: { id: 1, label: 'Tur', overrideLabel: 'Oda' },
    EXTRATOUR: { id: 2, label: 'Ekstra Tur' },
    CRUISE: { id: 3, label: 'Cruise' },
    CULTURE: { id: 4, label: 'Kültür' },
    VISA: { id: 5, label: 'Vize', additional: true },
    INSURANCE: { id: 6, label: 'Sigorta', additional: true },
};

export const BankTypes = {
    GarantiBankasi: { id: 1, label: 'Garanti' },
    ZiraatBankasi: { id: 2, label: 'Ziraat' },
    Denizbank: { id: 3, label: 'Denizbank' },
    IsBankasi: { id: 4, label: 'İş Bankası' },
    Akbank: { id: 5, label: 'Akbank' },
    YapiKredi: { id: 6, label: 'Yapı Kredi' },
    Halkbank: { id: 7, label: 'Halkbank' },
    IngBank: { id: 8, label: 'IngBank' },
    Finansbank: { id: 9, label: 'Finansbank' },
    Vakifbank: { id: 10, label: 'Vakifbank' },
};

export const ChangePropertyType = {
    Purchase: 1,
    Sale: 2,
    Commission: 3,
    DiscountRate: 4,
    DiscountAmount: 5,
};

export const TourReservationEditPageWizard = {
    RequestPanel: 1,
    ToursPanel: 2,
    ExtraPanel: 3,
    PaymentPanel: 4,
    PurchasePanel: 5,
    InvoicePanel: 6,
    PageInfoPanel: 7,
};

export const SaleCampaignsType = {
    PRODUCTGROUP: {
        id: 2,
        label: 'label.productGroup',
        name: 'productGroup',
    },
    PRODUCT: {
        id: 1,
        label: 'label.product',
        name: 'product',
    },
    DESTINATION: {
        id: 4,
        label: 'label.destination',
        name: 'destination',
    },
    ACCOMMODATION: {
        id: 3,
        label: 'label.accommodation',
        name: 'accommodation',
    },
};

export const DiscountTypes = {
    SALE: { id: 1, label: 'Satış Fiyatına İndirim' },
    PURCHASE: { id: 2, label: 'Alış Fiyatına İndirim' },
    ADDITIONAL: { id: 3, label: 'Ek Hizmet İndirimi' },
};

export const PosWayTypes = {
    IN: { id: 1, label: 'Alınan' },
    OUT: { id: 2, label: 'Verilen' },
};

export {
    DescType,
    RegionType,
    MessageType,
    TagType,
    DynamicPageType,
    CategoryType,
    SpecialDayType,
    findEnumById,
    YesNoType,
    PostType,
    ArticleType,
    SubContentTypes,
    IntroSubContentType,
    WordPositionType,
    PageType,
    PageStatus,
    PaymentTermTypes,
    PrivilegeTypes,
    PaymentTermDateTypes,
    PaymentTermRuleTypes,
    GenerateCombinationTypes,
    TermsOfPurchaseTypes,
    ExchangeRatesTypes,
    TermsOfParticipationTypes,
    GenerateContentType,
    TourType,
};
