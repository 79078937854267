import React from 'react';
import { TableSortLabel } from '@mui/material';
import { StyledTableCell } from '../DataTable';

const LabelCell = React.memo(({ column, orderBy, handleOrderBy, hasOrderBy, sortable }) => {
    return (
        <StyledTableCell
            align={column.numeric ? 'right' : 'left'}
            width={column.width}
            sortDirection={orderBy?.name === column.name ? orderBy?.orderType : false}>
            {hasOrderBy &&
                (sortable ? (
                    <TableSortLabel
                        active={orderBy?.name === column.name}
                        direction={orderBy?.name === column.name ? orderBy?.orderType : 'asc'}
                        onClick={() =>
                            handleOrderBy(column.customSort ? column.searchField : column.name)
                        }>
                        {column.label}
                    </TableSortLabel>
                ) : (
                    column.label
                ))}

            {!hasOrderBy && column.label}
        </StyledTableCell>
    );
});

export default LabelCell;
