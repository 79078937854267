import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import pageService from '../service/PageService';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import Chip from '@mui/material/Chip';

const PageInput = ({
    name,
    label,
    defaultValue,
    rules,
    error,
    multiple = true,
    disabled,
    control,
}) => {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        let active = true;
        setLoading(true);
        (async () => {
            try {
                const { resultObject } = await pageService.search({
                    start: 0,
                    data: { title: inputValue },
                    max: 50,
                });
                const result = resultObject
                    .filter(item => item.url)
                    .map(item => ({ id: item.id, title: item.title, url: item.url }));
                if (active) {
                    setOptions(result || []);
                    setLoading(false);
                }
            } catch (e) {}
        })();
        return () => {
            active = false;
        };
    }, [inputValue]);

    const handleChange = event => {
        setInputValue(event.target.value);
    };

    return (
        <Controller
            render={({ field }) => (
                <Autocomplete
                    {...field}
                    multiple={multiple}
                    getOptionLabel={page => page.title || ''}
                    isOptionEqualToValue={(option, value) => option.pageId === value.pageId}
                    options={options}
                    filterOptions={x => x}
                    loading={loading}
                    disabled={disabled}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                color="secondary"
                                size="small"
                                key={index}
                                style={{ borderRadius: 4, backgroundColor: '#2163a8' }}
                                label={option.title}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    renderInput={params => (
                        <TextField
                            {...params}
                            label={label || (multiple ? t('label.pageUrls') : t('label.pageUrl'))}
                            error={error && true}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            autoComplete="off"
                            helperText={error?.message}
                            multiline={multiple}
                            onChange={handleChange}
                        />
                    )}
                    onChange={(_, data) => field.onChange(data)}
                />
            )}
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
        />
    );
};

export default PageInput;
