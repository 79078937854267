import BaseService from './BaseService';

class SpecialDayService extends BaseService {
    constructor() {
        super('/common/specialdays');
    }
}

const specialDayService = new SpecialDayService();

export default specialDayService;
