import { Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import RenderTags from './RenderTags';
import { useTranslation } from 'react-i18next';
import regionService from '../service/RegionService';

const MatchRegionInput = ({
    name,
    label,
    defaultValue,
    rules,
    error,
    multiple = true,
    tourType,
    disabled,
    level = 0,
    control,
    regionId = null,
}) => {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const defaultRegionId = regionId !== null ? parseInt(regionId) : null;
    const { t } = useTranslation();

    useEffect(() => {
        let active = true;
        setLoading(true);
        if (inputValue === '') {
            if (active) {
                setOptions([]);
                setLoading(false);
            }
            return undefined;
        }
        (async () => {
            try {
                const { resultObject } = await regionService.search({
                    start: 0,
                    data: {
                        name: inputValue,
                        ...(level && { level: level }),
                        ...(tourType && { tourType: tourType }),
                    },
                    max: 50,
                });
                const result = resultObject.map(item => ({
                    regionId: defaultRegionId,
                    subRegionId: item.id,
                    name: item.name,
                    title: item.title,
                }));
                if (active) {
                    setOptions(result || []);
                    setLoading(false);
                }
            } catch (e) {}
        })();
        return () => {
            active = false;
        };
    }, [inputValue, level, tourType]);

    const handleChange = event => {
        setInputValue(event.target.value);
    };

    return (
        <>
            <Controller
                render={({ field }) => (
                    <Autocomplete
                        {...field}
                        multiple={multiple}
                        getOptionLabel={region => region.name || ''}
                        isOptionEqualToValue={(option, value) =>
                            option.subRegionId === value.subRegionId
                        }
                        options={options}
                        filterOptions={x => x}
                        loading={loading}
                        disabled={disabled}
                        renderTags={RenderTags}
                        limitTags={10}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={label || (multiple ? t('label.regions') : t('label.region'))}
                                error={error && true}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                autoComplete="off"
                                helperText={error?.message}
                                multiline={multiple}
                                onChange={handleChange}
                            />
                        )}
                        onChange={(_, data) => field.onChange(data)}
                    />
                )}
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={rules}
            />
        </>
    );
};

export default MatchRegionInput;
