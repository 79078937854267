import React, { useRef, useState, useEffect } from 'react';
import { TableCell } from '@mui/material';
import SearchInput from './SearchInput';

const MinMaxSearchCellForecast = React.memo(({ column, onSearch, defaultValue }) => {
    const [min, setMin] = useState('');
    const [max, setMax] = useState('');
    const process = useRef(null);

    useEffect(() => {
        if (defaultValue !== '' && defaultValue !== undefined && defaultValue !== null) {
            setMin(defaultValue ? defaultValue.min || null : null);
            setMax(defaultValue ? defaultValue.max || null : null);
        } else {
            setMin('');
            setMax('');
        }
    }, [defaultValue]);

    const onKeyPress = e => {
        if (e.charCode === 13 && onSearch) {
            // if(Number(max) > Number(min)) {
            setSearchData(min, max);
            // }
        }
    };

    const setSearchData = (min, max) => {
        if (process.current) {
            clearTimeout(process.current);
        }

        process.current = setTimeout(() => {
            const obj = {};
            if (min || max) {
                obj[`${column.name}`] = { min: null, max: null };
                if (min && !isNaN(+min)) {
                    obj[`${column.name}`].min = parseInt(min);
                } else {
                    delete obj[`${column.name}`].min;
                }
                if (max && !isNaN(+max)) {
                    obj[`${column.name}`].max = parseInt(max);
                } else {
                    delete obj[`${column.name}`].max;
                }
            } else {
                obj[`${column.name}`] = null;
            }
            onSearch(obj);
        }, 1000);
    };

    return (
        <TableCell
            style={{
                backgroundColor: '#f9f6f3',
                margin: 0,
                padding: '2px 2px 0px 8px',
                width: 110,
            }}>
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <SearchInput
                    value={min}
                    onChange={e => setMin(e.target.value)}
                    onKeyPress={e => onKeyPress(e)}
                    autoComplete="off"
                    placeholder={`Min...`}
                    type={column.type === Number ? 'number' : 'text'}
                />
                <SearchInput
                    value={max}
                    onChange={e => setMax(e.target.value)}
                    onKeyPress={e => onKeyPress(e)}
                    autoComplete="off"
                    placeholder={`Max...`}
                    style={{ borderLeft: '1px solid #e0e0e0' }}
                    type={column.type === Number ? 'number' : 'text'}
                />
            </div>
        </TableCell>
    );
});

export default MinMaxSearchCellForecast;
