import { useEffect, useState } from 'react';
import Page from '../../../layout/Page';
import { useHistory, useParams } from 'react-router-dom';
import categoryService from '../../../service/CategoryService';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Panel from '../../../ui/Panel';
import Input from '../../../ui/Input';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import PageActions from '../../../components/PageActions';
import Select from '../../../ui/Select';
import MenuItem from '@mui/material/MenuItem';
import { useForm } from 'react-hook-form';
import {
    CategoryType,
    findEnumById,
    GenerateCombinationTypes,
    MessageType,
    WordPositionType,
} from '../../../util/Enums';
import useMessage from '../../../util/useMessage';
import SubmitButton from '../../../ui/SubmitButton';
import DateInput from '../../../ui/DateInput';
import { formatDate } from '../../../util/DateUtil';

let MOUNT = true;

const CategoryEditPage = ({ title, ...props }) => {
    const param = useParams();
    const categoryId = param?.id;

    const { t } = useTranslation();
    const history = useHistory();
    const showMessage = useMessage();

    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [category, setCategory] = useState(null);

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors },
    } = useForm();

    const categoryType = watch('categoryType');
    const generateCombinationLink = watch('generateCombinationLink', false);

    useEffect(() => {
        if (categoryId) {
            (async () => {
                setPageLoading(true);
                try {
                    const { resultObject } = await categoryService.get(categoryId);
                    initPageValues(resultObject);
                } catch (e) {}
                setPageLoading(false);
            })();
        } else {
            setPageLoading(false);
        }

        return () => {
            MOUNT = false;
        };
    }, []);

    const initPageValues = _category => {
        setCategory(_category);
        setValue('categoryType', _category.categoryType);
        setValue('generateCombinationLink', Number(_category.generateCombinationLink));
    };

    const onSubmit = async data => {
        if (loading) return false;
        try {
            setLoading(true);
            const obj = {
                ...data,
                categoryType: parseInt(data.categoryType),
                volume: data?.volume ? parseInt(data.volume) : 0,
                generateCombinationLink: data?.generateCombinationLink === 1,
            };
            const { resultObject } = await categoryService.saveOrUpdate(categoryId, obj);
            showMessage(t(categoryId ? 'message.updated' : 'message.created'));

            history.push(`/settings/categories/edit/${resultObject.categoryId || resultObject.id}`);
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const ActionPanel = () => (
        <PageActions>
            <Button
                variant="contained"
                disabled={loading}
                startIcon={loading ? null : <KeyboardBackspaceIcon />}
                onClick={() => {
                    history.goBack();
                }}>
                {t('button.back')}
            </Button>

            <SubmitButton loading={loading} startIcon={<SaveIcon />}>
                {t('button.save')}
            </SubmitButton>
        </PageActions>
    );

    useEffect(() => {
        console.log(categoryType);
    }, [categoryType]);

    return (
        <Page title={title} loading={pageLoading}>
            <Container maxWidth="sm">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ActionPanel />
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Panel title={t('title.categoryInfos')}>
                                <Select
                                    name="categoryType"
                                    rules={{ required: t('validation.required') }}
                                    error={errors?.categoryType}
                                    label={t('label.categoryType')}
                                    defaultValue={category?.categoryType}
                                    control={control}>
                                    {Object.values(CategoryType).map(item => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {t(item.label)}
                                        </MenuItem>
                                    ))}
                                </Select>

                                <Input
                                    defaultValue={category?.name}
                                    label={t('label.name')}
                                    error={errors?.name}
                                    {...register('name', {
                                        required: t('validation.required'),
                                    })}
                                />
                                <Input
                                    label={t('label.visibleName')}
                                    defaultValue={category?.title}
                                    error={errors?.title}
                                    {...register('title')}
                                />

                                <Select
                                    name="wordPosition"
                                    rules={{ required: t('validation.required') }}
                                    error={errors?.wordPosition}
                                    label={t('label.wordPosition')}
                                    defaultValue={category?.wordPosition}
                                    control={control}>
                                    {Object.values(WordPositionType).map(item => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {t(item.label)}
                                        </MenuItem>
                                    ))}
                                </Select>

                                {Number(categoryType) === CategoryType.SPECIAL_PERIOD.id && (
                                    <>
                                        <DateInput
                                            name="periodStartDate"
                                            label={t('label.periodStartDate')}
                                            defaultValue={category?.periodStartDate || null}
                                            error={errors?.periodStartDate}
                                            rules={{ required: t('validation.required') }}
                                            control={control}
                                        />
                                        <DateInput
                                            name="periodEndDate"
                                            label={t('label.periodEndDate')}
                                            defaultValue={category?.periodEndDate || null}
                                            error={errors?.periodEndDate}
                                            rules={{ required: t('validation.required') }}
                                            control={control}
                                        />
                                    </>
                                )}

                                {findEnumById(CategoryType, categoryType)?.number === true && (
                                    <Input
                                        type="number"
                                        label={t('label.numberValue')}
                                        defaultValue={category?.numberValue}
                                        error={errors?.numberValue}
                                        {...register('numberValue', {
                                            required: t('validation.required'),
                                        })}
                                    />
                                )}

                                <Select
                                    name="generateCombinationLink"
                                    rules={{ required: t('validation.required') }}
                                    error={errors?.generateCombinationLink}
                                    label={t('label.generateCombinationLink')}
                                    defaultValue={category?.generateCombinationLink ? 1 : 0}
                                    control={control}>
                                    <MenuItem value={1}>{t('label.yes')}</MenuItem>
                                    <MenuItem value={0}>{t('label.no')}</MenuItem>
                                </Select>

                                {Boolean(generateCombinationLink) === true && (
                                    <Select
                                        name="generateCombinationType"
                                        rules={{ required: t('validation.required') }}
                                        error={errors?.generateCombinationType}
                                        label={t('label.generateCombinationType')}
                                        defaultValue={category?.generateCombinationType}
                                        control={control}>
                                        {Object.values(GenerateCombinationTypes).map(item => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {t(item.label)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}

                                <Input
                                    type="number"
                                    name="volume"
                                    defaultValue={category?.volume}
                                    label={t('label.searchVolume')}
                                    error={errors?.volume}
                                    {...register('volume')}
                                />
                            </Panel>
                        </Grid>
                    </Grid>
                    <ActionPanel />
                </form>
            </Container>
        </Page>
    );
};

export default CategoryEditPage;
