import { useCallback, useEffect, useState } from 'react';

const usePagination = ({ service, defaultSearchData = {}, defaultOrderBy, custom = false }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [disabledYachts, setDisabledYachts] = useState([]);
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [count, setCount] = useState(0);
    const [searchData, setSearchData] = useState(defaultSearchData);
    const [errors, setErrors] = useState(null);

    const [max, setMax] = useState(50);

    const [orderBy, setOrderBy] = useState(defaultOrderBy);

    useEffect(() => {
        setPage(0);
    }, [service, searchData, orderBy]);

    const search = useCallback(async () => {
        setLoading(true);
        let obj = {
            start: max * page,
            data: { ...searchData },
            max,
            orderBy,
        };
        if (custom) {
            const params = {};
            Object.keys(searchData).forEach(key => {
                if (
                    key !== 'search' &&
                    key !== 'selectedTourProgramIds' &&
                    searchData[key] !== null
                ) {
                    params[key] = searchData[key];
                }
            });
            obj = {
                start: max * page,
                max,
                orderBy,
                data: { ...params, ...searchData.search },
                selectedTourProgramIds: searchData.selectedTourProgramIds
                    ? searchData.selectedTourProgramIds
                    : null,
            };
        }
        try {
            const { resultObject, resultCount } = !custom
                ? await service.search(obj)
                : await service.customSearch(obj);
            if (custom) {
                const { tourForecastList, selectedTourForecastList } = resultObject;
                if (tourForecastList || selectedTourForecastList) {
                    if (tourForecastList) {
                        setData(tourForecastList || []);
                    }
                    if (selectedTourForecastList) {
                        setDisabledYachts(selectedTourForecastList || []);
                    }
                } else {
                    setData(resultObject);
                }
            } else {
                setData(resultObject);
            }
            setCount(resultCount);
            setErrors(null);

            const _pages = Math.ceil(resultCount / max);
            setPageCount(_pages);
        } catch (e) {
            setErrors(e);
            setData([]);
            setDisabledYachts([]);
            setCount(0);
            setPageCount(0);
        }
        setLoading(false);
    }, [service, max, searchData, orderBy, page]);

    useEffect(() => {
        (async () => {
            await search();
        })();
    }, [page, search]);

    const reset = () => {
        setPage(0);
        setSearchData(defaultSearchData);
        setOrderBy(defaultOrderBy);
    };

    return {
        loading,
        data,
        disabledYachts,
        errors,
        page,
        pageCount,
        count,
        setPage,
        searchData,
        setSearchData,
        orderBy,
        max,
        setMax,
        setOrderBy,
        reset,
        search,
        service,
        setLoading,
    };
};

export default usePagination;
