import Chip from '@mui/material/Chip';
import React from 'react';

const RenderTags = (value, getTagProps) =>
    value.map((option, index) => (
        <Chip
            color="secondary"
            size="small"
            key={index}
            style={{ borderRadius: 4, backgroundColor: '#2163a8' }}
            label={option.name || option.title}
            {...getTagProps({ index })}
        />
    ));

export default RenderTags;
