import BaseService from './BaseService';

class TagService extends BaseService {
    constructor() {
        super('/common/tags');
    }
}

const tagService = new TagService();

export default tagService;
