import Config from './Config';
import axios from 'axios';

export const EditorConfig = {
    attribution: false,
    placeholder: 'Yazmaya başlayın...',
    key: 'hWA2C-7F2B5D5D4D4E2vd1EAJLQCVLUVBa1NXNRSSATEXD-13C3B2E2F2E3B1B6C7C2F2==',
    language: 'tr',
    // pastePlain: true,
    pasteDeniedAttrs: ['lang', 'style', 'dir'],
    wordDeniedAttrs: ['lang', 'style', 'dir'],
    pasteDeniedTags: [
        'abbr',
        'address',
        'area',
        'article',
        'aside',
        'audio',
        'b',
        'base',
        'bdi',
        'bdo',
        'blockquote',
        'body',
        'br',
        'button',
        'canvas',
        'caption',
        'cite',
        'code',
        'col',
        'colgroup',
        'data',
        'datalist',
        'dd',
        'del',
        'details',
        'dfn',
        'dialog',
        'div',
        'dl',
        'dt',
        'em',
        'embed',
        'fieldset',
        'figure',
        'footer',
        'form',
        'head',
        'header',
        'hgroup',
        'hr',
        'html',
        'i',
        'iframe',
        'img',
        'input',
        'ins',
        'kbd',
        'keygen',
        'label',
        'legend',
        'link',
        'main',
        'map',
        'mark',
        'menu',
        'menuitem',
        'meta',
        'meter',
        'nav',
        'noscript',
        'object',
        'optgroup',
        'option',
        'output',
        'param',
        'pre',
        'progress',
        'q',
        'rb',
        'rp',
        'rt',
        'rtc',
        'ruby',
        's',
        'samp',
        'script',
        'section',
        'select',
        'small',
        'source',
        'span',
        'style',
        'sub',
        'summary',
        'sup',
        'table',
        'tbody',
        'td',
        'template',
        'textarea',
        'tfoot',
        'th',
        'thead',
        'time',
        'title',
        'tr',
        'track',
        'u',
        'var',
        'video',
        'wbr',
    ],
    linkAttributes: {
        'data-label': 'Etiket',
    },
    toolbarButtons: {
        moreText: {
            buttons: [
                'bold',
                'italic',
                'underline',
                'strikeThrough',
                'subscript',
                'superscript',
                'textColor',
                'backgroundColor',
                'inlineClass',
                'clearFormatting',
            ],
            buttonsVisible: 1,
        },
        moreParagraph: {
            buttons: [
                'paragraphFormat',
                'alignLeft',
                'alignCenter',
                'formatOLSimple',
                'alignRight',
                'alignJustify',
                'formatOL',
                'formatUL',
                'paragraphStyle',
                'lineHeight',
                'outdent',
                'indent',
                'quote',
            ],
            buttonsVisible: 1,
        },
        moreRich: {
            buttons: [
                'insertLink',
                'insertImage',
                'insertTable',
                'emoticons',
                'fontAwesome',
                'specialCharacters',
                'embedly',
                'insertHR',
            ],
            buttonsVisible: 1,
        },
        moreMisc: {
            buttons: [
                'html',
                'undo',
                'redo',
                'fullscreen',
                'print',
                'getPDF',
                'spellChecker',
                'selectAll',
                'help',
            ],
            buttonsVisible: 1,
        },
    },
    paragraphStyles: {
        'info-block info-blue': 'Mavi Uyarı',
        'info-block info-green': 'Yeşil Uyarı',
        'info-block info-red': 'Kırmızı Uyarı',
    },
    pluginsEnabled: [
        'image',
        'link',
        'align',
        'charCounter',
        'codeBeautifier',
        'codeView',
        'colors',
        'draggable',
        'embedly',
        'emoticons',
        'file',
        'fontAwesome',
        'fontFamily',
        'fontSize',
        'fullscreen',
        'image',
        'imageTUI',
        'imageManager',
        'inlineStyle',
        'inlineClass',
        'lineBreaker',
        'lineHeight',
        'link',
        'lists',
        'paragraphFormat',
        'paragraphStyle',
        'quickInsert',
        'quote',
        'save',
        'table',
        'url',
        'video',
        'wordPaste',
    ],
    imageAllowedTypes: ['jpeg', 'jpg', 'png'],
    events: {
        'image.beforeUpload': function (images) {
            const editor = this;
            const data = new FormData();
            data.append('folderName', 'editor');
            data.append('file', images[0]);
            axios
                .post(`${Config.API_URL}/media/uploadEditor`, data)
                .then(res => {
                    editor.image.insert(res.data.link, null, null, editor.image.get());
                })
                .catch(err => {
                    console.log(err);
                });
            return false;
        },
    },
};
