import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageEditorPanel from './ImageEditorPanel';
import fileUploadService from '../util/FileUploadService';
import ImageUploadProgressTable from './ImageUploadProgressTable';
import { Grid } from '@mui/material';
import Image from './Image';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const RegionImageUploadDialog = ({
    open,
    onUploadDone,
    onClose,
    savedImages = [],
    regionImages = [],
    loading,
}) => {
    const { t } = useTranslation();

    const [selectedImages, setSelectedImages] = useState([]);

    useEffect(() => {
        if (open) {
            const selectRegionImages = [];
            regionImages.forEach(img => {
                if (img.images?.length > 0) {
                    img.images.forEach(subImg => {
                        selectRegionImages.push({
                            ...subImg,
                            imageRegionId: img.regionId,
                            imageRegionName: img.name,
                        });
                    });
                }
            });
            setSelectedImages(selectRegionImages);
        }
    }, [open]);

    const handleChange = (input, inputImage, region) => {
        if (input.checked) {
            setSelectedImages([
                ...selectedImages,
                { ...inputImage, imageRegionId: region.id, imageRegionName: region.name },
            ]);
        } else {
            const removeImages = selectedImages
                .filter(item => item.id !== inputImage.id)
                .map(item => item);
            setSelectedImages(removeImages);
        }
    };

    const onSubmit = () => {
        const customImages = selectedImages;
        customImages.forEach(item => {
            delete item.id;
            delete item.imageTypeId;
        });
        onUploadDone(customImages);
    };

    return (
        <Dialog open={open} fullWidth maxWidth={'md'} scroll="paper">
            <DialogTitle>{t('title.imageSelectDialog')}</DialogTitle>
            <DialogContent dividers>
                {regionImages.map((region, index) => {
                    if (region.images?.length > 0) {
                        return (
                            <Grid container spacing={1} key={index}>
                                <Grid item xs={12}>
                                    {region.name}
                                </Grid>
                                {region.images.map((regionImage, regionIndex) => {
                                    if (regionImage.path) {
                                        const check = savedImages?.some(
                                            some => some.path === regionImage.path,
                                        );
                                        return (
                                            <Grid
                                                item
                                                xs={2}
                                                key={regionIndex}
                                                sx={{ position: 'relative' }}>
                                                <Image
                                                    src={regionImage.path}
                                                    style={{ width: '100%' }}
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            defaultChecked={check ? false : true}
                                                            onChange={e =>
                                                                handleChange(
                                                                    e.target,
                                                                    regionImage,
                                                                    region,
                                                                )
                                                            }
                                                            value={regionImage.id}
                                                            size="small"
                                                            sx={{
                                                                padding: '0px 4px 0px 10px',
                                                                fontSize: '12px',
                                                            }}
                                                            disabled={check ? true : false}
                                                        />
                                                    }
                                                    label="Seç"
                                                />
                                            </Grid>
                                        );
                                    }
                                })}
                                {index !== regionImages.length - 1 && (
                                    <Grid item xs={12}>
                                        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                            <Divider />
                                        </div>
                                    </Grid>
                                )}
                            </Grid>
                        );
                    }
                })}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" disabled={loading}>
                    {t('button.cancel')}
                </Button>
                <Button onClick={onSubmit} disabled={loading}>
                    {t('button.add')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RegionImageUploadDialog;
