import { v4 as uuidv4 } from 'uuid';
import { ActionType } from './ActionType';

const InitData = {
    notifications: [],
};

export const notificationReducer = (state = InitData, action) => {
    switch (action.type) {
        case ActionType.ADD_NOTIFICATION: {
            const notification = {
                id: uuidv4(),
                message: action.message,
                type: action.messageType,
            };

            return {
                ...state,
                notifications: [...state.notifications, notification],
            };
        }

        case ActionType.REMOVE_NOTIFICATION: {
            const filtered = state.notifications.filter(item => item.id !== action.id);

            return {
                ...state,
                notifications: filtered,
            };
        }

        default: {
            return state;
        }
    }
};
