import Page from '../../../layout/Page';
import Container from '@mui/material/Container';
import { useForm } from 'react-hook-form';
import {
    MessageType,
    TermsOfParticipationTypes,
    TermsOfPurchaseTypes,
    ProductGroupTypes,
    CurrencyTypes,
} from '../../../util/Enums';
import additionalService from '../../../service/AdditionalService';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useMessage from '../../../util/useMessage';
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Panel from '../../../ui/Panel';
import Input from '../../../ui/Input';
import CoverImageSelectionCard from '../../../components/CoverImageSelectionCard';
import Editor from '../../../ui/Editor';
import PageActions from '../../../components/PageActions';
import Button from '@mui/material/Button';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import SubmitButton from '../../../ui/SubmitButton';
import SaveIcon from '@mui/icons-material/Save';
import Select from '../../../ui/Select';
import MenuItem from '@mui/material/MenuItem';
import { DataTable } from '../../../components/DataTable';
import AddIcon from '@mui/icons-material/Add';
import AdditionalServicesChildRuleEditDialog from './AdditionalServicesChildRuleEditDialog';
import VendorInput from '../../../ui/VendorInput';
import CheckBox from '../../../ui/CheckBox';

let MOUNT = true;

const AdditionalServicesEditPage = ({ title, ...props }) => {
    const param = useParams();
    const additionalServiceId = param?.id;

    const { t } = useTranslation();
    const history = useHistory();
    const showMessage = useMessage();

    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [shortDesc, setShortDesc] = useState('');
    const [childRules, setChildRules] = useState([]);
    const [additionalServices, setAdditionalServices] = useState(null);
    const [openChildRuleDialog, setOpenChildRuleDialog] = useState(false);
    const [selectedChildRuleIndex, setSelectedChildRuleIndex] = useState(null);

    const [image, setImage] = useState(null);
    const [fileName, setFileName] = useState('');
    const [folderName] = useState('additionalservice');

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors },
    } = useForm();

    const termsOfParticipation = watch('termOfParticipation');
    const watchFileName = watch('title');

    const aspect = 720 / 480;

    useEffect(() => {
        setFileName(watchFileName || '');
    }, [watchFileName]);

    useEffect(() => {
        if (additionalServiceId) {
            (async () => {
                setPageLoading(true);
                try {
                    const { resultObject } = await additionalService.get(additionalServiceId);
                    initPageValues(resultObject);
                } catch (e) {}
                setPageLoading(false);
            })();
        } else {
            setPageLoading(false);
        }
        return () => {
            MOUNT = false;
        };
    }, []);

    const initPageValues = _additionalServices => {
        setAdditionalServices(_additionalServices);
        setImage(_additionalServices?.image);
        setFileName(_additionalServices?.name || '');
        setShortDesc(_additionalServices.shortDesc);
        setValue('termOfParticipation', _additionalServices.termOfParticipation);
        setChildRules(_additionalServices.childRules);
    };

    const onSubmit = async data => {
        if (loading) return false;
        try {
            setLoading(true);

            const obj = {
                ...data,
                image,
                childRules,
                shortDesc: shortDesc,
            };

            if (obj.termOfParticipation !== TermsOfParticipationTypes.NUMBER_OF_ADULT.id) {
                delete obj.minAdultAge;
            }

            const { resultObject } = await additionalService.saveOrUpdate(additionalServiceId, obj);
            showMessage(t(additionalServiceId ? 'message.updated' : 'message.created'));

            history.push(
                `/settings/additionalServices/edit/${resultObject.additionalServiceId || resultObject.id}`,
            );
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const ActionPanel = () => (
        <PageActions>
            <Button
                variant="contained"
                disabled={loading}
                startIcon={loading ? null : <KeyboardBackspaceIcon />}
                onClick={() => {
                    history.goBack();
                }}>
                {t('button.back')}
            </Button>

            <SubmitButton loading={loading} startIcon={<SaveIcon />}>
                {t('button.save')}
            </SubmitButton>
        </PageActions>
    );

    return (
        <Page title={title} loading={pageLoading}>
            <Container maxWidth="sm">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ActionPanel />
                    <Grid container spacing={1}>
                        <Grid item md={12}>
                            <Panel title={t('title.additionalServicesInfos')}>
                                <VendorInput
                                    name="contact"
                                    multiple={false}
                                    control={control}
                                    defaultValue={additionalServices?.contact}
                                    rules={{ required: t('validation.required') }}
                                    error={errors?.contact}
                                />
                                <Select
                                    name="productGroupId"
                                    rules={{ required: t('validation.required') }}
                                    error={errors?.productGroupId}
                                    label={t('purchase.productGroupId')}
                                    defaultValue={additionalServices?.productGroupId}
                                    control={control}>
                                    {Object.values(ProductGroupTypes).map(item => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {t(item.label)}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Input
                                    name="name"
                                    defaultValue={additionalServices?.name}
                                    label={t('label.name')}
                                    error={errors?.name}
                                    {...register('name', {
                                        required: t('validation.required'),
                                        minLength: {
                                            value: 3,
                                            message: t('validation.minLength', { val: 3 }),
                                        },
                                    })}
                                />
                                <Input
                                    name="title"
                                    defaultValue={additionalServices?.title}
                                    label={t('label.visibleName')}
                                    error={errors?.title}
                                    {...register('title', {
                                        required: t('validation.required'),
                                        minLength: {
                                            value: 3,
                                            message: t('validation.minLength', { val: 3 }),
                                        },
                                    })}
                                />

                                <CheckBox
                                    defaultChecked={additionalServices?.isApply}
                                    label={t('label.applyWhenAllConditionsAreRight')}
                                    {...register('isApply')}
                                />
                            </Panel>
                        </Grid>

                        <Grid item md={12}>
                            <Panel title={t('title.additionalServicesTermsOfPurchase')}>
                                <Select
                                    name="termOfPurchase"
                                    rules={{ required: t('validation.required') }}
                                    error={errors?.termOfPurchase}
                                    label={t('label.termsOfPurchase')}
                                    defaultValue={additionalServices?.termOfPurchase}
                                    control={control}>
                                    {Object.values(TermsOfPurchaseTypes).map(item => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {t(item.label)}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Select
                                    name="termOfParticipation"
                                    rules={{ required: t('validation.required') }}
                                    error={errors?.termOfParticipation}
                                    label={t('label.termsOfParticipation')}
                                    defaultValue={additionalServices?.termOfParticipation}
                                    control={control}>
                                    {Object.values(TermsOfParticipationTypes).map(item => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {t(item.label)}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {termsOfParticipation ===
                                    TermsOfParticipationTypes.NUMBER_OF_ADULT.id && (
                                    <Input
                                        defaultValue={additionalServices?.minAdultAge}
                                        label={t('label.minAdultAge')}
                                        error={errors?.minAdultAge}
                                        {...register('minAdultAge', {
                                            required: t('validation.required'),
                                        })}
                                    />
                                )}
                            </Panel>
                        </Grid>
                        <Grid item md={12}>
                            <Panel title={t('title.priceDefinition')}>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <Select
                                            name="purchaseCurrencyTypeId"
                                            defaultValue={
                                                additionalServices?.purchaseCurrencyTypeId || null
                                            }
                                            // rules={{required: t("validation.required")}}
                                            error={errors?.purchaseCurrencyTypeId}
                                            label={t('label.purchaseCurrencyType')}
                                            control={control}>
                                            {Object.values(CurrencyTypes).map(item => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Select
                                            name="saleCurrencyTypeId"
                                            defaultValue={
                                                additionalServices?.saleCurrencyTypeId || null
                                            }
                                            // rules={{required: t("validation.required")}}
                                            error={errors?.saleCurrencyTypeId}
                                            label={t('label.saleCurrencyType')}
                                            control={control}>
                                            {Object.values(CurrencyTypes).map(item => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            defaultValue={additionalServices?.purchasePrice}
                                            label={t('label.purchasePrice')}
                                            error={errors?.purchasePrice}
                                            {...register('purchasePrice')}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            defaultValue={additionalServices?.salePrice}
                                            label={t('label.salePrice')}
                                            error={errors?.salePrice}
                                            {...register('salePrice')}
                                        />
                                    </Grid>
                                </Grid>
                                {/* <Input
                                    defaultValue={additionalServices?.perPersonPrice}
                                    label={t('label.perPersonPrice')}
                                    error={errors?.perPersonPrice}
                                    {...register("perPersonPrice", {
                                        required: t('validation.required')
                                    })}
                                /> */}
                                {/* {termsOfParticipation !== TermsOfParticipationTypes.NUMBER_OF_ADULT.id &&
                                    <DataTable
                                        title={t('title.childRules')}
                                        cardStyle={{height: '100%'}}
                                        columns={[
                                            {name: "minAge", label: t('label.minAge')},
                                            {name: "maxAge", label: t('label.maxAge')},
                                            {name: "price", label: t('label.price')}
                                        ]}
                                        action={
                                            <Button
                                                color={"secondary"}
                                                startIcon={<AddIcon/>}
                                                onClick={() => {
                                                    setSelectedChildRuleIndex(null);
                                                    setOpenChildRuleDialog(true);
                                                }}>
                                                {t('button.add')}
                                            </Button>
                                        }
                                        onEdit={(item, index) => {
                                            setSelectedChildRuleIndex(index);
                                            setOpenChildRuleDialog(true);
                                        }}
                                        onDelete={(item, index) => {
                                            const list = childRules.filter((item, idx) => index !== idx);
                                            setChildRules(list);
                                        }}
                                        data={childRules?.map((item, index) => ({id: index, itemId: item.id, ...item}))}
                                    />
                                } */}
                            </Panel>
                        </Grid>
                        <Grid item md={12}>
                            <CoverImageSelectionCard
                                aspect={aspect}
                                title={t('title.image')}
                                {...{ image, setImage, fileName, folderName }}
                            />
                        </Grid>

                        <Grid item md={12}>
                            <Editor
                                title={t('label.shortDesc')}
                                value={shortDesc}
                                onChange={val => {
                                    setShortDesc(val);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <ActionPanel />
                </form>

                <AdditionalServicesChildRuleEditDialog
                    rule={childRules[selectedChildRuleIndex]}
                    open={openChildRuleDialog}
                    onDoneCallback={data => {
                        const items = [...childRules];
                        if (selectedChildRuleIndex !== null) {
                            items[selectedChildRuleIndex] = {
                                ...items[selectedChildRuleIndex],
                                ...data,
                            };
                        } else {
                            items.push(data);
                        }
                        setChildRules(items);
                        setOpenChildRuleDialog(false);
                        setSelectedChildRuleIndex(null);
                    }}
                    onClose={() => {
                        setOpenChildRuleDialog(false);
                        setSelectedChildRuleIndex(null);
                    }}
                />
            </Container>
        </Page>
    );
};

export default AdditionalServicesEditPage;
