import { Grid, MenuItem } from '@mui/material';
import {
    findEnumById,
    GenderTypes,
    GuestTypes,
    PassportType,
    ProductGroupTypes,
} from '../../../util/Enums';
import Input from '../../../ui/Input';
import Select from '../../../ui/Select';
import VisaTypeInput from '../../../ui/VisaTypeInput';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import DateInput from '../../../ui/DateInput';
import { formatDate } from '../../../util/DateUtil';
import CheckBox from '../../../ui/CheckBox';

function CopyMenu({ normalGuests, onCopy }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = saleItemGuestIndex => {
        setAnchorEl(null);
        onCopy(saleItemGuestIndex);
    };

    return (
        <div>
            <Button
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}>
                Kişi Bilgi Kopyala
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                {normalGuests?.map((saleItemGuest, saleItemGuestIndex) => {
                    return (
                        <MenuItem
                            key={saleItemGuestIndex}
                            onClick={() => handleClose(saleItemGuestIndex)}>
                            {saleItemGuestIndex + 1}. Kişi (
                            {findEnumById(GuestTypes, saleItemGuest.guestTypeId)?.label})
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
}

const TourReservationEditGuestEditPanel = ({
    saleItem,
    saleItemGuest,
    formName,
    saleItemIndex,
    saleItemGuestIndex,
    normalGuests,
}) => {
    const { t } = useTranslation();
    const { register, control, getValues, setValue } = useFormContext();

    const namePrefix = `saleItems[${saleItemIndex}].${formName}[${saleItemGuestIndex}]`;

    const groupType = Object.values(ProductGroupTypes).find(
        item => item.id === saleItem.productGroupId,
    );

    const copyFromRoomGuest = fromIndex => {
        const from = getValues(`saleItems[${saleItemIndex}].saleItemGuests[${fromIndex}]`);

        if (from) {
            const to = getValues(`${namePrefix}`);
            const { identityNumber, isNotCitizenTc, name, surname, telephone, email } = from;

            setValue(`${namePrefix}`, {
                ...to,
                identityNumber,
                isNotCitizenTc,
                name,
                surname,
                telephone,
                email,
            });
        }
    };

    return (
        <Grid container xs={12} spacing={1}>
            <Grid item xs={12}>
                <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                    <div>
                        {groupType.additional ? '1 Kişi' : `${saleItemGuestIndex + 1}. Kişi`}(
                        {findEnumById(GuestTypes, saleItemGuest.guestTypeId)?.label})
                        {groupType.additional
                            ? ` - (${groupType?.overrideLabel || groupType?.label})`
                            : ''}
                    </div>

                    {groupType?.additional && (
                        <CopyMenu normalGuests={normalGuests} onCopy={copyFromRoomGuest} />
                    )}
                </div>
            </Grid>
            <Grid item xs={2}>
                <Input
                    type="number"
                    label="Kimlik Numarası"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    {...register(`${namePrefix}.identityNumber`)}
                />
            </Grid>
            <Grid item xs={2} sx={{paddingTop: "15px !important"}}>
                <CheckBox
                    defaultChecked={saleItem.saleItemGuests[saleItemGuestIndex]?.isNotCitizenTc}
                    label="T.C. Vatandaşı Değil"
                    {...register(`${namePrefix}.isNotCitizenTc`)}
                />
            </Grid>
            <Grid item xs={1}>
                <Select name={`${namePrefix}.genderTypeId`} label="Cinsiyet" control={control}>
                    {Object.values(GenderTypes).map(item => (
                        <MenuItem key={item.id} value={item.id}>
                            {t(item.label)}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid container spacing={1} item xs={3}>
                <Grid item xs={6}>
                    <Input
                        label="Ad"
                        control={control}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        {...register(`${namePrefix}.name`)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Input
                        label="Soyad"
                        control={control}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        {...register(`${namePrefix}.surname`)}
                    />
                </Grid>
            </Grid>
            {!groupType.additional && (
                <>
                    <Grid item xs={2}>
                        <DateInput
                            name={`${namePrefix}.dateOfBirth`}
                            label="Doğum Tarihi"
                            defaultValue={null}
                            control={control}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Select
                            name={`${namePrefix}.passportTypeId`}
                            label={t('reservation.passportType')}
                            control={control}>
                            {Object.values(PassportType).map(item => (
                                <MenuItem key={item.id} value={item.id}>
                                    {t(item.label)}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={2}>
                        <Input
                            label="Pasaport Numarası"
                            control={control}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            {...register(`${namePrefix}.passportNumber`)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <DateInput
                            name={`${namePrefix}.passportExpirationDate`}
                            label="Pasaport Bitiş Tarihi"
                            defaultValue={null}
                            control={control}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <VisaTypeInput
                            name={`${namePrefix}.visaType`}
                            label={t('reservation.visa')}
                            control={control}
                            multiple={false}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <DateInput
                            name={`${namePrefix}.visaExpirationDate`}
                            label="Vize Bitiş Tarihi"
                            defaultValue={null}
                            control={control}
                        />
                    </Grid>

                    {saleItemGuest.guestTypeId === 1 && (
                        <>
                            <Grid item xs={2}>
                                <Input
                                    label="Telefon"
                                    control={control}
                                    {...register(`${namePrefix}.telephone`)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={e => {
                                        e.preventDefault();
                                        const val = e.target.value;
                                        setValue(
                                            `${namePrefix}.telephone`,
                                            val.replace(/ /g, '').replace(/-/g, ''),
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Input
                                    label="Email"
                                    control={control}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    {...register(`${namePrefix}.email`)}
                                />
                            </Grid>
                        </>
                    )}
                </>
            )}
        </Grid>
    );
};

export default TourReservationEditGuestEditPanel;
