import React, { useEffect, useState } from 'react';
import Page from '../../../layout/Page';
import { useHistory, useParams } from 'react-router-dom';
import campaignService from '../../../service/CampaignService';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Panel from '../../../ui/Panel';
import Input from '../../../ui/Input';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import PageActions from '../../../components/PageActions';
import { useForm } from 'react-hook-form';
import {
    MessageType,
    PaymentType,
    SaleCampaignsType,
    DiscountTypes,
    CurrencyTypes,
    findEnumById,
    ProductGroupTypes,
} from '../../../util/Enums';
import useMessage from '../../../util/useMessage';
import SubmitButton from '../../../ui/SubmitButton';
import CoverImageSelectionCard from '../../../components/CoverImageSelectionCard';
import VendorInput from '../../../ui/VendorInput';
import CheckBox from '../../../ui/CheckBox';
import Select from '../../../ui/Select';
import MenuItem from '@mui/material/MenuItem';
import SaleCampaignInput from '../../../ui/SaleCampaignInput';
import AdditionalServiceInput from '../../../ui/AdditionalServiceInput';
import DateInput from '../../../ui/DateInput';
import { formatDate } from '../../../util/DateUtil';

const SaleCampaignEditPage = ({ title, ...props }) => {
    const param = useParams();
    const saleCampaignId = param?.id;
    const { t } = useTranslation();
    const history = useHistory();
    const showMessage = useMessage();
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [saleCampaign, setSaleCampaign] = useState(null);
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        control,
        formState: { errors },
    } = useForm();
    const [image, setImage] = useState(null);
    const [fileName, setFileName] = useState('');
    const [folderName] = useState('saleCampaigns');
    const watchFileName = watch('title');
    const watchDiscountRate = watch('saleCampaignGiven.discountRate');
    const watchDiscountAmount = watch('saleCampaignGiven.discountAmount');
    const watchDiscountTypeId = watch('saleCampaignGiven.discountTypeId');
    const watchVendor = watch('contact');

    useEffect(() => {
        setFileName(watchFileName || '');
    }, [watchFileName]);

    useEffect(() => {
        if (saleCampaignId) {
            (async () => {
                setPageLoading(true);
                try {
                    const { resultObject } = await campaignService.get(saleCampaignId);
                    initPageValues(resultObject);
                } catch ({ code, message }) {
                    showMessage(`${code} - ${message}`, MessageType.ERROR);
                }
                setPageLoading(false);
            })();
        } else {
            setPageLoading(false);
        }
    }, []);

    useEffect(() => {
        if (
            watchDiscountAmount &&
            watchDiscountRate &&
            watchDiscountAmount !== null &&
            watchDiscountRate !== null
        ) {
            showMessage('Aynı anda hem Oran hem Tutar tanımlanamaz!', MessageType.ERROR);
            setValue('saleCampaignGiven.discountRate', null);
        }
    }, [watchDiscountRate]);

    useEffect(() => {
        if (
            watchDiscountRate &&
            watchDiscountAmount &&
            watchDiscountRate !== null &&
            watchDiscountAmount !== null
        ) {
            showMessage('Aynı anda hem Oran hem Tutar tanımlanamaz!', MessageType.ERROR);
            setValue('saleCampaignGiven.discountAmount', null);
        }
    }, [watchDiscountAmount]);

    useEffect(() => {
        if (watchDiscountTypeId && watchDiscountTypeId !== null) {
            if (watchDiscountTypeId !== 3) {
                setValue('additionalServices', null);
            } else if (
                watchDiscountTypeId === 3 &&
                (!watchDiscountRate || watchDiscountRate === null)
            ) {
                setValue('saleCampaignGiven.discountRate', '100');
                setValue('saleCampaignGiven.discountAmount', null);
            }
        }
    }, [watchDiscountTypeId]);

    const initPageValues = _saleCampaign => {
        setSaleCampaign(_saleCampaign);
        setImage(_saleCampaign?.image);
        setFileName(_saleCampaign?.title || '');
        Object.values(SaleCampaignsType).forEach(cam => {
            const data =
                _saleCampaign?.saleCampaignItems.filter(
                    item => item.saleCampaignItemTypeId === cam.id,
                ) || [];
            if (cam.id === 2) {
                const newData = [];
                data.forEach(item => {
                    newData.push({
                        ...item,
                        name: findEnumById(ProductGroupTypes, item.referenceId)?.label || '',
                    });
                });
                setValue(`saleCampaignItems[${cam.id}]`, newData);
            } else {
                setValue(`saleCampaignItems[${cam.id}]`, data);
            }
        });
        if (_saleCampaign.saleCampaignGiven?.referenceId && _saleCampaign.saleCampaignGiven?.name) {
            setValue('additionalServices', {
                id: _saleCampaign.saleCampaignGiven.referenceId,
                name: _saleCampaign.saleCampaignGiven.name,
            });
        }
        if (_saleCampaign.saleCampaignGiven?.discountTypeId) {
            setValue(
                'saleCampaignGiven.discountTypeId',
                _saleCampaign.saleCampaignGiven?.discountTypeId,
            );
        }
        if (_saleCampaign.saleCampaignGiven?.discountRate) {
            setValue(
                'saleCampaignGiven.discountRate',
                _saleCampaign.saleCampaignGiven?.discountRate,
            );
        }
        if (_saleCampaign.saleCampaignGiven?.discountAmount) {
            setValue(
                'saleCampaignGiven.discountAmount',
                _saleCampaign.saleCampaignGiven?.discountAmount,
            );
        }
    };

    const onSubmit = async data => {
        if (loading) return false;
        try {
            setLoading(true);
            const newData = {};
            Object.entries(data).forEach(([key, value]) => {
                if (key === 'saleCampaignGiven') {
                    const newGiven = {};
                    Object.entries(value).forEach(val => {
                        if (
                            val[1] !== null &&
                            val[1] !== '' &&
                            val[0] !== 'referenceId' &&
                            val[0] !== 'name'
                        ) {
                            newGiven[val[0]] = val[1];
                        }
                    });
                    if (
                        watchDiscountTypeId &&
                        watchDiscountTypeId === 3 &&
                        data.additionalServices
                    ) {
                        newGiven['referenceId'] = data.additionalServices.id || 0;
                        newGiven['name'] = data.additionalServices.name || '';
                    }
                    newData[key] = newGiven;
                } else if (
                    key !== 'additionalServices' &&
                    key !== 'saleCampaignItems' &&
                    value &&
                    value !== null &&
                    value !== ''
                ) {
                    console.log('value', value);
                    newData[key] = value;
                }
            });
            const cams = [];
            data.saleCampaignItems?.forEach(item => {
                if (item !== undefined) {
                    cams.push(...item);
                }
            });
            const obj = {
                ...newData,
                image,
                saleCampaignItems: cams,
            };
            const { resultObject } = await campaignService.saveOrUpdate(saleCampaignId, obj);

            showMessage(t(saleCampaignId ? 'message.updated' : 'message.created'));
            history.push(
                `/campaigns/saleCampaigns/edit/${resultObject.saleCampaignId || resultObject.id}`,
            );
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const ActionPanel = () => (
        <PageActions>
            <Button
                variant="contained"
                disabled={loading}
                startIcon={loading ? null : <KeyboardBackspaceIcon />}
                onClick={() => {
                    history.goBack();
                }}>
                {t('button.back')}
            </Button>
            <SubmitButton loading={loading} startIcon={<SaveIcon />}>
                {t('button.save')}
            </SubmitButton>
        </PageActions>
    );

    return (
        <Page title={title} loading={pageLoading}>
            <Container maxWidth="sm">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ActionPanel />
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Panel title={t('title.generalInfos')}>
                                <Input
                                    defaultValue={saleCampaign?.title}
                                    label={t('label.title')}
                                    error={errors?.title}
                                    {...register('title', {
                                        required: t('validation.required'),
                                    })}
                                />
                                <Input
                                    defaultValue={saleCampaign?.name}
                                    label={t('label.name')}
                                    error={errors?.name}
                                    {...register('name', {
                                        required: t('validation.required'),
                                    })}
                                />
                                <Input
                                    label={t('label.description')}
                                    defaultValue={saleCampaign?.description}
                                    multiline
                                    rows={3}
                                    error={errors?.description}
                                    {...register('description')}
                                />
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <DateInput
                                            name="startDate"
                                            label={t('label.startDate')}
                                            defaultValue={formatDate(saleCampaign?.startDate, 'dd/MM/yyyy') || null}
                                            error={errors?.startDate}
                                            rules={{ required: t('validation.required') }}
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DateInput
                                            name="endDate"
                                            label={t('label.endDate')}
                                            defaultValue={formatDate(saleCampaign?.endDate, 'dd/MM/yyyy') || null}
                                            error={errors?.endDate}
                                            rules={{ required: t('validation.required') }}
                                            control={control}
                                        />
                                    </Grid>
                                </Grid>
                                <CheckBox
                                    defaultChecked={saleCampaign?.isActive}
                                    label={t('label.active')}
                                    {...register('isActive')}
                                />
                            </Panel>
                        </Grid>
                        <Grid item xs={12}>
                            <CoverImageSelectionCard
                                title={t('title.image')}
                                {...{ image, setImage, fileName, folderName }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Panel title={t('label.dateInfos')}>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <DateInput
                                            name="checkInDate"
                                            label={t('label.checkInDate')}
                                            defaultValue={formatDate(saleCampaign?.checkInDate, 'dd/MM/yyyy') || null}
                                            error={errors?.checkInDate}
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DateInput
                                            name="checkOutDate"
                                            label={t('label.checkOutDate')}
                                            defaultValue={formatDate(saleCampaign?.checkOutDate, 'dd/MM/yyyy') || null}
                                            error={errors?.checkOutDate}
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DateInput
                                            name="saleStartDate"
                                            label={t('label.saleStartDate')}
                                            defaultValue={formatDate(saleCampaign?.saleStartDate, 'dd/MM/yyyy') || null}
                                            error={errors?.saleStartDate}
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DateInput
                                            name="saleEndDate"
                                            label={t('label.saleEndDate')}
                                            defaultValue={formatDate(saleCampaign?.saleEndDate, 'dd/MM/yyyy') || null}
                                            error={errors?.saleEndDate}
                                            control={control}
                                        />
                                    </Grid>
                                </Grid>
                            </Panel>
                        </Grid>
                        <Grid item xs={12}>
                            <Panel title={t('label.vendorInfos')}>
                                <VendorInput
                                    name="contact"
                                    control={control}
                                    defaultValue={saleCampaign?.contact}
                                    error={errors?.contact}
                                    multiple={false}
                                />
                                <Select
                                    name="paymentTypeId"
                                    error={errors?.paymentTypeId}
                                    label={t('label.paymentType')}
                                    defaultValue={saleCampaign?.paymentTypeId}
                                    control={control}>
                                    <MenuItem value={0}>{t('label.all')}</MenuItem>
                                    {Object.values(PaymentType).map(item => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {t(item.label)}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                        <Input
                                            type="number"
                                            label={t('label.minSaleTotal')}
                                            defaultValue={saleCampaign?.minSaleTotal}
                                            error={errors?.minSaleTotal}
                                            {...register('minSaleTotal', {
                                                setValueAs: v => {
                                                    if (typeof v === 'string') {
                                                        return parseFloat(v.replace(/,/, '.'));
                                                    } else {
                                                        return v;
                                                    }
                                                },
                                            })}
                                            onWheel={() => document.activeElement.blur()}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            type="number"
                                            label={t('label.maxSaleTotal')}
                                            defaultValue={saleCampaign?.maxSaleTotal}
                                            error={errors?.maxSaleTotal}
                                            {...register('maxSaleTotal', {
                                                setValueAs: v => {
                                                    if (typeof v === 'string') {
                                                        return parseFloat(v.replace(/,/, '.'));
                                                    } else {
                                                        return v;
                                                    }
                                                },
                                            })}
                                            onWheel={() => document.activeElement.blur()}
                                        />
                                    </Grid>
                                </Grid>
                            </Panel>
                        </Grid>
                        <Grid item xs={12}>
                            <Panel title={t('label.productInfos')}>
                                {Object.values(SaleCampaignsType).map(cam => (
                                    <SaleCampaignInput
                                        key={cam.id}
                                        name={`saleCampaignItems[${cam.id}]`}
                                        control={control}
                                        saleCampaignType={cam.id}
                                        label={t(cam.label)}
                                        multiple={true}
                                        vendor={watchVendor}
                                    />
                                ))}
                            </Panel>
                        </Grid>
                        <Grid item xs={12}>
                            <Panel title={t('label.discountInfos')}>
                                <Select
                                    name="saleCampaignGiven.discountTypeId"
                                    error={errors?.saleCampaignGiven?.discountTypeId}
                                    rules={{ required: t('validation.required') }}
                                    label={t('label.discountType')}
                                    defaultValue={saleCampaign?.saleCampaignGiven?.discountTypeId}
                                    control={control}>
                                    {Object.values(DiscountTypes).map(item => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {t(item.label)}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {watchDiscountTypeId === 3 && (
                                    <AdditionalServiceInput
                                        name="additionalServices"
                                        control={control}
                                        multiple={false}
                                        defaultValue={saleCampaign?.additionalServices}
                                    />
                                )}
                                <Input
                                    type="number"
                                    defaultValue={
                                        saleCampaign?.saleCampaignGiven?.discountRate || null
                                    }
                                    label={t('label.discountRate')}
                                    control={control}
                                    error={errors?.saleCampaignGiven?.discountRate}
                                    {...register('saleCampaignGiven.discountRate')}
                                    InputLabelProps={{
                                        shrink: watchDiscountRate ? true : false,
                                    }}
                                />
                                <Input
                                    type="number"
                                    defaultValue={
                                        saleCampaign?.saleCampaignGiven?.discountAmount || null
                                    }
                                    label={t('label.discountAmount')}
                                    control={control}
                                    error={errors?.saleCampaignGiven?.discountAmount}
                                    {...register('saleCampaignGiven.discountAmount')}
                                    InputLabelProps={{
                                        shrink: watchDiscountAmount ? true : false,
                                    }}
                                />
                                <Select
                                    name="saleCampaignGiven.currencyTypeId"
                                    defaultValue={
                                        saleCampaign?.saleCampaignGiven?.currencyTypeId || 1
                                    }
                                    error={errors?.saleCampaignGiven?.currencyTypeId}
                                    label={t('label.currencyType')}
                                    control={control}>
                                    {Object.values(CurrencyTypes).map(item => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Panel>
                        </Grid>
                    </Grid>
                    <ActionPanel />
                </form>
            </Container>
        </Page>
    );
};

export default SaleCampaignEditPage;
