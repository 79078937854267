import React, { useRef, useState } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import { EditorConfig } from '../EditorConfig';

import { Card, CardHeader } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Button from '@mui/material/Button';

import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/plugins.pkgd.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'froala-editor/js/languages/tr';
import '../styles/Editor.css';

const useStyles = makeStyles(theme => ({
    card: {
        //height: '100%',
        overflow: 'visible',
    },
    title: {
        fontSize: '1.2rem',
    },
}));

const Editor = ({ title, subtitle, value, onChange, expanded = false, elevation = 1 }) => {
    const classes = useStyles();
    const ref = useRef({ editor: null });

    const [isExpanded, setExpanded] = useState(expanded);

    const onModelChange = model => {
        onChange && onChange(model);
    };

    const toggle = () => {
        setExpanded(!isExpanded);
    };

    return (
        <Card className={classes.card} elevation={elevation}>
            <CardHeader
                title={title}
                subheader={subtitle}
                classes={{ title: classes.title }}
                onClick={toggle}
                action={
                    <Button color="secondary" onClick={toggle}>
                        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </Button>
                }
            />
            {isExpanded && (
                <FroalaEditor
                    ref={ref}
                    model={value}
                    onModelChange={onModelChange}
                    tag="textarea"
                    config={{ ...EditorConfig }}
                />
            )}
        </Card>
    );
};

export default Editor;
