import * as React from 'react';
import { useEffect, useRef } from 'react';
import { formatDate, formatDateForApi } from '../../util/DateUtil';
import Button from '@mui/material/Button';
import DateRangePickerPopup from './DateRangePickerPopup';
import { TableCell } from '@mui/material';

const DateFilterCell = ({ column, onSearch }) => {
    const process = useRef(null);
    const [value, setValue] = React.useState([null, null]);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    useEffect(() => {
        if (value[0] !== null && value[1] !== null) {
            setSearchData(value);
        }
    }, [value]);

    const setSearchData = value => {
        if (process.current) {
            clearTimeout(process.current);
        }
        process.current = setTimeout(() => {
            const columnName = `${column.searchField || column.name}`;
            const obj = {
                [columnName]: {
                    min: formatDateForApi(value[0]),
                    max: formatDateForApi(value[1]),
                },
            };
            onSearch(obj);
        }, 650);
    };

    const onClear = () => {
        setValue([null, null]);
        setSearchData([null, null]);
    };

    return (
        <TableCell
            style={{
                backgroundColor: 'rgb(249, 246, 243)',
                margin: 0,
                padding: '2px 2px 0px 8px',
                width: 110,
            }}>
            <div>
                <Button onClick={handleClick}>
                    {value[0] !== null && value[1] !== null
                        ? `${formatDate(value[0])}-${formatDate(value[1])}`
                        : 'Tümü'}
                </Button>
                <DateRangePickerPopup
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    value={value}
                    setValue={setValue}
                    onClear={onClear}
                />
            </div>
        </TableCell>
    );
};

export default DateFilterCell;
