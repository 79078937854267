import React, { useEffect, useState } from 'react';
import Page from '../../../layout/Page';
import { useTranslation } from 'react-i18next';
import usePagination from '../../../util/usePagination';
import tourSearchService from '../../../service/TourSearchService';
import { useForm } from 'react-hook-form';
import { ForecastTable } from '../../../components/ForecastTable';
import MinMaxSearchCellForecast from '../../../components/datatable/MinMaxSearchCellForecast';
import VendorSearch from '../../../components/datatable/VendorSearch';
import DepartureSearch from '../../../components/datatable/DepartureSearch';
import { MessageType } from '../../../util/Enums';
import { Container, Button, Grid, IconButton } from '@mui/material';
import ListPageActions from '../../../components/ListPageActions';
import { useHistory, useLocation } from 'react-router-dom';
import { YesNoTypeOptions } from '../../../components/YesNoTypeOptions';
import ForecastFilterDialog from './ForecastFilterDialog';
import useMessage from '../../../util/useMessage';
import Input from '../../../ui/Input';
import ForecastAddDialog from './ForecastAddDialog';
import RoomInput from '../../../ui/RoomInput';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import CategoryInput from '../../../ui/CategoryInput';
import visaTypeService from '../../../service/VisaTypeService';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { format } from 'date-fns';
import Tooltip from '@mui/material/Tooltip';
import SearchFilterCell from '../../../components/datatable/SearchFilterCell';
import categoryService from '../../../service/CategoryService';
import DateInput from '../../../ui/DateInput';
import { formatDate } from '../../../util/DateUtil';
import SubmitButton from '../../../ui/SubmitButton';
import Search from '@mui/icons-material/Search';
import EastIcon from '@mui/icons-material/East';
import {Card, CardHeader} from '@mui/material';
import vendorService from '../../../service/VendorService';

const TourSearchListPage = ({ title, ...props }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const showMessage = useMessage();

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        reset,
        watch,
        control,
        formState: { errors },
    } = useForm();
    // const watchAllFields = watch()
    const watchStartDate = watch('startDate');
    const watchEndDate = watch('endDate');
    const watchSpecialPeriod = watch('specialPeriod');
    const watchMonth = watch('month');
    const [personCounts, setPersonCounts] = useState([
        { adultCount: 2, childCount: 0, infantCount: 0 },
    ]);

    const [loading, setLoading] = useState(null);

    const [selected, setSelected] = useState([]);
    const [openYachtCatalogFilterDialog, setOpenYachtCatalogFilterDialog] = useState(false);

    const [openYachtForecastOfferAddDialog, setOpenYachtForecastOfferAddDialog] = useState(false);
    // const [clearFilter, setClearFilter] = useState(false);
    const [activities, setActivities] = useState({});
    // const [activities, setActivities] = useState(
    //     location?.state?.defaultSearchData?.destinations
    //         ? { destinations: location?.state?.defaultSearchData?.destinations }
    //         : {},
    // );
    const [activityInit, setActivityInit] = useState(false);
    const [defaultDestinations] = useState(location?.state?.destinationRegion || []);

    const [offer, setOffer] = useState(null);
    const [offerAdd, setOfferAdd] = useState(null);
    const [visaOptions, setVisaOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [vendorOptions, setVendorOptions] = useState([]);

    const [firstDate, setFirstDate] = useState(false);
    const [pageFilter, setPageFilter] = useState(false);

    useEffect(() => {
        if (location?.state?.defaultSearchData?.personCounts) {
            const personCountsList = location?.state?.defaultSearchData?.personCounts;
            location?.state?.defaultSearchData?.personCounts.forEach((personCount, personIndex) => {
                Object.entries(personCount).map(
                    ([key, value]) => (personCountsList[personIndex][key] = parseInt(value)),
                );
            });
            setPersonCounts(personCountsList);
        }
        (async () => {
            try {
                const { resultObject } = await visaTypeService.search({
                    start: 0,
                    data: {},
                    max: 50,
                });
                setVisaOptions([
                    { id: -1, name: 'Vizeli' },
                    { id: -2, name: 'Vizesiz' },
                    ...resultObject.map(item => ({ id: item.id, name: item.name })),
                ]);
            } catch (e) {
                console.log(e);
            }
        })();
        (async () => {
            try {
                const { resultObject } = await categoryService.search({
                    start: 0,
                    data: { categoryType: 10 },
                    max: 50,
                });
                setCategoryOptions([
                    ...resultObject.map(item => ({ id: item.id, name: item.name })),
                ]);
            } catch (e) {
                console.log(e);
            }
        })();
        (async () => {
            try {
                const { resultObject } = await vendorService.search({
                    start: 0,
                    data: {contactTypeId: 3},
                    max: 50,
                });
                setVendorOptions([
                    ...resultObject.map(item => ({ id: item.id, name: item.brandName })),
                ]);
            } catch (e) {
                console.log(e);
            }
        })();
    }, []);
    useEffect(() => {
        if (location?.state?.defaultSearchData) {
            if (location.state.defaultSearchData.startDate) {
                setValue('startDate', location.state.defaultSearchData.startDate);
            }
            if (location.state.defaultSearchData.endDate) {
                setValue('endDate', location.state.defaultSearchData.endDate);
            }
        }
    }, []);
    useEffect(() => {
        if (watchStartDate !== undefined) {
            if (firstDate) {
                const checkDate = new Date(watchStartDate);
                if(checkDate instanceof Date && !isNaN(checkDate.valueOf()) && watchStartDate !== '' && watchStartDate !== null) {
                    const newDate = new Date(watchStartDate);
                    newDate.setDate(newDate.getDate() + 30);
                    setValue('endDate', format(newDate, 'yyyy-MM-dd'));
                }
            } else {
                setFirstDate(true);
            }
        }
    }, [watchStartDate]);

    const columns = [
        {
            name: 'tourProgramId',
            label: 'ID',
            width: 10,
            type: Number,
            isId: true,
            searchable: true,
            searchField: 'tourProgramId',
        },
        {
            name: 'operationTitle',
            width: 75,
            label: t('forecast.operationTitle'),
            searchable: true,
            searchField: 'operationTitle',
        },
        {
            name: 'title',
            width: 200,
            label: t('forecast.title'),
            searchable: true,
            searchField: 'title',
        },
        {
            name: 'hotelName',
            label: t('forecast.hotelName'),
            searchable: true,
            searchField: 'hotelName',
            execute: item =>
                item && (
                    <Tooltip title={<p>{item}</p>}>
                        <div
                            style={{
                                whiteSpace: 'nowrap',
                                maxWidth: '100px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {item}
                        </div>
                    </Tooltip>
                ),
        },
        {
            name: 'night',
            label: 'Gece',
            searchable: true,
            type: Number,
            SearchCellComponent: MinMaxSearchCellForecast,
            searchField: 'night',
        },
        {
            name: 'transportCompany',
            label: "Ulaşım",
            searchable: true,
            searchField: 'transportCompanyValue',
            execute: item =>
                item && (
                    <Tooltip title={<p>{item.name || item}</p>}>
                        <div style={{
                                whiteSpace: 'nowrap',
                                maxWidth: '70px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>{item.value || item}</div>
                    </Tooltip>
                ),
        },
        // {
        //     name: 'departure',
        //     label: t('forecast.departure'),
        //     searchable: true,
        //     searchField: 'departureId',
        //     type: Number,
        //     SearchCellComponent: DepartureSearch,
        //     execute: item =>
        //         item && (
        //             <Tooltip title={<p>{item}</p>}>
        //                 <div
        //                     style={{
        //                         whiteSpace: 'nowrap',
        //                         maxWidth: '160px',
        //                         overflow: 'hidden',
        //                         textOverflow: 'ellipsis',
        //                     }}>
        //                     {item}
        //                 </div>
        //             </Tooltip>
        //         ),
        // },
        {
            name: 'departure',
            label: t('forecast.departure'),
            searchable: true,
            searchField: 'departure',
            execute: item =>
                item && (
                    <Tooltip title={<p>{item}</p>}>
                        <div style={{
                                whiteSpace: 'nowrap',
                                maxWidth: '70px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>{item.split(' ')[0]}</div>
                    </Tooltip>
                ),
        },
        // {
        //     name: "isExtraTour",
        //     label: t('forecast.extraTour'),
        //     type: Boolean,
        //     execute: (item) => t(item === true ? "Evet" : "Hayır"),
        //     searchable: true,
        //     searchType: 'select',
        //     selectOptions: <YesNoTypeOptions/>
        // },
        {
            name: 'price',
            label: t('forecast.price'),
            searchable: true,
            type: Number,
            SearchCellComponent: MinMaxSearchCellForecast,
            searchField: 'price',
        },
        {
            name: 'visaTypes',
            label: t('forecast.visa'),
            width: 75,
            type: Number,
            searchable: true,
            sortable: false,
            searchType: 'select',
            searchField: 'visaTypeId',
            selectOptions: visaOptions.map(item => (
                <option key={item.id} value={item.id}>
                    {item.name}
                </option>
            )),
        },
        {
            name: 'destinations',
            label: "Gidilecek",
            searchable: false,
            sortable: false,
            // searchField: "destinationId",
            execute: item =>
                item && (
                    <Tooltip title={<p>{item.map(item => item.title).join(', ')}</p>}>
                        <div
                            style={{
                                whiteSpace: 'nowrap',
                                maxWidth: '75px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {item.map(item => item.title).join(', ')}
                        </div>
                    </Tooltip>
                ),
        },
        {
            name: 'accommodations',
            label: "Kalınacak",
            searchable: false,
            sortable: false,
            // searchField: "accommodationId",
            execute: item =>
                item && (
                    <Tooltip title={<p>{item.map(item => item.title).join(', ')}</p>}>
                        <div
                            style={{
                                whiteSpace: 'nowrap',
                                maxWidth: '75px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {item.map(item => item.title).join(', ')}
                        </div>
                    </Tooltip>
                ),
        },
        {
            name: 'contact',
            label: t('forecast.vendor'),
            searchable: true,
            sortable: false,
            searchField: 'contactId',
            searchType: 'select',
            type: Number,
            // SearchCellComponent: VendorSearch,
            selectOptions: vendorOptions.map(item => (
                <option key={item.id} value={item.id}>
                    {item.name}
                </option>
            )),
            execute: item =>
                item?.brandName && (
                    <Tooltip title={<p>{item.title}</p>}>
                        <div
                            style={{
                                whiteSpace: 'nowrap',
                                maxWidth: '160px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {item.brandName}
                        </div>
                    </Tooltip>
                ),
        },
        {
            name: 'category',
            label: 'Kategori',
            width: 75,
            type: Number,
            searchable: true,
            sortable: false,
            searchType: 'select',
            searchField: 'categoryId',
            selectOptions: categoryOptions.map(item => (
                <option key={item.id} value={item.id}>
                    {item.name}
                </option>
            )),
            execute: item =>
                item && (
                    <Tooltip title={<p>{item}</p>}>
                        <div
                            style={{
                                whiteSpace: 'nowrap',
                                maxWidth: '50px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {item}
                        </div>
                    </Tooltip>
                ),
        },
    ];

    const yachts = usePagination({
        service: tourSearchService,
        defaultOrderBy: { name: 'tourProgramId', orderType: 'desc' },
        custom: true,
        defaultSearchData: { personCounts }
        // defaultSearchData:
        //     location.state !== undefined &&
        //     location.state !== null &&
        //     location.state.defaultSearchData !== undefined &&
        //     location.state.defaultSearchData !== null
        //         ? location.state.defaultSearchData
        //         : { personCounts },
    });

    const onAddOfferAddAction = value => {
        setOfferAdd(value);
    };

    // useEffect(() => {
    //     setClearFilter((Object.values(getValues()).every(item => item === undefined || item === null || item === "") && Object.keys(activities).length === 0) ? true : false);
    // }, [watchAllFields]);

    useEffect(() => {
        if (yachts.data?.length > 0) {
            const requiredList = yachts.data
                .filter(item => item.isRequired === true)
                .map(item => item);
            const dataList = selected.filter(item => item.isRequired !== true).map(item => item);
            setSelected([...dataList, ...requiredList]);
        }
    }, [yachts.data]);

    useEffect(() => {
        if (activityInit) {
            yachts.setSearchData({ ...yachts.searchData, ...activities });
        } else {
            setActivityInit(true);
        }
    }, [activities]);

    useEffect(() => {
        if (offer !== null) {
            let active = true;
            setLoading(true);
            const offerItem = offer;
            const yachtList = [];
            offer?.yachts?.forEach(item => {
                yachtList.push({ yachtId: item.yachtId });
            });
            offerItem.yachts = yachtList;
            if (
                (offerItem.offerDescription === null || offerItem.offerDescription === '') &&
                offerItem.name !== ''
            ) {
                offerItem.offerDescription = `<p>Dear ${offerItem.name}<br><br>You can find the following Gulets Available out Of Bodrum. Please check these options; however, if they are not suitable / or what you are looking for regarding Dates, Class, budget, route, or any other reason, please let me know. I can always help you with more options, as we have an extensive fleet selection. Therefore please don’t hesitate to let me know any questions!<br><br><br></p>`;
            }
            (async () => {
                try {
                    const { isSuccess } = await tourSearchService.generateOffer({
                        ...offerItem,
                        // stayLength: offerItem.stayLength && offerItem.stayLength !== null ? parseInt(offerItem.stayLength) : 7
                    });
                    if (active && isSuccess) {
                        showMessage('Teklif Oluşturuldu.');
                        setOffer(null);
                        setSelected([]);
                        setLoading(false);
                    }
                } catch (e) {
                    setLoading(false);
                }
            })();
            return () => {
                active = false;
            };
        }
    }, [offer]);

    useEffect(() => {
        if (offerAdd !== null) {
            let active = true;
            setLoading(true);
            const offerItem = offerAdd;
            const yachtList = [];
            offerAdd?.tourPrograms?.forEach(item => {
                yachtList.push({ tourProgramId: item.tourProgramId, hotelId: item.hotelId });
            });
            offerItem.tourPrograms = yachtList;
            (async () => {
                try {
                    const dates =
                        (watchStartDate && watchStartDate !== '') ||
                        (watchEndDate && watchEndDate !== '')
                            ? {
                                  startDate:
                                      watchStartDate && watchStartDate !== ''
                                          ? watchStartDate
                                          : null,
                                  endDate:
                                      watchEndDate && watchEndDate !== '' ? watchEndDate : null,
                              }
                            : watchSpecialPeriod !== undefined && watchSpecialPeriod !== undefined
                              ? {
                                    startDate: format(
                                        new Date(watchSpecialPeriod.periodStartDate),
                                        'yyyy-MM-dd',
                                    ),
                                    endDate: format(
                                        new Date(watchSpecialPeriod.periodEndDate),
                                        'yyyy-MM-dd',
                                    ),
                                }
                              : watchMonth !== undefined && watchMonth !== null
                                ? {
                                      startDate: format(
                                          new Date(
                                              new Date().getFullYear(),
                                              watchMonth.numberValue - 1,
                                              1,
                                          ),
                                          'yyyy-MM-dd',
                                      ),
                                      endDate: format(
                                          new Date(
                                              new Date().getFullYear(),
                                              watchMonth.numberValue,
                                              0,
                                          ),
                                          'yyyy-MM-dd',
                                      ),
                                  }
                                : {};
                    const { isSuccess } = await tourSearchService.addOffer({
                        ...offerItem,
                        ...dates,
                        personCounts,
                    });
                    if (active && isSuccess) {
                        setOpenYachtForecastOfferAddDialog(false);
                        showMessage(t('reservation.addedOffer'));
                        setOfferAdd(null);
                        setSelected([]);
                        setLoading(false);
                    }
                } catch (e) {
                    setLoading(false);
                }
            })();
            return () => {
                active = false;
            };
        }
    }, [offerAdd]);

    const onSubmit = async data => {
        if (loading) return false;
        const obj = {
            ...yachts.searchData,
            ...activities,
            startDate: data.startDate && data.startDate !== '' ? data.startDate : null,
            endDate: data.endDate && data.endDate !== '' ? data.endDate : null,
            specialPeriodId: data.specialPeriod ? data.specialPeriod.id : null,
            monthId: data.month ? data.month.id : null,
            personCounts,
        };
        yachts.setSearchData({ ...obj });
    };

    const onAddAction = value => {
        setActivities(value);
        setOpenYachtCatalogFilterDialog(false);
    };

    const onAddCheck = () => {
        setOpenYachtForecastOfferAddDialog(true);
        // if((watchStartDate !== undefined && watchStartDate !== "") ||
        // (watchEndDate !== undefined && watchEndDate !== "") ||
        // (watchMonth !== undefined && watchMonth !== null) ||
        // (watchSpecialPeriod !== undefined && watchSpecialPeriod !== null)) {
        //     setOpenYachtForecastOfferAddDialog(true);
        // } else {
        //     showMessage("Lütfen tarih girin.", MessageType.ERROR);
        // }
    };

    const onFilterSubmit = async data => {
        if (loading) return false;
        const obj = {
            ...data,
            startDate: data.startDate && data.startDate !== '' ? data.startDate : null,
            endDate: data.endDate && data.endDate !== '' ? data.endDate : null,
            departure: data.departure ? data.departure.name : null,
            personCounts,
        };
        yachts.setSearchData({ ...obj });
        setPageFilter(true);
    };

    const ActionButtons = () => (
        <ListPageActions
            left={
                <>
                    <div
                        style={{
                            display: 'inline-block',
                            marginRight: '10px',
                            height: '29px',
                            lineHeight: '34px',
                            color: '#666666',
                        }}>
                        {selected.length} adet seçildi.
                    </div>
                    {selected.length > 0 && (
                        <>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    marginRight: 'auto',
                                }}
                                onClick={onAddCheck}>
                                {t('forecast.addToOffer')}
                            </Button>
                        </>
                    )}
                </>
            }></ListPageActions>
    );

    return (
        <Page title={title}>
            {pageFilter ? 
                <Container maxWidth={false} sx={{ maxWidth: '1700px' }}>
                    <ActionButtons />
                    <ForecastTable
                        data={yachts}
                        columns={columns}
                        {...yachts}
                        {...{ selected, setSelected, activities, setActivities }}
                        action={
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={1}>
                                    <Grid container spacing={1} item xs={12} md={10} lg={10}>
                                        <Grid item xs={12} md={4} lg={2}>
                                            <DateInput
                                                name="startDate"
                                                label={t('label.startDate')}
                                                defaultValue={null}
                                                control={control}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4} lg={2}>
                                            <DateInput
                                                name="endDate"
                                                label={t('label.endDate')}
                                                defaultValue={null}
                                                control={control}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4} lg={2}>
                                            <CategoryInput
                                                name="specialPeriod"
                                                control={control}
                                                categoryType={1}
                                                label={t('label.specialPeriod')}
                                                multiple={false}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4} lg={2}>
                                            <CategoryInput
                                                name="month"
                                                control={control}
                                                categoryType={9}
                                                label={t('label.month')}
                                                multiple={false}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4} lg={4}>
                                            <RoomInput
                                                val={personCounts}
                                                setVal={setPersonCounts}
                                                onChange={(fieldType, updateType, fieldIndex) => {
                                                    setPersonCounts(prev => {
                                                        const updatedFilter = [...prev];
                                                        updatedFilter[fieldIndex][fieldType] =
                                                            updateType === 'increased'
                                                                ? personCounts[fieldIndex][fieldType] +
                                                                1
                                                                : personCounts[fieldIndex][fieldType] -
                                                                1;
                                                        return updatedFilter;
                                                    });
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={1} lg={2} sx={{ display: 'flex' }}>
                                        <IconButton
                                            aria-label="Search"
                                            size="small"
                                            type="submit"
                                            sx={{ marginTop: '8px' }}>
                                            <SearchIcon fontSize="medium" />
                                        </IconButton>
                                        <Button
                                            component="label"
                                            aria-label="Filter"
                                            role={undefined}
                                            sx={{ marginTop: '8px', marginLeft: 'auto' }}
                                            startIcon={<FilterListIcon />}
                                            onClick={() => {
                                                setOpenYachtCatalogFilterDialog(true);
                                            }}>
                                            FİLTRELE
                                        </Button>
                                        {/* <IconButton
                                            aria-label="Filtreleri Kaldır" 
                                            size="small"
                                            sx={{ marginTop: "8px" }}
                                            disabled={clearFilter}
                                            onClick={() => {
                                                setValue("startDate", null);
                                                setValue("endDate", null);
                                                reset();
                                                setPersonCounts([{ "adultCount": 2, "childCount": 0, "infantCount": 0 }])
                                                setActivities({});
                                            }}>
                                            <FilterListOffIcon fontSize="medium"/>
                                        </IconButton> */}
                                    </Grid>
                                </Grid>
                            </form>
                        }
                        setOtherFilters={setActivities}
                    />
                </Container> : 
                <Container maxWidth="lg">
                    <Card sx={{padding: "20px"}}>
                        <form onSubmit={handleSubmit(onFilterSubmit)}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={4} lg={2}>
                                    <DateInput
                                        name="startDate"
                                        label={t('label.startDate')}
                                        defaultValue={null}
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={2}>
                                    <DateInput
                                        name="endDate"
                                        label={t('label.endDate')}
                                        defaultValue={null}
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={2}>
                                    <Input
                                        label="Başlık"
                                        {...register('title')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={2}>
                                    <CategoryInput
                                        name="departure"
                                        control={control}
                                        categoryType={2}
                                        label="Kalkış"
                                        multiple={false}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={2}>
                                    <Input
                                        label="Operasyon"
                                        {...register('operationTitle')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={2} sx={{ display: 'flex' }}>
                                    <SubmitButton sx={{ marginTop: '8px', height: "40px" }} loading={loading} startIcon={<Search />}>
                                        Ara
                                    </SubmitButton>
                                    <Button
                                        variant="contained"
                                        disabled={loading}
                                        endIcon={loading ? null : <EastIcon />}
                                        sx={{ marginTop: '8px', height: "40px", marginLeft: '10px' }}
                                        onClick={() => {
                                            setValue('startDate', null);
                                            setValue('endDate', null);
                                            setPageFilter(true);
                                        }}>
                                        GEÇ
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Card>
                </Container>
            }
            

            <ForecastAddDialog
                loading={loading}
                offerId={
                    location.state && location.state.saleOfferFormId
                        ? location.state.saleOfferFormId
                        : null
                }
                open={openYachtForecastOfferAddDialog}
                selected={selected}
                onAddAction={onAddOfferAddAction}
                onClose={() => {
                    setOpenYachtForecastOfferAddDialog(false);
                }}
            />

            <ForecastFilterDialog
                open={openYachtCatalogFilterDialog}
                onAddAction={onAddAction}
                item={activities}
                onClose={() => {
                    setOpenYachtCatalogFilterDialog(false);
                }}
                defaultDestinations={defaultDestinations}
            />
        </Page>
    );
};

export default TourSearchListPage;
