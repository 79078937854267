import React from 'react';
import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import trLocale from 'date-fns/locale/tr';
import DatePicker from '@mui/lab/DatePicker';
import { format } from 'date-fns';

const DateRangeInput = ({ name, label, rules, control, error, defaultValue, disabled, onChangeDate }) => {
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={trLocale}>
                    <DatePicker
                        {...field}
                        label={label}
                        value={field.value}
                        disabled={disabled}
                        mask='__.__.____'
                        onChange={(value) => {
                            if(value instanceof Date && !isNaN(value.valueOf())) {
                                field.onChange(format(value, 'yyyy-MM-dd'));
                            } else {
                                field.onChange(value);
                            }
                            onChangeDate && onChangeDate();
                        }}
                        renderInput={(params) => 
                            <TextField 
                                {...params} 
                                error={!!error} 
                                helperText={error?.message} 
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                autoComplete="off"
                                disabled={disabled}
                            />
                        }
                    />
                </LocalizationProvider>
            )}
        />
    );
};

export default DateRangeInput;
