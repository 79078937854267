import BaseService from './BaseService';
import { easyGet } from '../util/Fetch';

class ExchangeRateService extends BaseService {
    constructor() {
        super('/common/exchangeRates');
    }

    async getCurrencyTypes() {
        return await easyGet(`/common/currencyTypes`);
    }
}

const exchangeRateService = new ExchangeRateService();

export default exchangeRateService;
