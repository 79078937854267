import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import SubmitButton from '../ui/SubmitButton';
import AddIcon from '@mui/icons-material/Add';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import parameterService from '../service/ParameterService';
import { MessageType, ParameterTypes, YesNoType } from '../util/Enums';
import useMessage from '../util/useMessage';
import Panel from '../ui/Panel';
import Input from '../ui/Input';
import Select from '../ui/Select';
import MenuItem from '@mui/material/MenuItem';

const DeparturePortAddDialog = ({ open, inputValue, onClose, onAddCallback }) => {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    const [loading, setLoading] = useState(false);

    const showMessage = useMessage();

    const onSubmit = async data => {
        if (loading) return false;
        try {
            setLoading(true);

            const obj = {
                ...data,
                isDefault: data.isDefault === 1 ? true : false,
            };
            const { resultObject } = await parameterService.saveOrUpdate(0, obj);
            showMessage(t('message.created'));

            onAddCallback && onAddCallback(resultObject);
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} fullWidth maxWidth="sm" scroll="paper">
            <DialogTitle>{t('title.createGeneralParameters')}</DialogTitle>
            <DialogContent dividers={true}>
                <Panel title={t('title.parameterInfo')}>
                    <Select
                        name="type"
                        defaultValue={ParameterTypes.DEPARTURE_PORT.id}
                        label={t('label.generalParameterType')}
                        error={errors?.type}
                        control={control}
                        rules={{ required: t('validation.required') }}>
                        <MenuItem selected="selectd" value={ParameterTypes.DEPARTURE_PORT.id}>
                            {t(ParameterTypes.DEPARTURE_PORT.label)}
                        </MenuItem>
                    </Select>
                    <Input
                        name="name"
                        defaultValue={inputValue || ''}
                        label={t('label.name')}
                        error={errors?.name}
                        {...register('name', {
                            required: t('validation.required'),
                        })}
                    />
                    <Input
                        name="value"
                        label={t('label.value')}
                        error={errors?.value}
                        {...register('value', {
                            required: t('validation.required'),
                        })}
                    />
                    <Select
                        name="isDefault"
                        label={t('label.isDefault')}
                        defaultValue={0}
                        rules={{ required: t('validation.required') }}
                        error={errors?.isDefault}
                        control={control}>
                        {Object.values(YesNoType).map(item => (
                            <MenuItem key={item.id} value={item.id}>
                                {t(item.label)}
                            </MenuItem>
                        ))}
                    </Select>
                </Panel>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" disabled={loading}>
                    {t('button.cancel')}
                </Button>
                <SubmitButton
                    type="button"
                    loading={loading}
                    startIcon={<AddIcon />}
                    onClick={handleSubmit(onSubmit)}>
                    {t('button.add')}
                </SubmitButton>
            </DialogActions>
        </Dialog>
    );
};

export default DeparturePortAddDialog;
