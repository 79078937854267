import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import SubmitButton from '../ui/SubmitButton';
import AddIcon from '@mui/icons-material/Add';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RegionEditInputPanel from '../pages/common/regions/RegionEditInputPanel';
import regionService from '../service/RegionService';
import { MessageType } from '../util/Enums';
import useMessage from '../util/useMessage';

const RegionAddDialog = ({ open, inputValue, onClose, onAddCallback }) => {
    const { t } = useTranslation();
    const methods = useForm();

    const [loading, setLoading] = useState(false);

    const [flag, setFlag] = useState(null);
    const [images, setImages] = useState(null);
    const [fileNameParam, setFileNameParam] = useState('');
    const [folderName] = useState('region');

    const showMessage = useMessage();

    useEffect(() => {
        if (open) {
            methods.reset();
            setImages(null);
            setFlag(null);
            setFileNameParam('');
        }
    }, [open]);

    const onSubmit = async data => {
        if (loading) return false;
        try {
            setLoading(true);
            const compileImages = flag !== null ? [...images, { ...flag, imageTypeId: 3 }] : images;
            const obj = {
                ...data,
                images: compileImages,
                volume: data?.volume ? parseInt(data.volume) : 0,
                springDegree: data?.springDegree ? parseFloat(data.springDegree) : null,
                summerDegree: data?.summerDegree ? parseFloat(data.summerDegree) : null,
                winterDegree: data?.winterDegree ? parseFloat(data.winterDegree) : null,
                autumnDegree: data?.autumnDegree ? parseFloat(data.autumnDegree) : null,
            };

            const { resultObject } = await regionService.saveOrUpdate(undefined, obj);
            showMessage(t('message.created'));

            onAddCallback &&
                onAddCallback({
                    id: resultObject.regionId,
                    name: data.name,
                });
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} fullWidth maxWidth="sm" scroll="paper">
            <DialogTitle>{t('title.addRegionDialog')}</DialogTitle>
            <DialogContent dividers={true}>
                <FormProvider {...methods}>
                    <RegionEditInputPanel
                        {...{
                            name: inputValue,
                            images,
                            setImages,
                            flag,
                            setFlag,
                            fileNameParam,
                            folderName,
                            added: true,
                        }}
                    />
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" disabled={loading}>
                    {t('button.cancel')}
                </Button>
                <SubmitButton
                    type="button"
                    loading={loading}
                    startIcon={<AddIcon />}
                    onClick={methods.handleSubmit(onSubmit)}>
                    {t('button.add')}
                </SubmitButton>
            </DialogActions>
        </Dialog>
    );
};

export default RegionAddDialog;
