import { useTranslation } from 'react-i18next';
import Panel from '../../../ui/Panel';
import Grid from '@mui/material/Grid';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { StyledTableCell } from '../../../components/DataTable';
import {
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
} from '@mui/material';
import { priceGridDayTabFormat } from '../../../util/DateUtil';
import makeStyles from '@mui/styles/makeStyles';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { MessageType } from '../../../util/Enums';
import useMessage from '../../../util/useMessage';
import tourPriceService from '../../../service/TourPriceService';
import TourPriceEditApplyGridPriceDialog from './TourPriceEditApplyGridPriceDialog';
import Loading from '../../../components/Loading';

const INPUT_TIMEOUT = 500;

const useStyles = makeStyles(theme => ({
    indicator: {
        backgroundColor: '#000000',
        height: '3px',
    },
    root: {
        backgroundColor: 'rgb(81, 105, 130)',
    },
    tabRoot: {
        color: '#bfcddd',
    },
    tabSelected: {
        color: 'white !important',
    },
    content: {
        position: "relative"
    },
    loading: {
        position: "absolute",
        left: "0",
        right: "0",
        top: "0",
        bottom: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        pointerEvents: "none"
    }
}));

const EditableCell = React.memo(({ index, name, item, type = 'text', handleOnChange }) => {
    const handleFocus = event => event.target.select();
    const {
        register,
        formState: { isValid, errors },
    } = useFormContext({ mode: 'onChange' });

    const isError =
        errors['period'] &&
        errors['period']['accommodations'] &&
        errors['period']['accommodations'][index] &&
        errors['period']['accommodations'][index][name];

    return (
        <TableCell className={`${type === 'text' ? 'priceGridCell' : ''}`}>
            <input
                type={type}
                data-name={name}
                data-index={index}
                className={`${type === 'text' ? 'priceGridInput' : ''} ${isError ? 'priceGridInput-error' : ''}`}
                onFocus={handleFocus}
                {...(type === 'text' && { defaultValue: item[name] })}
                {...(type === 'checkbox' && { defaultChecked: item[name] || false })}
                {...register(`period.accommodations.${index}.${name}`, {
                    required: type === 'text',
                    onChange: handleOnChange,
                    ...(type === 'text' && {
                        setValueAs: v => {
                            if (typeof v === 'string') {
                                return parseFloat(v.replace(/,/, '.'));
                            } else {
                                return v;
                            }
                        },
                    }),
                })}
            />
        </TableCell>
    );
});

const NormalCell = React.memo(({ index, item, name }) => {
    const { register } = useFormContext();

    return (
        <TableCell className="priceGridCell">
            <input
                className="priceGridReadOnlyInput"
                defaultValue={item[name]}
                {...register(`period.accommodations.${index}.${name}`, {
                    setValueAs: v => {
                        if (typeof v === 'string') {
                            return parseFloat(v.replace(/,/, '.'));
                        } else {
                            return v;
                        }
                    },
                })}
                readOnly
            />
        </TableCell>
    );
});

const TableInput = React.memo(
    ({ name, type = 'text', number = false, value, defaultValue, handleOnChange }) => {
        const {
            register,
            formState: { isValid, errors },
        } = useFormContext({ mode: 'onChange' });

        const isError = errors['period'] && errors['period'][name];
        const handleFocus = event => event.target.select();

        return (
            <input
                type={type}
                data-name={name}
                onFocus={handleFocus}
                {...(type === 'text' && value === undefined && { defaultValue: defaultValue })}
                {...(type === 'text' && defaultValue === undefined && { value: value })}
                {...(type === 'checkbox' && { defaultChecked: defaultValue || false })}
                className={`${type === 'text' ? 'priceGridInput' : ''} ${isError ? 'priceGridInput-error' : ''}`}
                {...register(`period.${name}`, {
                    required: type === 'text',
                    ...(type === 'text' &&
                        number === false && {
                            setValueAs: v => {
                                if (typeof v === 'string') {
                                    return parseFloat(v.replace(/,/, '.'));
                                } else {
                                    return v;
                                }
                            },
                        }),
                    ...(number === true && {
                        pattern: {
                            value: /^[0-9]+$/,
                        },
                    }),
                    onChange: handleOnChange,
                })}
            />
        );
    },
);

const TableCurrencySelect = React.memo(({ name, defaultValue, handleOnChange }) => {
    const {
        register,
        formState: { isValid, errors },
    } = useFormContext({ mode: 'onChange' });

    const isError = errors['period'] && errors['period'][name];
    // const handleFocus = (event) => event.target.select();

    return (
        <select
            name={name}
            data-name={name}
            // onFocus={handleFocus}
            defaultValue={defaultValue}
            className={`priceGridInput ${isError ? 'priceGridInput-error' : ''}`}
            {...register(`period.${name}`, {
                required: true,
                onChange: handleOnChange,
            })}>
            <option value={3}>EUR</option>
            <option value={2}>USD</option>
            <option value={4}>GBP</option>
            <option value={1} selected>
                TRY
            </option>
        </select>
    );
});

const Columns = [
    { name: 'commissionRate', type: 'text', readOnly: false },
    { name: 'purchasePrice', type: 'text', readOnly: true },
    { name: 'salePrice', type: 'text', readOnly: true },
    { name: 'discountRate', type: 'text', readOnly: false },
    { name: 'discountConstant', type: 'text', readOnly: false },

    { name: 'discountPurchasePrice', type: 'text', readOnly: true },
    { name: 'discountSalePrice', type: 'text', readOnly: true },
    { name: 'totalPurchasePrice', type: 'text', readOnly: true },
    { name: 'totalSalePrice', type: 'text', readOnly: true },

    // {name: "quota", type: "text", readOnly: false},
    // {name: "stop", type: "checkbox", readOnly: false},
];

const TourPriceEditGrid = React.forwardRef(
    (
        {
            periodMap,
            setPeriodMap,
            applyFormData,
            onSubmit,
            selectedHotel,
            commissionCalculationType,
            singleRoomDifference,
            editLoading,
        },
        ref,
    ) => {
        const classes = useStyles();
        const { t } = useTranslation();
        const showMessage = useMessage();

        const timeout = useRef(null);

        const [load, setLoad] = useState(0);
        const [monthTab, setMonthTab] = useState(null);
        const [dayTab, setDayTab] = useState(0);
        const [month, setMonth] = useState(null);

        const methods = useForm({ mode: 'onChange' });

        const [isOpenApplyPriceDialog, setOpenApplyPriceDialog] = useState(false);

        const [dayTabOnChangeData, setDayTabOnChangeData] = useState(null);
        const [monthTabOnChangeData, setMonthTabOnChangeData] = useState(null);

        const [isPriceLoading, setPriceLoading] = useState(false);

        const [isEdited, setEdited] = useState(false);
        const [isSubmit, setSubmit] = useState(false);

        const [loading, setLoading] = useState(false);

        const [discountValue, setDiscountValue] = useState({
            rate: 0,
            constant: 0,
            isEdited: false,
        });

        const applyCallback = useRef(null);

        useEffect(() => {
            if(periodMap.size > 0){
                setLoad(null);
                setMonthTab(periodMap.keys().next().value);
            }
            if (load > 20 || load === null) return;
            const intervalId = setInterval(() => {
                setLoad(load + 1);
            }, 1000);
            return () => clearInterval(intervalId);
          }, [load]);

        useEffect(() => {
            setLoading(true);
            setTimeout(() => {
                initInputFirstValues();
            }, 50);
        }, [dayTab, monthTab, periodMap]);

        useEffect(() => {
            onBasePriceInputChangeHandler({ discountEdited: discountValue.isEdited });
        }, [discountValue]);

        const initInputFirstValues = () => {
            methods.reset();
            setSubmit(false);
            setEdited(false);
            applyCallback.current = null;
            setOpenApplyPriceDialog(false);

            const _month = periodMap.get(monthTab);
            if (Array.isArray(_month)) {
                const day = _month[dayTab];
                const fields = [
                    'perPersonPrice',
                    'singleRoomPrice',
                    'additionalPriceAdt',
                    'additionalPriceChd',
                    'additionalPriceInf',
                    'childPrice1',
                    'childPrice2',
                    'periodDateQuota',
                    'periodDateStop',
                    'periodDateDiscountRate',
                    'periodDateDiscountConstant',
                    'periodDateDiscountCurrency',
                ];
                if (day) {
                    fields.forEach(field => {
                        if (day.hasOwnProperty(field)) {
                            methods.setValue(`period.${field}`, day[field], {
                                shouldValidate: true,
                                shouldDirty: true,
                            });
                        }
                    });

                    const accommodationsFields = [
                        ...Columns.map(item => item.name),
                        'adultCount',
                        'childCount',
                        'infantCount',
                    ];

                    day.accommodations?.forEach((item, index) => {
                        accommodationsFields.forEach(column => {
                            methods.setValue(
                                `period.accommodations.${index}.${column}`,
                                item[column],
                                {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                },
                            );
                        });
                    });
                }
            }
            setMonth(_month);
            setLoading(false);
            setDiscountValue(
                _month
                    ? {
                          rate: _month[dayTab]?.periodDateDiscountRate,
                          constant: _month[dayTab]?.periodDateDiscountConstant,
                          isEdited: false,
                      }
                    : { rate: 0, constant: 0, isEdited: false },
            );
        };

        useImperativeHandle(ref, () => ({
            isValid: async () => {
                await methods.trigger();
                return methods.formState.isValid;
            },
            getData: async () => {
                return methods.getValues();
            },

            apply: callback => {
                if (isEdited) {
                    setOpenApplyPriceDialog(true);
                    setSubmit(false);

                    applyCallback.current = callback;
                }
            },

            submit: async () => {
                if (isEdited) {
                    setOpenApplyPriceDialog(true);
                    setSubmit(true);
                }
            },
            isEdited: () => {
                return isEdited;
            },
        }));

        const applyGridValue = async applyType => {
            const { accommodations, ...periodData } = methods.getValues().period;
            const periods = [];

            const data = [];
            [...periodMap.values()].forEach(d => {
                data.push(...d);
            });

            const start = data.findIndex(
                item => item.tourProgramPeriodId === month[dayTab].tourProgramPeriodId,
            );

            switch (applyType) {
                case 'thisPeriod':
                    {
                        const period = mergePeriodData(data[start], periodData, accommodations);
                        periods.push(period);
                    }
                    break;
                case 'thisAndNextPeriods':
                    {
                        for (let i = start; i < data.length; i++) {
                            const period = mergePeriodData(data[i], periodData, accommodations);
                            periods.push(period);
                        }
                    }
                    break;
                case 'allPeriods':
                    {
                        for (let i = 0; i < data.length; i++) {
                            const period = mergePeriodData(data[i], periodData, accommodations);
                            periods.push(period);
                        }
                    }
                    break;
                case 'rollback': {
                    initInputFirstValues();
                    return;
                }
            }

            applyFormData && applyFormData(periods);

            if (monthTabOnChangeData) {
                setMonthTab(monthTabOnChangeData);
                setDayTab(0);
                setEdited(false);
            } else if (dayTabOnChangeData) {
                setDayTab(dayTabOnChangeData);
                setEdited(false);
            }

            if (isSubmit && onSubmit) {
                await onSubmit();
            }

            if (applyCallback.current) {
                applyCallback.current();
            }

            setOpenApplyPriceDialog(false);
        };

        const mergePeriodData = (item, formData, accommodations) => {
            const period = {
                ...item,
                ...formData,
                isChecked: true,
            };

            period.accommodations.forEach((item, index) => {
                period.accommodations[index] = {
                    ...item,
                    ...accommodations[index],
                };
            });

            return period;
        };

        const onMonthTabChange = async (event, newValue) => {
            if (isPriceLoading || loading) {
                return;
            }
            await methods.trigger();

            if (methods.formState.isValid) {
                if (isEdited) {
                    setMonthTabOnChangeData(newValue);
                    setDayTabOnChangeData(null);
                    setOpenApplyPriceDialog(true);
                } else {
                    setMonthTab(newValue);
                    setDayTab(0);
                    setEdited(false);
                }
            } else {
                showMessage(t('message.invalidatePriceForm'), MessageType.ERROR);
                setMonthTabOnChangeData(null);
                setDayTabOnChangeData(null);
            }
        };

        const onDayTabChange = async (event, newValue) => {
            if (isPriceLoading || loading) {
                return;
            }
            await methods.trigger();

            if (methods.formState.isValid) {
                if (isEdited) {
                    setDayTabOnChangeData(newValue);
                    setMonthTabOnChangeData(null);
                    setOpenApplyPriceDialog(true);
                } else {
                    setDayTab(newValue);
                    setEdited(false);
                }
            } else {
                showMessage(t('message.invalidatePriceForm'), MessageType.ERROR);
                setDayTabOnChangeData(null);
                setMonthTabOnChangeData(null);
            }
        };

        const GetDayTabLabel = ({ item }) => {
            const formatDate = priceGridDayTabFormat(item);
            const stop = item?.periodDateStop || false;
            const quota = item?.periodDateQuota || 0;
            return (
                <div
                    style={{
                        backgroundColor: item.isChecked ? 'yellow' : '',
                        textTransform: 'capitalize',
                    }}>
                    {formatDate}
                    <span style={{ fontSize: 12 }}> (Kota: {quota || 0}) </span>
                    {stop && <span style={{ color: 'red', fontSize: 12 }}>S</span>}
                </div>
            );
        };

        const onGridInputChangeHandler = e => {
            setEdited(true);
            if (timeout.current) {
                clearTimeout(timeout.current);
            }

            let inputTimeout = INPUT_TIMEOUT;
            const inputName = e.target.getAttribute('data-name');

            if (inputName === 'discountConstant' || inputName === 'discountRate') {
                inputTimeout = 3000;
            }

            timeout.current = setTimeout(async () => {
                await methods.trigger();
                if (methods.formState.isValid) {
                    setPriceLoading(true);
                    try {
                        const field = e.target.getAttribute('data-name');
                        const index = e.target.getAttribute('data-index');

                        const { period } = methods.getValues();
                        const prices = period.accommodations[index];

                        const request = generateRowRequest(prices, period);

                        if (field === 'discountRate') {
                            delete request.discountConstant;
                        } else if (field === 'discountConstant') {
                            delete request.discountRate;
                        }
                        const { resultObject } = await tourPriceService.generateRow({
                            ...request,
                            commissionCalculationType,
                            singleRoomDifference,
                        });
                        Object.keys(resultObject).forEach(name => {
                            methods.setValue(
                                `period.accommodations.${index}.${name}`,
                                resultObject[name],
                            );
                        });
                    } catch ({ code, message }) {
                        showMessage(message, MessageType.ERROR);
                    } finally {
                        setPriceLoading(false);
                    }
                }
            }, inputTimeout);
        };

        const onBasePriceInputChangeHandler = ({ discountEdited = true }) => {
            if (discountEdited) {
                setEdited(true);
            }
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
            timeout.current = setTimeout(async () => {
                await methods.trigger();
                if (methods.formState.isValid) {
                    try {
                        setPriceLoading(true);
                        const { period } = methods.getValues();
                        const requests = [];
                        period.accommodations.forEach(prices => {
                            const request = generateRowRequest(prices, {
                                ...period,
                                periodDateDiscountRate: discountValue.rate,
                                periodDateDiscountConstant: discountValue.constant,
                            });
                            requests.push(
                                tourPriceService.generateRow({
                                    ...request,
                                    commissionCalculationType,
                                    singleRoomDifference,
                                }),
                            );
                        });

                        const results = await Promise.all(requests);

                        results.forEach((result, index) => {
                            const { resultObject } = result;
                            Object.keys(resultObject).forEach(name => {
                                methods.setValue(
                                    `period.accommodations.${index}.${name}`,
                                    resultObject[name],
                                );
                            });
                        });
                    } catch ({ code, message }) {
                        showMessage(message, MessageType.ERROR);
                    } finally {
                        setPriceLoading(false);
                    }
                }
            }, INPUT_TIMEOUT);
        };

        const onDefaultInputChangeHandle = e => {
            setEdited(true);
        };

        const generateRowRequest = (prices, period) => {
            const { adultCount, childCount, infantCount } = prices;
            const { commissionRate, discountRate, discountConstant } = prices;
            const {
                perPersonPrice,
                singleRoomPrice,
                childPrice1,
                childPrice2,
                additionalPriceAdt,
                additionalPriceChd,
                additionalPriceInf,
                periodDateQuota,
                periodDateStop,
                periodDateDiscountRate,
                periodDateDiscountConstant,
                periodDateDiscountCurrency,
            } = period;

            return {
                adultCount,
                childCount,
                infantCount,
                commissionRate,
                discountRate,
                discountConstant,
                perPersonPrice,
                singleRoomPrice,
                childPrice1,
                childPrice2,
                additionalPriceAdt,
                additionalPriceChd,
                additionalPriceInf,
                periodDateQuota,
                periodDateStop,
                periodDateDiscountRate,
                periodDateDiscountConstant,
                periodDateDiscountCurrency,
            };
        };

        return (
            <>
                <FormProvider {...methods}>
                    <Grid item md={12} sx={{ position: 'relative' }}>
                        {editLoading && (
                            <div
                                style={{
                                    position: 'absolute',
                                    left: '16px',
                                    top: '16px',
                                    right: '0',
                                    bottom: '0',
                                    backgrounColor: '#b9b9b947',
                                    zIndex: '2',
                                    pointerEvents: 'none',
                                }}></div>
                        )}
                        <Panel
                            title={`${t('title.prices')}`}
                            subtitle={`${selectedHotel.name}`}
                            noPadding>
                            <Tabs
                                value={monthTab}
                                onChange={onMonthTabChange}
                                variant="scrollable"
                                scrollButtons
                                allowScrollButtonsMobile
                                classes={{ root: classes.root }}
                                TabIndicatorProps={{ className: classes.indicator }}>
                                {[...periodMap.keys()].map((item, index) => (
                                    <Tab
                                        key={index}
                                        value={item}
                                        label={item}
                                        classes={{
                                            root: classes.tabRoot,
                                            selected: classes.tabSelected,
                                        }}
                                    />
                                ))}
                            </Tabs>

                            <Tabs
                                value={dayTab}
                                onChange={onDayTabChange}
                                variant="scrollable"
                                scrollButtons
                                style={{
                                    backgroundColor: '#fff',
                                    borderBottom: '2px solid #e0e0e0',
                                }}
                                allowScrollButtonsMobile>
                                {month?.map((item, index) => (
                                    <Tab key={index} label={<GetDayTabLabel item={item} />} />
                                ))}
                            </Tabs>
                            {Array.isArray(month) && (
                                <div className={classes.content}>
                                    {loading && <div className={classes.loading}><Loading /></div>}
                                    <Grid container>
                                        <Grid item md={4}>
                                            <div style={{ padding: 8 }}>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>{t(`label.perPersonPrice`)}:</td>
                                                            <td
                                                                className="priceGridCell"
                                                                style={{ width: 100 }}>
                                                                <TableInput
                                                                    number={true}
                                                                    name="perPersonPrice"
                                                                    defaultValue={
                                                                        month[dayTab]?.perPersonPrice
                                                                    }
                                                                    handleOnChange={
                                                                        onBasePriceInputChangeHandler
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t(`label.singleRoomPrice`)}:</td>
                                                            <td className="priceGridCell">
                                                                <TableInput
                                                                    number={true}
                                                                    name="singleRoomPrice"
                                                                    defaultValue={
                                                                        month[dayTab]?.singleRoomPrice
                                                                    }
                                                                    handleOnChange={
                                                                        onBasePriceInputChangeHandler
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t(`priceGrid.additionalPriceAdt`)}:
                                                            </td>
                                                            <td className="priceGridCell">
                                                                <TableInput
                                                                    name="additionalPriceAdt"
                                                                    defaultValue={
                                                                        month[dayTab]?.additionalPriceAdt
                                                                    }
                                                                    handleOnChange={
                                                                        onBasePriceInputChangeHandler
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid>
                                        <Grid item md={4}>
                                            <div style={{ padding: 8 }}>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>{t(`priceGrid.childPrice2`)}:</td>
                                                            <td
                                                                className="priceGridCell"
                                                                style={{ width: 100 }}>
                                                                <TableInput
                                                                    name="childPrice2"
                                                                    defaultValue={
                                                                        month[dayTab]?.childPrice2
                                                                    }
                                                                    handleOnChange={
                                                                        onBasePriceInputChangeHandler
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t(`priceGrid.additionalPriceChd`)}:
                                                            </td>
                                                            <td
                                                                className="priceGridCell"
                                                                style={{ width: 100 }}>
                                                                <TableInput
                                                                    name="additionalPriceChd"
                                                                    defaultValue={
                                                                        month[dayTab]?.additionalPriceChd
                                                                    }
                                                                    handleOnChange={
                                                                        onBasePriceInputChangeHandler
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t(`priceGrid.childPrice1`)}:</td>
                                                            <td className="priceGridCell">
                                                                <TableInput
                                                                    name="childPrice1"
                                                                    defaultValue={
                                                                        month[dayTab]?.childPrice1
                                                                    }
                                                                    handleOnChange={
                                                                        onBasePriceInputChangeHandler
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t(`priceGrid.additionalPriceInf`)}:
                                                            </td>
                                                            <td className="priceGridCell">
                                                                <TableInput
                                                                    name="additionalPriceInf"
                                                                    defaultValue={
                                                                        month[dayTab]?.additionalPriceInf
                                                                    }
                                                                    handleOnChange={
                                                                        onBasePriceInputChangeHandler
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid>
                                        <Grid item md={4}>
                                            <div style={{ padding: 8 }}>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td>{t(`priceGrid.quota`)}:</td>
                                                            <td className="priceGridCell">
                                                                <TableInput
                                                                    name="periodDateQuota"
                                                                    defaultValue={
                                                                        month[dayTab]?.periodDateQuota
                                                                    }
                                                                    handleOnChange={
                                                                        onBasePriceInputChangeHandler
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t(`priceGrid.stop`)}:</td>
                                                            <td className="priceGridCell">
                                                                <TableInput
                                                                    name="periodDateStop"
                                                                    type="checkbox"
                                                                    defaultValue={
                                                                        month[dayTab]?.periodDateStop
                                                                    }
                                                                    handleOnChange={
                                                                        onBasePriceInputChangeHandler
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{t(`priceGrid.discountRate`)}:</td>
                                                            <td className="priceGridCell">
                                                                <TableInput
                                                                    name="periodDateDiscountRate"
                                                                    value={discountValue.rate || 0}
                                                                    handleOnChange={e => {
                                                                        setDiscountValue({
                                                                            constant: 0,
                                                                            rate: parseFloat(
                                                                                e.target.value.replace(
                                                                                    /,/,
                                                                                    '.',
                                                                                ),
                                                                            ),
                                                                            isEdited: true,
                                                                        });
                                                                        onBasePriceInputChangeHandler(
                                                                            e,
                                                                        );
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t(`priceGrid.discountConstant`)}:
                                                            </td>
                                                            <td className="priceGridCell">
                                                                <TableInput
                                                                    name="periodDateDiscountConstant"
                                                                    value={
                                                                        discountValue.constant || 0
                                                                    }
                                                                    handleOnChange={e => {
                                                                        setDiscountValue({
                                                                            rate: 0,
                                                                            constant: parseFloat(
                                                                                e.target.value.replace(
                                                                                    /,/,
                                                                                    '.',
                                                                                ),
                                                                            ),
                                                                            isEdited: true,
                                                                        });
                                                                        onBasePriceInputChangeHandler(
                                                                            e,
                                                                        );
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {t(`priceGrid.discountCurrency`)}:
                                                            </td>
                                                            <td className="priceGridCell">
                                                                <TableCurrencySelect
                                                                    name="periodDateDiscountCurrency"
                                                                    defaultValue={
                                                                        month[dayTab]?.periodDateDiscountCurrency ||
                                                                        1
                                                                    }
                                                                    handleOnChange={
                                                                        onBasePriceInputChangeHandler
                                                                    }
                                                                />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Grid>
                                    </Grid>

                                    <TableContainer>
                                        <Table size="small" className="data-table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell>
                                                        <div style={{ whiteSpace: 'nowrap' }}>
                                                            {t('priceGrid.accommodationType')}
                                                        </div>
                                                    </StyledTableCell>
                                                    {Columns.map((column, index) => (
                                                        <StyledTableCell key={index}>
                                                            {t(`priceGrid.${column.name}`)}
                                                        </StyledTableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {month[dayTab]?.accommodations.map((item, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            <div
                                                                style={{
                                                                    whiteSpace: 'nowrap',
                                                                    textAlign: 'left',
                                                                    minWidth: 160,
                                                                }}>
                                                                {item.accommodationType}
                                                            </div>
                                                        </TableCell>

                                                        <EditableCell
                                                            index={index}
                                                            item={item}
                                                            name="commissionRate"
                                                            handleOnChange={
                                                                onGridInputChangeHandler
                                                            }
                                                        />

                                                        <NormalCell
                                                            index={index}
                                                            item={item}
                                                            name="purchasePrice"
                                                        />

                                                        <NormalCell
                                                            index={index}
                                                            item={item}
                                                            name="salePrice"
                                                        />

                                                        <EditableCell
                                                            index={index}
                                                            item={item}
                                                            name="discountRate"
                                                            handleOnChange={
                                                                onGridInputChangeHandler
                                                            }
                                                        />

                                                        <EditableCell
                                                            index={index}
                                                            item={item}
                                                            name="discountConstant"
                                                            handleOnChange={
                                                                onGridInputChangeHandler
                                                            }
                                                        />

                                                        <NormalCell
                                                            index={index}
                                                            item={item}
                                                            name="discountPurchasePrice"
                                                        />

                                                        <NormalCell
                                                            index={index}
                                                            item={item}
                                                            name="discountSalePrice"
                                                        />

                                                        <NormalCell
                                                            index={index}
                                                            item={item}
                                                            name="totalPurchasePrice"
                                                        />

                                                        <NormalCell
                                                            index={index}
                                                            item={item}
                                                            name="totalSalePrice"
                                                        />

                                                        {/* <EditableCell
                                                        index={index}
                                                        item={item}
                                                        name="quota"
                                                        handleOnChange={onDefaultInputChangeHandle}
                                                    />

                                                    <EditableCell
                                                        index={index}
                                                        item={item}
                                                        name="stop"
                                                        type="checkbox"
                                                        handleOnChange={onDefaultInputChangeHandle}
                                                    /> */}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            )}

                            {loading && (
                                <div style={{ padding: 24 }}>
                                    <Loading />
                                </div>
                            )}
                        </Panel>
                    </Grid>
                </FormProvider>
                <TourPriceEditApplyGridPriceDialog
                    open={isOpenApplyPriceDialog}
                    onClose={() => setOpenApplyPriceDialog(false)}
                    onDone={applyGridValue}
                />
            </>
        );
    },
);

export default TourPriceEditGrid;
