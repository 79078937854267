import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import React from 'react';
import DoneIcon from '@mui/icons-material/Done';

const DoneButton = React.memo(({ type = 'button', loading, ...props }) => {
    const LoadingIcon = () => <CircularProgress size={18} style={{ color: 'white' }} />;

    return (
        <Button
            {...props}
            type={type}
            color="secondary"
            variant="contained"
            disabled={loading}
            startIcon={loading ? <LoadingIcon /> : <DoneIcon />}>
            {props.children}
        </Button>
    );
});

export default DoneButton;
