import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import SubmitButton from '../../ui/SubmitButton';
import { MessageType } from '../../util/Enums';
import useMessage from '../../util/useMessage';
import commentService from '../../service/CommentService';
import Input from '../../ui/Input';
import Editor from '../../ui/Editor';
import Select from '../../ui/Select';
import MenuItem from '@mui/material/MenuItem';
import { Grid } from '@mui/material';
import TourCatalogInput from '../../ui/TourCatalogInput';
import DateInput from '../../ui/DateInput';
import { formatDate } from '../../util/DateUtil';

const CommentEditDialog = ({ comment = null, open, onClose, onDoneCallback }) => {
    const { t } = useTranslation();
    const showMessage = useMessage();
    const [loading, setLoading] = useState(false);
    const {
        handleSubmit,
        register,
        watch,
        reset,
        control,
        formState: { errors },
    } = useForm();
    const [commentId, setCommentId] = useState(null);
    const [commentDescription, setCommentDescription] = useState('');

    useEffect(() => {
        if (open) {
            reset();
            setLoading(false);
            if (comment !== null) {
                setCommentDescription(comment?.commentDescription);
                setCommentId(comment?.commentId || null);
            }
        } else {
            setCommentDescription('');
            setCommentId(null);
        }
    }, [open, comment, reset]);

    const onSubmit = async data => {
        if (loading) return false;
        try {
            setLoading(true);
            const obj = {
                ...data,
                commentDescription,
            };
            const { isSuccess } = await commentService.saveOrUpdate(commentId, obj);
            if (isSuccess) {
                showMessage(t(commentId ? 'message.updated' : 'message.created'));
                onClose && onClose();
                onDoneCallback && onDoneCallback();
            }
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} fullWidth maxWidth="sm" scroll="paper">
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>
                    {t(commentId ? 'title.editCommentDialog' : 'title.addCommentDialog')}
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Input
                        defaultValue={comment?.customerName}
                        label={t('label.nameSurname')}
                        error={errors?.customerName}
                        {...register('customerName', {
                            required: t('validation.required'),
                        })}
                    />
                    <Input
                        defaultValue={comment?.profileImageUrl}
                        label={t('label.profileImageUrl')}
                        error={errors?.profileImageUrl}
                        {...register('profileImageUrl')}
                    />
                    <TourCatalogInput
                        name="tourCatalog"
                        multiple={false}
                        defaultValue={comment?.tourCatalog}
                        control={control}
                        error={errors?.tourCatalog}
                    />
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Select
                                name="travelItineraryStarCount"
                                defaultValue={comment?.travelItineraryStarCount || 5}
                                label={t('label.travelItineraryStarCount')}
                                error={errors?.travelItineraryStarCount}
                                control={control}
                                rules={{ required: t('validation.required') }}>
                                {[...Array(5).keys()].map(n => (
                                    <MenuItem key={n + 1} value={n + 1}>
                                        {n + 1}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                name="guideStarCount"
                                defaultValue={comment?.guideStarCount || 5}
                                label={t('label.guideStarCount')}
                                error={errors?.guideStarCount}
                                control={control}
                                rules={{ required: t('validation.required') }}>
                                {[...Array(5).keys()].map(n => (
                                    <MenuItem key={n + 1} value={n + 1}>
                                        {n + 1}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                name="transportationStarCount"
                                defaultValue={comment?.transportationStarCount || 5}
                                label={t('label.transportationStarCount')}
                                error={errors?.transportationStarCount}
                                control={control}
                                rules={{ required: t('validation.required') }}>
                                {[...Array(5).keys()].map(n => (
                                    <MenuItem key={n + 1} value={n + 1}>
                                        {n + 1}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                name="accommodationStarCount"
                                defaultValue={comment?.accommodationStarCount || 5}
                                label={t('label.accommodationStarCount')}
                                error={errors?.accommodationStarCount}
                                control={control}
                                rules={{ required: t('validation.required') }}>
                                {[...Array(5).keys()].map(n => (
                                    <MenuItem key={n + 1} value={n + 1}>
                                        {n + 1}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                name="foodStarCount"
                                defaultValue={comment?.foodStarCount || 5}
                                label={t('label.foodStarCount')}
                                error={errors?.foodStarCount}
                                control={control}
                                rules={{ required: t('validation.required') }}>
                                {[...Array(5).keys()].map(n => (
                                    <MenuItem key={n + 1} value={n + 1}>
                                        {n + 1}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                name="tourOperatorStarCount"
                                defaultValue={comment?.tourOperatorStarCount || 5}
                                label={t('label.tourOperatorStarCount')}
                                error={errors?.tourOperatorStarCount}
                                control={control}
                                rules={{ required: t('validation.required') }}>
                                {[...Array(5).keys()].map(n => (
                                    <MenuItem key={n + 1} value={n + 1}>
                                        {n + 1}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                    <Editor
                        title={t('label.comment')}
                        value={commentDescription}
                        expanded={true}
                        onChange={val => {
                            setCommentDescription(val);
                        }}
                    />
                    <DateInput
                        name="commentDate"
                        label={t('label.commentDate')}
                        defaultValue={formatDate(comment?.commentDate, 'dd/MM/yyyy') || null}
                        error={errors?.commentDate}
                        control={control}
                    />
                    <DateInput
                        name="travelDate"
                        label={t('label.travelDate')}
                        defaultValue={formatDate(comment?.travelDate, 'dd/MM/yyyy') || null}
                        error={errors?.travelDate}
                        control={control}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary" disabled={loading}>
                        {t('button.cancel')}
                    </Button>
                    <SubmitButton loading={loading}>
                        {t(commentId ? 'button.update' : 'button.add')}
                    </SubmitButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default React.memo(CommentEditDialog);
