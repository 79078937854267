import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
    FormTypePlatformTypes,
    YesNoType,
    ParameterTypes,
    PassportType,
} from '../../../util/Enums';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    MenuItem,
} from '@mui/material';
import DoneButton from '../../../ui/DoneButton';
import Input from '../../../ui/Input';
import RegionInput from '../../../ui/RegionInput';
import TourCatalogInput from '../../../ui/TourCatalogInput';
import FilterListOffIcon from '@mui/icons-material/Close';
import CategoryInput from '../../../ui/CategoryInput';
import ParameterInput from '../../../ui/ParameterInput';
import VisaTypeInput from '../../../ui/VisaTypeInput';
import Select from '../../../ui/Select';
import DateInput from '../../../ui/DateInput';
import { formatDate } from '../../../util/DateUtil';

const TourReservationListPageFilterDialog = ({ item, open, onClose, onAddAction }) => {
    const { t } = useTranslation();
    const {
        handleSubmit,
        control,
        register,
        reset,
        formState: { errors },
    } = useForm();
    const onSubmit = async data => {
        const obj = {
            regionId: data.regionId ? data.regionId.id : null,
            formPlatformTypeId: data.formPlatformTypeId ? data.formPlatformTypeId : null,
            departureId: data.departureId ? data.departureId.id : null,
            transportId: data.transportId ? data.transportId.id : null,
            transportCompanyId: data.transportCompanyId ? data.transportCompanyId.id : null,
            passportTypeId: data.passportTypeId ? data.passportTypeId : null,
            visaTypeId: data.visaTypeId ? data.visaTypeId.id : null,
            visaRegionId: data.visaRegionId ? data.visaRegionId.id : null,
            isVisaEntry: data.isVisaEntry !== undefined && data.isVisaEntry === 1 ? true : false,
            visaExpirationDate:
                data.visaExpirationDate && data.visaExpirationDate !== ''
                    ? data.visaExpirationDate
                    : null,
            isFinanced: data.isFinanced !== undefined && data.isFinanced === 1 ? true : false,
            name: data.name && data.name !== '' ? data.name : null,
            surname: data.surname && data.surname !== '' ? data.surname : null,
            vendorSaleCode:
                data.vendorSaleCode && data.vendorSaleCode !== '' ? data.vendorSaleCode : null,
            pageTitle: data.pageTitle && data.pageTitle !== '' ? data.pageTitle : null,
        };
        onAddAction && onAddAction(obj);
    };

    return (
        <Dialog open={open} fullWidth maxWidth="md" scroll="paper">
            <DialogTitle>Filtreler</DialogTitle>
            {Object.keys(item).length > 0 && (
                <Button
                    onClick={() => {
                        reset({
                            regionId: null,
                            formPlatformTypeId: null,
                            departureId: null,
                            transportId: null,
                            transportCompanyId: null,
                            passportTypeId: null,
                            visaTypeId: null,
                            visaRegionId: null,
                            isVisaEntry: null,
                            visaExpirationDate: null,
                            isFinanced: null,
                            name: null,
                            surname: null,
                            vendorSaleCode: null,
                            pageTitle: null,
                        });
                        onAddAction && onAddAction({});
                    }}
                    color="error"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}>
                    Filtreleri Temizle
                </Button>
            )}
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <RegionInput
                            name="regionId"
                            label="Şehir"
                            multiple={false}
                            control={control}
                            defaultValue={item?.regionId}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Input defaultValue={item?.name} label="Ad" {...register('name')} />
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            defaultValue={item?.surname}
                            label="Soyad"
                            {...register('surname')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            name="formPlatformTypeId"
                            label="Kanal"
                            defaultValue={item?.formPlatformTypeId}
                            control={control}>
                            {Object.values(FormTypePlatformTypes).map(item => (
                                <MenuItem key={item.id} value={item.id}>
                                    {t(item.label)}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            defaultValue={item?.vendorSaleCode}
                            label="Tedarikçi Rez. Numarası"
                            {...register('vendorSaleCode')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CategoryInput
                            name="departureId"
                            control={control}
                            categoryType={2}
                            label="Kalkış"
                            multiple={false}
                            defaultValue={item?.departureId}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CategoryInput
                            name="transportId"
                            control={control}
                            categoryType={6}
                            label="Ulaşım"
                            multiple={false}
                            defaultValue={item?.transportId}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <ParameterInput
                            label="Havayolu"
                            name="transportCompanyId"
                            control={control}
                            type={ParameterTypes.TRANSPORTATION.id}
                            defaultValue={item?.transportCompanyId}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            name="passportTypeId"
                            label="Pasaport Tipi"
                            defaultValue={item?.passportTypeId}
                            control={control}>
                            {Object.values(PassportType).map(item => (
                                <MenuItem key={item.id} value={item.id}>
                                    {t(item.label)}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={6}>
                        <VisaTypeInput
                            name="visaTypeId"
                            control={control}
                            defaultValue={item?.visaTypeId}
                            multiple={false}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <RegionInput
                            name="visaRegionId"
                            label="Vize Nereden Alındı"
                            multiple={false}
                            control={control}
                            defaultValue={item?.visaRegionId}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            name="isVisaEntry"
                            label="Vize Giriş Yaptı mı?"
                            defaultValue={
                                item?.isVisaEntry !== undefined
                                    ? item?.isVisaEntry === true
                                        ? '1'
                                        : '0'
                                    : null
                            }
                            control={control}>
                            {Object.values(YesNoType).map(item => (
                                <MenuItem key={item.id} value={item.id}>
                                    {t(item.label)}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={6}>
                        <DateInput
                            name="visaExpirationDate"
                            label="Vize Geçerlilik Süresi"
                            defaultValue={formatDate(item?.visaExpirationDate, 'dd/MM/yyyy') || null}
                            control={control}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            name="isFinanced"
                            label="Faturalaştı Mı?"
                            defaultValue={
                                item?.isFinanced !== undefined
                                    ? item?.isFinanced === true
                                        ? '1'
                                        : '0'
                                    : null
                            }
                            control={control}>
                            {Object.values(YesNoType).map(item => (
                                <MenuItem key={item.id} value={item.id}>
                                    {t(item.label)}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={6}>
                        <Input
                            defaultValue={item?.pageTitle}
                            label="Program H1 Başlık"
                            {...register('pageTitle')}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t('button.cancel')}
                </Button>
                <DoneButton onClick={handleSubmit(onSubmit)}>{t('button.done')}</DoneButton>
            </DialogActions>
        </Dialog>
    );
};

export default TourReservationListPageFilterDialog;
