import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import offerService from '../../../service/OfferService';
import { useTranslation } from 'react-i18next';
import Page from '../../../layout/Page';
import { Container, Grid } from '@mui/material';
import TourReservationEditHeadPanel from './TourReservationEditHeadPanel';
import TourReservationEditPageRequestPanel from './TourReservationEditPageRequestPanel';
import TourReservationEditPageToursPanel from './TourReservationEditPageToursPanel';
import TourReservationEditExtraPanel from './TourReservationEditExtraPanel';
import TourReservationEditPaymentPanel from './TourReservationEditPaymentPanel';
import TourReservationEditInvoicePanel from './TourReservationEditInvoicePanel';
import TourReservationEditPageInfoPanel from './TourReservationEditPageInfoPanel';
import TourReservationEditPurchasePanel from './TourReservationEditPurchasePanel';
import { TourReservationEditPageWizard } from '../../../util/Enums';
import TourReservationReceiptDialog from './TourReservationReceiptDialog';

let MOUNT = true;

const TourReservationEditPage = ({ title, ...props }) => {
    const param = useParams();
    const saleOfferFormId = param?.id;
    const { t } = useTranslation();
    const [reservation, setReservation] = useState(null);
    const [pageLoading, setPageLoading] = useState(true);
    const [expanded, setExpanded] = useState([TourReservationEditPageWizard.RequestPanel]);
    const [selectedProgram, setSelectedProgram] = useState(null);
    const [additionals, setAdditionals] = useState([]);

    useEffect(() => {
        if (saleOfferFormId) {
            (async () => {
                setPageLoading(true);
                try {
                    const { resultObject } = await offerService.get(saleOfferFormId);
                    setExpanded(
                        resultObject?.sale
                            ? [TourReservationEditPageWizard.PageInfoPanel]
                            : resultObject?.saleOfferTourPrograms?.length > 0
                              ? [TourReservationEditPageWizard.ToursPanel]
                              : [TourReservationEditPageWizard.RequestPanel],
                    );
                    initPageValues({ ...resultObject });
                } catch (e) {}
                setPageLoading(false);
            })();
        } else {
            setPageLoading(false);
        }
        return () => {
            MOUNT = false;
        };
    }, []);

    const initPageValues = _reservation => {
        setReservation(_reservation);
        if (_reservation.saleOfferTourPrograms && _reservation.saleOfferTourPrograms.length > 0) {
            const selProg = _reservation.saleOfferTourPrograms.find(
                item => item.isSelected === true,
            );
            if (selProg) {
                setSelectedProgram(selProg);
            }
        }
    };

    useEffect(() => {
        if (selectedProgram?.tourProgramId) {
            setPageLoading(true);

            const request = {
                tourProgramId: selectedProgram?.tourProgramId,
                data: {
                    saleOfferFormId: parseInt(saleOfferFormId),
                    personCounts: reservation.saleOfferItems,
                },
            };

            offerService
                .getAdditionals(request)
                .then(({ resultObject }) => {
                    if (resultObject?.length > 0) {
                        setAdditionals(resultObject);
                    } else {
                        setAdditionals([]);
                        toggle([TourReservationEditPageWizard.InvoicePanel]);
                    }
                })
                .catch(({ code, message }) => {
                    console.error('Code: %o, Message: %o', code, message);
                })
                .finally(() => {
                    setPageLoading(false);
                });
        }
    }, [selectedProgram]);

    const isExpanded = useCallback(
        wizardStep => {
            return expanded.find(item => item === wizardStep);
        },
        [expanded],
    );

    const toggle = expandedId => {
        const checkExpanded = expanded.find(item => item === expandedId);
        if (checkExpanded) {
            const removeExpanded = expanded.filter(item => item !== expandedId);
            setExpanded(removeExpanded);
        } else {
            setExpanded([...expanded, expandedId]);
        }
    };
    const next = accordionId => {
        const removeExpanded = expanded.filter(item => item !== accordionId);
        setExpanded([...removeExpanded, accordionId + TourReservationEditPageWizard.RequestPanel]);
    };

    const onToggle = async accordionId => {
        // setPageLoading(true);
        try {
            const { resultObject } = await offerService.get(saleOfferFormId);
            initPageValues({ ...resultObject });
            if (accordionId !== 0) {
                if (accordionId !== Object.keys(TourReservationEditPageWizard).length) {
                    next(accordionId);
                }
            }
        } catch (e) {}
        // setPageLoading(false);
    };

    return (
        <Page title={title} loading={pageLoading}>
            <Container maxWidth="xl">
                <Grid container spacing={1}>
                    {saleOfferFormId && (
                        <Grid item xs={12}>
                            <TourReservationEditHeadPanel
                                {...reservation}
                                saleOfferFormId={saleOfferFormId}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <TourReservationEditPageRequestPanel
                            {...reservation}
                            stepId={TourReservationEditPageWizard.RequestPanel}
                            toggle={toggle}
                            next={next}
                            onToggle={onToggle}
                            isExpanded={isExpanded}
                        />
                    </Grid>

                    {reservation?.saleOfferTourPrograms?.length > 0 && (
                        <Grid item xs={12}>
                            <TourReservationEditPageToursPanel
                                {...reservation}
                                stepId={TourReservationEditPageWizard.ToursPanel}
                                toggle={toggle}
                                next={next}
                                onToggle={onToggle}
                                isExpanded={isExpanded}
                            />
                        </Grid>
                    )}
                    {additionals.length > 0 && (
                        <Grid item xs={12}>
                            <TourReservationEditExtraPanel
                                {...reservation}
                                stepId={TourReservationEditPageWizard.ExtraPanel}
                                additionals={additionals}
                                toggle={toggle}
                                next={next}
                                onToggle={onToggle}
                                isExpanded={isExpanded}
                            />
                        </Grid>
                    )}
                    {reservation?.sale && (
                        <React.Fragment>
                            <Grid item xs={12}>
                                <TourReservationEditPaymentPanel
                                    {...reservation}
                                    stepId={TourReservationEditPageWizard.PaymentPanel}
                                    toggle={toggle}
                                    onToggle={onToggle}
                                    next={next}
                                    isExpanded={isExpanded}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TourReservationEditPurchasePanel
                                    {...reservation}
                                    stepId={TourReservationEditPageWizard.PurchasePanel}
                                    toggle={toggle}
                                    onToggle={onToggle}
                                    next={next}
                                    isExpanded={isExpanded}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TourReservationEditInvoicePanel
                                    {...reservation}
                                    stepId={TourReservationEditPageWizard.InvoicePanel}
                                    onToggle={onToggle}
                                    toggle={toggle}
                                    next={next}
                                    isExpanded={isExpanded}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TourReservationEditPageInfoPanel
                                    {...reservation}
                                    stepId={TourReservationEditPageWizard.PageInfoPanel}
                                    toggle={toggle}
                                    onToggle={onToggle}
                                    isExpanded={isExpanded}
                                />
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </Container>
        </Page>
    );
};

export default TourReservationEditPage;
