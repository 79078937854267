import React, { useRef } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import { EditorConfig } from '../EditorConfig';

import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/plugins.pkgd.min.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import 'froala-editor/js/languages/tr';
import '../styles/Editor.css';

const NativeEditor = ({ value, onChange }) => {
    const ref = useRef({ editor: null });
    const onModelChange = model => {
        onChange && onChange(model);
    };

    return (
        <FroalaEditor
            ref={ref}
            model={value}
            onModelChange={onModelChange}
            className="bordered-editor"
            tag="textarea"
            config={EditorConfig}
        />
    );
};

export default NativeEditor;
