import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import HotelInput from '../../../ui/HotelInput';
import { useForm } from 'react-hook-form';

const TourPriceEditHotelEditDialog = ({ open, selectedIndex, items, onEditDone, onClose }) => {
    const { t } = useTranslation();
    const {
        handleSubmit,
        setValue,
        reset,
        control,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (open) {
            reset();
            setValue('hotel', {
                id: items[selectedIndex]?.hotelId || 0,
                name: items[selectedIndex]?.name || '',
            });
        }
    }, [open, selectedIndex, items]);

    const onNewHotelCallback = data => {
        setValue('hotel', data);
    };

    const onSubmit = data => {
        const { hotel } = data;
        onEditDone &&
            onEditDone(
                {
                    ...items[selectedIndex],
                    hotelId: hotel.id,
                    name: hotel.name,
                    isActive: selectedIndex ? items[selectedIndex].isActive || true : true,
                },
                selectedIndex,
            );
    };

    return (
        <Dialog open={open} fullWidth maxWidth="sm" scroll="paper">
            <DialogTitle>{t(selectedIndex !== null ? 'title.edit' : 'title.create')}</DialogTitle>
            <DialogContent dividers={true}>
                <HotelInput
                    name="hotel"
                    label={t('label.hotel')}
                    multiple={false}
                    control={control}
                    defaultValue={
                        selectedIndex
                            ? {
                                  id: items[selectedIndex].hotelId || 0,
                                  name: items[selectedIndex].name || '',
                              }
                            : null
                    }
                    rules={{ required: t('validation.required') }}
                    error={errors?.hotel}
                    addNewCallback={onNewHotelCallback}
                    selectedItems={items}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t('button.cancel')}
                </Button>

                <Button color="secondary" onClick={handleSubmit(onSubmit)}>
                    {t('button.done')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default React.memo(TourPriceEditHotelEditDialog);
