import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMessage from '../../../util/useMessage';
import offerService from '../../../service/OfferService';
import { MessageType } from '../../../util/Enums';
import Loading from '../../../components/Loading';
import { PDFDownloadLink } from '@react-pdf/renderer';
import TourReservationReceiptPdf from './TourReservationReceiptPdf';

const TourReservationReceiptDialog = ({ open, saleCode, token, saleGuid, onClose }) => {
    const { t } = useTranslation();
    const showMessage = useMessage();

    const [loading, setLoading] = useState(false);
    const [receipt, setReceipt] = useState(null);

    useEffect(() => {
        if (open) {
            setLoading(true);
            offerService
                .getReceiptPayment({
                    token,
                    saleCode,
                })
                .then(result => {
                    setReceipt(result);
                })
                .catch(({ code, message }) => {
                    setReceipt(null);
                    showMessage(`${code} - ${message}`, MessageType.ERROR);
                })
                .finally(() => setLoading(false));
        }
    }, [open, saleCode, token]);

    if (!open) {
        return null;
    }

    return (
        <Dialog open={open} fullWidth maxWidth="sm" scroll="paper">
            <DialogTitle>Voucher Belgesi/Tahsilat Makbuzu</DialogTitle>
            <DialogContent dividers={true}>
                {loading && <Loading />}

                {!loading && receipt && (
                    <>
                        {/* <PDFDownloadLink
                            document={<TourReservationReceiptPdf receipt={receipt} />}
                            fileName={`${saleCode}-Tahsilat.pdf`}>
                            {({ loading }) => (loading ? 'Pdf Üretiliyor...' : 'İndir!')}
                        </PDFDownloadLink> */}

                        <a role="button" style={{ cursor: 'pointer', textDecoration: "underline" }} onClick={() => {navigator.clipboard.writeText(`${import.meta.env.VITE_B2C_URL}/pdf?g=${saleGuid}&s=${token}&sc=${saleCode}`)}}>
                            Linki Kopyala
                        </a>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" disabled={loading}>
                    {t('button.done')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TourReservationReceiptDialog;
