import BaseService from './BaseService';

class ExtraTourService extends BaseService {
    constructor() {
        super('/common/extraTours');
    }
}

const extraTourService = new ExtraTourService();

export default extraTourService;
