import { Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import RenderTags from './RenderTags';
import { useTranslation } from 'react-i18next';
import tourCatalogService from '../service/TourCatalogService';

const TourCatalogInput = ({
    name,
    label,
    defaultValue,
    categoryId,
    rules,
    error,
    multiple = true,
    disabled,
    control,
}) => {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        let active = true;
        setLoading(true);

        (async () => {
            try {
                const { resultObject } = await tourCatalogService.search({
                    start: 0,
                    data: {
                        title: inputValue,
                        categoryId,
                    },
                    max: 50,
                });
                const result = resultObject.map(item => ({ id: item.id, title: item.title }));
                if (active) {
                    setOptions(result || []);
                    setLoading(false);
                }
            } catch (e) {}
        })();
        return () => {
            active = false;
        };
    }, [inputValue]);

    const handleChange = event => {
        setInputValue(event.target.value);
    };

    return (
        <Controller
            render={({ field }) => (
                <Autocomplete
                    {...field}
                    multiple={multiple}
                    getOptionLabel={tourCatalog => tourCatalog.title || ''}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={options}
                    filterOptions={x => x}
                    loading={loading}
                    disabled={disabled}
                    renderTags={RenderTags}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label={
                                label ||
                                (multiple ? t('label.tourCatalogs') : t('label.tourCatalog'))
                            }
                            error={error && true}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            autoComplete="off"
                            helperText={error?.message}
                            multiline={multiple}
                            onChange={handleChange}
                        />
                    )}
                    onChange={(_, data) => field.onChange(data)}
                />
            )}
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
        />
    );
};

export default TourCatalogInput;
