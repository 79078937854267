import Page from '../../layout/Page';

import { useTranslation } from 'react-i18next';
import usePagination from '../../util/usePagination';
import tourCatalogService from '../../service/TourCatalogService';
import { DataTable } from '../../components/DataTable';
import Container from '@mui/material/Container';
import ListPageActions from '../../components/ListPageActions';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import TourCatalogDeleteDialog from './TourCatalogDeleteDialog';
import { getDeletedColumn, YesNoTypeOptions } from '../../components/YesNoTypeOptions';
import PreviewButton from '../../components/PreviewButton';
import MinMaxSearchCell from '../../components/datatable/MinMaxSearchCell';
import Tooltip from '@mui/material/Tooltip';
import TourCatalogAddProgramDialog from './TourCatalogAddProgramDialog';

const TourCatalogListPage = ({ title, ...props }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [selected, setSelected] = useState([]);
    const [deleted, setDeleted] = useState(null);
    const [openTourCatalogDeleteDialog, setOpenTourCatalogDeleteDialog] = useState(false);
    const [openCatalogAddProgramDialog, setOpenCatalogAddProgramDialog] = useState(false);

    const columns = [
        {
            name: 'id',
            label: 'ID',
            width: 10,
            searchable: true,
            type: Number,
        },
        {
            name: 'operationTitle',
            label: 'Operasyon',
            width: 10,
            searchable: true,
        },
        {
            name: 'region',
            label: t('label.region'),
            width: 155,
            searchable: true,
            searchField: 'regionName',
            execute: item => item.name,
        },
        {
            name: 'title',
            label: t('label.title'),
            searchable: true,
            execute: item =>
                item && (
                    <Tooltip title={<p>{item}</p>}>
                        <div
                            style={{
                                whiteSpace: 'nowrap',
                                maxWidth: '300px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {item}
                        </div>
                    </Tooltip>
                ),
        },
        // {
        //     name: "departure",
        //     label: t('label.departure'),
        //     searchable: true,
        //     searchField: "departureName",
        //     execute: (departure) => departure?.name
        // },
        // {
        //     name: "destinations",
        //     label: t('label.destinations'),
        //     searchable: true,
        //     searchField: "technicalStop",
        //     execute: (items) => items?.map(item => item?.region.name).join(", "),
        //     sortable: false,
        // },
        {
            name: 'url',
            label: t('label.url'),
            execute: url => (url ? <PreviewButton url={url} /> : ''),
        },
        {
            name: 'isDomestic',
            label: t('label.isDomestic'),
            execute: item => t(`label.${item}`),
            type: Boolean,
            searchable: true,
            searchType: 'select',
            sortable: false,
            selectOptions: <YesNoTypeOptions />,
        },
        // {
        //     name: "hasProgram",
        //     label: t('label.hasProgram'),
        //     execute: (item) => t(`label.${item}`),
        //     type: Boolean,
        //     searchable: true,
        //     searchType: 'select',
        //     sortable: false,
        //     selectOptions: <YesNoTypeOptions/>
        // },
        {
            name: 'tourPrograms',
            label: 'Tur Prog.',
            type: Number,
            sortable: true,
            width: 10,
            searchField: 'tourProgramCount',
            customSort: true,
            searchable: true,
            SearchCellComponent: MinMaxSearchCell,
            execute: item =>
                item &&
                (item.length > 0 ? (
                    <Tooltip
                        title={
                            <React.Fragment>
                                {item.map(tourProgram => (
                                    <p>{tourProgram.title || ''}</p>
                                ))}
                            </React.Fragment>
                        }>
                        <span>{item.length}</span>
                    </Tooltip>
                ) : (
                    <>0</>
                )),
        },
        {
            name: 'volume',
            label: t('label.volume'),
            width: 10,
            type: Number,
            searchable: true,
        },
        { ...getDeletedColumn(t) },
    ];

    const tourCatalogs = usePagination({
        service: tourCatalogService,
        defaultOrderBy: { name: 'id', orderType: 'desc' },
    });

    const ActionButtons = () => (
        <ListPageActions
            left={
                selected.length > 0 && (
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setOpenCatalogAddProgramDialog(true);
                        }}>
                        {t('button.addProgram')}
                    </Button>
                )
            }>
            <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={() => {
                    history.push(`/tours/catalogs/create`);
                }}>
                {t('button.addNew')}
            </Button>
        </ListPageActions>
    );

    return (
        <Page title={title}>
            <Container maxWidth={false} sx={{ maxWidth: '1588px' }}>
                <ActionButtons />
                <DataTable
                    title={t('label.tourCatalogList')}
                    columns={columns}
                    {...tourCatalogs}
                    headerNoWrap
                    {...{ selected, setSelected }}
                    onEdit={(item, index, e) => {
                        e.preventDefault();
                        if (e.ctrlKey || e.metaKey) {
                            window.open(`/tours/catalogs/edit/${item.id}`);
                        } else {
                            history.push(`/tours/catalogs/edit/${item.id}`);
                        }
                    }}
                    onDelete={item => {
                        setDeleted(item);
                        setOpenTourCatalogDeleteDialog(true);
                    }}
                />
                <ActionButtons />
            </Container>

            <TourCatalogDeleteDialog
                tourCatalog={deleted}
                open={openTourCatalogDeleteDialog}
                onClose={() => {
                    setOpenTourCatalogDeleteDialog(false);
                    setDeleted(null);
                }}
                onDoneCallback={tourCatalogs.search}
            />

            <TourCatalogAddProgramDialog
                tourCatalogs={selected}
                open={openCatalogAddProgramDialog}
                onSave={() => {
                    setOpenCatalogAddProgramDialog(false);
                    setSelected([]);
                }}
                onClose={() => {
                    setOpenCatalogAddProgramDialog(false);
                }}
            />
        </Page>
    );
};

export default TourCatalogListPage;
