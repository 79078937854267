import { useTranslation } from 'react-i18next';
import { DataTable } from '../../../components/DataTable';
import { Button } from '@mui/material';
import { useState } from 'react';
import TourPriceEditHotelEditDialog from './TourPriceEditHotelEditDialog';
import { MessageType } from '../../../util/Enums';
import useMessage from '../../../util/useMessage';

const TourPriceEditHotelListPanel = ({
    hotels,
    setHotels,
    activeHotel,
    setSelectedHotel,
    isValidForms,
}) => {
    const { t } = useTranslation();
    const showMessage = useMessage();

    const [openEditDialog, setOpenEditDialog] = useState(false);

    const [selectedIndex, setSelectedIndex] = useState(null);

    const addNewHotelOnClick = async () => {
        const isValid = await isValidForms(false);
        if (!isValid) {
            showMessage(t('message.invalidatePriceForm'), MessageType.ERROR);
        } else {
            setSelectedIndex(null);
            setOpenEditDialog(true);
        }
    };

    return (
        <>
            <DataTable
                title={t('title.hotels')}
                data={hotels}
                count={hotels.length}
                useRowIndexForKey
                columns={[
                    {
                        name: 'name',
                        label: t('label.name'),
                    },
                ]}
                onEdit={(item, index) => {
                    setSelectedIndex(index);
                    setOpenEditDialog(true);
                }}
                onDelete={(item, index) => {
                    const list = [...hotels];
                    const activeIndex = hotels.indexOf(activeHotel);
                    if (activeIndex === index) {
                        setSelectedHotel(null);
                    }
                    // do not delete this line..
                    setTimeout(() => {
                        list.splice(index, 1);
                        setHotels(list);
                    }, 100);
                }}
                onCheck={(item, rowIndex, e) => {
                    const list = [...hotels];
                    list[rowIndex].isActive = e.target.checked;
                    setHotels(list);
                    console.log('hotels', hotels);
                }}
                action={
                    <Button color={'secondary'} onClick={addNewHotelOnClick}>
                        {t('button.add')}
                    </Button>
                }
            />

            <TourPriceEditHotelEditDialog
                open={openEditDialog}
                items={hotels}
                selectedIndex={selectedIndex}
                onClose={() => setOpenEditDialog(false)}
                onEditDone={(data, index) => {
                    if (index !== null) {
                        const list = [...hotels];
                        list[selectedIndex] = data;
                        setHotels(list);
                    } else {
                        const _newHotel = { ...data };
                        setHotels([...hotels, _newHotel]);
                        setSelectedHotel(_newHotel);
                    }
                    setOpenEditDialog(false);
                    setSelectedIndex(null);
                }}
            />
        </>
    );
};

export default TourPriceEditHotelListPanel;
