import { useFieldArray, useFormContext } from 'react-hook-form';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BankTypes, CurrencyTypes, findEnumById } from '../../../util/Enums';
import { useEffect, useState } from 'react';
import { formatPrice } from '../../../util/CurrencyUtil';
import { format } from 'date-fns';

const cellStyle = { padding: '0 8px 0 1px', width: '100px', textAlign: 'right' };
const inputStyle = {
    width: '100%',
    height: '26px',
    textAlign: 'right',
    border: '1px solid #ccc',
    backgroundColor: 'rgb(255 254 219)',
};

const TourPurchaseUpdateTable = ({ currencyType, data }) => {
    const { t } = useTranslation();
    const {
        control,
        setValue,
        watch,
        register,
        reset,
        formState: { errors },
    } = useFormContext();
    const { fields } = useFieldArray({
        control,
        name: `currency.${currencyType}`,
        keyName: 'keyId',
    });

    const [editByTotal, setEditByTotal] = useState(true);
    const [globalBank, setGlobalBank] = useState('1');

    const [totalPurchasePrice, setTotalPurchasePrice] = useState(0);
    const [totalExchangeBuyTL, setTotalExchangeBuyTL] = useState(0);

    const values = watch();
    const [isMount, setIsMount] = useState(false);

    useEffect(() => {
        const { currency } = values;
        const items = currency[currencyType];

        let _totalPurchasePrice = 0;
        let _totalExchangeBuyTL = 0;

        items.forEach(item => {
            _totalPurchasePrice += item.purchasePrice || 0;
            _totalExchangeBuyTL += item.exchangeBuyTL || 0;
        });

        setTotalPurchasePrice(_totalPurchasePrice);

        if (!isMount || !editByTotal) {
            setTotalExchangeBuyTL(_totalExchangeBuyTL);
            setIsMount(true);
        }
    }, [editByTotal, isMount, values, currencyType]);

    useEffect(() => {
        if (editByTotal) {
            const items = data.get(currencyType);
            const decimal = parseFloat(totalExchangeBuyTL.toString()) || 0;
            if (!isNaN(decimal) && totalPurchasePrice > 0) {
                const $exchangeRate = decimal / totalPurchasePrice;
                items.forEach((item, index) => {
                    const exchangeBuyTL = parseFloat(
                        parseFloat(`${item.purchasePrice * $exchangeRate}`).toFixed(4),
                    );
                    setValue(
                        `currency.${currencyType}.${index}.exchangeBuyingRate`,
                        parseFloat(`${$exchangeRate}`).toFixed(4),
                    );
                    setValue(`currency.${currencyType}.${index}.exchangeBuyTL`, exchangeBuyTL);
                });
            }

            items.forEach((item, index) => {
                setValue(`currency.${currencyType}.${index}.bankId`, globalBank);
            });
        }
    }, [editByTotal, globalBank, currencyType, data, totalPurchasePrice, totalExchangeBuyTL]);

    const onTotalExchangeBuyTLInputChange = e => {
        setTotalExchangeBuyTL(e.target.value);
    };

    return (
        <div>
            <div style={{ padding: 6, backgroundColor: 'rgb(224 237 248)', fontWeight: 'bold' }}>
                {findEnumById(CurrencyTypes, currencyType)?.label}
            </div>
            <TableContainer>
                <Table size="small" className="data-table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('purchase.saleCode')}</TableCell>
                            <TableCell>{t('purchase.productName')}</TableCell>
                            <TableCell>{t('purchase.exchangeBuyStatusDate')}</TableCell>
                            <TableCell>{t('purchase.currencyTypeId')}</TableCell>
                            <TableCell>{t('purchase.purchasePrice')} Döviz</TableCell>
                            <TableCell>{t('purchase.exchangeBuyTL')}</TableCell>
                            <TableCell>{t('purchase.exchangeBuyingRate')}</TableCell>
                            <TableCell>{t('purchase.exchangeBank')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.map((field, index) => (
                            <TableRow key={field.keyId}>
                                <TableCell>{field.saleCode}</TableCell>
                                <TableCell>
                                    <div
                                        style={{
                                            whiteSpace: 'nowrap',
                                            width: '300px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                        }}
                                        title={field.productName}>
                                        {field.productName}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    {field.lastStatusUpdateDate
                                        ? format(new Date(field.lastStatusUpdateDate), 'dd.MM.yyyy')
                                        : ''}
                                </TableCell>
                                <TableCell style={cellStyle}>
                                    {findEnumById(CurrencyTypes, field.currencyTypeId)?.label}
                                </TableCell>
                                <TableCell style={cellStyle}>
                                    {formatPrice(field.purchasePrice)}
                                </TableCell>
                                <TableCell style={cellStyle}>
                                    <input
                                        disabled={editByTotal}
                                        style={inputStyle}
                                        {...register(
                                            `currency.${currencyType}.${index}.exchangeBuyTL`,
                                            {
                                                valueAsNumber: true,
                                                pattern: {
                                                    value: /^(0|[1-9]\d*)(\.\d+)?$/,
                                                },
                                            },
                                        )}
                                    />
                                </TableCell>
                                <TableCell style={cellStyle}>
                                    <input
                                        disabled={editByTotal}
                                        style={inputStyle}
                                        {...register(
                                            `currency.${currencyType}.${index}.exchangeBuyingRate`,
                                            {
                                                valueAsNumber: true,
                                                pattern: {
                                                    value: /^(0|[1-9]\d*)(\.\d+)?$/,
                                                },
                                            },
                                        )}
                                    />
                                </TableCell>
                                <TableCell style={cellStyle}>
                                    <select
                                        disabled={editByTotal}
                                        style={{ ...inputStyle, textAlign: 'left', height: '30px' }}
                                        {...register(`currency.${currencyType}.${index}.bankId`)}>
                                        {Object.values(BankTypes).map(item => (
                                            <option value={item.id}>{item.label}</option>
                                        ))}
                                    </select>
                                </TableCell>
                            </TableRow>
                        ))}

                        <TableRow>
                            <TableCell colSpan={4} style={{ textAlign: 'right' }}>
                                <label>
                                    <span>Toplama göre düzenle</span>
                                    <input
                                        type="checkbox"
                                        defaultChecked={editByTotal}
                                        style={{ margin: '0 5px' }}
                                        value={editByTotal}
                                        onChange={e => setEditByTotal(e.target.checked)}
                                    />
                                </label>
                                <strong>Toplam:</strong>
                            </TableCell>
                            <TableCell style={cellStyle}>
                                {formatPrice(totalPurchasePrice)}
                            </TableCell>
                            <TableCell style={cellStyle}>
                                {editByTotal ? (
                                    <input
                                        disabled={!editByTotal}
                                        style={inputStyle}
                                        value={totalExchangeBuyTL}
                                        onChange={onTotalExchangeBuyTLInputChange}
                                    />
                                ) : (
                                    formatPrice(totalExchangeBuyTL)
                                )}
                            </TableCell>
                            <TableCell />
                            <TableCell style={cellStyle}>
                                {editByTotal && (
                                    <select
                                        value={globalBank}
                                        onChange={e => setGlobalBank(e.target.value)}
                                        style={{
                                            ...inputStyle,
                                            textAlign: 'left',
                                            height: '30px',
                                        }}>
                                        {Object.values(BankTypes).map(item => (
                                            <option value={item.id}>{item.label}</option>
                                        ))}
                                    </select>
                                )}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default TourPurchaseUpdateTable;
