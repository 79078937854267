import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { FormProvider, useForm } from 'react-hook-form';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import SubmitButton from '../ui/SubmitButton';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMessage from '../util/useMessage';
import { MessageType } from '../util/Enums';
import vendorService from '../service/VendorService';
import VendorEditInputs from '../pages/common/vendors/VendorEditInputs';

const VendorCreateDialog = ({ open, inputValue, onClose, onAddCallback }) => {
    const { t } = useTranslation();
    const methods = useForm();

    const [loading, setLoading] = useState(false);

    const showMessage = useMessage();

    useEffect(() => {
        if (open) {
            methods.reset();
        }
    }, [open]);

    const onSubmit = async data => {
        if (loading) return false;
        try {
            setLoading(true);
            const obj = {
                ...data,
                contactTypeId: 3
            };
            const { resultObject } = await vendorService.saveOrUpdate(null, obj);
            console.log('result: %', resultObject);
            showMessage(t('message.created'));
            onAddCallback && onAddCallback(resultObject);
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} fullWidth maxWidth="sm" scroll="paper">
            <DialogTitle>{t('title.addVendorDialog')}</DialogTitle>
            <DialogContent dividers={true}>
                <FormProvider {...methods}>
                    <VendorEditInputs name={inputValue} />
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" disabled={loading}>
                    {t('button.cancel')}
                </Button>
                <SubmitButton
                    type="button"
                    loading={loading}
                    startIcon={<AddIcon />}
                    onClick={methods.handleSubmit(onSubmit)}>
                    {t('button.add')}
                </SubmitButton>
            </DialogActions>
        </Dialog>
    );
};

export default VendorCreateDialog;
