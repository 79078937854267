import PageLoading from '../components/PageLoading';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import Config from '../Config';
import { useDispatch } from 'react-redux';
import { ActionType } from '../store/ActionType';

const LogoutPage = () => {
    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = useCookies(['access_token', 'refresh_token']);

    useEffect(() => {
        removeCookie('access_token', Config.COOKIE_CONFIG);
        removeCookie('refresh_token', Config.COOKIE_CONFIG);

        dispatch({
            type: ActionType.LOGOUT,
        });
    }, []);

    return <PageLoading />;
};

export default LogoutPage;
