import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import { Controller } from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MaterialSelect from '@mui/material/Select';

const Select = ({
    name,
    label,
    error,
    disabled,
    multiple,
    defaultValue,
    rules,
    control,
    ...props
}) => {
    return (
        <FormControl
            fullWidth
            margin="dense"
            size="small"
            error={error && true}
            disabled={disabled}
            variant="outlined">
            <InputLabel htmlFor={name}>{label}</InputLabel>
            <Controller
                name={name}
                control={control}
                rules={rules}
                defaultValue={defaultValue}
                render={({ field }) => (
                    <MaterialSelect
                        {...field}
                        {...props}
                        multiple={multiple}
                        label={label}
                        inputProps={{
                            id: { name },
                        }}>
                        {props.children}
                    </MaterialSelect>
                )}
            />
            {error?.message && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
    );
};

export default Select;
