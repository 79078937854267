import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material';
import { StyledTableCell } from '../../../components/DataTable';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { parseAndFormatDateString } from '../../../util/DateUtil';
import { formatPrice } from '../../../util/CurrencyUtil';
import offerService from '../../../service/OfferService';
import { ChangePropertyType, CurrencyTypes, findEnumById, MessageType } from '../../../util/Enums';
import useMessage from '../../../util/useMessage';

const TourReservationEditToursPanelTable = ({
    saleOfferTourProgramPeriods,
    tourProgramIndex,
    onPriceCalculateCallback,
    onClickConfirm,
}) => {
    const { t } = useTranslation();
    const showMessage = useMessage();
    const purchasePriceTimeout = useRef(-1);
    const discountRateTimeout = useRef(-1);
    const discountAmountTimeout = useRef(-1);

    return (
        <TableContainer>
            <Table size="small" className="data-table operation">
                <TableHead>
                    <TableRow>
                        <StyledTableCell width={60}>{t('reservation.startDate')}</StyledTableCell>
                        <StyledTableCell width={60}>{t('reservation.endDate')}</StyledTableCell>

                        {/* <StyledTableCell width={60}>
                            {t('reservation.purchasePrice')}
                        </StyledTableCell> */}
                        <StyledTableCell width={60}>{t('reservation.salePrice')}</StyledTableCell>
                        <StyledTableCell width={60}>
                            {t('reservation.currencyType')}
                        </StyledTableCell>
                        <StyledTableCell width={60}>
                            {t('reservation.exchangeRate')}
                        </StyledTableCell>

                        <StyledTableCell width={60}>
                            {t('reservation.purchasePrice')} TL
                        </StyledTableCell>
                        <StyledTableCell width={60}>
                            {t('reservation.salePrice')} TL
                        </StyledTableCell>

                        {/* <StyledTableCell width={20}>
                            {t('reservation.commissionRate')}
                        </StyledTableCell> */}

                        <StyledTableCell width={1}>{t('reservation.discountRate')}</StyledTableCell>
                        <StyledTableCell width={1}>{t('reservation.discountAmount')}</StyledTableCell>

                        {/* <StyledTableCell width={60}>
                            {t('reservation.discountPurchasePrice')} TL
                        </StyledTableCell> */}
                        <StyledTableCell width={60}>
                            {t('reservation.discountSalePrice')} TL
                        </StyledTableCell>
                        {/* <StyledTableCell width={60}>
                            {t('reservation.profitMargin')}
                        </StyledTableCell> */}
                        <StyledTableCell width={1} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {saleOfferTourProgramPeriods?.map((programPeiod, dix) => (
                        <TableRow
                            key={dix}
                            sx={programPeiod.isStop === true ? { opacity: '0.3', backgroundColor: '#dfdfdf', pointerEvents: 'none'} : (programPeiod.isSelected ? { backgroundColor: '#dfffec' } : {})}>
                            <TableCell>
                                {parseAndFormatDateString(programPeiod.startDate)}
                            </TableCell>
                            <TableCell>{parseAndFormatDateString(programPeiod.endDate)}</TableCell>
                            {/* <TableCell>
                                {formatPrice(programPeiod.purchasePrice)}
                            </TableCell> */}
                            <TableCell>
                                {/* {formatPrice(programPeiod.salePrice)} */}
                                {programPeiod.salePrice}
                            </TableCell>
                            <TableCell>
                                {findEnumById(CurrencyTypes, programPeiod.currencyTypeId)?.label}
                            </TableCell>
                            <TableCell>{programPeiod.exchangeRate}</TableCell>

                            <TableCell>
                                {/* {formatPrice(
                                    programPeiod.purchasePrice * programPeiod.exchangeRate,
                                )} */}
                                {programPeiod.purchasePriceTl}
                            </TableCell>
                            <TableCell>
                                {/* {formatPrice(programPeiod.salePrice * programPeiod.exchangeRate)} */}
                                {programPeiod.salePriceTl}
                            </TableCell>

                            {/* <TableCell>
                                {programPeiod.commissionRate}
                            </TableCell> */}

                            <TableCell sx={{ padding: 0 }}>
                                <TextField
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    autoComplete="off"
                                    sx={{ margin: 0, backgroundColor: '#fae4bc' }}
                                    defaultValue={programPeiod.discountRate}
                                    onChange={e => {
                                        const { value } = e.target;
                                        if (value !== '') {
                                            clearTimeout(discountRateTimeout.current);
                                            discountRateTimeout.current = setTimeout(() => {
                                                offerService
                                                    .calculatePrices({
                                                        id: 0,
                                                        purchasePrice: programPeiod.purchasePrice,
                                                        discountSalePrice:
                                                            programPeiod.discountSalePrice,
                                                        salePrice: programPeiod.salePrice,
                                                        commissionRate: programPeiod.commissionRate,
                                                        discountRate: parseFloat(value.replace(/,/, '.')),
                                                        discountAmount: programPeiod.discountAmount,
                                                        exchangeRate: programPeiod.exchangeRate,
                                                        changePropertyTypeId:
                                                            ChangePropertyType.DiscountRate,
                                                    })
                                                    .then(({ resultObject }) => {
                                                        onPriceCalculateCallback({
                                                            tourProgramIndex,
                                                            itemIndex: dix,
                                                            data: resultObject,
                                                        });
                                                    })
                                                    .catch(({ code, message }) => {
                                                        showMessage(
                                                            `${code} - ${message}`,
                                                            MessageType.ERROR,
                                                        );
                                                    });
                                            }, 250);
                                        }
                                    }}
                                />
                            </TableCell>
                            <TableCell sx={{ padding: 0 }}>
                                <TextField
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    autoComplete="off"
                                    sx={{ margin: 0, backgroundColor: '#fae4bc' }}
                                    defaultValue={programPeiod.discountAmount}
                                    onChange={e => {
                                        const { value } = e.target;
                                        if (value !== '') {
                                            clearTimeout(discountAmountTimeout.current);
                                            discountAmountTimeout.current = setTimeout(() => {
                                                offerService
                                                    .calculatePrices({
                                                        id: 0,
                                                        purchasePrice: programPeiod.purchasePrice,
                                                        discountSalePrice:
                                                            programPeiod.discountSalePrice,
                                                        salePrice: programPeiod.salePrice,
                                                        commissionRate: programPeiod.commissionRate,
                                                        discountRate: programPeiod.discountRate,
                                                        discountAmount: parseFloat(value.replace(/,/, '.')),
                                                        exchangeRate: programPeiod.exchangeRate,
                                                        changePropertyTypeId:
                                                            ChangePropertyType.DiscountAmount,
                                                    })
                                                    .then(({ resultObject }) => {
                                                        onPriceCalculateCallback({
                                                            tourProgramIndex,
                                                            itemIndex: dix,
                                                            data: resultObject,
                                                        });
                                                    })
                                                    .catch(({ code, message }) => {
                                                        showMessage(
                                                            `${code} - ${message}`,
                                                            MessageType.ERROR,
                                                        );
                                                    });
                                            }, 250);
                                        }
                                    }}
                                />
                            </TableCell>

                            {/* <TableCell>
                                {formatPrice(programPeiod.discountPurchasePrice * programPeiod.exchangeRate)}
                            </TableCell> */}
                            <TableCell>
                                {/* {formatPrice(
                                    programPeiod.discountSalePrice * programPeiod.exchangeRate,
                                )} */}
                                {programPeiod.discountSalePriceTl}
                            </TableCell>
                            {/* <TableCell>
                                {parseFloat(programPeiod.profitMargin || "0").toFixed(2)}
                            </TableCell> */}

                            <TableCell>
                                {!programPeiod.isSelected && (
                                    <IconButton
                                        aria-label="Onayla"
                                        color="success"
                                        sx={{ paddingRight: '0' }}
                                        onClick={() => {
                                            onClickConfirm(
                                                programPeiod.saleOfferTourProgramPeriodId,
                                            );
                                        }}>
                                        <CheckCircleIcon />
                                    </IconButton>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TourReservationEditToursPanelTable;
