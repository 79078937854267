import React, { useCallback, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Panel from '../../../ui/Panel';
import { StyledTableCell } from '../../../components/DataTable';
import Input from '../../../ui/Input';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

const DEFAULT_CHILD_RULES = [
    { minAge: 0, maxAge: 1.99, price: null },
    { minAge: 2, maxAge: 11.99, price: null },
];

const ExtraTourEditChildRulesPanel = ({ extraTour, elevation = 1, participation }) => {
    const { t } = useTranslation();
    const {
        register,
        control,
        watch,
        formState: { errors },
    } = useFormContext();

    const childRules = useFieldArray({
        control,
        name: 'childRules',
    });

    const watchChildRules = watch('childRules');

    useEffect(() => {
        if (childRules.fields.length === 0) {
            let rules =
                extraTour?.childRules?.length > 0 ? extraTour?.childRules : DEFAULT_CHILD_RULES;
            rules.forEach((item, index) => {
                childRules.append({ ...item });
            });
        }
    }, [extraTour, childRules, participation]);

    const getAgeRules = useCallback(
        (field, index) => {
            if (index > 0 && watchChildRules) {
                if (field === 'minAge') {
                    const beforeMaxAge = parseInt(watchChildRules[index - 1].maxAge) + 1;
                    return {
                        min: {
                            value: beforeMaxAge,
                            message: t('validation.min', { val: beforeMaxAge }),
                        },
                    };
                } else {
                    return {};
                }
            }
            return {};
        },
        [watchChildRules],
    );

    return (
        <Panel
            title={t('title.childRules')}
            elevation={elevation}
            noPadding
            action={
                <Button
                    size={'small'}
                    variant={'contained'}
                    onClick={async () => {
                        childRules.append({
                            minAge: (watchChildRules[watchChildRules.length - 1].maxAge || -1) + 1,
                            maxAge: null,
                            price: null,
                        });
                    }}>
                    {t('button.add')}
                </Button>
            }>
            <TableContainer>
                <Table size="small" className="data-table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>{t('label.minAge')}</StyledTableCell>
                            <StyledTableCell>{t('label.maxAge')}</StyledTableCell>
                            <StyledTableCell>{t('label.price')}</StyledTableCell>
                            <StyledTableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {childRules.fields.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ padding: 0 }}>
                                    <Input
                                        style={{ padding: 0, margin: 0 }}
                                        error={
                                            errors?.childRules && errors.childRules[index]?.minAge
                                        }
                                        {...register(`childRules.[${index}].minAge`, {
                                            setValueAs: v => {
                                                if (typeof v === 'string') {
                                                    return parseFloat(v.replace(/,/, '.'));
                                                } else {
                                                    return v;
                                                }
                                            },
                                            required: true,
                                            ...getAgeRules('minAge', index),
                                        })}
                                    />
                                </TableCell>
                                <TableCell style={{ padding: 0 }}>
                                    <Input
                                        style={{ padding: 0, margin: 0 }}
                                        error={
                                            errors?.childRules && errors.childRules[index]?.maxAge
                                        }
                                        {...register(`childRules.[${index}].maxAge`, {
                                            setValueAs: v => {
                                                if (typeof v === 'string') {
                                                    return parseFloat(v.replace(/,/, '.'));
                                                } else {
                                                    return v;
                                                }
                                            },
                                            required: true,
                                            ...getAgeRules('maxAge', index),
                                        })}
                                    />
                                </TableCell>
                                <TableCell style={{ padding: 0 }}>
                                    <Input
                                        style={{ padding: 0, margin: 0 }}
                                        error={
                                            errors?.childRules && errors.childRules[index]?.price
                                        }
                                        {...register(`childRules[${index}].price`, {
                                            setValueAs: v => {
                                                if (typeof v === 'string') {
                                                    return parseFloat(v.replace(/,/, '.'));
                                                } else {
                                                    return v;
                                                }
                                            },
                                            required: true,
                                        })}
                                    />
                                </TableCell>
                                <TableCell style={{ padding: 0 }}>
                                    <IconButton
                                        aria-label="delete"
                                        size="small"
                                        onClick={() => {
                                            childRules.remove(index);
                                        }}>
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Panel>
    );
};

export default ExtraTourEditChildRulesPanel;
