import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import SubmitButton from '../../../ui/SubmitButton';
import { MessageType, ExchangeRatesTypes } from '../../../util/Enums';
import useMessage from '../../../util/useMessage';
import exchangeRateService from '../../../service/ExchangeRateService';
import Input from '../../../ui/Input';
import MenuItem from '@mui/material/MenuItem';
import Select from '../../../ui/Select';
import Loading from '../../../components/Loading';

const ExchangeItemEditDialog = ({
    item = null,
    open,
    onClose,
    onDoneCallback,
    amountAdded,
    amountAddedTypeOf,
}) => {
    const { t } = useTranslation();
    const showMessage = useMessage();

    const [loading, setLoading] = useState(false);
    const [currencyTypes, setCurrencyTypes] = useState([]);

    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        (async () => {
            setLoading(false);
            const { resultObject } = await exchangeRateService.getCurrencyTypes();
            setCurrencyTypes(resultObject);
        })();
    }, []);

    useEffect(() => {
        if (open) {
            reset();
        }
    }, [open]);

    const onSubmit = async data => {
        let form = data;
        if (item !== null) {
            const staticData = {
                currencyChangeId: item.currencyChangeId,
                liveCurrencyUpdateDate: item.liveCurrencyUpdateDate,
                liveCurrencyValue: item.liveCurrencyValue,
                lastLiveCurrencyUpdateDate: item.lastLiveCurrencyUpdateDate,
                lastLiveCurrencyValue: item.lastLiveCurrencyValue,
            };
            if (item.manualValue !== data.manualValue) {
                form = {
                    ...form,
                    ...staticData,
                    isManualControl: true,
                    value:
                        amountAddedTypeOf === ExchangeRatesTypes.PERCENT.id
                            ? (
                                  parseFloat(data.manualValue) +
                                  (data.manualValue * amountAdded) / 100
                              ).toFixed(4)
                            : (parseFloat(data.manualValue) + parseFloat(amountAdded)).toFixed(4),
                };
            } else {
                form = { ...form, ...staticData, isManualControl: false };
            }
        } else {
            if (data.manualValue === undefined || data.manualValue === '') {
                form = { ...form, isManualControl: false };
            } else {
                form = { ...form, isManualControl: true };
            }
        }
        try {
            onDoneCallback && onDoneCallback(form);
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        }
    };

    return (
        <Dialog open={open} fullWidth maxWidth="sm" scroll="paper">
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent dividers={true}>
                    {!loading && (
                        <>
                            {/* <input 
                            type="hidden" 
                            {...register(`tourProgramDayId`)} 
                            defaultValue={item?.currencyChangeId || 0}
                        /> */}
                            <Select
                                name="fromCurrencyType"
                                rules={{ required: t('validation.required') }}
                                error={errors?.fromCurrencyType}
                                label={t('label.fromCurrencyType')}
                                defaultValue={item?.fromCurrencyType}
                                disabled={item !== null ? true : false}
                                readOnly={item !== null ? true : false}
                                control={control}>
                                {currencyTypes.map(item => (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>

                            <Select
                                name="toCurrencyType"
                                rules={{ required: t('validation.required') }}
                                error={errors?.toCurrencyType}
                                label={t('label.toCurrencyType')}
                                defaultValue={item?.toCurrencyType}
                                disabled={item !== null ? true : false}
                                readOnly={item !== null ? true : false}
                                control={control}>
                                {currencyTypes.map(item => (
                                    <MenuItem key={item} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>

                            <Input
                                defaultValue={item?.manualValue}
                                label="Kur Değeri"
                                error={errors?.manualValue}
                                {...register('manualValue')}
                            />
                        </>
                    )}

                    {loading && <Loading />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary" disabled={loading}>
                        {t('button.cancel')}
                    </Button>
                    <SubmitButton loading={loading}>{t('button.done')}</SubmitButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default React.memo(ExchangeItemEditDialog);
