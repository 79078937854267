import Page from '../../layout/Page';
import { useTranslation } from 'react-i18next';
import usePagination from '../../util/usePagination';
import tourProgramService from '../../service/TourProgramService';
import { DataTable } from '../../components/DataTable';
import Container from '@mui/material/Container';
import ListPageActions from '../../components/ListPageActions';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import TourProgramDeleteDialog from './TourProgramDeleteDialog';
import { getDeletedColumn } from '../../components/YesNoTypeOptions';
import { CategoryType } from '../../util/Enums';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import Input from '../../ui/Input';
import TourProgramAddCatalogDialog from './TourProgramAddCatalogDialog';
import MinMaxSearchCell from '../../components/datatable/MinMaxSearchCell';
import Tooltip from '@mui/material/Tooltip';
import VendorSearch from '../../components/datatable/VendorSearch';
import DateInput from '../../ui/DateInput';
import { formatDate } from '../../util/DateUtil';

const TourProgramListPage = ({ title, ...props }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { register, handleSubmit, control } = useForm();
    const [selected, setSelected] = useState([]);
    const [deleted, setDeleted] = useState(null);
    const [openTourProgramDeleteDialog, setOpenTourProgramDeleteDialog] = useState(false);
    const [openProgramAddCatalogDialog, setOpenProgramAddCatalogDialog] = useState(false);

    const departureColumn = categories => {
        const departure = categories?.find(item => item.categoryType === CategoryType.DEPARTURE.id);
        return (
            departure?.name && (
                <Tooltip title={<p>{departure.name}</p>}>
                    <span style={{ whiteSpace: 'nowrap' }} title={departure.name}>
                        {departure.name.split(' ')[0]}...
                    </span>
                </Tooltip>
            )
        );
    };
    const nightColumn = categories => {
        const night = categories?.find(item => item.categoryType === CategoryType.NIGHTS.id);
        return <span>{night?.name}</span>;
    };
    const transportationColumn = categories => {
        const transportation = categories?.find(
            item => item.categoryType === CategoryType.TRANSPORTATION.id,
        );
        return <span>{transportation?.name}</span>;
    };

    const columns = [
        {
            name: 'id',
            label: 'ID',
            width: 10,
            searchable: true,
            type: Number,
        },
        {
            name: 'operationTitle',
            label: 'Operasyon',
            searchable: true,
        },
        {
            name: 'contact',
            label: t('forecast.vendor'),
            searchable: true,
            searchField: 'contactId',
            type: Number,
            SearchCellComponent: VendorSearch,
            execute: item =>
                item?.brandName && (
                    <Tooltip title={<p>{item.brandName}</p>}>
                        <div
                            style={{
                                whiteSpace: 'nowrap',
                                maxWidth: '160px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {item.brandName}
                        </div>
                    </Tooltip>
                ),
        },
        {
            name: 'definedCatalogCount',
            label: 'Tan. Kat.',
            width: 10,
            type: Number,
            sortable: true,
            searchable: true,
            SearchCellComponent: MinMaxSearchCell,
        },
        {
            name: 'title',
            label: t('label.title'),
            width: 600,
            searchable: true,
            execute: item =>
                item && (
                    <Tooltip title={<p>{item}</p>}>
                        <div
                            style={{
                                whiteSpace: 'nowrap',
                                maxWidth: '300px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {item}
                        </div>
                    </Tooltip>
                ),
        },
        {
            name: 'categories',
            label: t('label.departure'),
            execute: departureColumn,
            searchable: true,
            width: 10,
        },
        {
            name: 'categories',
            label: t('label.night'),
            execute: nightColumn,
            searchable: true,
            width: 175,
        },
        {
            name: 'categories',
            label: t('label.transportation'),
            execute: transportationColumn,
            searchable: true,
            width: 10,
        },
        { ...getDeletedColumn(t) },
    ];

    const tourPrograms = usePagination({
        service: tourProgramService,
        defaultOrderBy: { name: 'id', orderType: 'desc' },
    });

    const onSubmit = async data => {
        if (tourPrograms.loading) return false;
        const obj = {
            ...tourPrograms.searchData,
            containingDate:
                data.containingDate && data.containingDate !== '' ? data.containingDate : null,
            checkOutDate: data.checkOutDate && data.checkOutDate !== '' ? data.checkOutDate : null,
            checkInDate: data.checkInDate && data.checkInDate !== '' ? data.checkInDate : null,
        };
        tourPrograms.setSearchData({ ...obj });
    };

    const ActionButtons = () => (
        <ListPageActions
            left={
                selected.length > 0 && (
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setOpenProgramAddCatalogDialog(true);
                        }}>
                        {t('button.addProgram')}
                    </Button>
                )
            }>
            <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={() => {
                    history.push(`/tours/programs/create`);
                }}>
                {t('button.addNew')}
            </Button>
        </ListPageActions>
    );

    return (
        <Page title={title}>
            <Container maxWidth={false} sx={{ maxWidth: '1642px' }}>
                <ActionButtons />
                <DataTable
                    title={t('label.tourProgramList')}
                    columns={columns}
                    {...tourPrograms}
                    headerNoWrap
                    {...{ selected, setSelected }}
                    onEdit={(item, index, e) => {
                        e.preventDefault();
                        if (e.ctrlKey || e.metaKey) {
                            window.open(`/tours/programs/edit/${item.id}`);
                        } else {
                            history.push(`/tours/programs/edit/${item.id}`);
                        }
                    }}
                    onDelete={item => {
                        setDeleted(item);
                        setOpenTourProgramDeleteDialog(true);
                    }}
                    action={
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={4} lg={2}></Grid>
                                <Grid item xs={12} md={4} lg={3}>
                                    <DateInput
                                        name="containingDate"
                                        label={t('label.containingDate')}
                                        defaultValue={null}
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={3}>
                                    <DateInput
                                        name="checkInDate"
                                        label={t('label.checkIn')}
                                        defaultValue={null}
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={3}>
                                    <DateInput
                                        name="checkOutDate"
                                        label={t('label.checkOut')}
                                        defaultValue={null}
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} lg={1}>
                                    <IconButton
                                        aria-label="Search"
                                        size="medium"
                                        type="submit"
                                        sx={{ marginTop: '8px' }}>
                                        <SearchIcon fontSize="medium" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </form>
                    }
                />
                <ActionButtons />
            </Container>

            <TourProgramDeleteDialog
                tourProgram={deleted}
                open={openTourProgramDeleteDialog}
                onClose={() => {
                    setOpenTourProgramDeleteDialog(false);
                    setDeleted(null);
                }}
                onDoneCallback={tourPrograms.search}
            />

            <TourProgramAddCatalogDialog
                tourPrograms={selected}
                open={openProgramAddCatalogDialog}
                onSave={() => {
                    setOpenProgramAddCatalogDialog(false);
                    setSelected([]);
                }}
                onClose={() => {
                    setOpenProgramAddCatalogDialog(false);
                }}
            />
        </Page>
    );
};

export default TourProgramListPage;
