import React, { useEffect, useState } from 'react';
import Page from '../../layout/Page';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import pageService from '../../service/PageService';
import Editor from '../../ui/Editor';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Panel from '../../ui/Panel';
import Input from '../../ui/Input';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import PageActions from '../../components/PageActions';
import Select from '../../ui/Select';
import MenuItem from '@mui/material/MenuItem';
import { useForm } from 'react-hook-form';
import { DescType, MessageType, PageType, YesNoType } from '../../util/Enums';
import useMessage from '../../util/useMessage';
import SubmitButton from '../../ui/SubmitButton';
import FaqEditPanel from '../../components/FaqEditPanel';
import RegionInput from '../../ui/RegionInput';
import TagInput from '../../ui/TagInput';
import CategoryInput from '../../ui/CategoryInput';

let MOUNT = true;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const PageEditPage = ({ title, ...props }) => {
    const param = useParams();
    const query = useQuery();
    const pageId = param?.id;

    const { t } = useTranslation();
    const history = useHistory();
    const showMessage = useMessage();

    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [page, setPage] = useState(null);
    const [descriptions, setDescriptions] = useState({});
    const [faq, setFaq] = useState([]);

    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm();

    const pageType = watch('pageType');
    const displayFooter = watch('displayFooter');

    useEffect(() => {
        if (pageId) {
            (async () => {
                setPageLoading(true);
                try {
                    const { resultObject } = await pageService.get(pageId);
                    initPageValues(resultObject);
                } catch (e) {}
                setPageLoading(false);
            })();
        } else {
            setValue('pageType', query.get('pageType'));
            setPageLoading(false);
        }
        return () => {
            MOUNT = false;
        };
    }, [pageId]);

    const initPageValues = _page => {
        setPage(_page);
        const desc = {};
        Object.keys(DescType).forEach(item => (desc[item] = _page[item]));
        setDescriptions(desc);
        setValue('pageType', _page.pageType);
        setValue('displayFooter', Number(_page.displayFooter));
        setFaq(_page.faq || []);
    };

    const onSubmit = async data => {
        if (loading) return false;
        try {
            setLoading(true);

            if (!data?.displayFooter) {
                delete data?.displayFooterTitle;
            }

            const obj = {
                ...data,
                ...descriptions,
                faq,
                pageType: parseInt(data.pageType),
                volume: data?.volume ? parseInt(data.volume) : 0,
                displayFooter: data?.displayFooter === 1,
            };

            const { resultObject } = await pageService.saveOrUpdate(pageId, obj);
            showMessage(t(pageId ? 'message.updated' : 'message.created'));

            history.push(
                `/pages/edit/${resultObject.pageId || resultObject.id}?pageType=${data.pageType}`,
            );
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const ActionPanel = () => (
        <PageActions>
            <Button
                variant="contained"
                disabled={loading}
                startIcon={loading ? null : <KeyboardBackspaceIcon />}
                onClick={() => {
                    history.goBack();
                }}>
                {t('button.back')}
            </Button>

            <SubmitButton loading={loading} startIcon={<SaveIcon />}>
                {t('button.save')}
            </SubmitButton>
        </PageActions>
    );

    return (
        <Page title={title} loading={pageLoading}>
            <Container maxWidth="xl">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ActionPanel />
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={6}>
                            <Panel title={t('title.pageInfos')}>
                                <Select
                                    name="pageType"
                                    rules={{ required: t('validation.required') }}
                                    error={errors?.pageType}
                                    label={t('label.pageType')}
                                    defaultValue={page?.pageType}
                                    disabled={true}
                                    control={control}>
                                    {Object.values(PageType).map(item => {
                                        return (
                                            <MenuItem key={item.id} value={item.id}>
                                                {t(item.label)}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>

                                <Input
                                    defaultValue={page?.title}
                                    label={t('label.title')}
                                    error={errors?.title}
                                    {...register('title', {
                                        required: t('validation.required'),
                                    })}
                                />

                                {PageType[pageType]?.region && (
                                    <RegionInput
                                        name="region"
                                        multiple={false}
                                        control={control}
                                        error={errors?.region}
                                        defaultValue={page?.region}
                                        rules={pageId ? {} : { required: t('validation.required') }}
                                        disabled={pageId}
                                    />
                                )}

                                {PageType[pageType]?.tag && (
                                    <TagInput
                                        name="tag"
                                        multiple={false}
                                        control={control}
                                        error={errors?.tag}
                                        defaultValue={page?.tag}
                                        rules={{ required: t('validation.required') }}
                                        disabled={pageId}
                                    />
                                )}

                                {PageType[pageType]?.category && (
                                    <CategoryInput
                                        name="category"
                                        multiple={false}
                                        control={control}
                                        error={errors?.category}
                                        defaultValue={page?.category}
                                        rules={{ required: t('validation.required') }}
                                        disabled={pageId}
                                    />
                                )}

                                {PageType[pageType]?.displayFooter && (
                                    <>
                                        <Select
                                            name="displayFooter"
                                            label={t('label.displayFooter')}
                                            defaultValue={Number(page?.displayFooter) || 0}
                                            control={control}>
                                            {Object.values(YesNoType).map(item => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {t(item.label)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {displayFooter > 0 && (
                                            <Input
                                                label={t('label.displayFooterTitle')}
                                                defaultValue={page?.displayFooterTitle}
                                                error={errors?.displayFooterTitle}
                                                {...register('displayFooterTitle')}
                                            />
                                        )}
                                    </>
                                )}
                            </Panel>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                            <Panel title="SEO">
                                <Input
                                    label={t('label.metaTitle')}
                                    defaultValue={page?.metaTitle}
                                    error={errors?.metaTitle}
                                    {...register('metaTitle')}
                                />
                                <Input
                                    label={t('label.metaDesc')}
                                    defaultValue={page?.metaDesc}
                                    multiline
                                    rows={3}
                                    error={errors?.metaDesc}
                                    {...register('metaDesc')}
                                />
                                <Input
                                    type="number"
                                    name="volume"
                                    defaultValue={page?.volume}
                                    label={t('label.searchVolume')}
                                    error={errors?.volume}
                                    {...register('volume')}
                                />
                            </Panel>
                        </Grid>

                        {PageType[pageType]?.descriptions?.map(desc => (
                            <Grid item xs={12}>
                                <Editor
                                    title={t(`label.${desc}`)}
                                    value={descriptions[desc]}
                                    onChange={val => {
                                        setDescriptions({ ...descriptions, [desc]: val });
                                    }}
                                />
                            </Grid>
                        ))}

                        {!PageType[pageType]?.excludeFaq && (
                            <Grid item xs={12}>
                                <FaqEditPanel {...{ faq, setFaq }} />
                            </Grid>
                        )}
                    </Grid>
                    <ActionPanel />
                </form>
            </Container>
        </Page>
    );
};

export default PageEditPage;
