import BaseService from './BaseService';

class PostService extends BaseService {
    constructor() {
        super('/posts');
    }
}

const postService = new PostService();

export default postService;
