import BaseService from './BaseService';
import { easyPost, easyPut, easyDelete } from '../util/Fetch';

class SaleService extends BaseService {
    constructor() {
        super('/sale');
    }

    async updateSale({ saleOfferFormId, data }) {
        return await easyPut(`/sale/updateSale/${saleOfferFormId}`, data);
    }

    async setAdditional({ saleOfferFormId, data }) {
        return await easyPut(`/sale/saleAdditionalService/${saleOfferFormId}`, data);
    }

    async setInvoice({ saleId, data }) {
        return await easyPut(`/sale/updateSaleInvoice/${saleId}`, data);
    }

    async updateSaleItemPurchases(data) {
        return await easyPut(`/sale/updateSaleItemPurchases`, data);
    }

    async updatePayment({ saleId, data }) {
        return await easyPut(`/sale/updateSalePayment/${saleId}`, data);
    }

    async deletePayment({ saleId, data }) {
        return await easyDelete(`/sale/deleteSalePayment/${saleId}`, data);
    }
}

const saleService = new SaleService();

export default saleService;
