import * as React from 'react';
import { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import StaticDateRangePicker from '@mui/lab/StaticDateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import useOutsideClick from '../../util/useOutsideClick';
import trLocale from 'date-fns/locale/tr';
import Button from '@mui/material/Button';
import {
    addDays,
    endOfMonth,
    endOfWeek,
    parseISO,
    startOfMonth,
    startOfWeek,
    subDays,
} from 'date-fns';
import { useForm } from 'react-hook-form';
import CategoryInput from '../../ui/CategoryInput';

const QuickFilters = [
    'YESTERDAY',
    'TODAY',
    'TOMORROW',
    'THIS_WEEK',
    'LAST_WEEK',
    'NEXT_WEEK',
    'THIS_MONTH',
    'LAST_MONTH',
];
const QuickFilterLabel = [
    'Dün',
    'Bugün',
    'Yarın',
    'Bu Hafta',
    'Geçen Hafta',
    'Gelecek Hafta',
    'Bu Ay',
    'Geçen Ay',
];

const DateRangePickerPopup = ({
    anchorEl,
    setAnchorEl,
    value,
    setValue,
    mode,
    minDate,
    maxDate,
    onClear,
}) => {
    const open = Boolean(anchorEl);
    const wrapperRef = useOutsideClick(() => setAnchorEl(null));

    const { control, watch } = useForm();

    const specialPeriod = watch('specialPeriod');

    useEffect(() => {
        if (specialPeriod) {
            console.log('specialPeriod:%o', specialPeriod);
            const { periodStartDate, periodEndDate } = specialPeriod;
            const values = [parseISO(periodStartDate), parseISO(periodEndDate)];

            setValue(values);
            setAnchorEl(null);
        }
    }, [specialPeriod]);

    const onQuickFilterClick = value => {
        const toDate = new Date();
        switch (value) {
            case 'YESTERDAY':
                {
                    const yesterday = subDays(toDate, 1);
                    setValue([yesterday, yesterday]);
                }
                break;
            case 'TODAY':
                {
                    setValue([toDate, toDate]);
                }
                break;
            case 'TOMORROW':
                {
                    const tomorrow = addDays(toDate, 1);
                    setValue([tomorrow, tomorrow]);
                }
                break;
            case 'THIS_WEEK':
                {
                    const start = startOfWeek(toDate, { weekStartsOn: 1 });
                    const end = endOfWeek(toDate, { weekStartsOn: 1 });
                    setValue([start, end]);
                }
                break;
            case 'LAST_WEEK':
                {
                    const ref = subDays(startOfWeek(toDate, { weekStartsOn: 1 }), 1);
                    const start = startOfWeek(ref, { weekStartsOn: 1 });
                    const end = endOfWeek(ref, { weekStartsOn: 1 });
                    setValue([start, end]);
                }
                break;
            case 'NEXT_WEEK':
                {
                    const nextWeekDate = addDays(endOfWeek(toDate, { weekStartsOn: 1 }), 1);
                    const start = startOfWeek(nextWeekDate, { weekStartsOn: 1 });
                    const end = endOfWeek(nextWeekDate, { weekStartsOn: 1 });
                    setValue([start, end]);
                }
                break;
            case 'THIS_MONTH':
                {
                    const start = startOfMonth(toDate);
                    const end = endOfMonth(toDate);
                    setValue([start, end]);
                }
                break;
            case 'LAST_MONTH':
                {
                    const ref = subDays(startOfMonth(toDate, { weekStartsOn: 1 }), 1);
                    const start = startOfMonth(ref);
                    const end = endOfMonth(ref);
                    setValue([start, end]);
                }
                break;
        }
        setAnchorEl(null);
    };

    return (
        <Popper open={open} anchorEl={anchorEl} style={{ zIndex: 100 }}>
            <Paper ref={wrapperRef}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '6px',
                        padding: '12px',
                        borderBottom: '1px solid #e0e0e0',
                    }}>
                    {QuickFilters.map((item, index) => (
                        <Button key={item} onClick={() => onQuickFilterClick(item)}>
                            {QuickFilterLabel[index]}
                        </Button>
                    ))}
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={trLocale}>
                        <StaticDateRangePicker
                            views={mode}
                            minDate={minDate}
                            maxDate={maxDate}
                            displayStaticWrapperAs="desktop"
                            value={value}
                            onChange={newValue => {
                                setValue(newValue);
                            }}
                            renderInput={(startProps, endProps) => (
                                <React.Fragment>
                                    <TextField {...startProps} />
                                    <Box sx={{ mx: 2 }}> to </Box>
                                    <TextField {...endProps} />
                                </React.Fragment>
                            )}
                        />
                    </LocalizationProvider>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '12px',
                        backgroundColor: 'rgb(246 247 249)',
                    }}>
                    <div style={{ minWidth: 200 }}>
                        <CategoryInput
                            name="specialPeriod"
                            control={control}
                            categoryType={1}
                            label="Özel Dönem"
                            multiple={false}
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
                        <Button onClick={onClear}>TEMİZLE</Button>
                        <Button color="secondary" onClick={() => setAnchorEl(null)}>
                            TAMAM
                        </Button>
                    </div>
                </div>
            </Paper>
        </Popper>
    );
};

export default DateRangePickerPopup;
