import BaseService from './BaseService';
import { easyPut } from '../util/Fetch';

class UserService extends BaseService {
    constructor() {
        super('/auths/users');
    }

    async changePassword({ id, data }) {
        return await easyPut(`${this.path}/${id}/changePassword`, data);
    }
}

const userService = new UserService();

export default userService;
