import BaseService from './BaseService';

class PrivilegeService extends BaseService {
    constructor() {
        super('/auths/privileges');
    }
}

const privilegeService = new PrivilegeService();

export default privilegeService;
