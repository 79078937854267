import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Panel from '../../../ui/Panel';
import { StyledTableCell } from '../../../components/DataTable';
import Input from '../../../ui/Input';

const DEFAULT_CHILD_RULES = [
    { minAge: 0, maxAge: 1.99, price: null, additionalPrice: 0 },
    { minAge: 2, maxAge: 11.99, price: null, additionalPrice: 0 },
];

const TourPriceEditChildRulesPanel = React.forwardRef(({ hotel }, ref) => {
    const { t } = useTranslation();
    const {
        register,
        setValue,
        watch,
        trigger,
        getValues,
        formState: { errors, isValid },
    } = useForm({ mode: 'onChange' });
    const [childRules, setChildRules] = useState([]);

    const watchRules = watch('childRules');

    useEffect(() => {
        if (Array.isArray(hotel?.childRules) && hotel.childRules.length > 0) {
            setChildRules(hotel.childRules);
        } else {
            setChildRules(DEFAULT_CHILD_RULES);
        }
    }, [hotel]);

    useEffect(() => {
        childRules.forEach((item, index) => {
            setValue(`childRules.${index}.childRuleId`, item.childRuleId || 0, {
                shouldDirty: true,
                shouldValidate: true,
            });
            setValue(`childRules.${index}.minAge`, item.minAge, {
                shouldDirty: true,
                shouldValidate: true,
            });
            setValue(`childRules.${index}.maxAge`, item.maxAge, {
                shouldDirty: true,
                shouldValidate: true,
            });
            setValue(`childRules.${index}.price`, item.price, {
                shouldDirty: true,
                shouldValidate: true,
            });
            setValue(`childRules.${index}.additionalPrice`, item.additionalPrice, {
                shouldDirty: true,
                shouldValidate: true,
            });
        });
    }, [childRules]);

    useImperativeHandle(ref, () => ({
        isValid: async () => {
            await trigger();
            return isValid;
        },
        getData: async () => {
            return getValues();
        },
    }));

    const getAgeRules = useCallback(
        (field, index) => {
            if (index > 0 && watchRules) {
                if (field === 'minAge') {
                    const beforeMaxAge = parseInt(watchRules[index - 1].maxAge) + 1;
                    return {
                        min: {
                            value: beforeMaxAge,
                            message: t('validation.min', { val: beforeMaxAge }),
                        },
                    };
                } else {
                    return {};
                }
            }
            return {};
        },
        [watchRules],
    );

    return (
        <Panel title={t('title.childRules')} noPadding>
            <TableContainer>
                <Table size="small" className="data-table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>{t('label.minAge')}</StyledTableCell>
                            <StyledTableCell>{t('label.maxAge')}</StyledTableCell>
                            <StyledTableCell>{t('label.price')}</StyledTableCell>
                            <StyledTableCell>{t('label.additionalPrice')}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {childRules.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ padding: 0 }}>
                                    <Input
                                        style={{ padding: 0, margin: 0 }}
                                        error={
                                            errors?.childRules && errors.childRules[index]?.minAge
                                        }
                                        {...register(`childRules.[${index}].minAge`, {
                                            setValueAs: v => {
                                                if (typeof v === 'string') {
                                                    return parseFloat(v.replace(/,/, '.'));
                                                } else {
                                                    return v;
                                                }
                                            },
                                            required: true,
                                            ...getAgeRules('minAge', index),
                                        })}
                                    />
                                </TableCell>
                                <TableCell style={{ padding: 0 }}>
                                    <Input
                                        style={{ padding: 0, margin: 0 }}
                                        error={
                                            errors?.childRules && errors.childRules[index]?.maxAge
                                        }
                                        {...register(`childRules.[${index}].maxAge`, {
                                            setValueAs: v => {
                                                if (typeof v === 'string') {
                                                    return parseFloat(v.replace(/,/, '.'));
                                                } else {
                                                    return v;
                                                }
                                            },
                                            required: true,
                                            ...getAgeRules('maxAge', index),
                                        })}
                                    />
                                </TableCell>
                                <TableCell style={{ padding: 0 }}>
                                    <Input
                                        style={{ padding: 0, margin: 0 }}
                                        error={
                                            errors?.childRules && errors.childRules[index]?.price
                                        }
                                        {...register(`childRules[${index}].price`, {
                                            setValueAs: v => {
                                                if (typeof v === 'string') {
                                                    return parseFloat(v.replace(/,/, '.'));
                                                } else {
                                                    return v;
                                                }
                                            },
                                            required: true,
                                        })}
                                    />
                                </TableCell>
                                <TableCell style={{ padding: 0 }}>
                                    <Input
                                        style={{ padding: 0, margin: 0 }}
                                        size={'small'}
                                        error={
                                            errors?.childRules &&
                                            errors.childRules[index]?.additionalPrice
                                        }
                                        {...register(`childRules[${index}].additionalPrice`, {
                                            setValueAs: v => {
                                                if (typeof v === 'string') {
                                                    return parseFloat(v.replace(/,/, '.'));
                                                } else {
                                                    return v;
                                                }
                                            },
                                            required: true,
                                        })}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Panel>
    );
});

export default TourPriceEditChildRulesPanel;
