import React, { useEffect, useState } from 'react';
import { MessageType } from '../../../util/Enums';
import saleService from '../../../service/SaleService';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid } from '@mui/material';
import Input from '../../../ui/Input';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useMessage from '../../../util/useMessage';
import Checkbox from '@mui/material/Checkbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CountryInput from '../../../ui/CountryInput';
import CityInput from '../../../ui/CityInput';
import DistrictInput from '../../../ui/DistrictInput';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && <Box sx={{ padding: '24px 0 0 0' }}>{children}</Box>}
        </div>
    );
}

const TourReservationEditInvoicePanel = ({
    stepId,
    name,
    surname,
    email,
    sale,
    toggle,
    isExpanded,
    next,
    onToggle,
}) => {
    const { t } = useTranslation();
    const showMessage = useMessage();
    const {
        register,
        handleSubmit,
        watch,
        control,
        formState: { errors },
    } = useForm();
    const watchCountry = watch('country');
    const watchCity = watch('city');
    const [loading, setLoading] = useState(false);
    const [saleInvoice, setSaleInvoice] = useState(false);

    useEffect(() => {
        if (sale?.saleInvoice?.invoiceTypeId && sale?.saleInvoice?.invoiceTypeId === 2) {
            setSaleInvoice(true);
        }
    }, []);

    const onSubmit = async data => {
        if (loading) return false;
        try {
            setLoading(true);
            const obj = {
                ...data,
                invoiceTypeId: saleInvoice === false ? 1 : 2,
                saleId: sale.saleId || 0,
                saleInvoiceId: sale?.saleInvoice?.saleInvoiceId || 0,
            };
            const { isSuccess, resultObject } = await saleService.setInvoice({
                saleId: sale.saleId,
                data: obj,
            });
            if (isSuccess) {
                showMessage(t('message.updated'));
                onToggle(stepId);
            }
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    return (
        <form id="invoiceForm" onSubmit={handleSubmit(onSubmit)}>
            <Card>
                <CardHeader
                    title="Fatura Bilgileri"
                    titleTypographyProps={{ variant: 'subtitle1' }}
                    action={
                        <>
                            {isExpanded(stepId) && (
                                <Button color="secondary" type="submit">
                                    KAYDET VE İLERLE
                                </Button>
                            )}
                            <Button color="secondary" onClick={() => toggle(stepId)}>
                                {isExpanded(stepId) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </Button>
                        </>
                    }
                />
                {isExpanded(stepId) && (
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <FormGroup row={true}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={!saleInvoice}
                                                onChange={e => setSaleInvoice(!e.target.value)}
                                            />
                                        }
                                        label="Bireysel"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={saleInvoice}
                                                onChange={e => setSaleInvoice(e.target.value)}
                                            />
                                        }
                                        label="Kurumsal"
                                    />
                                </FormGroup>
                            </Grid>
                            {saleInvoice ? (
                                <>
                                    <Grid item xs={2}>
                                        <Input
                                            name={`companyName`}
                                            label={t('reservation.companyName')}
                                            defaultValue={sale?.saleInvoice?.companyName || null}
                                            control={control}
                                            {...register(`companyName`)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Input
                                            name={`taxDepartment`}
                                            label={t('reservation.taxDepartment')}
                                            defaultValue={sale?.saleInvoice?.taxDepartment || null}
                                            control={control}
                                            {...register(`taxDepartment`)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Input
                                            name={`taxNumber`}
                                            label={t('reservation.taxNumber')}
                                            defaultValue={sale?.saleInvoice?.taxNumber || null}
                                            control={control}
                                            {...register(`taxNumber`)}
                                        />
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item xs={2}>
                                        <Input
                                            name={`identityNumber`}
                                            label={t('reservation.identityNumber')}
                                            defaultValue={sale?.saleInvoice?.identityNumber || null}
                                            control={control}
                                            {...register(`identityNumber`)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Input
                                            name={`name`}
                                            label={t('reservation.name')}
                                            defaultValue={sale?.saleInvoice?.name || name || null}
                                            control={control}
                                            {...register(`name`)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Input
                                            name={`surname`}
                                            label={t('reservation.surname')}
                                            defaultValue={
                                                sale?.saleInvoice?.surname || surname || null
                                            }
                                            control={control}
                                            {...register(`surname`)}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Input
                                            name={`email`}
                                            label={t('reservation.email')}
                                            defaultValue={sale?.saleInvoice?.email || email || null}
                                            control={control}
                                            {...register(`email`)}
                                        />
                                    </Grid>
                                </>
                            )}
                            <Grid item container spacing={1} xs={12}>
                                <Grid item xs={2}>
                                    <CountryInput
                                        name="country"
                                        defaultValue={sale?.saleInvoice?.country || null}
                                        control={control}
                                        error={errors?.country}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <CityInput
                                        name="city"
                                        defaultValue={sale?.saleInvoice?.city || null}
                                        control={control}
                                        error={errors?.city}
                                        countryId={watchCountry?.countryId}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <DistrictInput
                                        name="district"
                                        defaultValue={sale?.saleInvoice?.district || null}
                                        control={control}
                                        error={errors?.district}
                                        cityId={watchCity?.cityId}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container spacing={1} xs={12}>
                                <Grid item xs={6}>
                                    <Input
                                        name={`address`}
                                        label={t('reservation.address')}
                                        defaultValue={sale?.saleInvoice?.address || null}
                                        control={control}
                                        {...register(`address`)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                )}
                {isExpanded(stepId) && (
                    <CardActions>
                        <Button color="secondary" sx={{ marginLeft: 'auto' }} type="submit">
                            KAYDET VE İLERLE
                        </Button>
                    </CardActions>
                )}
            </Card>
        </form>
    );
};

export default TourReservationEditInvoicePanel;
