import { useFieldArray, useFormContext } from 'react-hook-form';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BankTypes, findEnumById, PaymentType } from '../../../util/Enums';
import { useEffect, useState } from 'react';
import { formatPrice } from '../../../util/CurrencyUtil';

const cellStyle = { padding: '0 8px 0 1px', width: '100px', textAlign: 'right' };
const inputStyle = {
    width: '100%',
    height: '26px',
    textAlign: 'right',
    border: '1px solid #ccc',
    backgroundColor: 'rgb(255 254 219)',
};

const TourPaymentUpdateTable = ({ data }) => {
    const { t } = useTranslation();
    const {
        control,
        setValue,
        watch,
        register,
        reset,
        formState: { errors },
    } = useFormContext();
    const { fields } = useFieldArray({
        control,
        name: `payment`,
        keyName: 'keyId',
    });

    const [editByTotal, setEditByTotal] = useState(true);
    const [globalBank, setGlobalBank] = useState('1');

    const [totalSalePrice, setTotalSalePrice] = useState(0);
    const [totalIncomingAmount, setTotalIncomingAmount] = useState(0);

    const values = watch();

    const [isMount, setIsMount] = useState(false);

    useEffect(() => {
        let _totalSalePrice = 0;
        let _totalIncomingAmount = 0;

        const { payment } = values;

        payment?.forEach(item => {
            _totalSalePrice += item.salePrice || 0;
            _totalIncomingAmount += item.incomingAmount || 0;
        });

        setTotalSalePrice(_totalSalePrice);

        if (!isMount || !editByTotal) {
            setTotalIncomingAmount(_totalIncomingAmount);
            setIsMount(true);
        }
    }, [editByTotal, isMount, values]);

    useEffect(() => {
        if (editByTotal) {
            const items = data;
            const decimal = parseFloat(totalIncomingAmount.toString()) || 0;
            if (!isNaN(decimal) && totalSalePrice > 0) {
                const commissionRate =
                    ((totalSalePrice - totalIncomingAmount) * 100) / totalSalePrice;
                const commissionRateRound = parseFloat(parseFloat(commissionRate).toFixed(2));

                items.forEach((item, index) => {
                    const rate = (item.salePrice * commissionRate) / 100;
                    console.log(rate);
                    const incomingAmount = parseFloat(
                        parseFloat(`${item.salePrice - rate}`).toFixed(2),
                    );

                    setValue(`payment.${index}.incomingAmount`, incomingAmount);
                    setValue(`payment.${index}.commissionRate`, commissionRateRound);
                });
            }

            items.forEach((item, index) => {
                setValue(`payment.${index}.bankId`, globalBank);
            });
        }
    }, [editByTotal, globalBank, data, totalSalePrice, totalIncomingAmount]);

    const onTotalExchangeBuyTLInputChange = e => {
        setTotalIncomingAmount(e.target.value);
    };

    return (
        <TableContainer>
            <Table size="small" className="data-table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t('payment.saleCode')}</TableCell>
                        <TableCell>{t('payment.salePaymentTypeId')}</TableCell>
                        <TableCell>{t('payment.salePrice')}</TableCell>

                        <TableCell>{t('payment.incomingAmount')}</TableCell>
                        <TableCell>{t('payment.commissionRate')}</TableCell>

                        <TableCell>{t('payment.incomingBank')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fields.map((field, index) => (
                        <TableRow key={field.keyId}>
                            <TableCell>{field.saleCode}</TableCell>
                            <TableCell>
                                {findEnumById(PaymentType, field.salePaymentTypeId)?.label}
                            </TableCell>
                            <TableCell style={cellStyle}>{formatPrice(field.salePrice)}</TableCell>
                            <TableCell style={cellStyle}>
                                <input
                                    disabled={editByTotal}
                                    style={inputStyle}
                                    {...register(`payment.${index}.incomingAmount`, {
                                        valueAsNumber: true,
                                        pattern: {
                                            value: /^(0|[1-9]\d*)(\.\d+)?$/,
                                        },
                                    })}
                                />
                            </TableCell>
                            <TableCell style={cellStyle}>
                                <input
                                    disabled={editByTotal}
                                    style={inputStyle}
                                    {...register(`payment.${index}.commissionRate`, {
                                        valueAsNumber: true,
                                        pattern: {
                                            value: /^(0|[1-9]\d*)(\.\d+)?$/,
                                        },
                                    })}
                                />
                            </TableCell>
                            <TableCell style={cellStyle}>
                                <select
                                    disabled={editByTotal}
                                    style={{ ...inputStyle, textAlign: 'left', height: '30px' }}
                                    {...register(`payment.${index}.bankId`)}>
                                    {Object.values(BankTypes).map(item => (
                                        <option value={item.id}>{item.label}</option>
                                    ))}
                                </select>
                            </TableCell>
                        </TableRow>
                    ))}

                    <TableRow>
                        <TableCell colSpan={2} style={{ textAlign: 'right' }}>
                            <label>
                                <span>Toplama göre düzenle</span>
                                <input
                                    type="checkbox"
                                    defaultChecked={editByTotal}
                                    style={{ margin: '0 5px' }}
                                    value={editByTotal}
                                    onChange={e => setEditByTotal(e.target.checked)}
                                />
                            </label>
                            <strong>Toplam:</strong>
                        </TableCell>
                        <TableCell style={cellStyle}>{formatPrice(totalSalePrice)}</TableCell>
                        <TableCell style={cellStyle}>
                            {editByTotal ? (
                                <input
                                    disabled={!editByTotal}
                                    style={inputStyle}
                                    value={totalIncomingAmount}
                                    onChange={onTotalExchangeBuyTLInputChange}
                                />
                            ) : (
                                formatPrice(totalIncomingAmount)
                            )}
                        </TableCell>
                        <TableCell />
                        <TableCell style={cellStyle}>
                            {editByTotal && (
                                <select
                                    value={globalBank}
                                    onChange={e => setGlobalBank(e.target.value)}
                                    style={{ ...inputStyle, textAlign: 'left', height: '30px' }}>
                                    {Object.values(BankTypes).map(item => (
                                        <option value={item.id}>{item.label}</option>
                                    ))}
                                </select>
                            )}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TourPaymentUpdateTable;
