import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import DoneButton from '../../../ui/DoneButton';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const TourReservationOrderDialog = ({ open, saleOfferTourPrograms, onClose, onOrderChange }) => {
    const { t } = useTranslation();
    const [saleOfferTourProgramsArray, setSaleOfferTourProgramsArray] = useState(
        saleOfferTourPrograms || [],
    );

    const onSubmit = () => {
        onOrderChange(saleOfferTourProgramsArray);
        onClose();
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        result.forEach((item, index) => {
            item.order = index;
        });

        return result;
    };

    const onDragEnd = result => {
        if (result?.destination) {
            const items = reorder(
                saleOfferTourProgramsArray,
                result.source.index,
                result.destination.index,
            );
            setSaleOfferTourProgramsArray(items);
        }
    };
    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer

        padding: '6px',
        marginBottom: 4,
        display: 'flex',
        flexDirection: 'row',
        userSelect: 'none',
        alignItems: 'center',

        minWidth: 250,

        // change background colour if dragging
        background: isDragging ? '#f3ffc8' : '#f7f6ee',

        // styles we need to apply on draggables
        ...draggableStyle,
    });

    const getListStyle = isDraggingOver => ({
        // background: isDraggingOver ? "lightblue" : "lightgrey",
        marginTop: 12,
        minHeight: `${saleOfferTourPrograms.length * 35}px`,
    });

    return (
        <Dialog open={open} fullWidth maxWidth="sm" scroll="paper">
            <DialogTitle>Sıralama</DialogTitle>
            <DialogContent dividers={true}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}>
                                {saleOfferTourProgramsArray.map((item, index) => (
                                    <Draggable
                                        key={item.saleOfferTourProgramId}
                                        draggableId={`${item.saleOfferTourProgramId}`}
                                        index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style,
                                                )}>
                                                <div style={{ flex: 1 }}>
                                                    <span style={{ fontWeight: 'bold' }}>
                                                        {index + 1}
                                                    </span>
                                                    <span
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: 12,
                                                            marginRight: 4,
                                                        }}>
                                                        .
                                                    </span>
                                                    <span>
                                                        {item.operationTitle || ''} -{' '}
                                                        {item.hotel?.name || ''}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="primary"
                    // disabled={loading}
                >
                    {t('button.cancel')}
                </Button>
                <DoneButton
                    onClick={onSubmit}
                    // loading={loading}
                >
                    {t('button.save')}
                </DoneButton>
            </DialogActions>
        </Dialog>
    );
};

export default TourReservationOrderDialog;
