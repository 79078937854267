import { Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import parameterService from '../service/ParameterService';

const ParameterInput = ({ name, label, defaultValue, rules, error, disabled, control, type }) => {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    // const [resultList, setResultList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let active = true;
        setLoading(true);
        if (inputValue === '') {
            if (active) {
                setOptions([]);
                setLoading(false);
            }
            return undefined;
        }
        (async () => {
            try {
                const { resultObject } = await parameterService.search({
                    start: 0,
                    data: {
                        name: inputValue,
                        type: type,
                    },
                    max: 50,
                });
                const result = resultObject.map(item => ({ id: item.id, name: item.name }));
                if (active) {
                    setOptions(result || []);
                    // setResultList(resultObject);
                    setLoading(false);
                }
            } catch (e) {}
        })();
        return () => {
            active = false;
        };
    }, [inputValue, type]);

    const handleChange = event => {
        setInputValue(event.target.value);
    };

    // const onFieldChangeHandle = (field, newInputValue) => {
    //     if (newInputValue && newInputValue.length > 0) {
    //         let find = null;
    //         if (defaultValue?.name === newInputValue) {
    //             find = defaultValue;
    //         } else {
    //             find = resultList.find(item => item.name === newInputValue);
    //         }
    //         field.onChange({ id: find?.id || 0, name: newInputValue });
    //     } else {
    //         field.onChange(null);
    //     }
    // };

    return (
        <Controller
            render={({ field }) => (
                <Autocomplete
                    {...field}
                    // freeSolo
                    options={options}
                    loading={loading}
                    disabled={disabled}
                    getOptionLabel={item => item?.name || item || ''}
                    renderInput={params => (
                        <TextField
                            fullWidth
                            {...params}
                            label={label}
                            error={error && true}
                            margin="dense"
                            variant="outlined"
                            autoComplete="off"
                            helperText={error?.message}
                            onChange={handleChange}
                        />
                    )}
                    onChange={(_, data) => field.onChange(data)}
                    // onInputChange={(event, newInputValue) => {
                    //     onFieldChangeHandle(field, newInputValue);
                    // }}
                    // onChange={(_, data) => {
                    //     onFieldChangeHandle(field, data);
                    // }}
                />
            )}
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
        />
    );
};

export default ParameterInput;
