import BaseService from './BaseService';

class PageService extends BaseService {
    constructor() {
        super('/pages');
    }
}

const pageService = new PageService();

export default pageService;
