import Grid from '@mui/material/Grid';
import NativeEditor from '../../ui/NativeEditor';
import makeStyles from '@mui/styles/makeStyles';
import { Card, CardHeader } from '@mui/material';
import Button from '@mui/material/Button';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    card: {
        //height: '100%',
    },
    title: {
        fontSize: '1.2rem',
    },
}));

const PostSubContentEditor = ({ title, subtitle, value, onChange, expanded = false }) => {
    const classes = useStyles();

    const { t } = useTranslation();
    const [isExpanded, setExpanded] = useState(expanded);

    const toggle = () => {
        setExpanded(!isExpanded);
    };

    const handleOnChange = (type, val) => {
        onChange &&
            onChange({
                ...value,
                [type]: val,
            });
    };

    return (
        <Card className={classes.card}>
            <CardHeader
                title={title}
                subheader={subtitle}
                classes={{ title: classes.title }}
                onClick={toggle}
                action={
                    <Button color="secondary" onClick={toggle}>
                        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </Button>
                }
            />
            {isExpanded && (
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                        <div
                            style={{
                                fontSize: 16,
                                fontWeight: 'bold',
                                padding: '16px 8px',
                                backgroundColor: '#dae8ea',
                            }}>
                            {t('label.shortDesc')}
                        </div>
                        <NativeEditor
                            value={value?.shortDesc}
                            onChange={val => {
                                handleOnChange('shortDesc', val);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <div
                            style={{
                                fontSize: 16,
                                fontWeight: 'bold',
                                padding: '16px 8px',
                                backgroundColor: '#dae8ea',
                            }}>
                            {t('label.description')}
                        </div>
                        <NativeEditor
                            value={value?.description}
                            onChange={val => {
                                handleOnChange('description', val);
                            }}
                        />
                    </Grid>
                </Grid>
            )}
        </Card>
    );
};

export default PostSubContentEditor;
