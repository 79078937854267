

import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';
import ContactAddDialog from '../components/ContactAddDialog';
import InputAdornment from '@mui/material/InputAdornment';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const ContactInput = ({ name, label, rules, control, error, defaultValue, disabled = false }) => {
    const [selected, setSelected] = useState([]);
    const [openContactAddDialog, setOpenContactAddDialog] = useState(false);
    return (
        <>
            <Controller
                control={control}
                name={name}
                rules={rules}
                defaultValue={defaultValue}
                render={({ field }) => (
                    <>
                        <TextField 
                            value={field.value ? (field.value.name || "") + " " + (field.value.surname || "") : ""}
                            disabled={disabled}
                            label={label}
                            error={!!error} 
                            helperText={error?.message} 
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            autoComplete="off"
                            sx={{
                                input: {
                                  background: "white"
                                }
                              }}
                            InputLabelProps={{
                                shrink: field.value ? true : false,
                            }}
                            InputProps={
                                {
                                    readOnly: true, 
                                    disableUnderline: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {(field.value && !disabled) && 
                                                <HighlightOffIcon
                                                    fontSize="small"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={(e) => {
                                                        if(!disabled) {
                                                            e.stopPropagation();
                                                            field.onChange(null);
                                                            setSelected([]);
                                                        }
                                                    }}
                                                />
                                            }
                                            <MoreHorizIcon />
                                        </InputAdornment>
                                    )
                                }
                            }
                            onClick={() => {
                                if(!disabled) {
                                    setOpenContactAddDialog(true);
                                }
                            }}
                        />
                        <ContactAddDialog
                            {...{ selected, setSelected}}
                            open={openContactAddDialog}
                            onClose={() => setOpenContactAddDialog(false)}
                            onAddCallback={data => {
                                field.onChange(data)
                                setOpenContactAddDialog(false);
                            }}
                        />
                    </>
                )}
            />
        </>
    );
};

export default ContactInput;
