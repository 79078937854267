import { useEffect } from 'react';

const useExitPrompt = isDirty => {
    useEffect(() => {
        if (!isDirty) return;
        const handleBeforeUnload = event => event.preventDefault();
        //window.onbeforeunload = () => true;
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
        //return () => window.onbeforeunload = undefined;
    }, [isDirty]);
};

export default useExitPrompt;
