import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const TableLoading = React.memo(({ colSpan }) => (
    <TableRow>
        <TableCell colSpan={colSpan + 2} style={{ textAlign: 'center' }}>
            <div style={{ padding: 12 }}>
                <CircularProgress size={24} />
                <div style={{ padding: 10 }}>
                    YÜKLENİYOR <br /> LÜTFEN BEKLEYİN
                </div>
            </div>
        </TableCell>
    </TableRow>
));

export default TableLoading;
