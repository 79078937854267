import React, { useEffect, useState } from 'react';
import Page from '../../layout/Page';
import { useHistory, useParams } from 'react-router-dom';
import tourProgramService from '../../service/TourProgramService';
import Editor from '../../ui/Editor';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Panel from '../../ui/Panel';
import Input from '../../ui/Input';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import PageActions from '../../components/PageActions';
import { useForm } from 'react-hook-form';
import CoverImageSelectionCard from '../../components/CoverImageSelectionCard';
import {
    CategoryType,
    MessageType,
    ParameterTypes,
    AccommodationTypes,
    findEnumById,
} from '../../util/Enums';
import useMessage from '../../util/useMessage';
import SubmitButton from '../../ui/SubmitButton';
import CategoryInput from '../../ui/CategoryInput';
import VendorInput from '../../ui/VendorInput';
import AddIcon from '@mui/icons-material/Add';
import ProgramDayEditDialog from '../../components/ProgramDayEditDialog';
import { DataTable } from '../../components/DataTable';
import TourPeriodDateEditDialog from './TourPeriodDateEditDialog';
import { addDays, format, parse } from 'date-fns';
import TourPeriodDateWithDayEditDialog from './TourPeriodDateWithDayEditDialog';
import ParameterInput from '../../ui/ParameterInput';
import TourCatalogInput from '../../ui/TourCatalogInput';
import DeparturePortInput from '../../ui/DeparturePortInput';
import AdditionalServiceInput from '../../ui/AdditionalServiceInput';
import VisaTypeInput from '../../ui/VisaTypeInput';
import Link from '@mui/material/Link';

const DATE_FORMAT = 'yyyy-MM-dd';
const days = ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'];

const TourProgramEditPage = ({ title, ...props }) => {
    const param = useParams();
    const tourProgramId = param?.id;
    const { t } = useTranslation();
    const history = useHistory();
    const showMessage = useMessage();
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [tourProgram, setTourProgram] = useState(null);
    const [extraTours, setExtraTours] = useState(null);
    const [shortDesc, setShortDesc] = useState('');
    const [description, setDescription] = useState('');
    const [generalTerms, setGeneralTerms] = useState('');
    const [includedPriceDescription, setIncludedPriceDescription] = useState('');
    const [notIncludedPriceDescription, setNotIncludedPriceDescription] = useState('');
    const [hotelsToStay, setHotelsToStay] = useState('');
    const [programDays, setProgramDays] = useState([]);
    const [periodDates, setPeriodDates] = useState([]);
    const [newVendorValue, setNewVendorValue] = useState(null);
    const [tourProgramUrl, setTourProgramUrl] = useState(null);
    const [image, setImage] = useState(null);
    const [fileName, setFileName] = useState('');
    const [folderName] = useState('post');
    const aspect = 720 / 480;
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        formState: { errors, isDirty },
    } = useForm({ mode: 'onChange' });
    const watchNight = watch(`categories[${CategoryType.NIGHTS.id}]`, null);
    const watchVisa = watch(`categories[${CategoryType.VISA.id}]`, null);
    const watchPageTitle = watch('pageTitle');
    const watchFileName = watch('title');
    const [openProgramDayEditDialog, setOpenProgramDayEditDialog] = useState(false);
    const [selectedProgramDayIndex, setSelectedProgramDayIndex] = useState(null);
    const [openPeriodDateEditDialog, setOpenPeriodDateEditDialog] = useState(false);
    const [openPeriodDateWithDayEditDialog, setOpenPeriodDateWithDayEditDialog] = useState(false);

    useEffect(() => {
        if (tourProgramId) {
            (async () => {
                setPageLoading(true);
                try {
                    const { resultObject } = await tourProgramService.get(tourProgramId);
                    initPageValues(resultObject);
                } catch ({ code, message }) {
                    showMessage(`${code} - ${message}`, MessageType.ERROR);
                }
                setPageLoading(false);
            })();
        } else {
            setPageLoading(false);
        }
    }, [tourProgramId]);

    useEffect(() => {
        if (isDirty) {
            console.log('Dirty night changes');
            setProgramDays([]);
        }
    }, [watchNight]);

    useEffect(() => {
        setFileName(watchFileName || '');
    }, [watchFileName]);

    const initPageValues = _tourProgram => {
        setTourProgram(_tourProgram);
        setShortDesc(_tourProgram.shortDesc);
        setDescription(_tourProgram.description);
        setGeneralTerms(_tourProgram.generalTerms);
        setProgramDays(_tourProgram.programDays || []);
        setExtraTours(_tourProgram?.extraTours || []);
        setIncludedPriceDescription(_tourProgram?.includedPriceDescription);
        setNotIncludedPriceDescription(_tourProgram?.notIncludedPriceDescription);
        setHotelsToStay(_tourProgram?.hotelsToStay);
        setImage(_tourProgram.image);
        setValue("pageTitle", _tourProgram.pageTitle || null)
        if (_tourProgram.tourProgramPeriods) {
            const _ref = new Date();
            const convertList = _tourProgram.tourProgramPeriods?.map(item => ({
                ...item,
                date: parse(item.date, DATE_FORMAT, _ref),
            }));

            convertList.sort(function (a, b) {
                return a.date - b.date;
            });

            setPeriodDates(convertList);
        } else {
            setPeriodDates([]);
        }
        Object.values(CategoryType).forEach(cat => {
            const data =
                _tourProgram?.categories.filter(item => item.categoryType === cat.id) || [];
            setValue(`categories[${cat.id}]`, cat.programMultiple ? data : data[0]);
        });
        if (_tourProgram.link) {
            const tourProgramPreview = `${_tourProgram.link}${_tourProgram.link.includes('?') ? '&' : '?'}${_tourProgram.catalogs?.length > 0 && _tourProgram.catalogs[0].id ? 'tourCatalogId=' + _tourProgram.catalogs[0].id : ''}${_tourProgram.tourProgramPeriods?.length > 0 && _tourProgram.tourProgramPeriods[0].id ? '&tourProgramPeriodId=' + _tourProgram.tourProgramPeriods[0].id : ''}${_tourProgram.transportCompany?.id ? '&transportCompanyId=' + _tourProgram.transportCompany.id : ''}${_tourProgram.hotel?.hotelId ? '&hotelId=' + _tourProgram.hotel?.hotelId : ''}${_tourProgram.departurePort?.length > 0 && _tourProgram.departurePort[0].id ? '&departurePortId=' + _tourProgram.departurePort[0].id : ''}`;
            setTourProgramUrl(tourProgramPreview);
        }
    };

    const onSubmit = async data => {
        if (loading) return false;
        try {
            setLoading(true);

            const cats = [];
            data.categories?.forEach(item => {
                if (item !== undefined) {
                    if (Array.isArray(item)) {
                        cats.push(...item);
                    } else {
                        cats.push(item);
                    }
                }
            });
            const convertList = periodDates?.map(item => ({
                ...item,
                date: format(item.date, DATE_FORMAT),
            }));
            const obj = {
                ...data,
                extraTours,
                description,
                includedPriceDescription,
                notIncludedPriceDescription,
                hotelsToStay,
                programDays,
                categories: cats,
                shortDesc: shortDesc,
                generalTerms: generalTerms,
                periodDates: convertList,
                image,
                cancellationDayCount: data.cancellationDayCount
                    ? parseInt(data.cancellationDayCount)
                    : null,
            };

            const { resultObject } = await tourProgramService.saveOrUpdate(tourProgramId, obj);

            showMessage(t(tourProgramId ? 'message.updated' : 'message.created'));
            history.push(`/tours/programs/edit/${resultObject.tourProgramId || resultObject.id}`);
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const addNewDayProgramOnclick = () => {
        setSelectedProgramDayIndex(null);
        setOpenProgramDayEditDialog(true);
    };

    const dateSelectionDoneCallback = values => {
        setOpenPeriodDateEditDialog(false);
        setOpenPeriodDateWithDayEditDialog(false);

        const dateList = [];
        values.forEach(date => {
            const item = periodDates.find(item => item.date === date);
            if (!item) {
                dateList.push({
                    date: date,
                });
            } else {
                dateList.push(item);
            }
        });

        dateList.sort(function (a, b) {
            return a.date - b.date;
        });

        setPeriodDates(dateList);
    };

    const onAskClearAllPeriodDates = () => {
        const check = window.confirm(t('message.areYouSureClearAllEntries'));
        if (check) {
            setPeriodDates([]);
        }
    };

    const onAddVendorCallback = data => {
        const obj = { id: data.id, name: data.name };
        setValue('contact', obj);
        setNewVendorValue(obj);
    };

    const ActionPanel = () => (
        <PageActions>
            {tourProgramUrl !== null && (
                <Link
                    href={`${import.meta.env.VITE_B2C_URL}${tourProgramUrl}`}
                    target="_blank"
                    sx={{ marginRight: '10px' }}>
                    Program Ön İzleme
                </Link>
            )}
            {tourProgram && tourProgram.tourPriceId && (
                <Link
                    href={`/tours/prices/edit/${tourProgram.tourPriceId}`}
                    target="_blank"
                    sx={{ marginRight: '10px' }}>
                    Program Fiyatı
                </Link>
            )}
            <Button
                variant="contained"
                disabled={loading}
                startIcon={loading ? null : <KeyboardBackspaceIcon />}
                onClick={() => {
                    history.goBack();
                }}>
                {t('button.back')}
            </Button>
            <SubmitButton loading={loading} startIcon={<SaveIcon />}>
                {t('button.save')}
            </SubmitButton>
        </PageActions>
    );

    return (
        <Page title={title} loading={pageLoading}>
            <Container maxWidth="xl">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ActionPanel />
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={3}>
                            <Panel title={t('title.tourProgramInfos')}>
                                <VendorInput
                                    name="contact"
                                    multiple={false}
                                    control={control}
                                    defaultValue={tourProgram?.contact || newVendorValue}
                                    rules={{ required: t('validation.required') }}
                                    error={errors?.contact}
                                    addNewCallback={onAddVendorCallback}
                                />

                                <Input
                                    defaultValue={tourProgram?.title}
                                    label="Dosya Adı"
                                    error={errors?.title}
                                    {...register('title', {
                                        required: t('validation.required'),
                                    })}
                                />
                                <Input
                                    defaultValue={tourProgram?.operationTitle}
                                    label={t('label.operationTitle')}
                                    error={errors?.operationTitle}
                                    {...register('operationTitle')}
                                    disabled={true}
                                    readOnly={true}
                                />
                                <Input
                                    defaultValue={tourProgram?.pageTitle}
                                    label={t('label.h1')}
                                    error={errors?.pageTitle}
                                    {...register('pageTitle', {
                                        required: t('validation.required'),
                                    })}
                                />
                                <TourCatalogInput
                                    name="cannonicalCatalog"
                                    label={t('label.cannonicalCatalog')}
                                    multiple={false}
                                    defaultValue={tourProgram?.cannonicalCatalog}
                                    control={control}
                                    error={errors?.cannonicalCatalog}
                                    // rules={{required: t("validation.required")}}
                                />
                                <Input
                                    defaultValue={tourProgram?.name}
                                    label="Tur Adı"
                                    error={errors?.name}
                                    {...register('name')}
                                />
                                <Input
                                    defaultValue={tourProgram?.youTubeUrl}
                                    label="Youtube Linki"
                                    error={errors?.youTubeUrl}
                                    {...register('youTubeUrl')}
                                />
                            </Panel>
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <Panel title={t('label.transportInfos')}>
                                <DeparturePortInput
                                    label={t('label.departurePort')}
                                    name="departurePort"
                                    control={control}
                                    type={ParameterTypes.DEPARTURE_PORT.id}
                                    defaultValue={tourProgram?.departurePort}
                                    error={errors?.departurePort}
                                    rules={{ required: t('validation.required') }}
                                />
                                <ParameterInput
                                    label={t('label.transportCompany')}
                                    name="transportCompany"
                                    control={control}
                                    type={ParameterTypes.TRANSPORTATION.id}
                                    error={errors?.transportCompany}
                                    defaultValue={tourProgram?.transportCompany}
                                    rules={{ required: t('validation.required') }}
                                />
                                <Input
                                    defaultValue={tourProgram?.departureRoute}
                                    label={t('label.departureRoute')}
                                    error={errors?.departureRoute}
                                    {...register('departureRoute')}
                                />
                                <Input
                                    defaultValue={tourProgram?.arrivalRoute}
                                    label={t('label.arrivalRoute')}
                                    error={errors?.arrivalRoute}
                                    {...register('arrivalRoute')}
                                />
                            </Panel>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                            <Panel title={t('label.catalog')}>
                                <TourCatalogInput
                                    name="catalogs"
                                    multiple={true}
                                    defaultValue={tourProgram?.catalogs}
                                    control={control}
                                    error={errors?.catalogs}
                                    // rules={{required: t("validation.required")}}
                                    forTourProgram={true}
                                    disabled={watchPageTitle ? false : true}
                                />
                            </Panel>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <Panel title={t('label.categories')}>
                                <Grid container spacing={1}>
                                    {Object.values(CategoryType).map(cat => {
                                        let error = undefined;
                                        if (errors?.categories) {
                                            error = errors.categories[`${cat.id}`];
                                        }
                                        return (
                                            <>
                                                <Grid key={cat.id} item xs={12} md={6} lg={3}>
                                                    <CategoryInput
                                                        name={`categories[${cat.id}]`}
                                                        control={control}
                                                        error={error}
                                                        categoryType={cat.id}
                                                        label={t(cat.label)}
                                                        multiple={
                                                            cat.programMultiple ? true : false
                                                        }
                                                        disabled={cat.programDisabled && tourProgram?.categories[cat.id] ? true : false}
                                                    />
                                                </Grid>
                                                {cat.id === 8 && (
                                                    <Grid item xs={12} md={6} lg={3}>
                                                        {watchVisa &&
                                                            watchVisa.name === 'Vizeli' && (
                                                                <VisaTypeInput
                                                                    name="visaType"
                                                                    control={control}
                                                                    error={errors?.visaType}
                                                                    defaultValue={
                                                                        tourProgram?.visaType || []
                                                                    }
                                                                    multiple={true}
                                                                />
                                                            )}
                                                    </Grid>
                                                )}
                                            </>
                                        );
                                    })}
                                    <Grid item xs={12} md={6} lg={3}>
                                        <Input
                                            type="number"
                                            defaultValue={tourProgram?.cancellationDayCount || 30}
                                            label={t('label.cancellationNumberDays')}
                                            error={errors?.cancellationDayCount}
                                            {...register('cancellationDayCount')}
                                        />
                                    </Grid>
                                </Grid>
                            </Panel>
                        </Grid>

                        <Grid item xs={12} md={6} lg={8}>
                            <Panel title={t('title.additionalServicesInfos')}>
                                <AdditionalServiceInput
                                    name="additionalServices"
                                    control={control}
                                    defaultValue={tourProgram?.additionalServices}
                                />
                            </Panel>
                        </Grid>

                        <Grid item xs={12} md={6} lg={4}>
                            <CoverImageSelectionCard
                                title={t('title.image')}
                                subtitle="Program Kapak Resmi"
                                {...{ aspect, image, setImage, fileName, folderName }}
                            />
                        </Grid>

                        {watchNight && (
                            <Grid item xs={12}>
                                <DataTable
                                    data={programDays}
                                    title={t('title.nDaysProgram', {
                                        day: watchNight?.numberValue + 1,
                                        night: watchNight?.numberValue,
                                    })}
                                    columns={[
                                        {
                                            name: 'title',
                                            label: t('label.title'),
                                        },
                                        {
                                            name: 'days',
                                            label: t('label.days'),
                                            execute: item =>
                                                item && item.map(i => `${i}. Gün`).join(', '),
                                        },
                                        {
                                            name: 'destinations',
                                            label: t('label.destinationLocations'),
                                            execute: item => item?.map(i => i.name).join(', '),
                                        },
                                        {
                                            name: 'accommodations',
                                            label: t('label.stayLocations'),
                                            execute: item => item?.map(i => i.name).join(', '),
                                        },
                                        {
                                            name: 'accommodationTypeId',
                                            label: t('label.accommodationType'),
                                            execute: item =>
                                                item &&
                                                findEnumById(AccommodationTypes, item).label,
                                        },
                                    ]}
                                    action={
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<AddIcon />}
                                            size={'small'}
                                            onClick={addNewDayProgramOnclick}>
                                            {t('button.add')}
                                        </Button>
                                    }
                                    onEdit={(item, index) => {
                                        setSelectedProgramDayIndex(index);
                                        setOpenProgramDayEditDialog(true);
                                    }}
                                    onDelete={(item, index) => {
                                        setProgramDays(
                                            programDays.filter((i, idx) => idx !== index),
                                        );
                                    }}
                                />
                            </Grid>
                        )}
                        {watchNight && (
                            <Grid item xs={12}>
                                <DataTable
                                    title={t('label.periodDates')}
                                    columns={[
                                        {
                                            name: 'date',
                                            label: t('label.periodIndex'),
                                            execute: (date, rowIndex) =>
                                                t('label.periodIndex', { index: rowIndex + 1 }),
                                        },
                                        {
                                            name: 'date',
                                            label: t('label.periodStart'),
                                            execute: date => (
                                                <span
                                                    style={{
                                                        fontWeight: 'bold',
                                                        fontSize: 18,
                                                    }}>
                                                    {format(date, 'dd.MM.yyyy')}{' '}
                                                    {days[date.getDay()]}
                                                </span>
                                            ),
                                        },
                                        {
                                            name: 'date',
                                            label: t('label.periodEnd'),
                                            execute: date => (
                                                <span
                                                    style={{
                                                        fontWeight: 'bold',
                                                        fontSize: 18,
                                                    }}>
                                                    {format(
                                                        addDays(
                                                            date,
                                                            Number(watchNight?.numberValue),
                                                        ),
                                                        'dd.MM.yyyy',
                                                    )}{' '}
                                                    {
                                                        days[
                                                            addDays(
                                                                date,
                                                                Number(watchNight?.numberValue),
                                                            ).getDay()
                                                        ]
                                                    }
                                                </span>
                                            ),
                                        },
                                    ]}
                                    data={periodDates}
                                    action={
                                        <>
                                            {Array.isArray(periodDates) &&
                                                periodDates.length > 0 && (
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        color={'warning'}
                                                        style={{ marginRight: 30 }}
                                                        onClick={onAskClearAllPeriodDates}>
                                                        {t('button.clearAll')}
                                                    </Button>
                                                )}

                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="secondary"
                                                style={{ marginRight: 10 }}
                                                onClick={() =>
                                                    setOpenPeriodDateWithDayEditDialog(true)
                                                }>
                                                {t('button.produceFast')}
                                            </Button>

                                            <Button
                                                variant="contained"
                                                size="small"
                                                color={'primary'}
                                                onClick={() => setOpenPeriodDateEditDialog(true)}>
                                                {t('button.edit')}
                                            </Button>
                                        </>
                                    }
                                    onDelete={(item, index) => {
                                        const list = [...periodDates];
                                        list.splice(index, 1);
                                        setPeriodDates(list);
                                    }}
                                />
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <Editor
                                title={t('label.shortDesc')}
                                value={shortDesc}
                                onChange={val => {
                                    setShortDesc(val);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Editor
                                title={t('label.description')}
                                value={description}
                                onChange={val => {
                                    setDescription(val);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Editor
                                title={t('label.includedPriceDescription')}
                                value={includedPriceDescription}
                                onChange={val => {
                                    setIncludedPriceDescription(val);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Editor
                                title={t('label.notIncludedPriceDescription')}
                                value={notIncludedPriceDescription}
                                onChange={val => {
                                    setNotIncludedPriceDescription(val);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Editor
                                title={t('label.hotelsToStay')}
                                value={hotelsToStay}
                                onChange={val => {
                                    setHotelsToStay(val);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Editor
                                title={t('label.extraNotes')}
                                value={generalTerms}
                                onChange={val => {
                                    setGeneralTerms(val);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <ActionPanel />
                </form>
            </Container>

            <ProgramDayEditDialog
                open={openProgramDayEditDialog}
                night={watchNight?.numberValue || 0}
                items={programDays}
                selectedIndex={selectedProgramDayIndex}
                onClose={() => setOpenProgramDayEditDialog(false)}
                onEditDone={(data, index) => {
                    const temp = [...programDays];
                    const newData = { ...data };
                    Object.keys(newData).forEach(key => {
                        if (key === 'id') {
                            delete newData[key];
                            newData['tourProgramDayId'] = 0;
                            // newData = {...newData, tourProgramDayId: 0};
                        }
                    });
                    if (index !== null) {
                        temp[index] = newData;
                    } else {
                        temp.push(newData);
                    }
                    setProgramDays(temp);
                    setExtraTours(data.extraTours);
                    setOpenProgramDayEditDialog(false);
                    setSelectedProgramDayIndex(null);
                }}
            />

            <TourPeriodDateEditDialog
                dates={periodDates}
                open={openPeriodDateEditDialog}
                onClose={() => {
                    setOpenPeriodDateEditDialog(false);
                }}
                onDoneCallback={dateSelectionDoneCallback}
            />

            <TourPeriodDateWithDayEditDialog
                periodDates={periodDates}
                open={openPeriodDateWithDayEditDialog}
                onClose={() => {
                    setOpenPeriodDateWithDayEditDialog(false);
                }}
                onDoneCallback={dateSelectionDoneCallback}
            />
        </Page>
    );
};

export default TourProgramEditPage;
