import React, { useEffect, useState } from 'react';
import Page from '../../layout/Page';
import { useHistory, useParams } from 'react-router-dom';
import postService from '../../service/PostService';
import Editor from '../../ui/Editor';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Panel from '../../ui/Panel';
import Input from '../../ui/Input';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import PageActions from '../../components/PageActions';
import { useForm } from 'react-hook-form';
import {
    ArticleType,
    DescType,
    MessageType,
    PostType,
    SubContentTypes,
    TagType,
} from '../../util/Enums';
import useMessage from '../../util/useMessage';
import SubmitButton from '../../ui/SubmitButton';
import RegionInput from '../../ui/RegionInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '../../ui/Select';
import PostSubContentEditor from './PostSubContentEditor';
import TagInput from '../../ui/TagInput';
import FaqEditPanel from '../../components/FaqEditPanel';
import CoverImageSelectionCard from '../../components/CoverImageSelectionCard';
import ImageGalleryCard from '../../components/ImageGalleryCard';
import PageInput from '../../ui/PageInput';

let MOUNT = true;

const PostEditPage = ({ title, ...props }) => {
    const param = useParams();
    const postId = param?.id;

    const { t } = useTranslation();
    const history = useHistory();
    const showMessage = useMessage();

    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [post, setPost] = useState(null);
    const [descriptions, setDescriptions] = useState({});

    const [subContentTypes, setSubContentTypes] = useState([]);
    const [subContents, setSubContents] = useState({});

    const [faq, setFaq] = useState([]);
    const [images, setImages] = useState([]);
    const [image, setImage] = useState(null);
    const [fileName, setFileName] = useState('');
    const [folderName] = useState('post');

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        control,
        formState: { errors },
    } = useForm();

    const postType = watch('postType');
    const articleType = watch('articleType');
    const watchFileName = watch('title');

    const aspect = 720 / 480;

    useEffect(() => {
        setFileName(watchFileName || '');
    }, [watchFileName]);

    useEffect(() => {
        if (postId) {
            (async () => {
                setPageLoading(true);
                try {
                    const { resultObject } = await postService.get(postId);
                    initPageValues(resultObject);
                } catch (e) {}
                setPageLoading(false);
            })();
        } else {
            setPageLoading(false);
        }
        return () => {
            MOUNT = false;
        };
    }, [postId]);

    const initPageValues = _post => {
        setPost(_post);
        const desc = {};
        Object.keys(DescType).forEach(item => (desc[item] = _post[item]));

        desc['summary'] = _post['summary'];
        desc['content'] = _post['content'];

        setDescriptions(desc);
        setValue('postType', _post.postType);
        setValue('articleType', _post.articleType);
        setFaq(_post.faq || []);

        setImage(_post.image);
        setImages(_post.images || []);
        setFileName(_post?.title || '');

        const contents = {};
        _post.subContents?.forEach(item => {
            contents[item.contentType] = item;
        });
        setSubContents(contents);
    };

    useEffect(() => {
        if (articleType) {
            const article_type = Object.values(ArticleType).find(item => item.id === articleType);
            const contentTypes = SubContentTypes.filter(item =>
                item.articleTypes.includes(article_type),
            );
            setSubContentTypes(contentTypes);
        } else {
            setSubContentTypes([]);
        }
    }, [articleType]);

    const onSubmit = async data => {
        if (loading) return false;

        try {
            setLoading(true);
            const contents = Object.keys(subContents)?.map(key => ({
                contentType: key,
                ...subContents[key],
            }));
            const obj = {
                ...data,
                ...descriptions,
                faq,
                subContents: contents,
                images,
                image,
                volume: data?.volume ? parseInt(data.volume) : 0,
            };

            const { resultObject } = await postService.saveOrUpdate(postId, obj);
            showMessage(t(postId ? 'message.updated' : 'message.created'));

            history.push(`/posts/edit/${resultObject.postId || resultObject.id}`);
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const ActionPanel = () => (
        <PageActions>
            <Button
                variant="contained"
                disabled={loading}
                startIcon={loading ? null : <KeyboardBackspaceIcon />}
                onClick={() => {
                    history.goBack();
                }}>
                {t('button.back')}
            </Button>

            <SubmitButton loading={loading} startIcon={<SaveIcon />}>
                {t('button.save')}
            </SubmitButton>
        </PageActions>
    );

    return (
        <Page title={title} loading={pageLoading}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Container maxWidth={'xl'}>
                    <ActionPanel />
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={3}>
                            <Panel title={t('title.postInfos')}>
                                <Select
                                    name="postType"
                                    rules={{ required: t('validation.required') }}
                                    error={errors?.postType}
                                    label={t('label.postType')}
                                    defaultValue={post?.postType}
                                    control={control}>
                                    {Object.values(PostType).map(item => {
                                        return (
                                            <MenuItem key={item.id} value={item.id}>
                                                {t(item.label)}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>

                                {postType === PostType.ARTICLE.id && (
                                    <>
                                        <Select
                                            name="articleType"
                                            rules={{ required: t('validation.required') }}
                                            error={errors?.articleType}
                                            label={t('label.articleType')}
                                            defaultValue={post?.articleType}
                                            control={control}>
                                            {Object.values(ArticleType).map(item => {
                                                return (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {t(item.label)}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>

                                        <TagInput
                                            name="icon"
                                            multiple={false}
                                            defaultValue={post?.icon}
                                            label={t('label.iconTag')}
                                            control={control}
                                        />
                                    </>
                                )}

                                {articleType !== ArticleType.ADDITIONAL.id && (
                                    <RegionInput
                                        name="region"
                                        multiple={false}
                                        control={control}
                                        error={errors?.region}
                                        defaultValue={post?.region}
                                        rules={{ required: t('validation.required') }}
                                    />
                                )}
                                {articleType === ArticleType.ADDITIONAL.id && (
                                    <PageInput
                                        name="urlPage"
                                        multiple={false}
                                        control={control}
                                        error={errors?.urlPage}
                                        defaultValue={post?.urlPage}
                                        rules={{ required: t('validation.required') }}
                                    />
                                )}
                                <Input
                                    name="title"
                                    defaultValue={post?.title}
                                    label={t('label.title')}
                                    error={errors?.title}
                                    {...register('title', {
                                        required: t('validation.required'),
                                    })}
                                />
                            </Panel>
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <Panel title="SEO">
                                <Input
                                    label={t('label.metaTitle')}
                                    defaultValue={post?.metaTitle}
                                    error={errors?.metaTitle}
                                    {...register('metaTitle', {
                                        minLength: {
                                            value: 3,
                                            message: t('validation.minLength', { val: 3 }),
                                        },
                                    })}
                                />
                                <Input
                                    label={t('label.metaDesc')}
                                    defaultValue={post?.metaDesc}
                                    multiline
                                    rows={3}
                                    error={errors?.metaDesc}
                                    {...register('metaDesc', {
                                        minLength: {
                                            value: 3,
                                            message: t('validation.minLength', { val: 3 }),
                                        },
                                    })}
                                />
                                <Input
                                    type="number"
                                    name="volume"
                                    defaultValue={post?.volume}
                                    label={t('label.searchVolume')}
                                    error={errors?.volume}
                                    {...register('volume')}
                                />
                            </Panel>
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <Panel title={t('label.tags')}>
                                <TagInput
                                    name="tags"
                                    control={control}
                                    error={errors?.tags}
                                    defaultValue={post?.tags || []}
                                    tagType={
                                        postType === PostType.ARTICLE.id
                                            ? TagType.ARTICLE.id
                                            : TagType.PLACE.id
                                    }
                                />

                                {postType === PostType.PLACE.id && (
                                    <RegionInput
                                        name="regions"
                                        control={control}
                                        error={errors?.regions}
                                        defaultValue={post?.regions || []}
                                    />
                                )}
                            </Panel>
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <CoverImageSelectionCard
                                title={t('title.image')}
                                subtitle={t('title.postImage')}
                                {...{ aspect, image, setImage, fileName, folderName }}
                            />
                        </Grid>

                        {!subContentTypes.length && (
                            <>
                                <Grid item xs={12}>
                                    <Editor
                                        title={t('label.content')}
                                        value={descriptions['content']}
                                        onChange={val => {
                                            setDescriptions({ ...descriptions, content: val });
                                        }}
                                    />
                                </Grid>
                            </>
                        )}

                        {subContentTypes.map(item => (
                            <Grid item xs={12}>
                                <PostSubContentEditor
                                    title={t(item.label)}
                                    value={subContents[item.id]}
                                    onChange={value => {
                                        setSubContents({
                                            ...subContents,
                                            [item.id]: value,
                                        });
                                    }}
                                />
                            </Grid>
                        ))}

                        <Grid item xs={12}>
                            <Editor
                                title={t('label.microDesc')}
                                value={descriptions[DescType.microDesc]}
                                onChange={val => {
                                    setDescriptions({ ...descriptions, [DescType.microDesc]: val });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Editor
                                title={t('label.shortDesc')}
                                value={descriptions[DescType.shortDesc]}
                                onChange={val => {
                                    setDescriptions({ ...descriptions, [DescType.shortDesc]: val });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Editor
                                title={t('label.summary')}
                                value={descriptions['summary']}
                                onChange={val => {
                                    setDescriptions({ ...descriptions, summary: val });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <ImageGalleryCard
                                {...{ aspect, images, setImages, fileName, folderName }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FaqEditPanel {...{ faq, setFaq }} />
                        </Grid>
                    </Grid>
                    <ActionPanel />
                </Container>
            </form>
        </Page>
    );
};

export default PostEditPage;
