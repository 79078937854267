import BaseService from './BaseService';

class ParameterService extends BaseService {
    constructor() {
        super('/common/parameters');
    }
}

const parameterService = new ParameterService();

export default parameterService;
