import { useEffect, useState } from 'react';
import Page from '../../../layout/Page';
import { useHistory, useParams } from 'react-router-dom';
import receiptService from '../../../service/ReceiptService';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Panel from '../../../ui/Panel';
import Input from '../../../ui/Input';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import PageActions from '../../../components/PageActions';
import Select from '../../../ui/Select';
import MenuItem from '@mui/material/MenuItem';
import { useForm } from 'react-hook-form';
import { MessageType, PaymentType, BankTypes, CurrencyTypes, PosWayTypes } from '../../../util/Enums';
import useMessage from '../../../util/useMessage';
import SubmitButton from '../../../ui/SubmitButton';
import CoverImageSelectionCard from '../../../components/CoverImageSelectionCard';
import DateInput from '../../../ui/DateInput';
import { formatDate } from '../../../util/DateUtil';
import CheckBox from '../../../ui/CheckBox';
import ContactInput from '../../../ui/ContactInput';
import { format } from 'date-fns';

let MOUNT = true;

const ReceiptEditPage = ({ title, ...props }) => {
    const param = useParams();
    const receiptId = param?.id;

    const { t } = useTranslation();
    const history = useHistory();
    const showMessage = useMessage();

    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [receipt, setReceipt] = useState(null);
    const [icon, setIcon] = useState(null);
    const [fileName, setFileName] = useState('');
    const [folderName] = useState('receipt');

    const {
        register,
        handleSubmit,
        watch,
        control,
        setValue,
        formState: { errors },
    } = useForm();

    const watchFileName = watch('title');
    const watchPaymentTypeId = watch('paymentTypeId');

    useEffect(() => {
        setFileName(watchFileName || '');
    }, [watchFileName]);

    useEffect(() => {
        if (receiptId) {
            (async () => {
                setPageLoading(true);
                try {
                    const { resultObject } = await receiptService.get(receiptId);
                    initPageValues(resultObject);
                } catch (e) {}
                setPageLoading(false);
            })();
        } else {
            setPageLoading(false);
        }

        return () => {
            MOUNT = false;
        };
    }, [receiptId]);

    const initPageValues = _receipt => {
        setReceipt(_receipt);
        if(_receipt.total) {
            setValue("total", _receipt.total)
        }
    };

    const onSubmit = async data => {
        if (loading) return false;
        try {
            setLoading(true);
            const obj = {
                ...data,
                contactId: data.contact.id,
                paymentTypeId: parseInt(data.paymentTypeId),
                posWayTypeId: parseInt(data.posWayTypeId),
                bankId: parseInt(data.bankId),
                currencyTypeId: parseInt(data.currencyTypeId),
                maturityDate: data.maturityDate && data.maturityDate !== '' ? format(new Date(data.maturityDate), 'yyyy-MM-dd') : null,
                receiptDate: data.receiptDate && data.receiptDate !== '' ? format(new Date(data.receiptDate), 'yyyy-MM-dd') : null,
                installmentNumber: data.installmentNumber && data.installmentNumber !== "" ? data.installmentNumber : null,
                showInstallmentNumber: data.showInstallmentNumber && data.showInstallmentNumber !== "" ? data.showInstallmentNumber : null,
                maturityDifference: data.maturityDifference && data.maturityDifference !== "" ? data.maturityDifference : null,
                provisionCode: data.provisionCode && data.provisionCode !== "" ? data.provisionCode : null
            };
            delete obj['contact'];
            const { resultObject } = await receiptService.saveOrUpdate(receiptId, obj);
            showMessage(t(receiptId ? 'message.updated' : 'message.created'));

            history.push(`/finance/receipts/edit/${resultObject.receiptId || resultObject.id}`);
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const ActionPanel = () => (
        <PageActions>
            <Button
                variant="contained"
                disabled={loading}
                startIcon={loading ? null : <KeyboardBackspaceIcon />}
                onClick={() => {
                    history.goBack();
                }}>
                {t('button.back')}
            </Button>

            {!(receipt?.isManuel !== undefined && receipt.isManuel === false) && <SubmitButton loading={loading} startIcon={<SaveIcon />}>
                {t('button.save')}
            </SubmitButton>}
        </PageActions>
    );

    return (
        <Page title={title} loading={pageLoading}>
            <Container maxWidth="md">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ActionPanel />
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Panel title={t('title.receiptInfos')}>
                                <Grid container spacing={1}>
                                    <Grid item xs={3}>
                                        <Input
                                            defaultValue={receipt?.receiptCode}
                                            disabled={true}
                                            label="Dekont No"
                                            error={errors?.receiptCode}
                                            {...register('receiptCode')}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Input
                                            defaultValue={receipt?.provisionCode}
                                            label="Provizyon No"
                                            error={errors?.provisionCode}
                                            {...register('provisionCode', {
                                                required: watchPaymentTypeId === PaymentType.Pos.id ? t('validation.required') : false,
                                            })}
                                            disabled={(receipt?.isManuel !== undefined && receipt.isManuel === false) ? true : false}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <DateInput
                                            name="receiptDate"
                                            label="Belge Tarihi"
                                            defaultValue={receipt?.receiptDate ? formatDate(new Date(receipt.receiptDate), 'yyyy.MM.dd') : null}
                                            error={errors?.receiptDate}
                                            rules={{ required: t('validation.required') }}
                                            control={control}
                                            disabled={(receipt?.isManuel !== undefined && receipt.isManuel === false) ? true : false}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <DateInput
                                            name="maturityDate"
                                            label="Vade Tarihi"
                                            defaultValue={receipt?.maturityDate ? formatDate(new Date(receipt.maturityDate), 'yyyy.MM.dd') : null}
                                            error={errors?.maturityDate}
                                            rules={{ required: t('validation.required') }}
                                            control={control}
                                            disabled={(receipt?.isManuel !== undefined && receipt.isManuel === false) ? true : false}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Select
                                            name="paymentTypeId"
                                            rules={{ required: t('validation.required') }}
                                            error={errors?.paymentTypeId}
                                            label={t('label.paymentType')}
                                            defaultValue={receipt?.paymentTypeId}
                                            control={control}
                                            disabled={(receipt?.isManuel !== undefined && receipt.isManuel === false) ? true : false}
                                        >
                                            {Object.values(PaymentType).map(item => {
                                                return (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {t(item.label)}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Select
                                            name="posWayTypeId"
                                            rules={{ required: t('validation.required') }}
                                            error={errors?.posWayTypeId}
                                            label="Ödeme Yolu"
                                            defaultValue={receipt?.posWayTypeId}
                                            control={control}
                                            disabled={(receipt?.isManuel !== undefined && receipt.isManuel === false) ? true : false}
                                        >
                                            {Object.values(PosWayTypes).map(item => {
                                                return (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {t(item.label)}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ContactInput
                                            name="contact"
                                            label={t('label.contact')}
                                            defaultValue={receipt?.contact}
                                            error={errors?.contact}
                                            rules={{ required: t('validation.required') }}
                                            control={control}
                                            // disabled={(receipt?.isManuel !== undefined && receipt.isManuel === false) ? true : false}
                                            disabled={receiptId ? true : false}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            name="cardUserName"
                                            label="Kart Sahibi"
                                            defaultValue={receipt?.cardUserName}
                                            error={errors?.cardUserName}
                                            {...register('cardUserName', {
                                                required: watchPaymentTypeId === PaymentType.Pos.id ? t('validation.required') : false,
                                            })}
                                            disabled={(receipt?.isManuel !== undefined && receipt.isManuel === false) ? true : false}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Input
                                            name="cardNumber"
                                            label={t('label.cardNumber')}
                                            defaultValue={receipt?.cardNumber}
                                            error={errors?.cardNumber}
                                            {...register('cardNumber', {
                                                required: watchPaymentTypeId === PaymentType.Pos.id ? t('validation.required') : false,
                                            })}
                                            disabled={(receipt?.isManuel !== undefined && receipt.isManuel === false) ? true : false}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Select
                                            name="bankId"
                                            rules={{ required: watchPaymentTypeId === PaymentType.Cash.id ? false : t('validation.required') }}
                                            error={errors?.bankId}
                                            label={t('label.bankType')}
                                            defaultValue={receipt?.bankId}
                                            control={control}
                                            disabled={(receipt?.isManuel !== undefined && receipt.isManuel === false) ? true : false}
                                        >
                                            {Object.values(BankTypes).map(item => {
                                                return (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {t(item.label)}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Input
                                            name="installmentNumber"
                                            label={t('label.installment')}
                                            defaultValue={receipt?.installmentNumber}
                                            error={errors?.installmentNumber}
                                            {...register('installmentNumber')}
                                            disabled={(receipt?.isManuel !== undefined && receipt.isManuel === false) ? true : false}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Input
                                            name="showInstallmentNumber"
                                            label="Gösterilen Taksit"
                                            defaultValue={receipt?.showInstallmentNumber}
                                            error={errors?.showInstallmentNumber}
                                            {...register('showInstallmentNumber')}
                                            disabled={(receipt?.isManuel !== undefined && receipt.isManuel === false) ? true : false}
                                        />
                                    </Grid>
                                    <Grid item xs={2} sx={{paddingTop:"15px !important"}}>
                                        <CheckBox
                                            defaultChecked={receipt?.is3dSecure || false}
                                            label={t('label.isThreeD')}
                                            {...register('is3dSecure')}
                                            disabled={(receipt?.isManuel !== undefined && receipt.isManuel === false) ? true : false}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Input
                                            defaultValue={receipt?.usedTotal}
                                            label="Kullanılan Tutar"
                                            error={errors?.usedTotal}
                                            disabled
                                            {...register('usedTotal')}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Input
                                            defaultValue={receipt?.cancelTotal}
                                            label="İptal Tutarı"
                                            error={errors?.cancelTotal}
                                            disabled
                                            {...register('cancelTotal')}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Input
                                            defaultValue={receipt?.maturityDifference}
                                            label="Vade Farkı"
                                            error={errors?.maturityDifference}
                                            {...register('maturityDifference')}
                                            disabled={(receipt?.isManuel !== undefined && receipt.isManuel === false) ? true : false}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Input
                                            defaultValue={receipt?.total}
                                            label={t('label.amount')}
                                            error={errors?.total}
                                            {...register('total', {
                                                required: t('validation.required'),
                                            })}
                                            disabled={(receipt?.total !== undefined && receipt?.usedTotal !== undefined && receipt?.total === receipt?.usedTotal) || (receipt?.isManuel !== undefined && receipt.isManuel === false) ? true : false}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Select
                                            name="currencyTypeId"
                                            rules={{ required: t('validation.required') }}
                                            error={errors?.currencyTypeId}
                                            label={t('label.currencyType')}
                                            defaultValue={receipt?.currencyTypeId || 1}
                                            control={control}
                                            // disabled={(receipt?.isManuel !== undefined && receipt.isManuel === false) ? true : false}
                                            disabled={receipt?.currencyTypeId ? true : false}
                                        >
                                            {Object.values(CurrencyTypes).map(item => {
                                                return (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {t(item.label)}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </Grid>
                                </Grid>
                            </Panel>
                        </Grid>
                    </Grid>
                    <ActionPanel />
                </form>
            </Container>
        </Page>
    );
};

export default ReceiptEditPage;
