import React, { useEffect, useState } from 'react';
import Page from '../../layout/Page';
import { useHistory, useParams } from 'react-router-dom';
import tourCatalogService from '../../service/TourCatalogService';
import Editor from '../../ui/Editor';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Panel from '../../ui/Panel';
import Input from '../../ui/Input';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import PageActions from '../../components/PageActions';
import { useForm } from 'react-hook-form';
import { DescType, findEnumById, GenerateContentType, MessageType } from '../../util/Enums';
import useMessage from '../../util/useMessage';
import SubmitButton from '../../ui/SubmitButton';
import RegionInput from '../../ui/RegionInput';
import { ListItemText, MenuItem } from '@mui/material';
import Select from '../../ui/Select';
import Checkbox from '@mui/material/Checkbox';
import FaqEditPanel from '../../components/FaqEditPanel';
import ImageGalleryCard from '../../components/ImageGalleryCard';
import CoverImageSelectionCard from '../../components/CoverImageSelectionCard';
import useRequest from '../../util/useRequest';
import Link from '@mui/material/Link';

let MOUNT = true;

const TourCatalogEditPage = ({ title, ...props }) => {
    const param = useParams();
    const tourCatalogId = param?.id;

    const { t } = useTranslation();
    const history = useHistory();
    const showMessage = useMessage();

    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [tourCatalog, setTourCatalog] = useState(null);
    const [descriptions, setDescriptions] = useState({});
    const [images, setImages] = useState(null);
    const [fileName, setFileName] = useState('');
    const [folderName] = useState('tourcatalog');
    const [url, setUrl] = useState('');
    const [faq, setFaq] = useState([]);
    const [content, setContent] = useState('');
    const [regionIds, setRegionIds] = useState([]);
    const [tourPrograms, setTourPrograms] = useState([]);
    const [accommodations, setAccommodations] = useState([]);

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        formState: { errors },
    } = useForm();

    const watchContentRegions = watch('contentRegions', []);
    const watchFileName = watch('title', '');

    const aspect = 720 / 480;

    useEffect(() => {
        setFileName(watchFileName || '');
    }, [watchFileName]);

    useEffect(() => {
        if (tourCatalogId) {
            (async () => {
                setPageLoading(true);
                try {
                    const { resultObject } = await tourCatalogService.get(tourCatalogId);
                    initPageValues(resultObject);
                } catch (e) {}
                setPageLoading(false);
            })();
        } else {
            setPageLoading(false);
        }
        return () => {
            MOUNT = false;
        };
    }, []);

    useEffect(() => {
        if (watchContentRegions?.length > 0) {
            const selectRegions = [];
            watchContentRegions.forEach(reg => {
                selectRegions.push(reg.id);
            });
            setRegionIds(selectRegions);
        } else {
            setRegionIds([]);
        }
    }, [watchContentRegions]);

    const initPageValues = _tourCatalog => {
        setTourCatalog(_tourCatalog);
        setFaq(_tourCatalog.faq || []);
        setValue('contentRegions', _tourCatalog.contentRegions || []);

        setImages(_tourCatalog?.images || []);
        if (_tourCatalog?.images?.length > 0) {
            const convertImages = [];
            _tourCatalog?.images.forEach(item => {
                if (item.imageRegionName) {
                    convertImages.push(item);
                } else {
                    convertImages.push({ ...item, imageRegionName: 'Katalog' });
                }
            });
            setImages(convertImages || []);
        } else {
            setImages([]);
        }
        setFileName(_tourCatalog?.title || '');
        setUrl(_tourCatalog?.url || '');
        setContent(_tourCatalog?.content || '');
        const desc = {};
        Object.keys(DescType).forEach(item => {
            if (_tourCatalog[item]) {
                desc[item] = _tourCatalog[item];
            }
        });
        setDescriptions(desc);
        setTourPrograms(_tourCatalog?.tourPrograms || []);
        setAccommodations(_tourCatalog?.accommodations || []);
    };

    const generateContentRequest = useRequest({
        method: async params => await tourCatalogService.generateContent(params),
        onSuccess: data => {
            setContent(data);
        },
        onError: ({ code, message }) => {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        },
    });

    const onSubmit = async data => {
        if (loading) return false;

        try {
            setLoading(true);

            const obj = {
                ...data,
                ...descriptions,
                content: content,
                images,
                faq,
                volume: data?.volume ? parseInt(data.volume) : 0,
                url,
            };
            const { resultObject } = await tourCatalogService.saveOrUpdate(tourCatalogId, obj);
            showMessage(t(tourCatalogId ? 'message.updated' : 'message.created'));

            history.push(`/tours/catalogs/edit/${resultObject.tourCatalogId || resultObject.id}`);
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const ActionPanel = () => (
        <PageActions>
            <Button
                variant="contained"
                disabled={loading}
                startIcon={loading ? null : <KeyboardBackspaceIcon />}
                onClick={() => {
                    history.goBack();
                }}>
                {t('button.back')}
            </Button>
            <SubmitButton loading={loading} startIcon={<SaveIcon />}>
                {t('button.save')}
            </SubmitButton>
        </PageActions>
    );

    return (
        <Page title={title} loading={pageLoading}>
            <Container maxWidth="xl">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ActionPanel />
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={3}>
                            <Panel title={t('title.tourCatalogInfos')}>
                                <RegionInput
                                    name="region"
                                    multiple={false}
                                    control={control}
                                    defaultValue={tourCatalog?.region}
                                    rules={{ required: t('validation.required') }}
                                    error={errors?.region}
                                />
                                <RegionInput
                                    name="linkRegion"
                                    label={t('label.linkLocation')}
                                    multiple={false}
                                    control={control}
                                    defaultValue={tourCatalog?.linkRegion}
                                    rules={{ required: t('validation.required') }}
                                    error={errors?.linkRegion}
                                />

                                <Input
                                    name="title"
                                    defaultValue={tourCatalog?.title}
                                    label={t('label.title')}
                                    error={errors?.title}
                                    {...register('title', {
                                        required: t('validation.required'),
                                    })}
                                />

                                <Input
                                    label={t('label.subtitle')}
                                    defaultValue={tourCatalog?.subtitle}
                                    error={errors?.subtitle}
                                    {...register('subtitle', {
                                        required: t('validation.required'),
                                    })}
                                />
                                <Input
                                    defaultValue={tourCatalog?.operationTitle}
                                    label={t('label.operationTitle')}
                                    error={errors?.operationTitle}
                                    {...register('operationTitle')}
                                    disabled={true}
                                    readOnly={true}
                                />
                            </Panel>
                        </Grid>

                        <Grid item xs={12} md={6} lg={3}>
                            <Panel title="SEO">
                                <Input
                                    label={t('label.metaTitle')}
                                    defaultValue={tourCatalog?.metaTitle}
                                    error={errors?.metaTitle}
                                    {...register('metaTitle')}
                                />
                                <Input
                                    label={t('label.metaDesc')}
                                    defaultValue={tourCatalog?.metaDesc}
                                    multiline
                                    rows={3}
                                    error={errors?.metaDesc}
                                    {...register('metaDesc')}
                                />
                                <Input
                                    type="number"
                                    name="volume"
                                    defaultValue={tourCatalog?.volume}
                                    label={t('label.searchVolume')}
                                    error={errors?.volume}
                                    {...register('volume')}
                                />
                            </Panel>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                            <Panel title={t('title.contentRegions')}>
                                <RegionInput
                                    name="contentRegions"
                                    multiple
                                    control={control}
                                    defaultValue={tourCatalog?.contentRegions}
                                    error={errors?.contentRegions}
                                />
                            </Panel>
                        </Grid>
                        {tourPrograms.length > 0 && (
                            <Grid item xs={12} md={12}>
                                <Panel title={t('label.tourPrograms')}>
                                    <ul>
                                        {tourPrograms.map((item, index) => (
                                            <li key={index}>
                                                <Link
                                                    href={`/tours/programs/edit/${item.id}`}
                                                    target="_blank">
                                                    {item.title}
                                                </Link>
                                                {item.destinations &&
                                                    ' - G(' + item.destinations + ') '}
                                                {item.accommodations &&
                                                    ' - K(' + item.accommodations + ') '}
                                            </li>
                                        ))}
                                    </ul>
                                </Panel>
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <Editor
                                title={t('label.highlights')}
                                value={descriptions[DescType.description]}
                                onChange={val => {
                                    setDescriptions({ ...descriptions, description: val });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Editor
                                title={t('label.tourAbout')}
                                value={content}
                                onChange={val => {
                                    setContent(val);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <ImageGalleryCard
                                {...{
                                    aspect,
                                    images,
                                    setImages,
                                    fileName,
                                    folderName,
                                    isType: true,
                                    regionIds,
                                    accommodations,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FaqEditPanel {...{ faq, setFaq }} />
                        </Grid>
                    </Grid>
                    <ActionPanel />
                </form>
            </Container>
        </Page>
    );
};

export default TourCatalogEditPage;
