import Page from '../../layout/Page';

import { useTranslation } from 'react-i18next';
import usePagination from '../../util/usePagination';
import roleService from '../../service/RoleService';
import { DataTable } from '../../components/DataTable';
import Container from '@mui/material/Container';
import ListPageActions from '../../components/ListPageActions';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import RoleDeleteDialog from './RoleDeleteDialog';
import { getDeletedColumn } from '../../components/YesNoTypeOptions';

const RoleListPage = ({ title, ...props }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [selected, setSelected] = useState(null);
    const [openRoleDeleteDialog, setOpenRoleDeleteDialog] = useState(false);

    const columns = [
        { name: 'id', label: 'ID', width: 75, searchable: true, type: Number },
        { name: 'name', label: t('label.name'), searchable: true },
        { ...getDeletedColumn(t) },
    ];

    const roles = usePagination({
        service: roleService,
    });

    const ActionButtons = () => (
        <ListPageActions>
            <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={() => {
                    history.push(`/auths/roles/create`);
                }}>
                {t('button.addNew')}
            </Button>
        </ListPageActions>
    );

    return (
        <Page title={title}>
            <Container maxWidth="xl">
                <ActionButtons />
                <DataTable
                    title={t('label.roleList')}
                    columns={columns}
                    {...roles}
                    onEdit={item => {
                        history.push(`/auths/roles/edit/${item.id}`);
                    }}
                    onDelete={item => {
                        setSelected(item);
                        setOpenRoleDeleteDialog(true);
                    }}
                />
                <ActionButtons />
            </Container>

            <RoleDeleteDialog
                role={selected}
                open={openRoleDeleteDialog}
                onClose={() => {
                    setOpenRoleDeleteDialog(false);
                    setSelected(null);
                }}
                onDoneCallback={roles.search}
            />
        </Page>
    );
};

export default RoleListPage;
