import React from 'react';
import { format } from 'date-fns';
import {
    Button,
    Card,
    CardHeader,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { useTranslation } from 'react-i18next';
import SearchCell from './datatable/SearchCell';
import LabelCell from './datatable/LabelCell';
import NoResultFound from './datatable/NoResultFound';
import TableLoading from './datatable/TableLoading';
import makeStyles from '@mui/styles/makeStyles';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#f6f7f9',
        color: '#000000',
        fontWeight: 'bold',
    },
}))(TableCell);

const ColorTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#eeefff',
        color: '#26415c',
        fontWeight: 'bold',
    },
}))(TableCell);

const useStyles = makeStyles(theme => ({
    card: {
        height: '100%',
    },
    title: {
        fontSize: '1.2rem',
    },
    wrap: {
        // display: "flex"
    },
    filter: {
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        paddingLeft: '20px',
        justifyContent: 'end',
    },
    item: {
        margin: '0',
        fontSize: '12px',
        position: 'relative',
        backgroundColor: '#ebebeb',
        padding: '2px 18px 2px 6px',
        borderRadius: '2px',
        marginLeft: '4px',
        marginTop: '4px',
    },
    value: {
        paddingLeft: '1px',
    },
    close: {
        position: 'absolute',
        right: '2px',
        width: '12px !important',
        height: '12px !important',
        color: 'red',
        top: '50%',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
    },
    disabled: {
        opacity: '0.5',
    },
}));

const ForecastTable = React.memo(
    ({
        title,
        data,
        loading,
        columns,
        count,
        page,
        setPage,
        orderBy,
        setOrderBy,
        max,
        setMax,
        searchData,
        setSearchData,
        onEdit,
        onDelete,
        onRollback,
        onManage,
        rowOnClick,
        action,
        selected,
        setSelected,
        selectAll,
        cardStyle,
        useRowIndexForKey,
        elevation = 1,
        link,
        disabledYachts,
        activities,
        setActivities,
    }) => {
        const { t } = useTranslation();
        const classes = useStyles();
        const isSearchable = columns.some(item => item.searchable);

        const resolve = (item, column, rowIndex, colIndex) => {
            if (column.execute) {
                return (
                    <TableCell key={colIndex}>
                        {column.execute(item[column.name], rowIndex, colIndex)}
                    </TableCell>
                );
            }
            if (column.name === 'operationTitle') {
                return (
                    <TableCell key={colIndex}>
                        {item.operationTitle && (
                            <a href={`/tours/prices/edit/${item.tourPriceId || 0}`} target="_blank">
                                {item.operationTitle}
                            </a>
                        )}
                    </TableCell>
                );
            }
            if (column.name === 'title') {
                return (
                    <TableCell key={colIndex}>
                        {item.title && (
                            <Tooltip title={<p>{item.title}</p>}>
                                <div
                                    style={{
                                        whiteSpace: 'nowrap',
                                        maxWidth: '300px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}>
                                    <a
                                        href={`/tours/programs/edit/${item.tourProgramId || 0}`}
                                        target="_blank">
                                        {item.title}
                                    </a>
                                </div>
                            </Tooltip>
                        )}
                    </TableCell>
                );
            }
            if (column.name === 'visaTypes') {
                return (
                    <TableCell key={colIndex}>
                        {item.visa === false ? (
                            <span>Vizesiz</span>
                        ) : item.visaTypes?.length > 0 ? (
                            <Tooltip
                                title={
                                    <React.Fragment>
                                        {item.visaTypes?.map((visa, index) => (
                                            <p key={index}>{visa.name}</p>
                                        ))}
                                    </React.Fragment>
                                }>
                                <span>Vizeli</span>
                            </Tooltip>
                        ) : (
                            <span>Vizeli</span>
                        )}
                    </TableCell>
                );
            }
            if (column.name === 'price') {
                const tourProgramUrl =
                    item.tourProgramUrl && item.tourPricePeriods.length > 0
                        ? `${import.meta.env.VITE_B2C_URL}${item.tourProgramUrl}${item.tourProgramUrl.includes('?') ? '&' : '?'}${item.tourProgramId ? '&tourProgramId=' + item.tourProgramId : ''}${item.hotelId ? '&hotelId=' + item.hotelId : ''}${item.tourPricePeriods[0].tourProgramPeriodId ? '&tourProgramPeriodId=' + item.tourPricePeriods[0].tourProgramPeriodId : ''}`
                        : '#';
                return (
                    <TableCell key={colIndex}>
                        {item.tourPricePeriods.length > 0 ? (
                            <Tooltip
                                title={
                                    <React.Fragment>
                                        {item.tourPricePeriods?.map((period, index) => (
                                            <p
                                                key={index}
                                                style={{
                                                    color: `${period.isAskSell ? 'orange' : !period.isStop ? '#b8ffc9' : '#ffb8b8'}`,
                                                }}>
                                                {format(new Date(period.startDate), 'dd.MM.yyyy')} -{' '}
                                                {format(new Date(period.endDate), 'dd.MM.yyyy')} -{' '}
                                                {period.discountSalePrice} TL
                                            </p>
                                        ))}
                                    </React.Fragment>
                                }>
                                <a href={tourProgramUrl} target="_blank">
                                    {item.price || '-'} TL
                                </a>
                            </Tooltip>
                        ) : (
                            <a href={tourProgramUrl} target="_blank">
                                {item.price || '-'} TL
                            </a>
                        )}
                    </TableCell>
                );
            }
            if (Array.isArray(item[column.name])) {
                return (
                    <TableCell key={colIndex}>
                        {item[column.name].map((item, index) => item.name).join(',')}
                    </TableCell>
                );
            }
            return <TableCell key={colIndex}>{item[column.name]}</TableCell>;
        };

        const handleOrderBy = name => {
            setOrderBy({
                name: name,
                orderType: orderBy?.name === name && orderBy?.orderType === 'desc' ? 'asc' : 'desc',
            });
        };

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = event => {
            setMax(parseInt(event.target.value, 10));
            setPage(0);
        };

        const isSelected = item => {
            return selected?.some(
                some => some.tourProgramId === item.tourProgramId && some.hotelId === item.hotelId,
            );
        };

        const onSelect = (item, e) => {
            if (e.target.checked) {
                setSelected && setSelected([...selected, item]);
            } else {
                const itemIndex = selected?.findIndex(
                    i => i.tourProgramId === item.tourProgramId && i.hotelId === item.hotelId,
                );
                const items = [];
                selected?.forEach((i, index) => {
                    if (itemIndex !== index) {
                        items.push(i);
                    }
                });
                setSelected && setSelected(items);
            }
        };

        const EmptyHeadCell = () => (
            <TableCell
                style={{
                    backgroundColor: '#f9f6f3',
                    margin: 0,
                }}
            />
        );

        return (
            <Card style={cardStyle} elevation={elevation} className="forecast-table">
                <CardHeader
                    title={title}
                    action={
                        <div>
                            <div style={{ paddingRight: '10px' }}>{action}</div>
                            {Object.keys(activities).length > 0 && (
                                <div className={classes.filter}>
                                    {Object.entries(activities)
                                        .filter(([_, filterValue]) => filterValue !== null)
                                        .map(([filterKey, filterValue], i) => {
                                            if (
                                                filterValue !== null && filterValue !== "" &&
                                                filterKey !== 'personCounts'
                                            ) {
                                                return (
                                                    <p
                                                        className={classes.item}
                                                        key={i}
                                                        style={{
                                                            opacity: loading ? '0.5 ' : '1',
                                                            pointerEvents: loading
                                                                ? 'none'
                                                                : 'unset',
                                                        }}>
                                                        {t(`forecast.${filterKey}`)}:{' '}
                                                        <span className={classes.value}>
                                                            {Array.isArray(filterValue)
                                                                ? filterValue
                                                                      ?.map(item => item)
                                                                      .join(', ')
                                                                : filterValue}
                                                        </span>
                                                        <DisabledByDefaultIcon
                                                            className={classes.close}
                                                            onClick={() => {
                                                                if (!loading) {
                                                                    const searchFilter = searchData;
                                                                    searchFilter[filterKey] = null;
                                                                    setActivities({
                                                                        ...searchFilter,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </p>
                                                );
                                            }
                                        })}
                                </div>
                            )}
                        </div>
                    }
                />
                <TableContainer>
                    <Table size="small" className="data-table data-table-no-wrap">
                        <TableHead>
                            {isSearchable && (
                                <TableRow>
                                    {setSelected && <EmptyHeadCell />}
                                    {columns.map((column, index) => {
                                        const SearchCellComponent = column.SearchCellComponent
                                            ? column.SearchCellComponent
                                            : SearchCell;
                                        return column.searchable ? (
                                            <SearchCellComponent
                                                key={index}
                                                placeholder={t('label.search')}
                                                column={column}
                                                defaultValue={
                                                    searchData[column.searchField] !== undefined
                                                        ? searchData[column.searchField]
                                                        : ''
                                                }
                                                searchData={searchData.waterSports || null}
                                                data={data}
                                                onSearch={data => {
                                                    if (column.waterSport) {
                                                        setSearchData({
                                                            ...searchData,
                                                            waterSports: data,
                                                        });
                                                    } else {
                                                        setSearchData({
                                                            ...searchData,
                                                            ...data,
                                                        });
                                                    }
                                                }}
                                            />
                                        ) : (
                                            <EmptyHeadCell />
                                        );
                                    })}

                                    {onEdit && <EmptyHeadCell />}

                                    {onDelete && <EmptyHeadCell />}

                                    {onManage && <EmptyHeadCell />}
                                </TableRow>
                            )}

                            <TableRow>
                                {setSelected && (
                                    <StyledTableCell
                                        padding="checkbox"
                                        width={10}
                                        style={{ textAlign: 'center' }}>
                                        {selectAll && <Checkbox style={{ padding: 0 }} />}
                                    </StyledTableCell>
                                )}
                                {columns.map((column, index) => (
                                    <LabelCell
                                        key={index}
                                        column={column}
                                        orderBy={orderBy}
                                        hasOrderBy={setOrderBy}
                                        handleOrderBy={handleOrderBy}
                                        sortable={
                                            column.sortable !== undefined ? column.sortable : true
                                        }
                                        colored={column.type === 0 ? true : false}
                                    />
                                ))}
                                {onEdit && <StyledTableCell width={50} />}
                                {onDelete && <StyledTableCell width={50} />}
                                {onManage && <StyledTableCell width={50} />}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {loading && <TableLoading colSpan={columns.length} />}

                            {disabledYachts?.map((item, rowIndex) => (
                                <TableRow
                                    hover
                                    key={useRowIndexForKey ? rowIndex : item.id}
                                    sx={{ opacity: '0.5' }}>
                                    {setSelected && (
                                        <TableCell
                                            padding="checkbox"
                                            style={{ textAlign: 'center' }}></TableCell>
                                    )}

                                    {columns.map((column, colIndex) => {
                                        return resolve(item, column, rowIndex, colIndex);
                                    })}

                                    {onEdit && !link && (
                                        <TableCell style={{ padding: 0, textAlign: 'center' }}>
                                            <Button
                                                color="secondary"
                                                size="small"
                                                style={{ padding: 0 }}
                                                onClick={() => {
                                                    onEdit(item, rowIndex);
                                                }}>
                                                {t('button.edit')}
                                            </Button>
                                        </TableCell>
                                    )}

                                    {onEdit && link && (
                                        <TableCell style={{ padding: 0, textAlign: 'center' }}>
                                            <a
                                                href={`${link}${item.id}`}
                                                className="table-cell-link"
                                                color="secondary"
                                                size="small"
                                                style={{ padding: 0 }}>
                                                {t('button.edit')}
                                            </a>
                                        </TableCell>
                                    )}

                                    {onDelete && (
                                        <TableCell style={{ padding: 0, textAlign: 'center' }}>
                                            {!item?.isDeleted && (
                                                <Button
                                                    color="secondary"
                                                    size="small"
                                                    style={{ padding: 0 }}
                                                    onClick={() => {
                                                        onDelete(item, rowIndex);
                                                    }}>
                                                    {t('button.delete')}
                                                </Button>
                                            )}

                                            {item.isDeleted && onRollback && (
                                                <Button
                                                    color="primary"
                                                    size="small"
                                                    style={{ padding: 0 }}
                                                    onClick={() => {
                                                        onRollback(item, rowIndex);
                                                    }}>
                                                    {t('button.rollback')}
                                                </Button>
                                            )}
                                        </TableCell>
                                    )}

                                    {onManage && (
                                        <TableCell style={{ padding: 0, textAlign: 'center' }}>
                                            <Button
                                                color="secondary"
                                                size="small"
                                                style={{ padding: 0 }}
                                                onClick={() => {
                                                    onManage(item, rowIndex);
                                                }}>
                                                {t('button.manage')}
                                            </Button>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}

                            {selected?.map((item, rowIndex) => (
                                <TableRow
                                    hover
                                    key={useRowIndexForKey ? rowIndex : item.id}
                                    selected={isSelected(item)}
                                    onClick={() => {
                                        rowOnClick && rowOnClick(item);
                                    }}>
                                    {setSelected && (
                                        <TableCell
                                            padding="checkbox"
                                            style={{ textAlign: 'center' }}>
                                            <Checkbox
                                                style={{ padding: 0 }}
                                                checked={isSelected(item)}
                                                onChange={e => {
                                                    onSelect(item, e);
                                                }}
                                            />
                                        </TableCell>
                                    )}

                                    {columns.map((column, colIndex) => {
                                        return resolve(item, column, rowIndex, colIndex);
                                    })}

                                    {onEdit && !link && (
                                        <TableCell style={{ padding: 0, textAlign: 'center' }}>
                                            <Button
                                                color="secondary"
                                                size="small"
                                                style={{ padding: 0 }}
                                                onClick={() => {
                                                    onEdit(item, rowIndex);
                                                }}>
                                                {t('button.edit')}
                                            </Button>
                                        </TableCell>
                                    )}

                                    {onEdit && link && (
                                        <TableCell style={{ padding: 0, textAlign: 'center' }}>
                                            <a
                                                href={`${link}${item.id}`}
                                                className="table-cell-link"
                                                color="secondary"
                                                size="small"
                                                style={{ padding: 0 }}>
                                                {t('button.edit')}
                                            </a>
                                        </TableCell>
                                    )}

                                    {onDelete && (
                                        <TableCell style={{ padding: 0, textAlign: 'center' }}>
                                            {!item?.isDeleted && (
                                                <Button
                                                    color="secondary"
                                                    size="small"
                                                    style={{ padding: 0 }}
                                                    onClick={() => {
                                                        onDelete(item, rowIndex);
                                                    }}>
                                                    {t('button.delete')}
                                                </Button>
                                            )}

                                            {item.isDeleted && onRollback && (
                                                <Button
                                                    color="primary"
                                                    size="small"
                                                    style={{ padding: 0 }}
                                                    onClick={() => {
                                                        onRollback(item, rowIndex);
                                                    }}>
                                                    {t('button.rollback')}
                                                </Button>
                                            )}
                                        </TableCell>
                                    )}

                                    {onManage && (
                                        <TableCell style={{ padding: 0, textAlign: 'center' }}>
                                            <Button
                                                color="secondary"
                                                size="small"
                                                style={{ padding: 0 }}
                                                onClick={() => {
                                                    onManage(item, rowIndex);
                                                }}>
                                                {t('button.manage')}
                                            </Button>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}

                            {data
                                ?.filter(
                                    item =>
                                        !selected.some(
                                            sel =>
                                                sel.tourProgramId === item.tourProgramId &&
                                                sel.hotelId === item.hotelId,
                                        ) &&
                                        !disabledYachts.some(
                                            sel =>
                                                sel.tourProgramId === item.tourProgramId &&
                                                sel.hotelId === item.hotelId,
                                        ),
                                )
                                .map((item, rowIndex) => (
                                    <TableRow
                                        hover
                                        key={useRowIndexForKey ? rowIndex : item.id}
                                        selected={isSelected(item)}
                                        onClick={() => {
                                            rowOnClick && rowOnClick(item);
                                        }}>
                                        {setSelected && (
                                            <TableCell
                                                padding="checkbox"
                                                style={{ textAlign: 'center' }}>
                                                <Checkbox
                                                    style={{ padding: 0 }}
                                                    checked={
                                                        item.isRequired ? true : isSelected(item)
                                                    }
                                                    disabled={item.isRequired ? true : false}
                                                    readOnly={item.isRequired ? true : false}
                                                    onChange={e => {
                                                        onSelect(item, e);
                                                    }}
                                                />
                                            </TableCell>
                                        )}

                                        {columns.map((column, colIndex) => {
                                            return resolve(item, column, rowIndex, colIndex);
                                        })}

                                        {onEdit && !link && (
                                            <TableCell style={{ padding: 0, textAlign: 'center' }}>
                                                <Button
                                                    color="secondary"
                                                    size="small"
                                                    style={{ padding: 0 }}
                                                    onClick={() => {
                                                        onEdit(item, rowIndex);
                                                    }}>
                                                    {t('button.edit')}
                                                </Button>
                                            </TableCell>
                                        )}

                                        {onEdit && link && (
                                            <TableCell style={{ padding: 0, textAlign: 'center' }}>
                                                <a
                                                    href={`${link}${item.id}`}
                                                    className="table-cell-link"
                                                    color="secondary"
                                                    size="small"
                                                    style={{ padding: 0 }}>
                                                    {t('button.edit')}
                                                </a>
                                            </TableCell>
                                        )}

                                        {onDelete && (
                                            <TableCell style={{ padding: 0, textAlign: 'center' }}>
                                                {!item?.isDeleted && (
                                                    <Button
                                                        color="secondary"
                                                        size="small"
                                                        style={{ padding: 0 }}
                                                        onClick={() => {
                                                            onDelete(item, rowIndex);
                                                        }}>
                                                        {t('button.delete')}
                                                    </Button>
                                                )}

                                                {item.isDeleted && onRollback && (
                                                    <Button
                                                        color="primary"
                                                        size="small"
                                                        style={{ padding: 0 }}
                                                        onClick={() => {
                                                            onRollback(item, rowIndex);
                                                        }}>
                                                        {t('button.rollback')}
                                                    </Button>
                                                )}
                                            </TableCell>
                                        )}

                                        {onManage && (
                                            <TableCell style={{ padding: 0, textAlign: 'center' }}>
                                                <Button
                                                    color="secondary"
                                                    size="small"
                                                    style={{ padding: 0 }}
                                                    onClick={() => {
                                                        onManage(item, rowIndex);
                                                    }}>
                                                    {t('button.manage')}
                                                </Button>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))}

                            {!loading && count === 0 && (
                                <NoResultFound
                                    colSpan={columns.length}
                                    text={t('message.noResultFound')}
                                />
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {setPage && (
                    <TablePagination
                        rowsPerPageOptions={[10, 15, 25, 50]}
                        component="div"
                        count={count}
                        rowsPerPage={max}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}

                {!setPage && <div style={{ height: 10 }} />}
            </Card>
        );
    },
);

export { ForecastTable, StyledTableCell, ColorTableCell };
