import Grid from '@mui/material/Grid';
import Panel from '../../../ui/Panel';
import RegionInput from '../../../ui/RegionInput';
import Input from '../../../ui/Input';
import Select from '../../../ui/Select';
import { TermsOfParticipationTypes, TermsOfPurchaseTypes } from '../../../util/Enums';
import MenuItem from '@mui/material/MenuItem';
import CoverImageSelectionCard from '../../../components/CoverImageSelectionCard';
import Editor from '../../../ui/Editor';
import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ExtraTourEditChildRulesPanel from './ExtraTourEditChildRulesPanel';

const ExtraTourEditInputPanel = ({
    extraTour,
    name,
    image,
    setImage,
    fileNameParam,
    folderName,
    shortDesc,
    setShortDesc,
    elevation = 1,
    setParticipation,
}) => {
    const { t } = useTranslation();
    const [fileName, setFileName] = useState(fileNameParam);
    const {
        register,
        control,
        watch,
        formState: { errors },
    } = useFormContext();

    const termOfParticipation = watch('termOfParticipation');
    const watchFileName = watch('name');

    const aspect = 720 / 480;

    useEffect(() => {
        setFileName(watchFileName || '');
    }, [watchFileName]);

    useEffect(() => {
        setParticipation && setParticipation(termOfParticipation);
    }, [termOfParticipation]);

    return (
        <Grid container spacing={1}>
            <Grid item md={12}>
                <Panel title={t('title.extraTourInfos')} elevation={elevation}>
                    <RegionInput
                        name="region"
                        multiple={false}
                        control={control}
                        error={errors?.region}
                        defaultValue={extraTour?.region}
                        rules={{ required: t('validation.required') }}
                    />

                    <Input
                        name="name"
                        defaultValue={extraTour?.name || name || ''}
                        label={t('label.name')}
                        error={errors?.name}
                        {...register('name', {
                            required: t('validation.required'),
                            minLength: {
                                value: 3,
                                message: t('validation.minLength', { val: 3 }),
                            },
                        })}
                    />
                </Panel>
            </Grid>

            <Grid item md={12}>
                <Panel title={t('title.additionalServicesTermsOfPurchase')} elevation={elevation}>
                    <Select
                        name="termOfPurchase"
                        rules={{ required: t('validation.required') }}
                        error={errors?.termOfPurchase}
                        label={t('label.termsOfPurchase')}
                        defaultValue={extraTour?.termOfPurchase}
                        control={control}>
                        {Object.values(TermsOfPurchaseTypes).map(item => (
                            <MenuItem key={item.id} value={item.id}>
                                {t(item.label)}
                            </MenuItem>
                        ))}
                    </Select>
                    <Select
                        name="termOfParticipation"
                        rules={{ required: t('validation.required') }}
                        error={errors?.termOfParticipation}
                        label={t('label.termsOfParticipation')}
                        defaultValue={extraTour?.termOfParticipation}
                        control={control}>
                        {Object.values(TermsOfParticipationTypes).map(item => (
                            <MenuItem key={item.id} value={item.id}>
                                {t(item.label)}
                            </MenuItem>
                        ))}
                    </Select>
                    {termOfParticipation === TermsOfParticipationTypes.NUMBER_OF_ADULT.id && (
                        <Input
                            defaultValue={extraTour?.minAdultAge}
                            label={t('label.minAdultAge')}
                            error={errors?.minAdultAge}
                            {...register('minAdultAge', {
                                required: t('validation.required'),
                            })}
                        />
                    )}

                    <Input
                        defaultValue={extraTour?.perPersonPrice}
                        label={t('label.perPersonPrice')}
                        error={errors?.perPersonPrice}
                        {...register('perPersonPrice', {
                            required: t('validation.required'),
                        })}
                    />
                </Panel>
            </Grid>

            {termOfParticipation !== TermsOfParticipationTypes.NUMBER_OF_ADULT.id && (
                <Grid item md={12}>
                    <ExtraTourEditChildRulesPanel
                        extraTour={extraTour}
                        elevation={elevation}
                        participation={termOfParticipation}
                    />
                </Grid>
            )}

            <Grid item md={12}>
                <CoverImageSelectionCard
                    aspect={aspect}
                    elevation={elevation}
                    title={t('title.image')}
                    {...{ image, setImage, fileName, folderName }}
                />
            </Grid>

            <Grid item md={12}>
                <Editor
                    title={t('label.shortDesc')}
                    value={shortDesc}
                    elevation={elevation}
                    onChange={val => {
                        setShortDesc(val);
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default ExtraTourEditInputPanel;
