import { format, parse } from 'date-fns';
import { tr } from 'date-fns/esm/locale';

const PARSE_FORMAT = 'yyyy-MM-dd';
const days = ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'];

export function parseAndFormatDateString(dateString, dateFormat) {
    if (!dateString) {
        return '';
    }

    const date = parse(dateString, PARSE_FORMAT, new Date());
    return format(date, dateFormat || 'dd.MM.yyyy', { locale: tr });
}

export function parseDate(dateString) {
    if (!dateString) {
        return null;
    }
    return parse(dateString, PARSE_FORMAT, new Date());
}

export function priceGridDayTabFormat(item) {
    const startDate = parse(item.startDate, PARSE_FORMAT, new Date());
    const endDate = parse(item.endDate, PARSE_FORMAT, new Date());

    return `${format(startDate, 'dd')} ${days[startDate.getDay()]} - ${format(endDate, 'dd')} ${days[endDate.getDay()]}`;
}

export const formatDateForApi = date => {
    if (date) {
        return format(new Date(date), 'yyyy-MM-dd');
    }
    return null;
};

export const formatDate = (date, pattern = 'dd.MM.yyyy') => {
    if (date) {
        return format(new Date(date), pattern);
    }
    return null;
};
