import { useSnackbar } from 'notistack';
import { MessageType } from './Enums';

const useMessage = () => {
    const { enqueueSnackbar } = useSnackbar();

    const showMessage = (
        message,
        type = MessageType.INFO,
        vertical = 'top',
        horizontal = 'right',
    ) => {
        enqueueSnackbar(message, {
            variant: type,
            anchorOrigin: {
                vertical: vertical,
                horizontal: horizontal,
            },
        });
    };

    return showMessage;
};

export default useMessage;
