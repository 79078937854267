import React, { useState, useEffect } from 'react';
import vendorService from '../../service/VendorService';
import { TableCell } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const VendorSearch = React.memo(({ column, onSearch }) => {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(null);

    useEffect(() => {
        let active = true;
        setLoading(true);
        // if (inputValue === '') {
        //     if (active) {
        //         setOptions([]);
        //         setLoading(false);
        //     }
        //     return undefined;
        // }
        (async () => {
            try {
                const { resultObject } = await vendorService.search({
                    start: 0,
                    data: { contactTypeId: 3, brandName: inputValue },
                    max: 50,
                });
                const result = resultObject.map(item => ({
                    id: item.id,
                    name: item.name,
                    brandName: item.brandName,
                }));
                if (active) {
                    setOptions(result || []);
                    setLoading(false);
                }
            } catch (e) {
                console.log(e);
            }
        })();
        return () => {
            active = false;
        };
    }, [inputValue]);

    return (
        <TableCell
            style={{
                backgroundColor: '#f9f6f3',
                margin: 0,
                padding: '2px 2px 0px 8px',
                width: 110,
            }}>
            <Autocomplete
                value={value}
                options={options}
                getOptionLabel={option => option.brandName}
                underlineShow={false}
                blurOnSelect
                id="vendor-on-select"
                onChange={(event, newValue) => {
                    setValue(newValue);
                    const obj = {};
                    const key = column.searchField || column.name;
                    if (newValue !== null) {
                        obj[key] = newValue.id;
                        onSearch(obj);
                    } else {
                        obj[key] = null;
                        onSearch(obj);
                    }
                }}
                renderInput={params => (
                    <TextField
                        {...params}
                        InputProps={{ ...params.InputProps, disableUnderline: true }}
                        variant="standard"
                        placeholder="Tümü"
                        value={inputValue}
                        onChange={e => setInputValue(e.target.value)}
                        sx={{}}
                    />
                )}
            />
        </TableCell>
    );
});

export default VendorSearch;
