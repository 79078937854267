import { useFormContext } from 'react-hook-form';
import Panel from '../../../ui/Panel';
import VendorInput from '../../../ui/VendorInput';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckBox from '../../../ui/CheckBox';
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';
import Config from '../../../Config';

const TourPriceEditProgramInputs = ({ tourPriceId, tourPrice }) => {
    const { t } = useTranslation();
    const {
        watch,
        control,
        formState: { errors },
    } = useFormContext();

    const watchVendor = watch('contact', tourPrice?.contact);
    return (
        <Panel title={t('title.programInfo')}>
            <VendorInput
                name="contact"
                multiple={false}
                defaultValue={tourPrice?.contact}
                control={control}
                rules={{ required: t('validation.required') }}
                error={errors?.contact}
            />
            {(tourPrice?.contact?.commissionCalculationType ||
                tourPrice?.contact?.singleRoomDifference) && (
                <FormGroup>
                    {tourPrice?.contact?.commissionCalculationType && (
                        <CheckBox
                            checked={true}
                            label={`${tourPrice?.contact?.commissionCalculationType === 1 || tourPrice?.contact?.commissionCalculationType === '1' ? 'Üzerine Ekle Markupla' : 'Komisyon Dahil Satış Fiyatı'}`}
                            disabled={true}
                        />
                    )}
                    {tourPrice?.contact?.singleRoomDifference && (
                        <CheckBox
                            checked={true}
                            label="Single oda farkı komisyon dahil değil"
                            disabled={true}
                        />
                    )}
                    <br />
                </FormGroup>
            )}
            {watchVendor && (
                <>
                    {tourPrice?.tourProgram?.title && (
                        <p>Program Adı: {tourPrice?.tourProgram?.title}</p>
                    )}
                    {tourPrice?.tourProgram?.id && (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                window
                                    .open(
                                        `/tours/programs/edit/${tourPrice.tourProgram.id}`,
                                        '_blank',
                                    )
                                    .focus();
                            }}>
                            {t('button.seeProgram')}
                        </Button>
                    )}
                </>
            )}
        </Panel>
    );
};

export default TourPriceEditProgramInputs;
