import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ActionType } from '../store/ActionType';
import { useCookies } from 'react-cookie';
import { Card, CardActions, CardContent, CardHeader, Grid, InputAdornment } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PersonIcon from '@mui/icons-material/Person';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Input from '../ui/Input';
import { useForm } from 'react-hook-form';
import SubmitButton from '../ui/SubmitButton';
import Container from '@mui/material/Container';
import authService from '../service/AuthService';
import Config from '../Config';

const LoginPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const [inited, setInited] = useState(false);
    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [cookies, setCookie] = useCookies(['access_token', 'refresh_token']);

    const { user } = useSelector(state => state.auth);

    useEffect(() => {
        if (!inited && user) {
            history.push('/');
        } else {
            setInited(true);
        }
    }, [user, inited]);

    const onSubmit = async data => {
        try {
            setLoading(true);
            const { username, password } = data;

            const { access_token, refresh_token } = await authService.login(username, password);
            const account = await authService.getAccount(access_token);

            if (account) {
                setCookie('access_token', access_token, Config.COOKIE_CONFIG);
                setCookie('refresh_token', refresh_token, Config.COOKIE_CONFIG);

                dispatch({
                    type: ActionType.LOGIN,
                    user: account,
                    token: access_token,
                });
                setLoading(false);
                setTimeout(() => {
                    history.push('/');
                }, 200);
            }
        } catch (e) {
            if (e.response?.status === 401) {
                alert(t('message.invalidUsernameOrPassword'));
            } else {
                alert(e.message);
                console.log(e);
            }
            setLoading(false);
        }
    };

    return (
        <div className="login-panel">
            <Container>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item lg={4}>
                        <Card className="login-card">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <CardHeader
                                    title={t('title.login')}
                                    subheader={t('title.loginSubtitle')}
                                />
                                <CardContent>
                                    <Input
                                        label={t('label.email')}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <PersonIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={errors?.username}
                                        {...register('username', {
                                            required: t('validation.required'),
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: t('validation.email'),
                                            },
                                        })}
                                    />
                                    <Input
                                        type="password"
                                        label={t('label.password')}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <VpnKeyIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        error={errors?.password}
                                        {...register('password', {
                                            required: t('validation.required'),
                                        })}
                                    />
                                </CardContent>
                                <CardActions>
                                    <SubmitButton
                                        loading={loading}
                                        startIcon={<LockOpenIcon />}
                                        fullWidth>
                                        {t('button.login')}
                                    </SubmitButton>
                                </CardActions>
                            </form>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default LoginPage;
