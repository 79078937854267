import BaseService from './BaseService';

class AdditionalService extends BaseService {
    constructor() {
        super('/common/additionalServices');
    }
}

const additionalService = new AdditionalService();

export default additionalService;
