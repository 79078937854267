import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../../../ui/Input';
import { useForm } from 'react-hook-form';
import useMessage from '../../../util/useMessage';
import { MessageType, Months } from '../../../util/Enums';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '../../../ui/Select';
import ImageGalleryCard from '../../../components/ImageGalleryCard';
import CoverImageSelectionCard from '../../../components/CoverImageSelectionCard';

const HomePagesEditDialog = ({ open, selectedIndex, items, onEditDone, onClose }) => {
    const { t } = useTranslation();
    const showMessage = useMessage();

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        reset,
        control,
        formState: { errors },
    } = useForm();
    const watchFileName = watch('title');
    const [image, setImage] = useState(null);
    const [fileName, setFileName] = useState('');
    const [folderName] = useState('room');

    const aspect = 720 / 480;

    useEffect(() => {
        if (open) {
            reset();
            if (items[selectedIndex]?.image) {
                setImage(items[selectedIndex]?.image);
            }
        } else {
            setImage(null);
        }
    }, [open, selectedIndex, items]);

    useEffect(() => {
        setValue('image', image);
    }, [image]);

    useEffect(() => {
        setFileName(watchFileName || '');
    }, [watchFileName]);

    const onSubmit = data => {
        if (onEditDone) {
            const obj = {
                ...data,
                image,
                homePageId: parseInt(data.homePageId),
            };
            onEditDone(obj, selectedIndex);
        }
    };

    return (
        <Dialog open={open} fullWidth maxWidth={'sm'} scroll="paper">
            <DialogTitle>{t('title.seasonEdit')}</DialogTitle>
            <DialogContent dividers>
                <input
                    type="hidden"
                    {...register(`homePageId`)}
                    defaultValue={items[selectedIndex]?.homePageId || 0}
                />
                <Input
                    defaultValue={items[selectedIndex]?.title}
                    label={t('label.title')}
                    error={errors?.title}
                    {...register('title', {
                        required: t('validation.required'),
                    })}
                />
                <Input
                    defaultValue={items[selectedIndex]?.url}
                    label={t('label.url')}
                    error={errors?.url}
                    {...register('url', {
                        required: t('validation.required'),
                    })}
                />
                <div
                    style={{
                        border: '1px solid #c4c4c4',
                        borderRadius: 4,
                        marginTop: '8px',
                        marginBottom: '4px',
                    }}>
                    <CoverImageSelectionCard
                        aspect={aspect}
                        title={t('title.image')}
                        {...{ image, setImage, fileName, folderName }}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t('button.cancel')}
                </Button>

                <Button onClick={handleSubmit(onSubmit)} color="secondary">
                    {t('button.done')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default HomePagesEditDialog;
