import BaseService from './BaseService';

class CampaignOpportunityService extends BaseService {
    constructor() {
        super('/campaigns/opportunity');
    }
}

const campaignOpportunityService = new CampaignOpportunityService();

export default campaignOpportunityService;
