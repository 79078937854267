import Page from '../../layout/Page';
import { useTranslation } from 'react-i18next';
import usePagination from '../../util/usePagination';
import commentService from '../../service/CommentService';
import { DataTable } from '../../components/DataTable';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import CommentEditDialog from './CommentEditDialog';
import CommentDeleteDialog from './CommentDeleteDialog';
import { getDeletedColumn } from '../../components/YesNoTypeOptions';
import useMessage from '../../util/useMessage';
import { MessageType } from '../../util/Enums';
import Panel from '../../ui/Panel';
import { parseAndFormatDateString } from '../../util/DateUtil';

const CommentListPage = ({ title, ...props }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const showMessage = useMessage();
    const [loading, setLoading] = useState(null);
    const [selectedComment, setSelectedComment] = useState(null);
    const [openCommentEditDialog, setOpenCommentEditDialog] = useState(false);
    const [openCommentDeleteDialog, setOpenCommentDeleteDialog] = useState(false);

    const columnsComment = [
        {
            name: 'commentId',
            label: 'ID',
            width: 50,
            searchable: true,
            type: Number,
        },
        {
            name: 'customerName',
            label: t('label.nameSurname'),
            searchable: true,
        },
        {
            name: 'commentDate',
            label: t('label.commentDate'),
            type: Date,
            searchable: true,
            width: 110,
            execute: date => date && parseAndFormatDateString(date, 'dd.MM.yyyy'),
        },
        {
            name: 'travelDate',
            label: t('label.travelDate'),
            type: Date,
            searchable: true,
            width: 110,
            execute: date => date && parseAndFormatDateString(date, 'dd.MM.yyyy'),
        },
        {
            name: 'travelItineraryStarCount',
            label: t('label.travelItineraryStarCount'),
            searchable: true,
            type: Number,
        },
        {
            name: 'guideStarCount',
            label: t('label.guideStarCount'),
            searchable: true,
            type: Number,
        },
        {
            name: 'transportationStarCount',
            label: t('label.transportationStarCount'),
            searchable: true,
            type: Number,
        },
        {
            name: 'accommodationStarCount',
            label: t('label.accommodationStarCount'),
            searchable: true,
            type: Number,
        },
        {
            name: 'foodStarCount',
            label: t('label.foodStarCount'),
            searchable: true,
            type: Number,
        },
        {
            name: 'tourOperatorStarCount',
            label: t('label.tourOperatorStarCount'),
            searchable: true,
            type: Number,
        },
        { ...getDeletedColumn(t) },
    ];

    const comments = usePagination({
        service: commentService,
        defaultOrderBy: { name: 'commentId', orderType: 'desc' },
    });

    return (
        <Page title={title}>
            <Container maxWidth="xl">
                <Panel>
                    <DataTable
                        title={t('label.commentList')}
                        columns={columnsComment}
                        {...comments}
                        action={
                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    setOpenCommentEditDialog(true);
                                }}>
                                {t('button.addNew')}
                            </Button>
                        }
                        onEdit={item => {
                            setSelectedComment(item);
                            setOpenCommentEditDialog(true);
                        }}
                        onDelete={item => {
                            setSelectedComment(item);
                            setOpenCommentDeleteDialog(true);
                        }}
                        onRollback={async (item, index) => {
                            if (loading) return false;
                            try {
                                setLoading(true);
                                await commentService.delete(item?.commentId, { isDeleted: false });
                                showMessage(t('message.comeBack'));
                                comments.search();
                            } catch ({ code, message }) {
                                showMessage(`${code} - ${message}`, MessageType.ERROR);
                            } finally {
                                setLoading(false);
                            }
                        }}
                    />
                </Panel>
            </Container>
            <CommentEditDialog
                comment={selectedComment}
                open={openCommentEditDialog}
                onClose={() => {
                    setOpenCommentEditDialog(false);
                    setSelectedComment(null);
                }}
                onDoneCallback={comments.search}
            />
            <CommentDeleteDialog
                comment={selectedComment}
                open={openCommentDeleteDialog}
                onClose={() => {
                    setOpenCommentDeleteDialog(false);
                    setSelectedComment(null);
                }}
                onDoneCallback={comments.search}
            />
        </Page>
    );
};

export default CommentListPage;
