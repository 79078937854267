import BaseService from './BaseService';

class CampaignService extends BaseService {
    constructor() {
        super('/common/saleCampaigns');
    }
}

const campaignService = new CampaignService();

export default campaignService;
