import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledTableCell } from '../../../components/DataTable';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { getRoomTitleBySaleItem } from '../../../util/CommonUtil';
import { formatPrice } from '../../../util/CurrencyUtil';
import AddIcon from '@mui/icons-material/Add';
import ReceiptAddDialog from '../../../components/ReceiptAddDialog';
import { CurrencyTypes, findEnumById, BankTypes, MessageType } from '../../../util/Enums';
import useMessage from '../../../util/useMessage';
import saleService from '../../../service/SaleService';
import TourReservationPaymentDeleteDialog from './TourReservationPaymentDeleteDialog';
import TourReservationPaymentDialog from './TourReservationPaymentDialog';
import axios from 'axios';
import { format } from 'date-fns';
import DateInput from '../../../ui/DateInput';
import { useForm } from 'react-hook-form';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && <Box sx={{ padding: '24px 0 0 0' }}>{children}</Box>}
        </div>
    );
}

const TourReservationEditPaymentPanel = ({ stepId, sale, saleGuid, contactId, toggle, onToggle, isExpanded, next, saleOfferFormId, saleOfferTourPrograms, saleOfferItems, token }) => {
    const { t } = useTranslation();
    const showMessage = useMessage();
    const {
        watch,
        control
    } = useForm();
    const [openReceiptAddDialog, setOpenReceiptAddDialog] = useState(false);
    const [receipts, setReceipts] = useState([]);
    const [changed, setChanged] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [data, setData] = useState([]);
    const [selected, setSelected] = useState(null);
    const [openPaymentDeleteDialog, setOpenPaymentDeleteDialog] = useState(false);
    const [openTourReservationPaymentDialog, setOpenTourReservationPaymentDialog] = useState(false);
    const watchPaymentMaturityDate = watch('paymentMaturityDate');

    useEffect(() => {
        if(sale?.salePayments?.length > 0) {
            setReceipts(sale.salePayments);
        }
    }, []);

    // useEffect(() => {
    //     const parents = sale.saleItems.filter(item => !item.saleItemReferenceGuid);
    //     parents?.forEach(parent => {
    //         parent['additionals'] = sale.saleItems.filter(
    //             item => item.saleItemReferenceGuid === parent.saleItemGuid,
    //         );
    //     });

    //     setData(parents);
    // }, [sale]);

    // const getTotalSalePrice = saleItem => {
    //     let total = saleItem.discountSalePrice;
    //     saleItem.additionals?.forEach(additionalItem => {
    //         total = total + additionalItem.discountSalePrice * additionalItem.quantity;
    //     });
    //     return formatPrice(total);
    // };

    const onSalePayment = async (paymentData) => {
        if (loading) return false;
        try {
            setLoading(true);
            const selectedProgram = saleOfferTourPrograms.find(item => item.isSelected === true);
            if(selectedProgram?.hotel?.hotelId) {
                const selectedPeriod = selectedProgram.saleOfferTourProgramPeriods.find(item => item.isSelected === true);
                if(selectedPeriod?.tourProgramPeriodId) {
                    const response  = await axios.post(import.meta.env.VITE_API_URL + "/b2c/sales", 
                        {
                            tourProgramPeriodId: selectedPeriod?.tourProgramPeriodId,
                            hotelId: selectedProgram?.hotel?.hotelId,
                            // saleOfferFormId,
                            saleGuid,
                            personCounts: saleOfferItems
                        }, 
                        {
                            timeout: 60000,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        }
                    );
                    if (response.data.isSuccess) {
                        window.open(`${import.meta.env.VITE_B2C_URL}/odeme/${saleGuid}?p=${paymentData.totalPrice.replace(/\./g, "")}${paymentData.paymentMaturityDate ? ("&m=" + format(new Date(paymentData.paymentMaturityDate), 'yyyy-MM-dd')) : ""}`, "_blank").focus();
                    } else {
                        showMessage('Bir hata oldu.', MessageType.ERROR);
                    }
                }
            }
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const onSubmit = async () => {
        if (loading) return false;
        try {
            setLoading(true);
            const updateSaleItems = [];
            receipts?.filter(item => (item.isManuel !== undefined && item.isManuel === true)).forEach((updateSaleItem, updateSaleIndex) => {
                updateSaleItems.push({receiptId: updateSaleItem.receiptId || updateSaleItem.id, usedTotal: updateSaleItem.usedTotal});
            }); 
            const { isSuccess } = await saleService.updatePayment({
                saleId: sale.saleId,
                data: {receipts: updateSaleItems, paymentMaturityDate: watchPaymentMaturityDate ? watchPaymentMaturityDate : null},
            });
            if (isSuccess) {
                showMessage('Kaydedildi.');
                const receiptsList = receipts;
                receiptsList.forEach((item, index) => {
                    delete item.isNew;
                });
                setReceipts(receiptsList);
                onToggle(0);
            }
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Card>
                <CardHeader
                    title="Tahsilat"
                    titleTypographyProps={{ variant: 'subtitle1' }}
                    action={
                        <>
                            {isExpanded(stepId) && (
                                <CardActions>
                                    {changed ? 
                                        <Button color="secondary" sx={{ marginLeft: 'auto' }} onClick={onSubmit}>
                                            KAYDET
                                        </Button> : 
                                        <Button color="secondary" sx={{ marginLeft: 'auto' }} onClick={() => next(4)}>
                                            İLERLE
                                        </Button>
                                    }
                                    
                                </CardActions>
                            )}
                            <Button color="secondary" onClick={() => toggle(stepId)}>
                                {isExpanded(stepId) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </Button>
                        </>
                    }
                />

                {isExpanded(stepId) && (
                    <CardContent>
                        {sale?.salePaymentSummary?.salePaymentSummaryItems?.map((saleItem, saleItemIndex) => (
                            <Card sx={{ marginBottom: '10px' }}>
                                <CardHeader
                                    titleTypographyProps={{ variant: 'subtitle2' }}
                                    title={saleItem.roomName || (saleItemIndex + 1 + " Oda")}
                                    sx={{ paddingBottom: '0', marginBottom: '-10px' }}
                                />
                                <CardContent>
                                    <TableContainer sx={{ marginTop: '20px' }}>
                                        <Table size="small" className="data-table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell width="100px">
                                                        Tur Fiyatı
                                                    </StyledTableCell>
                                                    {saleItem.summaryItems?.length > 0 &&
                                                        saleItem.summaryItems.map(
                                                            (additionalItem, additionalIndex) => (
                                                                <StyledTableCell
                                                                    key={additionalIndex}>
                                                                    {additionalItem.productName}
                                                                </StyledTableCell>
                                                            ),
                                                        )}
                                                    <StyledTableCell width="100px">
                                                        Toplam
                                                    </StyledTableCell>
                                                    {saleGuid && <StyledTableCell width="100px"></StyledTableCell>}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow key={saleItemIndex}>
                                                    <TableCell>
                                                        {saleItem.tourPrice} TL
                                                    </TableCell>

                                                    {saleItem.summaryItems?.length > 0 &&
                                                        saleItem.summaryItems.map(
                                                            (additionalItem, additionalIndex) => (
                                                                <TableCell key={additionalIndex}>
                                                                    {additionalItem.salePrice}{' '}
                                                                    TL x {additionalItem.quantity}{' '}
                                                                    Kişi ={' '}
                                                                    {additionalItem.totalPrice}{' '}
                                                                    TL
                                                                </TableCell>
                                                            ),
                                                        )}

                                                    <TableCell>
                                                        {saleItem.totalPrice} TL
                                                    </TableCell>

                                                    {saleGuid && <TableCell>
                                                        <Button 
                                                            color="primary"
                                                            onClick={() => {
                                                                onSalePayment({totalPrice: saleItem.totalPrice});
                                                            }}
                                                        >
                                                            ÖDEME AL
                                                        </Button>
                                                    </TableCell>}
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        ))}
                        {sale?.salePaymentSummary && 
                            <Card>
                                <CardHeader
                                    titleTypographyProps={{ variant: 'subtitle2' }}
                                    title="Özet"
                                    sx={{ paddingBottom: '0', marginBottom: '-10px' }}
                                />
                                <CardContent>
                                    <TableContainer sx={{ marginTop: '20px' }}>
                                        <Table size="small" className="data-table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell>
                                                        Tolam Satış Fiyatı
                                                    </StyledTableCell>
                                                    <StyledTableCell>Yatırılan Tutar</StyledTableCell>
                                                    <StyledTableCell>Kalan Tutar</StyledTableCell>
                                                    {/* {sale.salePaymentSummary.paymentMaturityDate && <StyledTableCell>Vade Tarihi</StyledTableCell>} */}
                                                    {saleGuid && <StyledTableCell></StyledTableCell>}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>{sale.salePaymentSummary.totalSalePrice} TL</TableCell>
                                                    <TableCell>{sale.salePaymentSummary.paidAmount} TL</TableCell>
                                                    <TableCell>{sale.salePaymentSummary.remainingAmount} TL</TableCell>
                                                    {/* {sale.salePaymentSummary.paymentMaturityDate && <StyledTableCell>{format(new Date(sale.salePaymentSummary.paymentMaturityDate), 'dd.MM.yyyy')}</StyledTableCell>} */}
                                                    {saleGuid && <TableCell>
                                                        <Button 
                                                            color="primary"
                                                            onClick={() => {
                                                                onSalePayment({totalPrice: sale.salePaymentSummary.remainingAmount});
                                                            }}
                                                        >
                                                            TOPLAM ÖDEME AL
                                                        </Button>
                                                        <Button
                                                            color="primary"
                                                            sx={{ marginLeft: '10px' }}
                                                            onClick={() => {
                                                                setOpenTourReservationPaymentDialog(true);
                                                            }}
                                                        >
                                                            PARÇALI ÖDEME AL
                                                        </Button>
                                                    </TableCell>}
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </CardContent>
                            </Card>
                        }
                        <div style={{ display: 'flex', marginTop: "20px" }}>
                            <Button
                                color={'secondary'}
                                startIcon={<AddIcon />}
                                // variant="contained"
                                onClick={() => setOpenReceiptAddDialog(true)}
                                // loading={loading}
                                sx={{ margin: '8px 0 0 0' }}
                            >
                                TAHSİLAT EKLE
                            </Button>
                            <div style={{ marginLeft: "20px" }}>
                                <DateInput
                                    name="paymentMaturityDate"
                                    label={t('purchase.maturityDate')}
                                    defaultValue={sale.salePaymentSummary.paymentMaturityDate || null}
                                    control={control}
                                    onChangeDate={() => {
                                        setChanged(true);
                                    }}
                                />
                            </div>
                            {(sale?.salePayments?.length > 0 && sale?.saleCode !== undefined && token !== undefined) &&
                                <Button
                                    color="primary"
                                    onClick={() =>
                                        window
                                            .open(
                                                `${import.meta.env.VITE_B2C_URL}/receipt?s=${token}&sc=${sale.saleCode}`,
                                                '_blank',
                                            )
                                            .focus()
                                    }
                                    sx={{ margin: '8px 0 0 auto' }}>
                                    TAHSİLAT MAKBUZU
                                </Button>
                            }
                        </div>
                        {receipts.length > 0 && (
                            <TableContainer sx={{ marginTop: '20px' }}>
                                <Table size="small" className="data-table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>
                                                Dekont No.
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {t('reservation.bank')}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {t('reservation.cardNumber')}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {t('reservation.installmentNumber')}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                Kullanılan Tutar
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                Dekont Toplam Tutar
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {t('reservation.currencyId')}
                                            </StyledTableCell>
                                            <StyledTableCell>
                                                {t('reservation.provisionCode')}
                                            </StyledTableCell>
                                            <StyledTableCell></StyledTableCell>
                                            {/* <StyledTableCell>{t('reservation.resultMessage')}</StyledTableCell> */}
                                            {/* <StyledTableCell>{t('reservation.status')}</StyledTableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {receipts.map(
                                            (salePaymentItem, salePaymentIndex) => {
                                                const remainingAmount = sale?.salePaymentSummary?.remainingAmount ? parseInt(sale.salePaymentSummary.remainingAmount.replace(/\./g, '')) : 0;
                                                // const usableTotal = salePaymentItem.usableTotal ? parseInt(salePaymentItem.usableTotal.replace(/./g, '')) : 0;
                                                const usableTotal = salePaymentItem.usableTotal;
                                                return (
                                                    <TableRow key={salePaymentIndex}>
                                                        <TableCell>
                                                            {salePaymentItem.receiptCode || "-"}
                                                        </TableCell>
                                                        <TableCell>
                                                            {findEnumById(BankTypes, salePaymentItem.bankId)?.label || salePaymentItem.cardIssuer || ""}
                                                        </TableCell>
                                                        <TableCell>
                                                            {salePaymentItem.cardNumber}
                                                        </TableCell>
                                                        <TableCell>
                                                            {salePaymentItem.installmentNumber}
                                                        </TableCell>
                                                        {(salePaymentItem.isManuel !== undefined && salePaymentItem.isManuel === true) ?  
                                                            <TableCell sx={{ padding: 0 }}>
                                                                <TextField
                                                                    type="number"
                                                                    margin="dense"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    autoComplete="off"
                                                                    sx={{ margin: 0 }}
                                                                    defaultValue={salePaymentItem.usedTotal}
                                                                    disabled={salePaymentItem.isNew ? false : true}
                                                                    onChange={e => {
                                                                        const { value } = e.target;
                                                                        if (value === "" || value > usableTotal || value > remainingAmount) {
                                                                            e.preventDefault();
                                                                            showMessage("Tutar alanı Toplam Tutar ve Kalan Tutardan fazla olamaz!", 'warn');
                                                                            setChanged(false);
                                                                        } else {
                                                                            const receiptsList = receipts;
                                                                            receiptsList.forEach(item => {
                                                                                if(item.receiptId = salePaymentItem.receiptId) {
                                                                                    item.usedTotal = value
                                                                                }
                                                                            });
                                                                            setReceipts(receiptsList);
                                                                            setChanged(true);
                                                                        }
                                                                    }}
                                                                />
                                                            </TableCell> : 
                                                            <TableCell>
                                                                {salePaymentItem.amount || salePaymentItem.usedTotal}
                                                            </TableCell>
                                                        }
                                                        <TableCell>
                                                            {salePaymentItem.total || "0"}
                                                        </TableCell>
                                                        <TableCell>
                                                            {findEnumById(CurrencyTypes, salePaymentItem.currencyTypeId)?.label || ""}
                                                        </TableCell>
                                                        <TableCell>
                                                            {salePaymentItem.provisionCode}
                                                        </TableCell>
                                                        <TableCell>
                                                            {/* {(salePaymentItem.isManuel !== undefined && salePaymentItem.isManuel === true) && ( */}
                                                                <Button
                                                                    color="secondary"
                                                                    size="small"
                                                                    style={{ padding: 0 }}
                                                                    onClick={() => {
                                                                        setSelected(salePaymentItem);
                                                                        setOpenPaymentDeleteDialog(true);
                                                                    }}>
                                                                    {t('button.delete')}
                                                                </Button>
                                                            {/* )} */}
                                                        </TableCell>
                                                        {/* <TableCell>{salePaymentItem.resultMessage}</TableCell>
                                                    <TableCell>{salePaymentItem.status}</TableCell> */}
                                                    </TableRow>
                                                )
                                            },
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </CardContent>
                )}
                {isExpanded(stepId) && (
                    <CardActions>
                        {changed ? 
                            <Button color="secondary" sx={{ marginLeft: 'auto' }} onClick={onSubmit}>
                                KAYDET
                            </Button> : 
                            <Button color="secondary" sx={{ marginLeft: 'auto' }} onClick={() => next(4)}>
                                İLERLE
                            </Button>
                        }
                        
                    </CardActions>
                )}
            </Card>
            <ReceiptAddDialog
                contactId={contactId || null}
                open={openReceiptAddDialog}
                disabledItems={receipts.filter(item => item.isManuel)}
                onClose={() => setOpenReceiptAddDialog(false)}
                onAddCallback={data => {
                    const remainingAmount = sale?.salePaymentSummary?.remainingAmount ? parseInt(sale.salePaymentSummary.remainingAmount.replace(/\./g, '')) : 0;
                    const usableTotal = data.usableTotal;
                    setReceipts([...receipts, {...data, receiptId: data.id || data.receiptId, isManuel: true, isNew: true, usedTotal: remainingAmount <= usableTotal ? remainingAmount : usableTotal}])
                    setOpenReceiptAddDialog(false);
                    setChanged(true);
                }}
            />
            <TourReservationPaymentDeleteDialog
                saleId={sale.saleId}
                paymentItem={selected}
                open={openPaymentDeleteDialog}
                onClose={() => {
                    setOpenPaymentDeleteDialog(false);
                    setSelected(null);
                }}
                onDoneCallback={(salePaymentItem) => {
                    const removedReceipts = receipts.filter(item => item.receiptId !== salePaymentItem.receiptId);
                    setReceipts(removedReceipts);
                    if(salePaymentItem.isNew) {
                        setChanged(true);
                    } else {
                        onToggle(0);
                    }
                }}
            />
            <TourReservationPaymentDialog
                remainingAmount={sale?.salePaymentSummary?.remainingAmount ? parseInt(sale.salePaymentSummary.remainingAmount.replace(/\./g, '')) : null}
                open={openTourReservationPaymentDialog}
                onClose={() => {
                    setOpenTourReservationPaymentDialog(false);
                }}
                onDoneCallback={(paymentData) => {
                    onSalePayment(paymentData);
                }}
            />
        </>
    );
};

export default TourReservationEditPaymentPanel;
