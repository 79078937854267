import React, { useState, useEffect } from 'react';
import {
    Popper,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
    IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import Input from './Input';
import makeStyles from '@mui/styles/makeStyles';
import useOutsideClick from '../util/useOutsideClick';
import { FieldInputs } from '../util/Enums';

const useStyles = makeStyles(theme => ({
    wrap: {
        cursor: 'pointer',
    },
}));

const RoomInput = ({ val, setVal, onChange, disabled }) => {
    const classes = useStyles();
    const wrapperRef = useOutsideClick(() => setAnchorEl(null));
    const [anchorEl, setAnchorEl] = useState(null);
    const [openClose, setOpenClose] = useState(false);
    const [totalCount, setTotalCount] = useState();
    const handleClick = e => {
        setAnchorEl(anchorEl ? null : e.currentTarget);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    useEffect(() => {
        var reducedArray = val.reduce(function (accumulator, item) {
            Object.keys(item).forEach(function (key) {
                accumulator[key] = (accumulator[key] || 0) + item[key];
            });
            return accumulator;
        }, {});

        const computedSelectedCount = Object.entries(reducedArray)
            .filter(([_, value]) => value)
            .map(entry => {
                const [key, value] = entry;
                const fieldObject = Object.entries(FieldInputs).find(item => item[0] === key)[1];

                return `${value} ${fieldObject.title}`;
            })
            .join(', ');
        setTotalCount(computedSelectedCount);
    }, [val]);

    return (
        <div
            style={{ position: 'relative' }}
            onMouseEnter={() => setOpenClose(true)}
            onMouseLeave={() => setOpenClose(false)}>
            <Input
                name="personCount"
                label="Kişi Sayısı"
                value={totalCount}
                aria-describedby={id}
                onClick={!disabled && handleClick}
                variant="outlined"
                readOnly
                disabled={disabled}
                InputLabelProps={{
                    shrink: totalCount && totalCount !== '' ? true : false,
                }}
                InputProps={{
                    readOnly: true,
                    className: classes.wrap,
                }}
                size="small"
            />
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement={'bottom-start'}
                ref={wrapperRef}
                style={{ zIndex: '9999' }}>
                <Card>
                    <CardContent>
                        {val.map((item, index) => {
                            const disabled =
                                item.adultCount + item.childCount + item.infantCount === 3
                                    ? true
                                    : false;
                            return (
                                <React.Fragment key={index}>
                                    <List>
                                        <ListItem disablePadding>
                                            <ListItemText primary={index + 1 + '. Oda'} />
                                            {val.length > 1 && (
                                                <IconButton
                                                    edge="end"
                                                    aria-label="delete"
                                                    onClick={() => {
                                                        const removeFilter = [];
                                                        val.forEach((ritem, rindex) => {
                                                            if (rindex !== index) {
                                                                removeFilter.push(ritem);
                                                            }
                                                        });
                                                        setVal(removeFilter);
                                                    }}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            )}
                                        </ListItem>
                                        <ListItem disablePadding>
                                            {Object.entries(item)
                                                .filter(
                                                    ([key, value]) =>
                                                        key === 'adultCount' ||
                                                        key === 'childCount' ||
                                                        key === 'infantCount',
                                                )
                                                .map(([key, value], findex) => (
                                                    <React.Fragment key={findex}>
                                                        <ListItemText
                                                            primary={FieldInputs[key].title}
                                                            secondary={FieldInputs[key].description}
                                                        />
                                                        <IconButton
                                                            aria-label="Minus"
                                                            disabled={value === 0 ? true : false}
                                                            onClick={() => {
                                                                if (key === 'adultCount') {
                                                                    if (val[key] !== 1) {
                                                                        onChange(
                                                                            key,
                                                                            'decreased',
                                                                            index,
                                                                        );
                                                                    }
                                                                } else {
                                                                    onChange(
                                                                        key,
                                                                        'decreased',
                                                                        index,
                                                                    );
                                                                }
                                                            }}>
                                                            <RemoveIcon />
                                                        </IconButton>
                                                        {value}
                                                        <IconButton
                                                            aria-label="Plus"
                                                            disabled={disabled}
                                                            onClick={() => {
                                                                !disabled &&
                                                                    onChange(
                                                                        key,
                                                                        'increased',
                                                                        index,
                                                                    );
                                                            }}>
                                                            <AddIcon />
                                                        </IconButton>
                                                        {findex !== 2 && (
                                                            <Divider
                                                                orientation="vertical"
                                                                variant="middle"
                                                                flexItem
                                                                sx={{ marginRight: '10px' }}
                                                            />
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                        </ListItem>
                                    </List>
                                    <Divider />
                                </React.Fragment>
                            );
                        })}
                        <List>
                            <ListItem disablePadding>
                                <ListItemButton
                                    onClick={() => {
                                        setVal([
                                            ...val,
                                            { adultCount: 2, childCount: 0, infantCount: 0 },
                                        ]);
                                    }}>
                                    <ListItemIcon>
                                        <AddIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Oda Ekle" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
            </Popper>
        </div>
    );
};

export default RoomInput;
