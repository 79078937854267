import axios from 'axios';
import Config from '../Config';

export const easyGet = async (path, data = {}) => {
    const response = await axios.get(Config.API_URL + path, {
        //withCredentials: true,
        timeout: 60000,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};

export const easyPost = async (path, data = {}) => {
    const response = await axios.post(Config.API_URL + path, data, {
        //withCredentials: true,
        timeout: 60000,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};

export const easyPut = async (path, data = {}) => {
    const response = await axios.put(Config.API_URL + path, data, {
        //withCredentials: true,
        timeout: 60000,
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return response.data;
};

export const easySearch = async (
    path,
    start = 0,
    data,
    max = Config.MAX_RESULT,
    orderBy = null,
) => {
    try {
        if (data.isDeleted === undefined) {
            data['isDeleted'] = false;
        } else if (data.isDeleted === '' || data.isDeleted === null) {
            delete data['isDeleted'];
        } else {
            data.isDeleted = data.isDeleted === '1';
            console.log(data.isDeleted);
        }

        const proxy = { ...data };
        Object.keys(proxy).forEach(key => {
            if (data[key] === null) {
                delete data[key];
            }
        });

        const response = await axios.post(
            Config.API_URL + path,
            {
                start: start,
                max: max,
                data: { ...data },
                orderBy: orderBy,
            },
            {
                //withCredentials: true,
                timeout: 60000,
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        return response.data;
    } catch (e) {
        alert(JSON.stringify(e));
        return null;
    }
};

export const easyCustomSearch = async (
    path,
    start = 0,
    data,
    max = Config.MAX_RESULT,
    orderBy = null,
) => {
    try {
        if (data.isDeleted === undefined) {
            data['isDeleted'] = false;
        } else if (data.isDeleted === '' || data.isDeleted === null) {
            delete data['isDeleted'];
        } else {
            data.isDeleted = data.isDeleted === '1';
            console.log(data.isDeleted);
        }

        const proxy = { ...data };
        Object.keys(proxy).forEach(key => {
            if (data[key] === null) {
                delete data[key];
            }
        });

        const response = await axios.post(
            Config.API_URL + path,
            {
                start: start,
                max: max,
                ...data,
                orderBy: orderBy,
            },
            {
                //withCredentials: true,
                timeout: 60000,
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        );

        return response.data;
    } catch (e) {
        alert(JSON.stringify(e));
        return null;
    }
};

export const easyDelete = async (path, data = {}) => {
    const response = await axios.delete(
        Config.API_URL + path,
        { data },
        {
            //withCredentials: true,
            timeout: 60000,
            headers: {
                'Content-Type': 'application/json',
            },
        },
    );

    return response.data;
};
