import BaseService from './BaseService';

class HomeService extends BaseService {
    constructor() {
        super('/common/home');
    }
}

const homeService = new HomeService();

export default homeService;
