import { useEffect, useState } from 'react';
import Page from '../../../layout/Page';
import { useHistory } from 'react-router-dom';
import generalConditionService from '../../../service/GeneralConditionService';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import PageActions from '../../../components/PageActions';
import { MessageType } from '../../../util/Enums';
import useMessage from '../../../util/useMessage';
import SubmitButton from '../../../ui/SubmitButton';
import Editor from '../../../ui/Editor';

let MOUNT = true;

const GeneralConditionEditPage = ({ title, ...props }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const showMessage = useMessage();
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [content, setContent] = useState('');

    useEffect(() => {
        (async () => {
            setPageLoading(true);
            try {
                const { resultObject } = await generalConditionService.getStatic();
                initPageValues(resultObject);
            } catch (e) {
                console.log(e);
            }
            setPageLoading(false);
        })();
        return () => {
            MOUNT = false;
        };
    }, []);

    const initPageValues = _generalCondition => {
        setContent(_generalCondition.content || '');
    };

    const onSubmit = async () => {
        if (loading) return false;
        try {
            setLoading(true);
            const { resultObject } = await generalConditionService.save({ content });
            showMessage(t('message.updated'));
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const ActionPanel = () => (
        <PageActions>
            <Button
                variant="contained"
                disabled={loading}
                startIcon={loading ? null : <KeyboardBackspaceIcon />}
                onClick={() => {
                    history.goBack();
                }}>
                {t('button.back')}
            </Button>

            <SubmitButton loading={loading} onClick={onSubmit} startIcon={<SaveIcon />}>
                {t('button.save')}
            </SubmitButton>
        </PageActions>
    );

    return (
        <Page title={title} loading={pageLoading}>
            <Container maxWidth="md">
                <ActionPanel />
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Editor
                            title={t('label.content')}
                            expanded={true}
                            value={content}
                            onChange={val => {
                                setContent(val);
                            }}
                        />
                    </Grid>
                </Grid>
                <ActionPanel />
            </Container>
        </Page>
    );
};

export default GeneralConditionEditPage;
