import { useEffect, useState } from 'react';
import Page from '../../../layout/Page';
import Panel from '../../../ui/Panel';
import { useHistory } from 'react-router-dom';
import homeService from '../../../service/HomeService';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import PageActions from '../../../components/PageActions';
import { MessageType } from '../../../util/Enums';
import useMessage from '../../../util/useMessage';
import SubmitButton from '../../../ui/SubmitButton';
import Editor from '../../../ui/Editor';
import TourCatalogInput from '../../../ui/TourCatalogInput';
import { useForm } from 'react-hook-form';
import HomePagesEditDialog from './HomePagesEditDialog';
import HomeDeparturesEditDialog from './HomeDeparturesEditDialog';
import AddIcon from '@mui/icons-material/Add';
import { DataTable } from '../../../components/DataTable';

let MOUNT = true;

const HomeEditPage = ({ title, ...props }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const showMessage = useMessage();
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [home, setHome] = useState('');
    const [pages, setPages] = useState([]);
    const [departures, setDepartures] = useState([]);

    const [selectedPagesIndex, setSelectedPagesIndex] = useState(null);
    const [openPagesEditDialog, setOpenPagesEditDialog] = useState(false);

    const [selectedDeparturesIndex, setSelectedDeparturesIndex] = useState(null);
    const [openDeparturesEditDialog, setOpenDeparturesEditDialog] = useState(false);

    const {
        register,
        handleSubmit,
        watch,
        control,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        (async () => {
            setPageLoading(true);
            try {
                const { resultObject } = await homeService.getStatic();
                initPageValues(resultObject);
            } catch (e) {
                console.log(e);
            }
            setPageLoading(false);
        })();
        return () => {
            MOUNT = false;
        };
    }, []);

    const initPageValues = _home => {
        setHome(_home);
        setPages(_home?.pages || []);
        setDepartures(_home?.departures || []);
    };

    const onSubmit = async data => {
        if (loading) return false;
        try {
            setLoading(true);
            const { resultObject } = await homeService.save({ ...data, pages, departures });
            showMessage(t('message.updated'));
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const ActionPanel = () => (
        <PageActions>
            <Button
                variant="contained"
                disabled={loading}
                startIcon={loading ? null : <KeyboardBackspaceIcon />}
                onClick={() => {
                    history.goBack();
                }}>
                {t('button.back')}
            </Button>

            <SubmitButton loading={loading} startIcon={<SaveIcon />}>
                {t('button.save')}
            </SubmitButton>
        </PageActions>
    );

    return (
        <Page title={title} loading={pageLoading}>
            <Container maxWidth="md">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ActionPanel />
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Panel title={t('title.opportunityTours')}>
                                <TourCatalogInput
                                    name="opportunityTours"
                                    multiple={true}
                                    defaultValue={home?.opportunityTours}
                                    control={control}
                                    error={errors?.opportunityTours}
                                />
                            </Panel>
                        </Grid>
                        <Grid item xs={12}>
                            <Panel title={t('title.popularTours')}>
                                <DataTable
                                    data={pages}
                                    title={t('title.pages')}
                                    columns={[
                                        {
                                            name: 'title',
                                            label: t('label.title'),
                                        },
                                        {
                                            name: 'url',
                                            label: t('label.url'),
                                        },
                                    ]}
                                    action={
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<AddIcon />}
                                            size={'small'}
                                            onClick={() => {
                                                setSelectedPagesIndex(null);
                                                setOpenPagesEditDialog(true);
                                            }}>
                                            {t('button.add')}
                                        </Button>
                                    }
                                    onEdit={(item, index) => {
                                        setSelectedPagesIndex(index);
                                        setOpenPagesEditDialog(true);
                                    }}
                                    onDelete={(item, index) => {
                                        setPages(pages.filter((i, idx) => idx !== index));
                                    }}
                                />
                            </Panel>
                        </Grid>
                        <Grid item xs={12}>
                            <Panel title={t('title.visaFreeTours')}>
                                <TourCatalogInput
                                    name="visaFreeTours"
                                    multiple={true}
                                    defaultValue={home?.visaFreeTours}
                                    control={control}
                                    error={errors?.visaFreeTours}
                                    categoryId={1}
                                />
                            </Panel>
                        </Grid>
                        <Grid item xs={12}>
                            <Panel title={t('title.summerTours')}>
                                <TourCatalogInput
                                    name="summerTours"
                                    multiple={true}
                                    defaultValue={home?.summerTours}
                                    control={control}
                                    error={errors?.summerTours}
                                />
                            </Panel>
                        </Grid>
                        <Grid item xs={12}>
                            <Panel title={t('title.departureTours')}>
                                <DataTable
                                    data={departures}
                                    title={t('title.departures')}
                                    columns={[
                                        {
                                            name: 'departure',
                                            label: t('label.departure'),
                                            execute: item => (item ? item.name : ''),
                                        },
                                        {
                                            name: 'tours',
                                            label: t('label.tours'),
                                            execute: items =>
                                                items?.map(item => item.title).join(', '),
                                        },
                                    ]}
                                    action={
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<AddIcon />}
                                            size={'small'}
                                            onClick={() => {
                                                setSelectedDeparturesIndex(null);
                                                setOpenDeparturesEditDialog(true);
                                            }}>
                                            {t('button.add')}
                                        </Button>
                                    }
                                    onEdit={(item, index) => {
                                        setSelectedDeparturesIndex(index);
                                        setOpenDeparturesEditDialog(true);
                                    }}
                                    onDelete={(item, index) => {
                                        setDepartures(departures.filter((i, idx) => idx !== index));
                                    }}
                                />
                            </Panel>
                        </Grid>
                    </Grid>
                    <ActionPanel />
                </form>
            </Container>
            <HomePagesEditDialog
                open={openPagesEditDialog}
                items={pages}
                selectedIndex={selectedPagesIndex}
                onClose={() => setOpenPagesEditDialog(false)}
                onEditDone={(data, index) => {
                    const temp = [...pages];
                    const newData = { ...data };
                    Object.keys(newData).forEach(key => {
                        if (key === 'id') {
                            delete newData[key];
                            newData['homePageId'] = 0;
                        }
                    });
                    if (index !== null) {
                        temp[index] = newData;
                    } else {
                        temp.push(newData);
                    }
                    setPages(temp);
                    setOpenPagesEditDialog(false);
                    setSelectedPagesIndex(null);
                }}
            />
            <HomeDeparturesEditDialog
                open={openDeparturesEditDialog}
                items={departures}
                selectedIndex={selectedDeparturesIndex}
                onClose={() => setOpenDeparturesEditDialog(false)}
                onEditDone={(data, index) => {
                    const temp = [...departures];
                    const newData = { ...data };
                    Object.keys(newData).forEach(key => {
                        if (key === 'id') {
                            delete newData[key];
                            newData['homeDepartureId'] = 0;
                        }
                    });
                    if (index !== null) {
                        temp[index] = newData;
                    } else {
                        temp.push(newData);
                    }
                    setDepartures(temp);
                    setOpenDeparturesEditDialog(false);
                    setSelectedDeparturesIndex(null);
                }}
            />
        </Page>
    );
};

export default HomeEditPage;
