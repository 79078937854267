import React, { useEffect, useState } from 'react';
import Page from '../../layout/Page';
import { useHistory, useParams } from 'react-router-dom';
import userService from '../../service/UserService';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Panel from '../../ui/Panel';
import Input from '../../ui/Input';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import PageActions from '../../components/PageActions';
import { useForm } from 'react-hook-form';
import { MessageType } from '../../util/Enums';
import useMessage from '../../util/useMessage';
import SubmitButton from '../../ui/SubmitButton';
import RoleInput from '../../ui/RoleInput';
import LockIcon from '@mui/icons-material/Lock';
import UserPasswordEditDialog from './UserPasswordEditDialog';

let MOUNT = true;

const UserEditPage = ({ title, ...props }) => {
    const param = useParams();
    const userId = param?.id;

    const { t } = useTranslation();
    const history = useHistory();
    const showMessage = useMessage();

    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [user, setUser] = useState(null);
    const [openChangePasswordDialog, setOpenChangePasswordDialog] = useState(false);

    const {
        register,
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm();

    const password = watch('password', '');

    useEffect(() => {
        if (userId) {
            (async () => {
                setPageLoading(true);
                try {
                    const { resultObject } = await userService.get(userId);
                    initPageValues(resultObject);
                } catch (e) {}
                setPageLoading(false);
            })();
        } else {
            setPageLoading(false);
        }
        return () => {
            MOUNT = false;
        };
    }, [userId]);

    const initPageValues = _user => {
        setUser(_user);
    };

    const onSubmit = async data => {
        if (loading) return false;
        try {
            setLoading(true);
            const obj = {
                ...data,
            };
            const { resultObject } = await userService.saveOrUpdate(userId, obj);
            showMessage(t(userId ? 'message.updated' : 'message.created'));

            history.push(`/auths/users/edit/${resultObject.userId || resultObject.id}`);
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const ActionPanel = () => (
        <PageActions>
            <Button
                variant="contained"
                disabled={loading}
                startIcon={loading ? null : <KeyboardBackspaceIcon />}
                onClick={() => {
                    history.goBack();
                }}>
                {t('button.back')}
            </Button>

            {user && (
                <Button
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    startIcon={loading ? null : <LockIcon />}
                    onClick={() => {
                        setOpenChangePasswordDialog(true);
                    }}>
                    {t('button.changePassword')}
                </Button>
            )}

            <SubmitButton loading={loading} startIcon={<SaveIcon />}>
                {t('button.save')}
            </SubmitButton>
        </PageActions>
    );

    return (
        <Page title={title} loading={pageLoading}>
            <Container maxWidth="lg">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ActionPanel />
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Panel title={t('title.userInfos')}>
                                <Input
                                    defaultValue={user?.name}
                                    label={t('label.name')}
                                    error={errors?.name}
                                    {...register('name', {
                                        required: t('validation.required'),
                                        minLength: {
                                            value: 2,
                                            message: t('validation.minLength', { val: 2 }),
                                        },
                                    })}
                                />
                                <Input
                                    defaultValue={user?.surname}
                                    label={t('label.surname')}
                                    error={errors?.surname}
                                    {...register('surname', {
                                        required: t('validation.required'),
                                        minLength: {
                                            value: 2,
                                            message: t('validation.minLength', { val: 2 }),
                                        },
                                    })}
                                />
                                <Input
                                    defaultValue={user?.email}
                                    label={t('label.email')}
                                    error={errors?.email}
                                    {...register('email', {
                                        required: t('validation.required'),
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: t('validation.email'),
                                        },
                                    })}
                                />

                                <Input
                                    defaultValue={user?.phone}
                                    label={t('label.phone')}
                                    error={errors?.phone}
                                    {...register('phone', {
                                        required: t('validation.required'),
                                        minLength: {
                                            value: 10,
                                            message: t('validation.minLength', { val: 10 }),
                                        },
                                    })}
                                />

                                {!userId && (
                                    <>
                                        <Input
                                            type="password"
                                            defaultValue={user?.password}
                                            label={t('label.password')}
                                            error={errors?.password}
                                            {...register('password', {
                                                required: t('validation.required'),
                                                minLength: {
                                                    value: 5,
                                                    message: t('validation.minLength', { val: 5 }),
                                                },
                                            })}
                                        />

                                        <Input
                                            type="password"
                                            defaultValue={user?.passwordAgain}
                                            label={t('label.passwordAgain')}
                                            error={errors?.passwordAgain}
                                            {...register('passwordAgain', {
                                                validate: value =>
                                                    value === password ||
                                                    t('validation.passwordAgain'),
                                            })}
                                        />
                                    </>
                                )}

                                <RoleInput
                                    name="roles"
                                    defaultValue={user?.roles}
                                    control={control}
                                    rules={{ required: t('validation.required') }}
                                    error={errors?.roles}
                                />
                            </Panel>
                        </Grid>
                    </Grid>
                    <ActionPanel />
                </form>

                <UserPasswordEditDialog
                    user={user}
                    open={openChangePasswordDialog}
                    onClose={() => setOpenChangePasswordDialog(false)}
                />
            </Container>
        </Page>
    );
};

export default UserEditPage;
