const getPersonCountBySaleItemGuest = saleItemGuests => {
    let saleItemGuestsResult = saleItemGuests.reduce((obj, itm) => {
        obj.guestTypeId = itm.guestTypeId;
        obj[itm.guestTypeId] = obj[itm.guestTypeId] + 1 || 1;
        return obj;
    }, {});

    return `(${saleItemGuestsResult['1']} Yetişkin${saleItemGuestsResult['2'] !== undefined && saleItemGuestsResult['2'] !== 0 ? ', ' + saleItemGuestsResult['2'] + ' Çocuk' : ''}${saleItemGuestsResult['3'] !== undefined && saleItemGuestsResult['3'] !== 0 ? ', ' + saleItemGuestsResult['3'] + ' Bebek' : ''})`;
};

export const getRoomTitleBySaleItem = (saleItemIndex, saleItem) => {
    return `${saleItemIndex + 1}. Oda ${saleItem.saleItemGuests?.length > 0 ? getPersonCountBySaleItemGuest(saleItem.saleItemGuests) : ''}`;
};
