export function formatPrice(value) {
    if (!(typeof value === 'number')) {
        return '';
    }

    const formatter = new Intl.NumberFormat('tr-TR', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    return formatter.format(value);
}
