import BaseService from './BaseService';
import { easyPost, easyPut } from '../util/Fetch';

class OfferService extends BaseService {
    constructor() {
        super('/offer');
    }

    async addTour(data) {
        return await easyPost(`/offer/addTour`, data);
    }

    async reservationForm(data) {
        return await easyPost(`/offer/offerForm`, data);
    }

    async confirmProgram(data) {
        return await easyPost(`/offer/offerTourProgramPeriodSelected`, data);
    }

    async updateStatus({ saleOfferFormId, data }) {
        return await easyPut(`/offer/updateSaleOfferFormStatus/${saleOfferFormId}`, data);
    }

    async getAdditionals({ tourProgramId, data }) {
        return await easyPost(`/tours/programs/${tourProgramId}/additionalServices`, data);
    }

    async calculatePrices(data) {
        return await easyPost(`/tours/calculatePrices`, data);
    }

    async getReceiptPayment(data) {
        const { resultObject } = await easyPost(`/b2c/offer/receiptPayment`, data);
        return resultObject || null;
    }

    async getVoucher(data) {
        const { resultObject } = await easyPost(`/b2c/offer/voucher`, data);
        return resultObject || null;
    }
}

const offerService = new OfferService();

export default offerService;
