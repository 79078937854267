import DayPicker from 'react-day-picker';
import React, { useState } from 'react';
import useDatePickerLocale from '../../util/useDatePickerLocale';

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear, 0);
const toMonth = new Date(currentYear + 10, 11);

function YearMonthForm({ months, date, onChange }) {
    const years = [];
    for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
        years.push(i);
    }

    const handleChange = function handleChange(e) {
        const { year, month } = e.target.form;
        onChange(new Date(year.value, month.value));
    };

    return (
        <form className="DayPicker-Caption">
            <select name="month" onChange={handleChange} value={date.getMonth()}>
                {months.map((month, i) => (
                    <option key={month} value={i}>
                        {month}
                    </option>
                ))}
            </select>
            <select name="year" onChange={handleChange} value={date.getFullYear()}>
                {years.map(year => (
                    <option key={year} value={year}>
                        {year}
                    </option>
                ))}
            </select>
        </form>
    );
}

const CalendarInput = ({ values, handleDayClick, numberOfMonths = 2, today }) => {
    const { months, weekdaysLong, weekdaysShort } = useDatePickerLocale();

    const [month, setMonth] = useState(null);

    const handleYearMonthChange = _month => {
        setMonth(_month);
    };

    return (
        <DayPicker
            {...{ months, weekdaysLong, weekdaysShort }}
            month={month}
            fromMonth={fromMonth}
            toMonth={toMonth}
            captionElement={({ date }) => (
                <YearMonthForm date={date} months={months} onChange={handleYearMonthChange} />
            )}
            selectedDays={values}
            firstDayOfWeek={1}
            onDayClick={handleDayClick}
            initialMonth={values[0]}
            numberOfMonths={numberOfMonths}
            disabledDays={{ before: today }}
        />
    );
};

export default CalendarInput;
