import { useEffect, useState } from 'react';
import Page from '../../../layout/Page';
import { useHistory, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import PageActions from '../../../components/PageActions';
import { FormProvider, useForm } from 'react-hook-form';
import { MessageType } from '../../../util/Enums';
import useMessage from '../../../util/useMessage';
import SubmitButton from '../../../ui/SubmitButton';
import visaTypeService from '../../../service/VisaTypeService';
import VisaTypeEditInputPanel from './VisaTypeEditInputPanel';

let MOUNT = true;

const VisaTypeEditPage = ({ title, ...props }) => {
    const param = useParams();
    const visaTypeId = param?.id;

    const { t } = useTranslation();
    const history = useHistory();
    const showMessage = useMessage();

    const [visaType, setVisaType] = useState(null);
    const [description, setDescription] = useState('');

    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const methods = useForm();

    useEffect(() => {
        if (visaTypeId) {
            (async () => {
                setPageLoading(true);
                try {
                    const { resultObject } = await visaTypeService.get(visaTypeId);
                    initPageValues(resultObject);
                } catch (e) {}
                setPageLoading(false);
            })();
        } else {
            setPageLoading(false);
        }

        return () => {
            MOUNT = false;
        };
    }, []);

    const initPageValues = _visaType => {
        setVisaType(_visaType);
        setDescription(_visaType?.description || '');
    };

    const onSubmit = async data => {
        if (loading) return false;
        try {
            setLoading(true);

            const obj = {
                ...data,
                description,
                volume: data?.volume ? parseInt(data.volume) : 0,
            };
            const { resultObject } = await visaTypeService.saveOrUpdate(visaTypeId, obj);
            showMessage(t(visaTypeId ? 'message.updated' : 'message.created'));

            history.push(`/settings/visaTypes/edit/${resultObject.visaTypeId || resultObject.id}`);
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const ActionPanel = () => (
        <PageActions>
            <Button
                variant="contained"
                disabled={loading}
                startIcon={loading ? null : <KeyboardBackspaceIcon />}
                onClick={() => {
                    history.goBack();
                }}>
                {t('button.back')}
            </Button>
            <SubmitButton loading={loading} startIcon={<SaveIcon />}>
                {t('button.save')}
            </SubmitButton>
        </PageActions>
    );

    return (
        <Page title={title} loading={pageLoading}>
            <Container maxWidth="sm">
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <ActionPanel />
                        <VisaTypeEditInputPanel {...{ visaType, description, setDescription }} />
                        <ActionPanel />
                    </form>
                </FormProvider>
            </Container>
        </Page>
    );
};

export default VisaTypeEditPage;
