import withStyles from '@mui/styles/withStyles';
import NativeSelect from '@mui/material/NativeSelect';

const SearchSelect = withStyles(theme => ({
    root: {
        width: '100%',
        border: 'none',
    },
}))(NativeSelect);

export default SearchSelect;
