import {
    Card,
    CardContent,
    CardHeader,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { StyledTableCell } from '../../../components/DataTable';
import React, { useCallback } from 'react';
import { formatPrice } from '../../../util/CurrencyUtil';
import { CurrencyTypes, findEnumById } from '../../../util/Enums';
import { useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { getRoomTitleBySaleItem } from '../../../util/CommonUtil';

const TourReservationEditPurchasePanelGroup = ({ saleItem, saleItemIndex }) => {
    const { saleItemPurchase } = saleItem;
    const getCurrencyType = useCallback(type => {
        return findEnumById(CurrencyTypes, type)?.label || '';
    }, []);

    const {
        register,
        formState: { errors },
    } = useFormContext();

    return (
        <Card sx={{ marginBottom: '10px' }}>
            <CardHeader
                titleTypographyProps={{ variant: 'subtitle2' }}
                title={getRoomTitleBySaleItem(saleItemIndex, saleItem)}
                sx={{ paddingBottom: '0', marginBottom: '-10px' }}
            />
            <CardContent>
                <TableContainer sx={{ marginTop: '20px' }}>
                    <Table size="small" className="data-table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Tedarikçi</StyledTableCell>
                                <StyledTableCell>Ürün</StyledTableCell>
                                <StyledTableCell>Alış</StyledTableCell>
                                <StyledTableCell>İndirimli Alış</StyledTableCell>
                                <StyledTableCell>Pr.Br</StyledTableCell>
                                <StyledTableCell>Dvz.Kur</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Tooltip title={<p>{saleItemPurchase.contact.name}</p>}>
                                        <div
                                            style={{
                                                whiteSpace: 'nowrap',
                                                maxWidth: '300px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}>
                                            {saleItemPurchase.contact.name}
                                        </div>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>
                                    <Tooltip title={<p>{saleItemPurchase.productName}</p>}>
                                        <div
                                            style={{
                                                whiteSpace: 'nowrap',
                                                maxWidth: '300px',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}>
                                            {saleItemPurchase.productName}
                                        </div>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>{formatPrice(saleItemPurchase.purchasePrice)}</TableCell>
                                <TableCell sx={{ padding: 0 }}>
                                    <TextField
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        autoComplete="off"
                                        sx={{ margin: 0, backgroundColor: '#fae4bc' }}
                                        error={
                                            errors?.saleItem?.[saleItemIndex]?.saleItemPurchase
                                                ?.discountPurchasePrice
                                        }
                                        {...register(
                                            `saleItems.${saleItemIndex}.saleItemPurchase.discountPurchasePrice`,
                                            {
                                                required: true,
                                                valueAsNumber: true,
                                            },
                                        )}
                                    />
                                </TableCell>
                                <TableCell>
                                    {getCurrencyType(saleItemPurchase.currencyTypeId)}
                                </TableCell>
                                <TableCell>{saleItemPurchase.exchangeRate}</TableCell>
                            </TableRow>

                            {saleItem.additionals.map((additionalItem, additionalIndex) => (
                                <TableRow key={additionalIndex}>
                                    <TableCell>
                                        <Tooltip
                                            title={additionalItem.saleItemPurchase.contact.name}>
                                            <div
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}>
                                                {additionalItem.saleItemPurchase.contact.name}
                                            </div>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip
                                            title={additionalItem.saleItemPurchase.productName}>
                                            <div
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}>
                                                {additionalItem.saleItemPurchase.productName}
                                            </div>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        {formatPrice(additionalItem.saleItemPurchase.purchasePrice)}
                                    </TableCell>
                                    <TableCell sx={{ padding: 0 }}>
                                        <TextField
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth
                                            autoComplete="off"
                                            sx={{ margin: 0, backgroundColor: '#fae4bc' }}
                                            error={
                                                errors?.saleItem?.[saleItemIndex]?.additionals[
                                                    additionalIndex
                                                ]?.saleItemPurchase?.discountPurchasePrice
                                            }
                                            {...register(
                                                `saleItems.${saleItemIndex}.additionals.${additionalIndex}.saleItemPurchase.discountPurchasePrice`,
                                                {
                                                    required: true,
                                                    valueAsNumber: true,
                                                },
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {getCurrencyType(
                                            additionalItem.saleItemPurchase.currencyTypeId,
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {additionalItem.saleItemPurchase.exchangeRate}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
};

export default TourReservationEditPurchasePanelGroup;
