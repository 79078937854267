import React from 'react';
import {
    Card,
    CardHeader,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import useMessage from '../util/useMessage';
import { MessageType } from '../util/Enums';
import SearchCell from './datatable/SearchCell';
import LabelCell from './datatable/LabelCell';
import NoResultFound from './datatable/NoResultFound';
import TableLoading from './datatable/TableLoading';

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: '#f6f7f9',
        color: '#26415c',
        fontWeight: 'bold',
    },
}))(TableCell);

const useStyles = makeStyles(theme => ({
    card: {
        height: '100%',
    },
    title: {
        fontSize: '1.2rem',
    },
    content: {
        flex: '0.2 1 20%',
    },
    action: {
        flex: '1 1 auto',
    },
    disabled: {
        opacity: '0.5',
    },
}));

const DataTable = React.memo(
    ({
        title,
        data,
        loading,
        columns,
        count,
        page,
        setPage,
        orderBy,
        setOrderBy,
        max,
        setMax,
        searchData,
        setSearchData,
        onCheck,
        onEdit,
        onDelete,
        onManage,
        rowOnClick,
        action,
        selected,
        setSelected,
        onSelectItem,
        setLoading,
        selectAll,
        cardStyle,
        service,
        search,
        useRowIndexForKey,
        elevation = 1,
        link,
        headerNoWrap = false,
        buttons = false,
        multiSelect = true,
        disabledItems
    }) => {
        const { t } = useTranslation();

        const classes = useStyles();

        const isSearchable = columns.some(item => item.searchable);
        const showMessage = useMessage();

        const resolve = (item, column, rowIndex, colIndex) => {
            if (column.execute) {
                return column.execute(item[column.name], rowIndex, colIndex);
            }
            if (column.saleOfferFormId) {
                return item.saleOfferFormId ? <a href={"/tours/reservations/edit/" + item.saleOfferFormId} target="_blank">{item[column.name]}</a> : item[column.name];
            }
            return item[column.name];
        };

        const handleOrderBy = name => {
            setOrderBy({
                name: name,
                orderType: orderBy?.name === name && orderBy?.orderType === 'asc' ? 'desc' : 'asc',
            });
        };

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = event => {
            setMax(parseInt(event.target.value, 10));
            setPage(0);
        };

        const isSelected = item => {
            return selected?.some(some => some.id === item.id);
        };

        const isCheck = item => {
            return data?.some(some => some.id === item.id && some.isActive);
        };

        const onSelect = (item, e) => {
            if (e.target.checked) {
                if(multiSelect) {
                    setSelected && setSelected([...selected, item]);
                } else {
                    setSelected && setSelected([item]);
                }
            } else {
                if(multiSelect) {
                    const items = selected?.filter(i => i.id !== item.id);
                    setSelected && setSelected(items);
                } else {
                    setSelected && setSelected([]);
                }
            }
        };

        // silinmis kaydi geri al.
        const onRollback = async (item, index) => {
            try {
                setLoading(true);
                await service.rollback(item?.id || item?.saleCampaignId);
                await search();
                showMessage(t('message.updated'));
            } catch ({ code, message }) {
                showMessage(`${code} - ${message}`, MessageType.ERROR);
            } finally {
                setLoading(false);
            }
        };

        const EmptyHeadCell = () => (
            <TableCell
                style={{
                    backgroundColor: '#f9f6f3',
                    margin: 0,
                }}
            />
        );

        return (
            <Card style={cardStyle} elevation={elevation}>
                <CardHeader
                    title={title}
                    action={action}
                    classes={{
                        title: classes.title,
                        action: buttons ? classes.action : '',
                        content: buttons ? classes.content : '',
                    }}
                    {...(selected
                        ? { subheader: t('title.selected', { value: selected?.length || 0 }) }
                        : {})}
                />
                <TableContainer>
                    <Table
                        size="small"
                        className={`data-table ${headerNoWrap ? 'data-table-no-wrap' : ''}`}>
                        <TableHead>
                            {isSearchable && (
                                <TableRow>
                                    {setSelected && <EmptyHeadCell />}
                                    {columns.map((column, index) => {
                                        const SearchCellComponent = column.SearchCellComponent
                                            ? column.SearchCellComponent
                                            : SearchCell;
                                        return column.searchable ? (
                                            <SearchCellComponent
                                                key={index}
                                                placeholder={t('label.search')}
                                                column={column}
                                                defaultValue={
                                                    column.defaultValue !== undefined
                                                        ? column.defaultValue
                                                        : ''
                                                }
                                                onSearch={data => {
                                                    setSearchData({
                                                        ...searchData,
                                                        ...data,
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <EmptyHeadCell />
                                        );
                                    })}

                                    {onCheck && <EmptyHeadCell />}

                                    {onEdit && <EmptyHeadCell />}

                                    {onDelete && <EmptyHeadCell />}

                                    {onManage && <EmptyHeadCell />}
                                </TableRow>
                            )}

                            <TableRow>
                                {setSelected && (
                                    <StyledTableCell
                                        padding="checkbox"
                                        width={10}
                                        style={{ textAlign: 'center' }}>
                                        {selectAll && <Checkbox style={{ padding: 0 }} />}
                                    </StyledTableCell>
                                )}
                                {columns.map((column, index) => (
                                    <LabelCell
                                        key={index}
                                        column={column}
                                        orderBy={orderBy}
                                        hasOrderBy={setOrderBy}
                                        handleOrderBy={handleOrderBy}
                                        sortable={
                                            column.sortable !== undefined ? column.sortable : true
                                        }
                                    />
                                ))}
                                {onCheck && (
                                    <StyledTableCell
                                        padding="checkbox"
                                        width={10}
                                        style={{ textAlign: 'center' }}>
                                        {t('label.active')}
                                    </StyledTableCell>
                                )}
                                {onEdit && <StyledTableCell width={50} />}
                                {onDelete && <StyledTableCell width={50} />}
                                {onManage && <StyledTableCell width={50} />}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {loading && <TableLoading colSpan={columns.length} />}
                            {disabledItems?.map((item, rowIndex) => (
                                <TableRow
                                    hover
                                    key={useRowIndexForKey ? rowIndex : item.id}
                                    selected={isSelected(item)}
                                    sx={{ opacity: '0.5' }}>
                                    {setSelected && (
                                        <TableCell padding="checkbox"></TableCell>
                                    )}

                                    {columns.map((column, colIndex) => (
                                        <TableCell key={colIndex}>
                                            {resolve(item, column, rowIndex, colIndex)}
                                        </TableCell>
                                    ))}

                                    {onCheck && (
                                        <TableCell style={{ padding: 0, textAlign: 'center' }}></TableCell>
                                    )}

                                    {onEdit && (
                                        <TableCell style={{ padding: 0, textAlign: 'center' }}></TableCell>
                                    )}

                                    {onDelete && (
                                        <TableCell style={{ padding: 0, textAlign: 'center' }}></TableCell>
                                    )}

                                    {onManage && (
                                        <TableCell style={{ padding: 0, textAlign: 'center' }}></TableCell>
                                    )}
                                </TableRow>
                            ))}
                            {data?.filter(item => !disabledItems?.some(sel => sel.receiptId === item.id)).map((item, rowIndex) => (
                                <TableRow
                                    hover
                                    key={useRowIndexForKey ? rowIndex : item.id}
                                    selected={isSelected(item)}
                                    onClick={() => {
                                        rowOnClick && rowOnClick(item);
                                    }}>
                                    {setSelected && (
                                        <TableCell
                                            padding="checkbox"
                                            style={{ textAlign: 'center' }}>
                                            <Checkbox
                                                style={{ padding: 0 }}
                                                checked={isSelected(item)}
                                                onChange={e => {
                                                    onSelectItem
                                                        ? onSelectItem(item, e)
                                                        : onSelect(item, e);
                                                }}
                                            />
                                        </TableCell>
                                    )}

                                    {columns.map((column, colIndex) => (
                                        <TableCell key={colIndex}>
                                            {resolve(item, column, rowIndex, colIndex)}
                                        </TableCell>
                                    ))}

                                    {onCheck && (
                                        <TableCell style={{ padding: 0, textAlign: 'center' }}>
                                            <Checkbox
                                                style={{ padding: 0 }}
                                                defaultChecked={isCheck(item)}
                                                onChange={e => {
                                                    onCheck(item, rowIndex, e);
                                                }}
                                            />
                                        </TableCell>
                                    )}

                                    {onEdit && !link && (
                                        <TableCell style={{ padding: 0, textAlign: 'center' }}>
                                            <Button
                                                color="secondary"
                                                size="small"
                                                // style={{padding: 0}}
                                                onClick={e => {
                                                    onEdit(item, rowIndex, e);
                                                }}>
                                                {t('button.edit')}
                                            </Button>
                                        </TableCell>
                                    )}

                                    {onEdit && link && (
                                        <TableCell style={{ padding: 0, textAlign: 'center' }}>
                                            <a
                                                href={`${link}${item.id}`}
                                                className="table-cell-link"
                                                color="secondary"
                                                size="small"
                                                style={{ padding: 0 }}>
                                                {t('button.edit')}
                                            </a>
                                        </TableCell>
                                    )}

                                    {onDelete && (
                                        <TableCell style={{ padding: 0, textAlign: 'center' }}>
                                            {!item?.isDeleted && (
                                                <Button
                                                    color="secondary"
                                                    size="small"
                                                    style={{ padding: 0 }}
                                                    onClick={() => {
                                                        onDelete(item, rowIndex);
                                                    }}>
                                                    {t('button.delete')}
                                                </Button>
                                            )}

                                            {item.isDeleted && service && (
                                                <Button
                                                    color="primary"
                                                    size="small"
                                                    style={{ padding: 0 }}
                                                    onClick={async () => {
                                                        await onRollback(item, rowIndex);
                                                    }}>
                                                    {t('button.rollback')}
                                                </Button>
                                            )}
                                        </TableCell>
                                    )}

                                    {onManage && (
                                        <TableCell style={{ padding: 0, textAlign: 'center' }}>
                                            <Button
                                                color="secondary"
                                                size="small"
                                                style={{ padding: 0 }}
                                                onClick={e => {
                                                    onManage(item, rowIndex, e);
                                                }}>
                                                {t('button.manage')}
                                            </Button>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}

                            {!loading && count === 0 && (
                                <NoResultFound
                                    colSpan={columns.length}
                                    text={t('message.noResultFound')}
                                />
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {setPage && (
                    <TablePagination
                        rowsPerPageOptions={[10, 15, 25, 50]}
                        component="div"
                        count={count}
                        rowsPerPage={max}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                )}

                {!setPage && <div style={{ height: 10 }} />}
            </Card>
        );
    },
);

export { DataTable, StyledTableCell };
