import React, { useEffect, useState } from 'react';
import { Button, Card, CardActions, CardContent, CardHeader } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TourReservationEditPurchasePanelGroup from './TourReservationEditPurchasePanelGroup';
import { FormProvider, useForm } from 'react-hook-form';
import { MessageType } from '../../../util/Enums';
import useMessage from '../../../util/useMessage';
import saleService from '../../../service/SaleService';

const TourReservationEditPurchasePanel = ({ stepId, sale, toggle, onToggle, isExpanded, next }) => {
    const showMessage = useMessage();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const methods = useForm();

    useEffect(() => {
        const parents = sale.saleItems.filter(item => !item.saleItemReferenceGuid);
        parents?.forEach(parent => {
            parent['additionals'] = sale.saleItems.filter(
                item => item.saleItemReferenceGuid === parent.saleItemGuid,
            );
        });
        setData(parents);
        methods.reset({
            saleItems: parents,
        });
    }, [sale]);

    const onSubmit = data => {
        if (loading) return false;
        const saleItemPurchases = [];

        const saleId = sale.saleId;
        data.saleItems.forEach(saleItem => {
            const { saleItemPurchaseId, discountPurchasePrice, exchangeRate } =
                saleItem.saleItemPurchase;
            saleItemPurchases.push({
                saleItemId: saleItem.saleItemId,
                saleItemPurchaseId,
                discountPurchasePrice,
                exchangeRate,
            });
            saleItem.additionals.forEach(additionalSaleItem => {
                const { saleItemPurchaseId, discountPurchasePrice, exchangeRate } =
                    additionalSaleItem.saleItemPurchase;
                saleItemPurchases.push({
                    saleItemId: additionalSaleItem.saleItemId,
                    saleItemPurchaseId,
                    discountPurchasePrice,
                    exchangeRate,
                });
            });
        });

        setLoading(true);
        saleService
            .updateSaleItemPurchases({
                saleId,
                saleItemPurchases,
            })
            .then(() => {
                showMessage('Kaydedildi.');
                onToggle(stepId);
            })
            .catch(({ code, message }) => {
                showMessage(`${code} - ${message}`, MessageType.ERROR);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Card>
                    <CardHeader
                        title="Satınalma"
                        titleTypographyProps={{ variant: 'subtitle1' }}
                        action={
                            <>
                                {isExpanded(stepId) && (
                                    <Button color="secondary" type="submit">
                                        İLERLE
                                    </Button>
                                )}
                                <Button color="secondary" onClick={() => toggle(stepId)}>
                                    {isExpanded(stepId) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </Button>
                            </>
                        }
                    />

                    {isExpanded(stepId) && (
                        <CardContent>
                            {data.map((saleItem, saleItemIndex) => (
                                <TourReservationEditPurchasePanelGroup
                                    key={saleItemIndex}
                                    saleItem={saleItem}
                                    saleItemIndex={saleItemIndex}
                                />
                            ))}
                        </CardContent>
                    )}
                    {isExpanded(stepId) && (
                        <CardActions>
                            <Button type="submit" color="secondary" sx={{ marginLeft: 'auto' }}>
                                İLERLE
                            </Button>
                        </CardActions>
                    )}
                </Card>
            </form>
        </FormProvider>
    );
};

export default TourReservationEditPurchasePanel;
