import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Panel from '../../../ui/Panel';
import Input from '../../../ui/Input';
import Select from '../../../ui/Select';
import { PaymentTermDateTypes, PaymentTermRuleTypes } from '../../../util/Enums';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckBox from '../../../ui/CheckBox';

const VendorEditInputs = ({ vendor, name, contact = false }) => {
    const { t } = useTranslation();
    const [paymentTermText, setPaymentTermText] = useState(null);

    const DEFAULT_COMMISSION_RATE = 20;

    const {
        register,
        control,
        watch,
        setValue,
        formState: { errors },
    } = useFormContext();

    const watchPaymentTermDateType = watch('paymentTermDateType', vendor?.paymentTermDateType);
    const watchPaymentTermRuleType = watch('paymentTermRuleType');
    const watchPaymentTermValue = watch('paymentTermValue');

    const generatePaymentTermText = (dateType, ruleType, termValue) => {
        const dateTypeEnum = PaymentTermDateTypes.find(item => item.id === dateType);
        const ruleTypeEnum = PaymentTermRuleTypes.find(item => item.id === ruleType);
        if (dateTypeEnum && ruleTypeEnum) {
            return t('label.paymentTermCombinationText', {
                dateType: t(dateTypeEnum?.label),
                ruleType: t(ruleTypeEnum?.label),
                value: termValue,
            });
        }

        return null;
    };

    useEffect(() => {
        setValue('commissionCalculationType', 2);
    }, []);

    useEffect(() => {
        const text = generatePaymentTermText(
            watchPaymentTermDateType,
            watchPaymentTermRuleType,
            watchPaymentTermValue,
        );
        setPaymentTermText(text);
    }, [watchPaymentTermRuleType, watchPaymentTermDateType, watchPaymentTermValue]);

    useEffect(() => {
        if (vendor) {
            const text = generatePaymentTermText(
                vendor.paymentTermDateType,
                vendor.paymentTermRuleType,
                vendor.paymentTermValue,
            );
            setPaymentTermText(text);
        }
    }, [vendor]);

    const handleChange = event => {
        setValue('commissionCalculationType', event.target.value);
    };

    const handleGenderChange = event => {
        setValue('genderTypeId', event.target.value);
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                {contact ? 
                    <Panel title={t('title.contactInfo')}>
                        <Input
                            defaultValue={vendor?.name}
                            label={t('label.name')}
                            error={errors?.name}
                            {...register('name', {
                                required: t('validation.required'),
                            })}
                        />
                        <Input
                            defaultValue={vendor?.surname}
                            label={t('label.surname')}
                            error={errors?.surname}
                            {...register('surname', {
                                required: t('validation.required'),
                            })}
                        />
                        <Input
                            defaultValue={vendor?.identityNumber}
                            label={t('reservation.identityNumber')}
                            error={errors?.identityNumber}
                            {...register('identityNumber', {
                                required: t('validation.required'),
                            })}
                        />
                        <FormControl>
                            <RadioGroup
                                aria-label="genderTypeId"
                                defaultValue={vendor?.genderTypeId || 1}
                                onChange={handleGenderChange}
                                name="genderTypeId">
                                <FormControlLabel
                                    value={1}
                                    control={<Radio />}
                                    label="Erkek"
                                />
                                <FormControlLabel
                                    value={2}
                                    control={<Radio />}
                                    label="Kadın"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Panel> :
                    <Panel title={t('title.vendorInfos')}>
                        <Input
                            defaultValue={vendor?.title || name}
                            label={t('label.title')}
                            error={errors?.title}
                            {...register('title', {
                                required: t('validation.required'),
                            })}
                        />
                        <Input
                            defaultValue={vendor?.brandName}
                            label={t('label.brandName')}
                            error={errors?.brandName}
                            {...register('brandName', {
                                required: t('validation.required'),
                            })}
                        />
                        <Input
                            defaultValue={vendor?.taxNo}
                            label={t('label.taxNo')}
                            error={errors?.taxNo}
                            {...register('taxNo', {
                                required: t('validation.required'),
                            })}
                        />
                        <Input
                            defaultValue={vendor?.taxAdministration}
                            label={t('label.taxAdministration')}
                            error={errors?.taxAdministration}
                            {...register('taxAdministration', {
                                required: t('validation.required'),
                            })}
                        />
                    </Panel>
                }
            </Grid>
            <Grid item xs={12}>
                <Panel title={t('title.contactInfos')}>
                    <Input
                        defaultValue={vendor?.email}
                        label={t('label.email')}
                        error={errors?.email}
                        {...register('email', {
                            required: t('validation.required'),
                        })}
                    />
                    <Input
                        defaultValue={vendor?.telephone1}
                        label={t('label.telephone1')}
                        error={errors?.telephone1}
                        {...register('telephone1', {
                            required: t('validation.required'),
                        })}
                    />
                    <Input
                        defaultValue={vendor?.telephone2}
                        label={t('label.telephone2')}
                        error={errors?.telephone2}
                        {...register('telephone2')}
                    />
                    <Input
                        defaultValue={vendor?.addressLine1}
                        label={t('label.addressLine1')}
                        error={errors?.addressLine1}
                        {...register('addressLine1', {
                            required: t('validation.required'),
                        })}
                    />
                    <Input
                        defaultValue={vendor?.addressLine2}
                        label={t('label.addressLine2')}
                        error={errors?.addressLine2}
                        {...register('addressLine2')}
                    />
                </Panel>
            </Grid>
            {!contact && <Grid item xs={12}>
                <Panel title={t('title.paymentInfos')}>
                    <Input
                        defaultValue={vendor?.bankName}
                        label={t('label.bankName')}
                        error={errors?.bankName}
                        {...register('bankName', {
                            required: t('validation.required'),
                        })}
                    />
                    <Input
                        defaultValue={vendor?.bankIban}
                        label={t('label.bankIban')}
                        error={errors?.bankIban}
                        {...register('bankIban', {
                            required: t('validation.required'),
                        })}
                    />

                    <Select
                        name="paymentTermDateType"
                        rules={{ required: t('validation.required') }}
                        error={errors?.paymentTermDateType}
                        label={t('label.paymentTermDateType')}
                        defaultValue={vendor?.paymentTermDateType}
                        control={control}>
                        {PaymentTermDateTypes.map(item => {
                            return (
                                <MenuItem key={item.id} value={item.id}>
                                    {t(item.label)}
                                </MenuItem>
                            );
                        })}
                    </Select>

                    <Select
                        name="paymentTermRuleType"
                        rules={{ required: t('validation.required') }}
                        error={errors?.paymentTermRuleType}
                        label={t('label.paymentTermRuleType')}
                        defaultValue={vendor?.paymentTermRuleType}
                        control={control}>
                        {PaymentTermRuleTypes.map(item => {
                            return (
                                <MenuItem key={item.id} value={item.id}>
                                    {t(item.label)}
                                </MenuItem>
                            );
                        })}
                    </Select>

                    <Input
                        type="number"
                        defaultValue={vendor?.paymentTermValue}
                        label={t('label.paymentTermValue')}
                        error={errors?.paymentTermValue}
                        {...register('paymentTermValue', {
                            required: t('validation.required'),
                            pattern: {
                                value: /^\d+$/,
                                message: t('validation.invalidNumber'),
                            },
                        })}
                    />
                    <Input
                        type="commissionRate"
                        defaultValue={vendor?.commissionRate || DEFAULT_COMMISSION_RATE}
                        label={t('label.commissionRate')}
                        error={errors?.commissionRate}
                        {...register('commissionRate', {
                            required: t('validation.required'),
                        })}
                    />
                    <FormLabel id="commissionCalculationType">Cinsiyet</FormLabel>
                    <RadioGroup
                        row
                        aria-label="commissionCalculationType"
                        defaultValue={vendor?.commissionCalculationType || 2}
                        onChange={handleChange}
                        name="commissionCalculationType">
                        <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label="Üzerine Ekle Markupla"
                        />
                        <FormControlLabel
                            value={2}
                            control={<Radio />}
                            label="Komisyon Dahil Satış Fiyatı"
                        />
                    </RadioGroup>
                    <CheckBox
                        defaultChecked={(vendor?.singleRoomDifference !== undefined && vendor?.singleRoomDifference === false) ? false : true}
                        label="Single oda farkı komisyon dahil değil"
                        {...register('singleRoomDifference')}
                    />
                    <br />
                    <CheckBox
                        defaultChecked={vendor?.isIncludingCommissionExcludingTaxes || false}
                        label="Vergi Hariç Komisyon Dahil"
                        {...register('isIncludingCommissionExcludingTaxes')}
                    />
                    {paymentTermText && (
                        <div style={{ marginTop: 10 }}>
                            <strong>Vade:</strong> {paymentTermText}
                        </div>
                    )}
                </Panel>
            </Grid>}
        </Grid>
    );
};

export default VendorEditInputs;
