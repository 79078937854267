import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Input from '../ui/Input';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import TourCatalogInput from '../ui/TourCatalogInput';

const ExtraOpportunityEditDialog = ({ open, selectedIndex, items, onClose, onEditDone }) => {
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (open) {
            reset();
        }
    }, [open, selectedIndex, items]);

    const onSubmit = data => {
        if (onEditDone) {
            const obj = {
                id: items[selectedIndex]?.id,
                ...data,
            };
            onEditDone(obj, selectedIndex);
        }
    };

    return (
        <Dialog open={open} fullWidth maxWidth={'xs'} scroll="paper">
            <DialogTitle>{t('title.extraOpportunity')}</DialogTitle>
            <DialogContent dividers>
                <TourCatalogInput
                    name="catalog"
                    multiple={false}
                    control={control}
                    rules={{ required: t('validation.required') }}
                    error={errors?.catalog}
                    defaultValue={items[selectedIndex]?.catalog}
                    disabled={selectedIndex !== null}
                />
                <Input
                    defaultValue={items[selectedIndex]?.sequence}
                    label={t('label.sortOrder')}
                    error={errors?.sequence}
                    {...register('sequence', {
                        required: t('validation.required'),
                    })}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t('button.cancel')}
                </Button>
                <Button onClick={handleSubmit(onSubmit)} color="secondary">
                    {t('button.done')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExtraOpportunityEditDialog;
