import { TextField } from '@mui/material';
import React from 'react';

const Input = React.forwardRef(({ error, ...props }, ref) => {
    return (
        <>
            <TextField
                {...props}
                error={error && true}
                inputRef={ref}
                margin="dense"
                variant="outlined"
                fullWidth
                autoComplete="off"
                helperText={error?.message}
            />
        </>
    );
});

export default Input;
