import { useState } from 'react';

const useRequest = ({ method, onSuccess, onError }) => {
    const [loading, setLoading] = useState(false);

    const onRequest = async params => {
        try {
            setLoading(true);
            const result = await method(params);
            onSuccess && onSuccess(result);
        } catch (e) {
            onError && onError(e);
        } finally {
            setLoading(false);
        }
    };

    return { loading, onRequest };
};

export default useRequest;
