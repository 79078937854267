import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';

const TourPriceEditApplyGridPriceDialog = ({ open, onClose, onDone }) => {
    const { t } = useTranslation();

    const [value, setValue] = useState('thisPeriod');

    useEffect(() => {
        if (!open) {
            setValue('thisPeriod');
        }
    }, [open]);

    const handleChange = event => {
        setValue(event.target.value);
    };

    const onSubmit = () => {
        onDone && onDone(value);
    };

    return (
        <Dialog open={open} fullWidth maxWidth="sm" scroll="paper">
            <DialogTitle>{t('title.apply')}</DialogTitle>
            <DialogContent dividers={true}>
                <p>Bu peryodta yapılan değişikleri diğer peryodlara da uygulamak ister misiniz?</p>

                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="period-applyType"
                        value={value}
                        onChange={handleChange}
                        name="period-applyType-radio-buttons-group">
                        <FormControlLabel
                            value="thisPeriod"
                            control={<Radio />}
                            label="Sadece bu periyoda uygula"
                        />
                        <FormControlLabel
                            value="thisAndNextPeriods"
                            control={<Radio />}
                            label="Bu ve bundan sonraki peryodlara uygula"
                        />
                        <FormControlLabel
                            value="allPeriods"
                            control={<Radio />}
                            label="Tüm peryodlara uygula"
                        />
                        <FormControlLabel
                            value="rollback"
                            control={<Radio />}
                            label="Vazgeç (Düzenlemeyi geri al)"
                        />
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t('button.cancel')}
                </Button>

                <Button color="secondary" onClick={onSubmit}>
                    {t('button.done')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TourPriceEditApplyGridPriceDialog;
