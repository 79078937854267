import Page from '../../../layout/Page';
import { useTranslation } from 'react-i18next';
import usePagination from '../../../util/usePagination';
import purchaseService from '../../../service/PurchaseService';
import { DataTable } from '../../../components/DataTable';
import Container from '@mui/material/Container';
import ListPageActions from '../../../components/ListPageActions';
import { format } from 'date-fns';
import {
    CurrencyTypes,
    findEnumById,
    ProductGroupTypes,
    SaleItemPurchaseType,
} from '../../../util/Enums';
import { YesNoTypeOptions } from '../../../components/YesNoTypeOptions';
import MinMaxSearchCellForecast from '../../../components/datatable/MinMaxSearchCellForecast';
import DateFilterCell from '../../../components/datatable/DateFilterCell';
import { useState } from 'react';
import TourPurchaseListStatusUpdate from './TourPurchaseListStatusUpdate';
import { formatPrice } from '../../../util/CurrencyUtil';

const TourPurchaseListPage = ({ title, ...props }) => {
    const { t } = useTranslation();
    const [selected, setSelected] = useState([]);

    const SaleStatusOptions = () =>
        Object.values(SaleItemPurchaseType).map(item => (
            <option key={item.id} value={item.id}>
                {t(item.label)}
            </option>
        ));

    const CurrencyOptions = () =>
        Object.values(CurrencyTypes).map(item => (
            <option key={item.id} value={item.id}>
                {t(item.label)}
            </option>
        ));

    const ReferanceOptions = () =>
        Object.values(ProductGroupTypes).map(item => (
            <option key={item.id} value={item.id}>
                {t(item.label)}
            </option>
        ));

    const columns = [
        {
            name: 'saleCode',
            label: t('purchase.saleCode'),
            searchable: true,
            saleOfferFormId: true
        },
        {
            name: 'purchasePaymentStatusTypeId',
            label: t('purchase.purchasePaymentStatusTypeId'),
            type: Number,
            searchable: true,
            searchType: 'select',
            selectOptions: <SaleStatusOptions />,
            execute: item => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {findEnumById(SaleItemPurchaseType, item)?.label}
                </span>
            ),
        },
        {
            name: 'contactTitle',
            label: t('purchase.vendorName'),
            execute: item => (
                <span style={{ whiteSpace: 'nowrap' }} title={item}>
                    {item.split(' ')[0]}...
                </span>
            ),
            searchable: true,
        },
        {
            name: 'productGroupId',
            label: t('purchase.productGroupId'),
            type: Number,
            searchable: true,
            searchType: 'select',
            selectOptions: <ReferanceOptions />,
            execute: item => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {findEnumById(ProductGroupTypes, item)?.label}
                </span>
            ),
        },
        {
            name: 'productName',
            label: t('purchase.productName'),
            execute: item => (
                <div
                    style={{
                        whiteSpace: 'nowrap',
                        maxWidth: '300px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                    title={item}>
                    {item}
                </div>
            ),
            searchable: true,
        },
        {
            name: 'saleDate',
            label: t('purchase.saleDate'),
            execute: item => (item ? format(new Date(item), 'dd.MM.yyyy') : ''),
            searchable: true,
            SearchCellComponent: DateFilterCell,
        },
        {
            name: 'maturityDate',
            label: t('purchase.maturityDate'),
            execute: item => (item ? format(new Date(item), 'dd.MM.yyyy') : ''),
            searchable: true,
            SearchCellComponent: DateFilterCell,
        },
        {
            name: 'currencyTypeId',
            label: t('purchase.currencyTypeId'),
            searchable: true,
            searchType: 'select',
            selectOptions: <CurrencyOptions />,
            type: Number,
            execute: item => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {findEnumById(CurrencyTypes, item)?.label}
                </span>
            ),
        },
        {
            name: 'purchasePrice',
            label: t('purchase.purchasePrice'),
            searchable: true,
            type: Number,
            SearchCellComponent: MinMaxSearchCellForecast,
            execute: item => formatPrice(item),
        },
        {
            name: 'salePrice',
            label: t('purchase.salePrice'),
            searchable: true,
            type: Number,
            SearchCellComponent: MinMaxSearchCellForecast,
            execute: item => formatPrice(item),
        },
        {
            name: 'commissionRate',
            label: t('purchase.commissionRate'),
            searchable: true,
            type: Number,
            SearchCellComponent: MinMaxSearchCellForecast,
        },
        {
            name: 'checkInDate',
            label: t('purchase.checkInDate'),
            execute: item => (item ? format(new Date(item), 'dd.MM.yyyy') : ''),
            searchable: true,
            SearchCellComponent: DateFilterCell,
        },
        {
            name: 'checkOutDate',
            label: t('purchase.checkOutDate'),
            execute: item => (item ? format(new Date(item), 'dd.MM.yyyy') : ''),
            searchable: true,
            SearchCellComponent: DateFilterCell,
        },
        {
            name: 'exchangeBuyingRate',
            label: t('purchase.exchangeBuyingRate'),
            searchable: true,
            type: Number,
            SearchCellComponent: MinMaxSearchCellForecast,
            execute: item => formatPrice(item),
        },
        {
            name: 'exchangeBuyTL',
            label: t('purchase.exchangeBuyTL'),
            searchable: true,
            type: Number,
            SearchCellComponent: MinMaxSearchCellForecast,
            execute: item => formatPrice(item),
        },
        {
            name: 'IsFinanced',
            label: t('purchase.IsFinanced'),
            searchable: true,
            searchType: 'select',
            selectOptions: <YesNoTypeOptions />,
            execute: item => (item ? 'EVET' : 'HAYIR'),
            type: Boolean,
        },
    ];

    const purchases = usePagination({
        service: purchaseService,
        defaultOrderBy: { name: 'maturityDate', orderType: 'desc' },
    });

    const onSelectItem = (item, e) => {
        if (e.target.checked) {
            if (selected.length > 0) {
                if (selected[0].purchasePaymentStatusTypeId !== item.purchasePaymentStatusTypeId) {
                    alert('Sadece aynı statüde olan kayıtları seçebilirsiniz!');
                    return;
                }
            }
            setSelected && setSelected([...selected, item]);
        } else {
            const items = selected?.filter(i => i.id !== item.id);
            setSelected && setSelected(items);
        }
    };

    const onUpdateComplete = () => {
        setSelected([]);
        purchases.search();
    };

    const ActionButtons = () => (
        <ListPageActions
            left={
                <TourPurchaseListStatusUpdate
                    purchaseList={selected}
                    onUpdateComplete={onUpdateComplete}
                />
            }>
            <b>Statüler:</b>{' '}
            {Object.values(SaleItemPurchaseType)
                .map(item => item.label)
                .join(' -> ')}
        </ListPageActions>
    );

    return (
        <Page title={title}>
            <Container maxWidth={false} sx={{ maxWidth: '2190px' }}>
                <ActionButtons />
                <DataTable
                    {...purchases}
                    headerNoWrap
                    title={t('label.purchaseList')}
                    columns={columns}
                    data={purchases.data?.purchases?.map(item => ({
                        id: item.saleItemPurchasePaymentId,
                        ...item,
                    }))}
                    selected={selected}
                    setSelected={setSelected}
                    onSelectItem={onSelectItem}
                    action={
                        <table
                            className="summary-table"
                            cellSpacing={2}
                            cellPadding={2}
                            style={{ marginBottom: '24px' }}>
                            <thead>
                                <tr>
                                    <th>Birim</th>
                                    <th>Ort.Sat.</th>
                                    <th>Ort.Al.</th>
                                    <th>Ort.Kom.</th>
                                    <th>Top.Sat.</th>
                                    <th>Top.Al.</th>
                                </tr>
                            </thead>
                            <tbody>
                                {purchases.data?.summary &&
                                    Object.keys(purchases.data?.summary).map((key, index) => {
                                        const currency = purchases.data.summary[key];
                                        if (currency) {
                                            return (
                                                <tr key={index}>
                                                    <td>{key.toUpperCase()}</td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {currency['averageSalePrice']}
                                                    </td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {currency['averagePurchasePrice']}
                                                    </td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {currency['averageCommissionRate']}
                                                    </td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {currency['totalSalePrice']}
                                                    </td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {currency['totalPurchasePrice']}
                                                    </td>
                                                </tr>
                                            );
                                        }
                                        return null;
                                    })}
                            </tbody>
                        </table>
                    }
                />
            </Container>
        </Page>
    );
};

export default TourPurchaseListPage;
