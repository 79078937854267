import { useEffect, useState } from 'react';
import Page from '../../../layout/Page';
import { useHistory, useParams } from 'react-router-dom';
import tagService from '../../../service/TagService';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Panel from '../../../ui/Panel';
import Input from '../../../ui/Input';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import PageActions from '../../../components/PageActions';
import Select from '../../../ui/Select';
import MenuItem from '@mui/material/MenuItem';
import { useForm } from 'react-hook-form';
import { MessageType, TagType, DynamicPageType } from '../../../util/Enums';
import useMessage from '../../../util/useMessage';
import SubmitButton from '../../../ui/SubmitButton';
import CoverImageSelectionCard from '../../../components/CoverImageSelectionCard';

let MOUNT = true;

const TagEditPage = ({ title, ...props }) => {
    const param = useParams();
    const tagId = param?.id;

    const { t } = useTranslation();
    const history = useHistory();
    const showMessage = useMessage();

    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [tag, setTag] = useState(null);
    const [icon, setIcon] = useState(null);
    const [fileName, setFileName] = useState('');
    const [folderName] = useState('tag');

    const {
        register,
        handleSubmit,
        watch,
        control,
        formState: { errors },
    } = useForm();

    const watchFileName = watch('title');

    useEffect(() => {
        setFileName(watchFileName || '');
    }, [watchFileName]);

    useEffect(() => {
        if (tagId) {
            (async () => {
                setPageLoading(true);
                try {
                    const { resultObject } = await tagService.get(tagId);
                    initPageValues(resultObject);
                } catch (e) {}
                setPageLoading(false);
            })();
        } else {
            setPageLoading(false);
        }

        return () => {
            MOUNT = false;
        };
    }, [tagId]);

    const initPageValues = _tag => {
        setTag(_tag);
        setIcon(_tag?.icon);
        setFileName(_tag?.title || '');
    };

    const onSubmit = async data => {
        if (loading) return false;
        try {
            setLoading(true);
            const obj = {
                ...data,
                icon,
                tagType: parseInt(data.tagType),
                dynamicPageTypeId: parseInt(data.dynamicPageTypeId),
                volume: data?.volume ? parseInt(data.volume) : 0,
            };
            const { resultObject } = await tagService.saveOrUpdate(tagId, obj);
            showMessage(t(tagId ? 'message.updated' : 'message.created'));

            history.push(`/settings/tags/edit/${resultObject.tagId || resultObject.id}`);
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const ActionPanel = () => (
        <PageActions>
            <Button
                variant="contained"
                disabled={loading}
                startIcon={loading ? null : <KeyboardBackspaceIcon />}
                onClick={() => {
                    history.goBack();
                }}>
                {t('button.back')}
            </Button>

            <SubmitButton loading={loading} startIcon={<SaveIcon />}>
                {t('button.save')}
            </SubmitButton>
        </PageActions>
    );

    return (
        <Page title={title} loading={pageLoading}>
            <Container maxWidth="md">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ActionPanel />
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Panel title={t('title.tagInfos')}>
                                <Select
                                    name="tagType"
                                    rules={{ required: t('validation.required') }}
                                    error={errors?.tagType}
                                    label={t('label.tagType')}
                                    defaultValue={tag?.tagType}
                                    control={control}>
                                    {Object.values(TagType).map(item => {
                                        return (
                                            <MenuItem key={item.id} value={item.id}>
                                                {t(item.label)}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <Input
                                    defaultValue={tag?.name}
                                    label={t('label.name')}
                                    error={errors?.name}
                                    {...register('name', {
                                        required: t('validation.required'),
                                    })}
                                />
                                <Input
                                    name="title"
                                    label={t('label.visibleName')}
                                    defaultValue={tag?.title}
                                    error={errors?.title}
                                    {...register('title')}
                                />

                                <Input
                                    type="number"
                                    name="volume"
                                    defaultValue={tag?.volume}
                                    label={t('label.searchVolume')}
                                    error={errors?.volume}
                                    {...register('volume')}
                                />
                                <Select
                                    name="dynamicPageTypeId"
                                    error={errors?.dynamicPageTypeId}
                                    label={t('label.dynamicPageTypeId')}
                                    defaultValue={tag?.dynamicPageTypeId || ''}
                                    control={control}>
                                    <MenuItem value="">
                                        <em>{t('pageType.none')}</em>
                                    </MenuItem>
                                    {Object.values(DynamicPageType).map(item => {
                                        return (
                                            <MenuItem key={item.id} value={item.id}>
                                                {t(item.label)}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </Panel>
                        </Grid>

                        <Grid item xs={12}>
                            <CoverImageSelectionCard
                                title={t('title.image')}
                                subtitle={t('title.tagIcon')}
                                image={icon}
                                setImage={setIcon}
                                canBeCropped={false}
                                {...{ fileName, folderName }}
                            />
                        </Grid>
                    </Grid>
                    <ActionPanel />
                </form>
            </Container>
        </Page>
    );
};

export default TagEditPage;
