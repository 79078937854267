import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { authReducer } from './AuthReducer';
import { notificationReducer } from './NotificationReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    notification: notificationReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));

export default store;
