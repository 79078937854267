import React from 'react';
import { useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LangMenu from './LangMenu';

const useStyles = makeStyles(theme => ({
    title: {
        flexGrow: 1,
    },
    toolBar: {
        backgroundColor: '#ffffff',
        color: '#333',
        paddingLeft: 80,
    },
    avatar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
    },
    username: {
        marginLeft: 5,
    },
}));

const ToolBar = ({ title = '', showAvatar = true, right, ...props }) => {
    const { user } = useSelector(state => state.auth);

    const classes = useStyles();

    return (
        <AppBar position="fixed" elevation={0}>
            <Toolbar variant="regular" className={classes.toolBar}>
                <Typography variant="h6" className={classes.title}>
                    {title}
                </Typography>

                {right}

                <LangMenu />

                {showAvatar && (
                    <div className={classes.avatar}>
                        <AccountCircleIcon />
                        <span className={classes.username}>{`${user.name} ${user.surname}`}</span>
                    </div>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default ToolBar;
