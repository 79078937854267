import React, { useEffect, useState } from 'react';
import Page from '../../../layout/Page';
import { useHistory, useParams } from 'react-router-dom';
import extraTourService from '../../../service/ExtraTourService';
import Container from '@mui/material/Container';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import PageActions from '../../../components/PageActions';
import { FormProvider, useForm } from 'react-hook-form';
import useMessage from '../../../util/useMessage';
import SubmitButton from '../../../ui/SubmitButton';
import { MessageType, TermsOfParticipationTypes } from '../../../util/Enums';
import ExtraTourEditInputPanel from './ExtraTourEditInputPanel';

const ExtraTourEditPage = ({ title, ...props }) => {
    const param = useParams();
    const extraTourId = param?.id;

    const { t } = useTranslation();
    const history = useHistory();
    const showMessage = useMessage();

    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [shortDesc, setShortDesc] = useState('');
    const [image, setImage] = useState(null);
    const [fileNameParam, setFileNameParam] = useState('');
    const [folderName] = useState('extratour');
    const [extraTour, setExtraTour] = useState(null);
    const [participation, setParticipation] = useState();

    useEffect(() => {
        setImage(extraTour?.image);
        setFileNameParam(extraTour?.name || '');
        setShortDesc(extraTour?.shortDesc);
        methods.setValue('termOfParticipation', extraTour?.termOfParticipation);
    }, [extraTour]);

    const methods = useForm();

    useEffect(() => {
        if (extraTourId) {
            (async () => {
                setPageLoading(true);
                try {
                    const { resultObject } = await extraTourService.get(extraTourId);
                    setExtraTour(resultObject);
                } catch ({ code, message }) {
                    showMessage(message, MessageType.ERROR);
                }
                setPageLoading(false);
            })();
        } else {
            setPageLoading(false);
        }
        return () => {};
    }, []);

    const onSubmit = async data => {
        if (loading) return false;
        try {
            setLoading(true);

            const obj = {
                ...data,
                image,
                shortDesc: shortDesc,
            };
            delete obj['extraTourType'];
            if (participation === TermsOfParticipationTypes.NUMBER_OF_ADULT.id) {
                obj['childRules'] = [];
            }

            const { resultObject } = await extraTourService.saveOrUpdate(extraTourId, obj);
            showMessage(t(extraTourId ? 'message.updated' : 'message.created'));
            history.push(
                `/settings/extraTours/edit/${resultObject.extraTourId || resultObject.id}`,
            );
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const ActionPanel = () => (
        <PageActions>
            <Button
                variant="contained"
                disabled={loading}
                startIcon={loading ? null : <KeyboardBackspaceIcon />}
                onClick={() => {
                    history.goBack();
                }}>
                {t('button.back')}
            </Button>

            <SubmitButton loading={loading} startIcon={<SaveIcon />}>
                {t('button.save')}
            </SubmitButton>
        </PageActions>
    );

    return (
        <Page title={title} loading={pageLoading}>
            <Container maxWidth="sm">
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <ActionPanel />
                        <ExtraTourEditInputPanel
                            {...{
                                extraTour,
                                image,
                                setImage,
                                fileNameParam,
                                folderName,
                                shortDesc,
                                setShortDesc,
                                setParticipation,
                            }}
                        />
                        <ActionPanel />
                    </form>
                </FormProvider>
            </Container>
        </Page>
    );
};

export default ExtraTourEditPage;
