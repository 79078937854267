import BaseService from './BaseService';

class ReceiptService extends BaseService {
    constructor() {
        super('/finance/receipts');
    }
}

const receiptService = new ReceiptService();

export default receiptService;
