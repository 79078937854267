import React, { useEffect, useState } from 'react';
import Page from '../../../layout/Page';
import { useHistory, useParams } from 'react-router-dom';
import foodService from '../../../service/FoodService';
import Editor from '../../../ui/Editor';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Panel from '../../../ui/Panel';
import Input from '../../../ui/Input';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import PageActions from '../../../components/PageActions';
import { useForm } from 'react-hook-form';
import { DescType, MessageType, TagType } from '../../../util/Enums';
import useMessage from '../../../util/useMessage';
import SubmitButton from '../../../ui/SubmitButton';
import RegionInput from '../../../ui/RegionInput';
import CoverImageSelectionCard from '../../../components/CoverImageSelectionCard';
import TagInput from '../../../ui/TagInput';

let MOUNT = true;

const FoodEditPage = ({ title, ...props }) => {
    const param = useParams();
    const foodId = param?.id;

    const { t } = useTranslation();
    const history = useHistory();
    const showMessage = useMessage();

    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [food, setFood] = useState(null);
    const [image, setImage] = useState(null);
    const [fileName, setFileName] = useState('');
    const [folderName] = useState('food');
    const [descriptions, setDescriptions] = useState({});

    const {
        register,
        handleSubmit,
        watch,
        control,
        formState: { errors },
    } = useForm();

    const watchFileName = watch('name');

    const aspect = 720 / 480;

    useEffect(() => {
        setFileName(watchFileName || '');
    }, [watchFileName]);

    useEffect(() => {
        if (foodId) {
            (async () => {
                setPageLoading(true);
                try {
                    const { resultObject } = await foodService.get(foodId);
                    initPageValues(resultObject);
                } catch (e) {}
                setPageLoading(false);
            })();
        } else {
            setPageLoading(false);
        }

        return () => {
            MOUNT = false;
        };
    }, [foodId]);

    const initPageValues = _food => {
        setFood(_food);
        setImage(_food?.image);
        setFileName(_food?.name || '');
        const desc = {};
        Object.keys(DescType).forEach(item => (desc[item] = _food[item]));
        setDescriptions(desc);
    };

    const onSubmit = async data => {
        if (loading) return false;
        try {
            setLoading(true);
            const obj = {
                ...data,
                ...descriptions,
                image,
                volume: data?.volume ? parseInt(data.volume) : 0,
            };
            const { resultObject } = await foodService.saveOrUpdate(foodId, obj);
            showMessage(t(foodId ? 'message.updated' : 'message.created'));

            history.push(`/settings/foods/edit/${resultObject.foodId || resultObject.id}`);
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const ActionPanel = () => (
        <PageActions>
            <Button
                variant="contained"
                disabled={loading}
                startIcon={loading ? null : <KeyboardBackspaceIcon />}
                onClick={() => {
                    history.goBack();
                }}>
                {t('button.back')}
            </Button>

            <SubmitButton loading={loading} startIcon={<SaveIcon />}>
                {t('button.save')}
            </SubmitButton>
        </PageActions>
    );

    return (
        <Page title={title} loading={pageLoading}>
            <Container maxWidth="xl">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ActionPanel />
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={6}>
                            <Panel title={t('title.foodInfos')}>
                                <RegionInput
                                    name="region"
                                    multiple={false}
                                    control={control}
                                    error={errors?.region}
                                    defaultValue={food?.region}
                                    rules={{ required: t('validation.required') }}
                                />

                                <Input
                                    name="name"
                                    defaultValue={food?.name}
                                    label={t('label.name')}
                                    error={errors?.name}
                                    {...register('name', {
                                        required: t('validation.required'),
                                    })}
                                />

                                <TagInput
                                    name="tags"
                                    control={control}
                                    error={errors?.tags}
                                    defaultValue={food?.tags || []}
                                    tagType={TagType.FOOD.id}
                                />

                                <Input
                                    type="number"
                                    name="volume"
                                    defaultValue={food?.volume}
                                    label={t('label.searchVolume')}
                                    error={errors?.volume}
                                    {...register('volume')}
                                />
                            </Panel>
                        </Grid>

                        <Grid item xs={12} md={6} lg={6}>
                            <CoverImageSelectionCard
                                title={t('title.image')}
                                subtitle={t('title.foodImage')}
                                {...{ aspect, image, setImage, fileName, folderName }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Editor
                                title={t('label.description')}
                                value={descriptions[DescType.description]}
                                onChange={val => {
                                    setDescriptions({ ...descriptions, description: val });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Editor
                                title={t('label.microDesc')}
                                value={descriptions[DescType.microDesc]}
                                onChange={val => {
                                    setDescriptions({ ...descriptions, microDesc: val });
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Editor
                                title={t('label.shortDesc')}
                                value={descriptions[DescType.shortDesc]}
                                onChange={val => {
                                    setDescriptions({ ...descriptions, shortDesc: val });
                                }}
                            />
                        </Grid>
                    </Grid>
                    <ActionPanel />
                </form>
            </Container>
        </Page>
    );
};

export default FoodEditPage;
