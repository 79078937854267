import { useEffect, useState } from 'react';
import Page from '../../../layout/Page';
import { useHistory, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Panel from '../../../ui/Panel';
import Input from '../../../ui/Input';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import PageActions from '../../../components/PageActions';
import Select from '../../../ui/Select';
import MenuItem from '@mui/material/MenuItem';
import { useForm } from 'react-hook-form';
import { MessageType, ParameterTypes, YesNoType } from '../../../util/Enums';
import useMessage from '../../../util/useMessage';
import SubmitButton from '../../../ui/SubmitButton';
import parameterService from '../../../service/ParameterService';

let MOUNT = true;

const ParameterEditPage = ({ title, ...props }) => {
    const param = useParams();
    const parameterId = param?.id;

    const { t } = useTranslation();
    const history = useHistory();
    const showMessage = useMessage();

    const [parameter, setParameter] = useState(null);

    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const {
        register,
        handleSubmit,
        watch,
        control,
        formState: { errors },
    } = useForm();
    const watchType = watch('type');

    useEffect(() => {
        if (parameterId) {
            (async () => {
                setPageLoading(true);
                try {
                    const { resultObject } = await parameterService.get(parameterId);
                    initPageValues(resultObject);
                } catch (e) {}
                setPageLoading(false);
            })();
        } else {
            setPageLoading(false);
        }

        return () => {
            MOUNT = false;
        };
    }, []);

    const initPageValues = _parameter => {
        setParameter(_parameter);
    };

    const onSubmit = async data => {
        if (loading) return false;
        try {
            setLoading(true);

            console.log(data);

            const obj = {
                ...data,
                isDefault: data.isDefault === 1 ? true : false,
            };
            const { resultObject } = await parameterService.saveOrUpdate(parameterId, obj);
            showMessage(t(parameterId ? 'message.updated' : 'message.created'));

            history.push(
                `/settings/parameters/edit/${resultObject.parameterId || resultObject.id}`,
            );
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const ActionPanel = () => (
        <PageActions>
            <Button
                variant="contained"
                disabled={loading}
                startIcon={loading ? null : <KeyboardBackspaceIcon />}
                onClick={() => {
                    history.goBack();
                }}>
                {t('button.back')}
            </Button>

            <SubmitButton loading={loading} startIcon={<SaveIcon />}>
                {t('button.save')}
            </SubmitButton>
        </PageActions>
    );

    return (
        <Page title={title} loading={pageLoading}>
            <Container maxWidth="sm">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ActionPanel />

                    <Panel title={t('title.parameterInfo')}>
                        <Select
                            name="type"
                            defaultValue={parameter?.type}
                            label={t('label.generalParameterType')}
                            error={errors?.type}
                            control={control}
                            rules={{ required: t('validation.required') }}>
                            {Object.values(ParameterTypes).map(item => (
                                <MenuItem key={item.id} value={item.id}>
                                    {t(item.label)}
                                </MenuItem>
                            ))}
                        </Select>
                        <Input
                            name="name"
                            defaultValue={parameter?.name}
                            label={t('label.name')}
                            error={errors?.name}
                            {...register('name', {
                                required: t('validation.required'),
                            })}
                        />
                        <Input
                            name="value"
                            defaultValue={parameter?.value}
                            label={t('label.value')}
                            error={errors?.value}
                            {...register('value', {
                                required: t('validation.required'),
                            })}
                        />

                        {!(watchType === 1 || watchType === 2 || watchType === 3) && (
                            <Select
                                name="isDefault"
                                label={t('label.isDefault')}
                                defaultValue={parameter?.isDefault === true ? 1 : 0}
                                rules={{ required: t('validation.required') }}
                                error={errors?.isDefault}
                                control={control}>
                                {Object.values(YesNoType).map(item => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {t(item.label)}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    </Panel>

                    <ActionPanel />
                </form>
            </Container>
        </Page>
    );
};

export default ParameterEditPage;
