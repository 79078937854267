import BaseService from './BaseService';

class DistrictService extends BaseService {
    constructor() {
        super('/location/districts');
    }
}

const districtService = new DistrictService();

export default districtService;
