import React, { useEffect, useState } from 'react';
import Page from '../../layout/Page';
import { useHistory, useParams } from 'react-router-dom';
import roleService from '../../service/RoleService';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Panel from '../../ui/Panel';
import Input from '../../ui/Input';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import PageActions from '../../components/PageActions';
import { useForm } from 'react-hook-form';
import { MessageType, PrivilegeTypes } from '../../util/Enums';
import useMessage from '../../util/useMessage';
import SubmitButton from '../../ui/SubmitButton';
import { DataTable } from '../../components/DataTable';
import usePagination from '../../util/usePagination';
import privilegeService from '../../service/PrivilegeService';

let MOUNT = true;

const RoleEditPage = ({ title, ...props }) => {
    const param = useParams();
    const roleId = param?.id;

    const { t } = useTranslation();
    const history = useHistory();
    const showMessage = useMessage();

    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [role, setRole] = useState(null);
    const [selected, setSelected] = useState([]);

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        formState: { errors },
    } = useForm();

    const columns = [
        {
            name: 'group',
            label: t('label.group'),
            type: Number,
            width: 100,
            searchable: true,
            execute: item => t(PrivilegeTypes.find(p => p.id === item)?.label),
            searchType: 'select',
            selectOptions: PrivilegeTypes.map(item => (
                <option key={item.id} value={item.id}>
                    {t(item.label)}
                </option>
            )),
        },
        {
            name: 'name',
            label: t('label.name'),
            searchable: true,
        },
        {
            name: 'description',
            label: t('label.description'),
            searchable: true,
        },
    ];

    const privileges = usePagination({
        service: privilegeService,
    });

    useEffect(() => {
        if (roleId) {
            (async () => {
                setPageLoading(true);
                try {
                    const { resultObject } = await roleService.get(roleId);
                    initPageValues(resultObject);
                } catch (e) {}
                setPageLoading(false);
            })();
        } else {
            setPageLoading(false);
        }
        return () => {
            MOUNT = false;
        };
    }, [roleId]);

    const initPageValues = _role => {
        setRole(_role);
        setSelected(_role?.privileges || []);
    };

    const onSubmit = async data => {
        if (loading) return false;
        try {
            setLoading(true);
            const obj = {
                ...data,
                privileges: selected,
            };
            const { resultObject } = await roleService.saveOrUpdate(roleId, obj);
            showMessage(t(roleId ? 'message.updated' : 'message.created'));

            history.push(`/auths/roles/edit/${resultObject.roleId || resultObject.id}`);
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const ActionPanel = () => (
        <PageActions>
            <Button
                variant="contained"
                disabled={loading}
                startIcon={loading ? null : <KeyboardBackspaceIcon />}
                onClick={() => {
                    history.goBack();
                }}>
                {t('button.back')}
            </Button>
            <SubmitButton loading={loading} startIcon={<SaveIcon />}>
                {t('button.save')}
            </SubmitButton>
        </PageActions>
    );

    return (
        <Page title={title} loading={pageLoading}>
            <Container maxWidth="lg">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ActionPanel />
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Panel title={t('title.roleInfos')}>
                                <Input
                                    defaultValue={role?.name}
                                    label={t('label.name')}
                                    error={errors?.name}
                                    {...register('name', {
                                        required: t('validation.required'),
                                    })}
                                />
                            </Panel>
                        </Grid>

                        <Grid item xs={12}>
                            <DataTable
                                title={t('title.privileges')}
                                columns={columns}
                                {...privileges}
                                {...{ selected, setSelected }}
                            />
                        </Grid>
                    </Grid>
                    <ActionPanel />
                </form>
            </Container>
        </Page>
    );
};

export default RoleEditPage;
