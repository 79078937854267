import BaseService from './BaseService';

class CityService extends BaseService {
    constructor() {
        super('/location/cities');
    }
}

const cityService = new CityService();

export default cityService;
