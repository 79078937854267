import BaseService from './BaseService';

class CountryService extends BaseService {
    constructor() {
        super('/location/countries');
    }
}

const countryService = new CountryService();

export default countryService;
