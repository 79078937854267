import withStyles from '@mui/styles/withStyles';
import InputBase from '@mui/material/InputBase';

const SearchInput = withStyles(theme => ({
    root: {
        width: '100%',
    },
    input: {
        width: '100%',
    },
}))(InputBase);

export default SearchInput;
