import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import vendorService from '../service/VendorService';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import RenderTags from './RenderTags';
import VendorCreateDialog from '../components/VendorCreateDialog';
import Button from '@mui/material/Button';

const VendorInput = ({
    name,
    label,
    defaultValue,
    addNewCallback,
    rules,
    error,
    multiple = true,
    disabled,
    level = 0,
    control,
}) => {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const [openVendorCreateDialog, setOpenVendorCreateDialog] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        let active = true;
        setLoading(true);
        (async () => {
            try {
                const { resultObject } = await vendorService.search({
                    start: 0,
                    data: { contactTypeId: 3, brandName: inputValue },
                    max: 50,
                });
                const result = resultObject.map(item => ({
                    id: item.id,
                    title: item.title,
                    brandName: item.brandName,
                }));
                if (active) {
                    setOptions(result || []);
                    setLoading(false);
                }
            } catch (e) {}
        })();
        return () => {
            active = false;
        };
    }, [inputValue, level]);

    const handleChange = event => {
        setInputValue(event.target.value);
    };

    return (
        <>
            <Controller
                render={({ field }) => (
                    <Autocomplete
                        {...field}
                        multiple={multiple}
                        getOptionLabel={vendor => vendor.brandName || ''}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        options={options}
                        filterOptions={x => x}
                        loading={loading}
                        disabled={disabled}
                        renderTags={RenderTags}
                        noOptionsText={
                            <Button
                                variant={'contained'}
                                color="primary"
                                size={'small'}
                                onClick={() => {
                                    setOpenVendorCreateDialog(true);
                                }}>
                                {t('button.addNewVendor')}
                            </Button>
                        }
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={label || (multiple ? t('label.vendors') : t('label.vendor'))}
                                error={error && true}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                autoComplete="off"
                                helperText={error?.message}
                                multiline={multiple}
                                onChange={handleChange}
                            />
                        )}
                        onChange={(_, data) => field.onChange(data)}
                    />
                )}
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={rules}
            />

            <VendorCreateDialog
                inputValue={inputValue}
                open={openVendorCreateDialog}
                onClose={() => setOpenVendorCreateDialog(false)}
                onAddCallback={data => {
                    addNewCallback && addNewCallback(data);
                    setOpenVendorCreateDialog(false);
                }}
            />
        </>
    );
};

export default VendorInput;
