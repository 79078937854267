import BaseService from './BaseService';

class FoodService extends BaseService {
    constructor() {
        super('/common/foods');
    }
}

const foodService = new FoodService();

export default foodService;
