import BaseService from './BaseService';
import { easyPost } from '../util/Fetch';

class RegionService extends BaseService {
    constructor() {
        super('/common/regions');
    }

    async getImages(data) {
        return await easyPost('/common/regions/search/images', data);
    }
}

const regionService = new RegionService();

export default regionService;
