import Page from '../../../layout/Page';
import { useTranslation } from 'react-i18next';
import usePagination from '../../../util/usePagination';
import { DataTable } from '../../../components/DataTable';
import Container from '@mui/material/Container';
import ListPageActions from '../../../components/ListPageActions';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import visaTypeService from '../../../service/VisaTypeService';
import VisaTypeDeleteDialog from './VisaTypeDeleteDialog';
import { useState } from 'react';
import { getDeletedColumn } from '../../../components/YesNoTypeOptions';

const VisaTypeListPage = ({ title, ...props }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [selected, setSelected] = useState(null);
    const [openVisaTypeDeleteDialog, setOpenVisaTypeDeleteDialog] = useState(false);
    const columns = [
        {
            name: 'id',
            label: 'ID',
            width: 10,
            searchable: true,
            type: Number,
        },
        {
            name: 'name',
            label: t('label.name'),
            searchable: true,
        },
        {
            name: 'description',
            label: t('label.description'),
            searchable: true,
            execute: item =>
                item && (
                    <div
                        className="list-inner-wrap"
                        dangerouslySetInnerHTML={{
                            __html: item.length > 30 ? item.substring(0, 30) + '...' : item,
                        }}
                    />
                ),
        },
        getDeletedColumn(t),
    ];

    const visaTypes = usePagination({
        service: visaTypeService,
        defaultOrderBy: { name: 'id', orderType: 'desc' },
    });

    const ActionButtons = () => (
        <ListPageActions>
            <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={() => {
                    history.push(`/settings/visaTypes/create`);
                }}>
                {t('button.addNew')}
            </Button>
        </ListPageActions>
    );

    return (
        <Page title={title}>
            <Container maxWidth="xl">
                <ActionButtons />
                <DataTable
                    title={t('label.visaTypes')}
                    columns={columns}
                    {...visaTypes}
                    onEdit={item => {
                        history.push(`/settings/visaTypes/edit/${item.id}`);
                    }}
                    onDelete={item => {
                        setSelected(item);
                        setOpenVisaTypeDeleteDialog(true);
                    }}
                />
                <ActionButtons />
            </Container>

            <VisaTypeDeleteDialog
                visaType={selected}
                open={openVisaTypeDeleteDialog}
                onClose={() => {
                    setOpenVisaTypeDeleteDialog(false);
                    setSelected(null);
                }}
                onDoneCallback={visaTypes.search}
            />
        </Page>
    );
};

export default VisaTypeListPage;
