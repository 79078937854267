import BaseService from './BaseService';

class VendorService extends BaseService {
    constructor() {
        super('/common/contacts');
    }
}

const vendorService = new VendorService();

export default vendorService;
