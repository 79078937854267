import Cropper from 'react-easy-crop';
import React, { useCallback, useState } from 'react';
import cropImage from '../util/ImageUtil';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';

const ImageEditorPanel = ({ images, canBeCropped, setImages, aspect }) => {
    const { t } = useTranslation();

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);

    const [selected, setSelected] = useState(0);

    const onCropComplete = useCallback(
        async (croppedArea, croppedAreaPixels) => {
            if (images[selected]) {
                const temp = [...images];
                temp[selected].cropData = croppedAreaPixels;
                temp[selected].cropImage = await cropImage(temp[selected].url, croppedAreaPixels);
                setImages(temp);
            }
        },
        [images, selected],
    );

    const onSelect = async index => {
        setSelected(index);
        setCrop({ x: 0, y: 0 });
        setZoom(1);
    };

    return (
        <>
            {images?.map((item, index) => {
                if (index === selected) {
                    return (
                        <>
                            {canBeCropped && (
                                <div key={index} style={{ height: 300, position: 'relative' }}>
                                    <Cropper
                                        image={item.url}
                                        aspect={aspect}
                                        crop={crop}
                                        zoom={zoom}
                                        initialCroppedAreaPixels={item.cropData}
                                        onCropChange={setCrop}
                                        onCropComplete={onCropComplete}
                                        onZoomChange={setZoom}
                                    />
                                </div>
                            )}

                            {!canBeCropped && (
                                <div style={{ textAlign: 'center' }}>
                                    <img
                                        key={index}
                                        src={item.url}
                                        style={{ width: '100%', height: '100%', maxWidth: 200 }}
                                        alt=""
                                    />
                                </div>
                            )}
                        </>
                    );
                }
                return <React.Fragment key={index} />;
            })}

            {images[selected] && (
                <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    autoComplete="off"
                    label={t('label.description')}
                    value={images[selected]?.desc || ''}
                    onChange={e => {
                        const items = [...images];
                        items[selected].desc = e.target.value;
                        setImages(items);
                    }}
                />
            )}

            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 10 }}>
                {images?.map((item, index) => (
                    <img
                        key={index}
                        src={item.cropImage?.url || item.url}
                        style={{
                            width: 75,
                            height: 75,
                            margin: 4,
                            cursor: 'pointer',
                            padding: 2,
                            border: item.cropImage ? '2px solid #a0db1f' : '2px solid #ff6c00',
                        }}
                        onClick={async () => {
                            await onSelect(index);
                        }}
                        alt=""
                    />
                ))}
            </div>
        </>
    );
};

export default ImageEditorPanel;
