import React, { useState, useEffect } from 'react';
import ImageUploadDialog from './ImageUploadDialog';
import RegionImageUploadDialog from './RegionImageUploadDialog';
import { useTranslation } from 'react-i18next';
import Panel from '../ui/Panel';
import Button from '@mui/material/Button';
import Image from './Image';
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import regionService from '../service/RegionService';

let MOUNT = true;

const ImageGalleryCard = ({
    aspect,
    images,
    setImages,
    fileName,
    folderName,
    isCover = false,
    isType = false,
    regionIds,
    accommodations = [],
}) => {
    const { t } = useTranslation();
    const [openImageUploadDialog, setOpenImageUploadDialog] = useState(false);
    const [openRegionImageUploadDialog, setOpenRegionImageUploadDialog] = useState(false);
    const [regionLoading, setRegionLoading] = useState(false);
    const [regionImages, setRegionImages] = useState([]);

    const onImage = async () => {
        if (regionLoading) return false;
        try {
            const { resultObject } = await regionService.getImages({ regionIds });
            if (resultObject) {
                setRegionImages(resultObject);
                setOpenRegionImageUploadDialog(true);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setRegionLoading(false);
        }
    };

    const onOpenImageUploadDialog = () => {
        setOpenImageUploadDialog(true);
    };

    const onImageSelectDone = items => {
        let customImages = [];
        if (images?.length > 0) {
            customImages = images;
        }
        if (isType === true) {
            items.forEach(item => {
                customImages.push({ ...item, imageRegionName: 'Katalog' });
            });
        } else {
            items.forEach(item => {
                customImages.push(item);
            });
        }
        setImages(customImages);
    };

    const onRemoveImage = path => {
        const items = images?.filter((item, idx) => path !== item.path);
        setImages(items);
    };

    return (
        <>
            <Panel
                title={t('title.imageGallery')}
                action={
                    <>
                        {regionIds?.length > 0 && (
                            <Button color="secondary" onClick={onImage}>
                                {t('button.locationSelect')}
                            </Button>
                        )}
                        <Button color="secondary" onClick={onOpenImageUploadDialog}>
                            {t('button.addImage')}
                        </Button>
                    </>
                }>
                <Grid container spacing={1}>
                    {isType === true &&
                        images?.length > 0 &&
                        Object.entries(
                            images.reduce((prev, curr) => {
                                prev[curr.imageRegionName]
                                    ? prev[curr.imageRegionName].push(curr)
                                    : (prev[curr.imageRegionName] = [curr]);
                                return prev;
                            }, {}),
                        ).map(([key, value]) => {
                            return (
                                <>
                                    {
                                        <Grid item xs={12}>
                                            <p>
                                                <strong>{key}</strong>
                                                {key !== 'Katalog' &&
                                                    (accommodations.some(acc => acc.name === key)
                                                        ? ' (Kalınacak)'
                                                        : ' (Gidilecek)')}
                                            </p>
                                        </Grid>
                                    }
                                    {value.map((item, index) => {
                                        const itemIndex = images.findIndex(
                                            el => el.path === item.path,
                                        );
                                        return (
                                            <Grid key={index} item xs={6} md={4} lg={2}>
                                                <div style={{ textAlign: 'center' }}>
                                                    <Image
                                                        src={item.path}
                                                        style={{ width: '100%' }}
                                                    />
                                                    <div>
                                                        <TextField
                                                            fullWidth
                                                            margin="dense"
                                                            variant="outlined"
                                                            autoComplete="off"
                                                            label={t('label.description')}
                                                            value={item.desc}
                                                            onChange={e => {
                                                                const items = [...images];
                                                                items[itemIndex].desc =
                                                                    e.target.value;
                                                                setImages(items);
                                                            }}
                                                        />
                                                    </div>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    disabled={
                                                                        images.some(
                                                                            img =>
                                                                                img.imageTypeId ===
                                                                                    1 &&
                                                                                img.path !==
                                                                                    item.path,
                                                                        ) ||
                                                                        item.imageTypeId === 2 ||
                                                                        (images.some(
                                                                            img =>
                                                                                img.imageTypeId ===
                                                                                1,
                                                                        ) &&
                                                                            item.imageTypeId ===
                                                                                undefined)
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    checked={
                                                                        images[itemIndex]
                                                                            .imageTypeId !==
                                                                            undefined &&
                                                                        images[itemIndex]
                                                                            .imageTypeId === 1
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={e => {
                                                                        const items = [...images];
                                                                        if (e.target.checked) {
                                                                            items[
                                                                                itemIndex
                                                                            ].imageTypeId = 1;
                                                                        } else {
                                                                            delete items[itemIndex]
                                                                                .imageTypeId;
                                                                        }
                                                                        setImages(items);
                                                                    }}
                                                                    value={item.id}
                                                                    size="small"
                                                                    sx={{
                                                                        padding: '0px 4px 0px 10px',
                                                                        fontSize: '12px',
                                                                    }}
                                                                />
                                                            }
                                                            label="Kapak"
                                                        />
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    disabled={
                                                                        item.imageTypeId === 1 ||
                                                                        (images.filter(
                                                                            img =>
                                                                                img.imageTypeId ===
                                                                                2,
                                                                        )?.length > 3 &&
                                                                            images.some(
                                                                                img =>
                                                                                    img.path ===
                                                                                        item.path &&
                                                                                    img.imageTypeId !==
                                                                                        2,
                                                                            ))
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    checked={
                                                                        images[itemIndex]
                                                                            .imageTypeId !==
                                                                            undefined &&
                                                                        images[itemIndex]
                                                                            .imageTypeId === 2
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={e => {
                                                                        const items = [...images];

                                                                        if (e.target.checked) {
                                                                            items[
                                                                                itemIndex
                                                                            ].imageTypeId = 2;
                                                                        } else {
                                                                            delete items[itemIndex]
                                                                                .imageTypeId;
                                                                        }
                                                                        setImages(items);
                                                                    }}
                                                                    value={item.id}
                                                                    size="small"
                                                                    sx={{
                                                                        padding: '0px 4px 0px 10px',
                                                                        fontSize: '12px',
                                                                    }}
                                                                />
                                                            }
                                                            label="Detay"
                                                        />
                                                    </FormGroup>
                                                    <div>
                                                        <Button
                                                            color="secondary"
                                                            size="small"
                                                            variant="contained"
                                                            onClick={() => onRemoveImage(item.path)}
                                                            startIcon={<DeleteIcon />}>
                                                            {t('button.delete')}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Grid>
                                        );
                                    })}
                                </>
                            );
                        })}
                    {isType === false &&
                        images?.map((item, index) => (
                            <Grid key={index} item xs={6} md={4} lg={2}>
                                <div style={{ textAlign: 'center' }}>
                                    <Image src={item.path} style={{ width: '100%' }} />
                                    <div>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            variant="outlined"
                                            autoComplete="off"
                                            label={t('label.description')}
                                            value={item.desc}
                                            onChange={e => {
                                                const items = [...images];
                                                items[index].desc = e.target.value;
                                                setImages(items);
                                            }}
                                        />
                                    </div>
                                    {isCover && (
                                        <FormControlLabel
                                            label={t('label.coverPhoto')}
                                            control={
                                                <Checkbox
                                                    disabled={
                                                        images.some(
                                                            img =>
                                                                img.imageTypeId !== undefined &&
                                                                img.imageTypeId === 1 &&
                                                                img.id !== item.id,
                                                        ) ||
                                                        (images.some(
                                                            img =>
                                                                img.imageTypeId !== undefined &&
                                                                img.imageTypeId === 1,
                                                        ) &&
                                                            (item.imageTypeId === undefined ||
                                                                item.imageTypeId !== 1))
                                                            ? true
                                                            : false
                                                    }
                                                    defaultChecked={
                                                        item?.imageTypeId && item?.imageTypeId === 1
                                                            ? true
                                                            : (item?.imageTypeId !== undefined &&
                                                              item?.imageTypeId === 1
                                                                  ? true
                                                                  : false) || false
                                                    }
                                                    onChange={e => {
                                                        const items = [...images];
                                                        if (e.target.checked) {
                                                            items[index].imageTypeId = 1;
                                                        } else {
                                                            delete items[index].imageTypeId;
                                                        }
                                                        setImages(items);
                                                    }}
                                                    value={item.id}
                                                    size="small"
                                                    sx={{
                                                        padding: '0px 4px 0px 10px',
                                                        fontSize: '12px',
                                                    }}
                                                />
                                            }
                                        />
                                    )}
                                    <div>
                                        <Button
                                            color="secondary"
                                            size="small"
                                            variant="contained"
                                            onClick={() => onRemoveImage(item.path)}
                                            startIcon={<DeleteIcon />}>
                                            {t('button.delete')}
                                        </Button>
                                    </div>
                                </div>
                            </Grid>
                        ))}
                </Grid>

                {!images?.length && (
                    <div style={{ textAlign: 'center', padding: 16 }}>
                        {t('message.noResultFound')}
                    </div>
                )}
            </Panel>
            <ImageUploadDialog
                multiple={true}
                aspect={aspect}
                open={openImageUploadDialog}
                onUploadDone={onImageSelectDone}
                onClose={() => setOpenImageUploadDialog(false)}
                fileName={fileName}
                folderName={folderName}
                savedImages={images}
            />
            <RegionImageUploadDialog
                open={openRegionImageUploadDialog}
                onUploadDone={items => {
                    const selectImages = items.filter(
                        item => !images?.some(some => some.path === item.path),
                    );
                    if (images?.length > 0) {
                        setImages([...images, ...selectImages]);
                    } else {
                        setImages([...selectImages]);
                    }
                    setOpenRegionImageUploadDialog(false);
                }}
                onClose={() => setOpenRegionImageUploadDialog(false)}
                savedImages={images}
                regionImages={regionImages}
                loading={regionLoading}
            />
        </>
    );
};

export default React.memo(ImageGalleryCard);
