import BaseService from './BaseService';
import { easyPost } from '../util/Fetch';

class TourCatalogService extends BaseService {
    constructor() {
        super('/tours/catalogs');
    }

    async generateContent(data) {
        const { resultObject } = await easyPost(`${this.path}/generateContent`, data);
        return resultObject?.tourCatalogGenerateContentResult;
    }

    async programsAddCatalog({ data }) {
        return await easyPost(`/tours/catalogs/addTourProgramToTourCatalog`, data);
    }
}

const tourCatalogService = new TourCatalogService();

export default tourCatalogService;
