import React, { useState } from 'react';
import { TableCell } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchSelect from './SearchSelect';
import SearchInput from './SearchInput';

const SearchCell = React.memo(({ column, defaultValue = '', placeholder, onSearch }) => {
    const [value, setValue] = useState(defaultValue);
    const [process, setProcess] = useState(null);

    const handleOnChange = e => {
        setValue(e.target.value);
    };

    const handleOnSelect = e => {
        setValue(e.target.value);
        setSearchData(e.target.value);
    };

    const clear = () => {
        setValue('');
        if (onSearch) {
            setSearchData('');
        }
    };

    const onKeyPress = e => {
        if (e.charCode === 13 && onSearch) {
            setSearchData(value);
        }
    };

    const setSearchData = val => {
        if (process) {
            clearTimeout(process);
        }

        const search = setTimeout(() => {
            const obj = {};
            const key = column.searchField || column.name;

            if (val === '') {
                obj[key] = null;
            } else if (column.type) {
                if (column.type === Boolean) {
                    obj[key] = Boolean(Number(val));
                } else if (column.type === Date) {
                    obj[key] = val;
                } else {
                    obj[key] = column.type(val);
                }
            } else {
                obj[key] = val;
            }
            onSearch(obj);
        }, 250);

        setProcess(search);
    };

    const getInputType = () => {
        if (column.type === Number) {
            return 'number';
        } else if (column.type === Date) {
            return 'date';
        } else {
            return 'text';
        }
    };

    return (
        <TableCell
            style={{
                backgroundColor: '#f9f6f3',
                margin: 0,
                padding: '2px 2px 0px 8px',
            }}>
            {column?.searchType === 'select' ? (
                <SearchSelect disableUnderline value={value} onChange={handleOnSelect}>
                    <option value="">Tümü</option>
                    {column.selectOptions}
                </SearchSelect>
            ) : (
                <SearchInput
                    value={value}
                    onChange={handleOnChange}
                    onKeyPress={onKeyPress}
                    autoComplete="off"
                    placeholder={`${placeholder}...`}
                    type={getInputType()}
                    startAdornment={<SearchIcon fontSize="small" style={{ marginRight: 5 }} />}
                    endAdornment={
                        value?.length === 0 ? undefined : (
                            <HighlightOffIcon
                                fontSize="small"
                                style={{ cursor: 'pointer' }}
                                onClick={clear}
                            />
                        )
                    }
                />
            )}
        </TableCell>
    );
});

export default SearchCell;
