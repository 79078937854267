import React from 'react';
import Loading from './Loading';

const PageLoading = React.memo(() => {
    return (
        <div className="page-loading">
            <Loading />
        </div>
    );
});

export default PageLoading;
