import React from 'react';
import Config from '../Config';

const Image = ({ src, width = 500, height = 500, ...props }) => {
    var _src = '';
    if (import.meta.env.REACT_APP_ENVIRONMENT === 'production') {
        _src = `https://cdn.ytur.net/fit-in/${width}x${height}/filters:quality(100)/filters:format(webp)/${src}`;
    } else {
        _src = `https://testcdn.ytur.net/fit-in/${width}x${height}/filters:quality(100)/filters:format(webp)/${src}`;
    }

    return <img {...props} src={`${Config.CDN_URL}/${src}`} alt="" />;
};

export default Image;
