import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import DoneButton from '../../../ui/DoneButton';
import Grid from '@mui/material/Grid';
import Input from '../../../ui/Input';
import { DataTable } from '../../../components/DataTable';
import CheckBox from '../../../ui/CheckBox';
import { useHistory } from 'react-router-dom';

const ForecastAddDialog = ({ offerId, open, onClose, onAddAction, selected, loading }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        control,
        formState: { errors },
        reset,
    } = useForm();
    const watchId = watch('saleOfferFormId');

    useEffect(() => {
        if (!watchId && offerId) {
            setValue('saleOfferFormId', offerId);
        }
    }, []);

    useEffect(() => {
        if (!open) {
            reset();
        }
    }, [open, reset]);

    const onSubmit = async data => {
        const obj = {
            saleOfferFormId: parseInt(data.saleOfferFormId),
            tourPrograms: selected,
        };
        onAddAction && onAddAction(obj);
    };

    const columns = [
        {
            name: 'tourProgramId',
            label: 'ID',
            width: 75,
            searchable: false,
            type: Number,
        },
        {
            name: 'operationTitle',
            label: t('forecast.operationTitle'),
            searchable: false,
        },
        {
            name: 'title',
            label: t('forecast.title'),
            searchable: false,
        },
        {
            name: 'price',
            label: t('forecast.price'),
            searchable: false,
            execute: item => item && item + ' TL',
        },
    ];

    return (
        <Dialog open={open} fullWidth maxWidth="md" scroll="paper">
            <DialogTitle>{t('forecast.addProgramToOffer')}</DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <DataTable
                            title={t('forecast.selectedPrograms')}
                            data={selected}
                            columns={columns}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            type="number"
                            name="saleOfferFormId"
                            label="Teklif No"
                            defaultValue={offerId}
                            control={control}
                            {...register('saleOfferFormId', {
                                required: t('validation.required'),
                            })}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" disabled={loading}>
                    {t('button.cancel')}
                </Button>
                <DoneButton onClick={handleSubmit(onSubmit)} loading={loading}>
                    {t('button.add')}
                </DoneButton>
                {/* {watchId && 
                    <DoneButton
                        onClick={() => {
                            handleSubmit(onSubmit);
                            history.push(`/tours/reservations/edit/${watchId}`);
                        }}
                        loading={loading}>
                        EKLE VE TEKLİFE GİT
                    </DoneButton>
                } */}
            </DialogActions>
        </Dialog>
    );
};

export default ForecastAddDialog;
