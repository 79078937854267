import Panel from '../../../ui/Panel';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Input from '../../../ui/Input';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import Grid from '@mui/material/Grid';
import vendorService from '../../../service/VendorService';
import Loading from '../../../components/Loading';

export const UNIT_PRICE_FIELDS = [
    { name: 'perPersonPrice', label: 'twoPersonRoomPrice', required: true },
    { name: 'singleRoomPrice', required: true },
    { name: 'additionalPrice', required: true },
    { name: 'maxPax', required: true },
    { name: 'commissionRate', required: true },
    { name: 'defaultDiscountRate', required: true },
    { name: 'defaultQuota', required: true },
];

const TourPriceEditHotelUnitPricePanel = React.forwardRef(({ hotel, vendor }, ref) => {
    const { t } = useTranslation();
    const {
        register,
        trigger,
        getValues,
        setValue,
        formState: { errors, isValid },
    } = useForm({ mode: 'onChange' });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        UNIT_PRICE_FIELDS.forEach(field => {
            setValue(
                field.name,
                hotel[field.name] === 0 ? 0 : hotel[field.name] ? hotel[field.name] : 0,
            );
        });
    }, [hotel]);

    useEffect(() => {
        if (!hotel.commissionRate) {
            (async () => {
                try {
                    setLoading(true);
                    const { resultObject } = await vendorService.get(vendor.id);
                    setValue('commissionRate', resultObject?.commissionRate);
                } catch ({ code, message }) {
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [hotel, vendor]);

    useImperativeHandle(ref, () => ({
        isValid: async () => {
            await trigger();
            return isValid;
        },
        getData: async () => {
            return getValues();
        },
    }));

    const getInputRules = name => {
        switch (name) {
            case 'maxPax':
                return {
                    max: {
                        value: 3,
                        message: t('validation.max', { val: 3 }),
                    },
                    min: {
                        value: 1,
                        message: t('validation.min', { val: 1 }),
                    },
                };
            // case "commissionRate":
            //     return {
            //         min: {
            //             value: 15,
            //             message: t('validation.min', {val: 15})
            //         }
            //     }
            default:
                return {};
        }
    };

    return (
        <Panel title={t('title.defaultValues')}>
            <Grid container spacing={1}>
                {loading && <Loading />}
                {UNIT_PRICE_FIELDS.map((field, index) => (
                    <Grid key={index} item md={6}>
                        <Input
                            label={t(`label.${field.label || field.name}`)}
                            error={errors && errors[field.name]}
                            type="number"
                            {...register(`${field.name}`, {
                                setValueAs: v => {
                                    if (typeof v === 'string') {
                                        return parseFloat(v.replace(/,/, '.'));
                                    } else {
                                        return v;
                                    }
                                },
                                required: field.required ? t('validation.required') : false,
                                ...getInputRules(field.name),
                            })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onWheel={() => document.activeElement.blur()}
                        />
                    </Grid>
                ))}
            </Grid>
        </Panel>
    );
});

export default TourPriceEditHotelUnitPricePanel;
