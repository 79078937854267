import Page from '../../layout/Page';

import { useTranslation } from 'react-i18next';
import usePagination from '../../util/usePagination';
import userService from '../../service/UserService';
import { DataTable } from '../../components/DataTable';
import Container from '@mui/material/Container';
import ListPageActions from '../../components/ListPageActions';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import UserDeleteDialog from './UserDeleteDialog';
import { getDeletedColumn } from '../../components/YesNoTypeOptions';

const UserListPage = ({ title, ...props }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [selected, setSelected] = useState(null);
    const [openUserDeleteDialog, setOpenUserDeleteDialog] = useState(false);

    const columns = [
        {
            name: 'id',
            label: 'ID',
            width: 75,
            searchable: true,
            type: Number,
        },
        {
            name: 'name',
            label: t('label.name'),
            searchable: true,
        },
        {
            name: 'surname',
            label: t('label.surname'),
            searchable: true,
        },
        {
            name: 'email',
            label: t('label.email'),
            searchable: true,
        },
        {
            name: 'phone',
            label: t('label.phone'),
            searchable: true,
        },
        {
            name: 'roles',
            label: t('label.roles'),
            searchable: true,
            searchField: 'roleName',
            execute: roles => roles?.map(item => item.name).join(', '),
        },
        { ...getDeletedColumn(t) },
    ];

    const users = usePagination({
        service: userService,
    });

    const ActionButtons = () => (
        <ListPageActions>
            <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={() => {
                    history.push(`/auths/users/create`);
                }}>
                {t('button.addNew')}
            </Button>
        </ListPageActions>
    );

    return (
        <Page title={title}>
            <Container maxWidth="xl">
                <ActionButtons />
                <DataTable
                    title={t('label.userList')}
                    columns={columns}
                    {...users}
                    onEdit={item => {
                        history.push(`/auths/users/edit/${item.id}`);
                    }}
                    onDelete={item => {
                        setSelected(item);
                        setOpenUserDeleteDialog(true);
                    }}
                />
                <ActionButtons />
            </Container>

            <UserDeleteDialog
                user={selected}
                open={openUserDeleteDialog}
                onClose={() => {
                    setOpenUserDeleteDialog(false);
                    setSelected(null);
                }}
                onDoneCallback={users.search}
            />
        </Page>
    );
};

export default UserListPage;
