import React from 'react';
import NetworkService from './util/NetworkService';
import store from './store/Store';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import MainRouter from './MainRouter';
import './styles/App.css';
import './styles/SideMenu.css';
import 'react-day-picker/lib/style.css';

NetworkService.setupInterceptors(store);

function App() {
    const { t } = useTranslation();
    const snackbarRef = React.createRef();

    const onClickDismiss = key => () => {
        snackbarRef.current.closeSnackbar(key);
    };

    return (
        <Provider store={store}>
            <SnackbarProvider
                ref={snackbarRef}
                maxSnack={3}
                action={key => (
                    <Button size="small" style={{ color: '#fff' }} onClick={onClickDismiss(key)}>
                        {t('button.dismiss')}
                    </Button>
                )}>
                <Router>
                    <MainRouter />
                </Router>
            </SnackbarProvider>
        </Provider>
    );
}

export default App;
