import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import usePagination from '../../../util/usePagination';
import vendorService from '../../../service/VendorService';
import ListPageActions from '../../../components/ListPageActions';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Page from '../../../layout/Page';
import Container from '@mui/material/Container';
import { DataTable } from '../../../components/DataTable';
import VendorDeleteDialog from '../vendors/VendorDeleteDialog';
import { useState } from 'react';
import { getDeletedColumn } from '../../../components/YesNoTypeOptions';

const VendorListPage = ({ title, ...props }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [selected, setSelected] = useState(null);
    const [openVendorDeleteDialog, setOpenVendorDeleteDialog] = useState(false);

    const columns = [
        {
            name: 'id',
            label: 'ID',
            searchable: true,
            width: 100,
            type: Number,
        },
        {
            name: 'title',
            label: t('label.name'),
            searchable: true,
        },
        {
            name: 'brandName',
            label: t('label.brandName'),
            searchable: true,
        },
        {
            name: 'taxNo',
            label: t('label.taxNo'),
            searchable: true,
        },
        {
            name: 'email',
            label: t('label.email'),
            searchable: true,
        },
        {
            name: 'telephone1',
            label: t('label.telephone1'),
            searchable: true,
        },
        {
            name: 'commissionRate',
            label: t('label.commissionRate'),
        },
        {
            name: 'paymentTerm',
            label: t('label.paymentTerm'),
        },
        { ...getDeletedColumn(t) },
    ];

    const vendors = usePagination({
        service: vendorService,
        defaultSearchData: {
            contactTypeId: 3,
        }
    });

    const ActionButtons = () => (
        <ListPageActions>
            <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={() => {
                    history.push(`/settings/vendors/create`);
                }}>
                {t('button.addNew')}
            </Button>
        </ListPageActions>
    );

    return (
        <Page title={title}>
            <Container maxWidth="xl">
                <ActionButtons />
                <DataTable
                    title={t('label.vendorList')}
                    columns={columns}
                    {...vendors}
                    onEdit={item => {
                        history.push(`/settings/vendors/edit/${item.id}`);
                    }}
                    onDelete={item => {
                        setSelected(item);
                        setOpenVendorDeleteDialog(true);
                    }}
                />
                <ActionButtons />
            </Container>

            <VendorDeleteDialog
                vendor={selected}
                open={openVendorDeleteDialog}
                onClose={() => {
                    setOpenVendorDeleteDialog(false);
                    setSelected(null);
                }}
                onDoneCallback={vendors.search}
            />
        </Page>
    );
};

export default VendorListPage;
