import React, { useEffect, useState } from 'react';
import { MessageType, ProductGroupTypes } from '../../../util/Enums';
import saleService from '../../../service/SaleService';
import { Button, Card, CardActions, CardContent, CardHeader, Grid } from '@mui/material';
import Input from '../../../ui/Input';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useMessage from '../../../util/useMessage';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TourReservationEditGuestEditPanel from './TourReservationEditGuestEditPanel';

const getPersonCount = saleItemGuests => {
    if (!saleItemGuests?.length) {
        return '';
    }
    let saleItemGuestsResult = saleItemGuests.reduce((obj, itm) => {
        obj.guestTypeId = itm.guestTypeId;
        obj[itm.guestTypeId] = obj[itm.guestTypeId] + 1 || 1;
        return obj;
    }, {});
    return `(${saleItemGuestsResult['1']} Yetişkin${saleItemGuestsResult['2'] !== undefined && saleItemGuestsResult['2'] !== 0 ? ', ' + saleItemGuestsResult['2'] + ' Çocuk' : ''}${saleItemGuestsResult['3'] !== undefined && saleItemGuestsResult['3'] !== 0 ? ', ' + saleItemGuestsResult['3'] + ' Bebek' : ''})`;
};

const getCardTitleOfSaleItem = (saleItem, saleItemIndex) => {
    const groupType = Object.values(ProductGroupTypes).find(
        item => item.id === saleItem.productGroupId,
    );
    return `${saleItemIndex + 1}. ${groupType?.overrideLabel || groupType?.label} ${getPersonCount(saleItem.saleItemGuests)}`;
};

const SalesItemPanel = ({ saleItem, saleItemIndex }) => {
    const [expanded, setExpanded] = useState(true);

    return (
        <Card key={saleItemIndex} style={{ marginBottom: 16 }}>
            <CardHeader
                title={getCardTitleOfSaleItem(saleItem, saleItemIndex)}
                titleTypographyProps={{ variant: 'subtitle1' }}
                style={{
                    borderBottom: expanded ? '3px solid #ccc' : 0,
                    backgroundColor: '#fffbe0',
                }}
                action={
                    <>
                        <Button
                            color="secondary"
                            onClick={() => setExpanded(prevState => !prevState)}>
                            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </Button>
                    </>
                }
            />

            {expanded && (
                <CardContent style={{ padding: 0 }}>
                    {saleItem.saleItemGuests?.map((saleItemGuest, saleItemGuestIndex) => {
                        return (
                            <div
                                key={saleItemGuestIndex}
                                style={{
                                    marginBottom: 12,
                                    borderBottom: '2px solid #ccc',
                                    padding: 12,
                                }}>
                                <TourReservationEditGuestEditPanel
                                    saleItem={saleItem}
                                    saleItemIndex={saleItemIndex}
                                    saleItemGuest={saleItemGuest}
                                    formName="saleItemGuests"
                                    saleItemGuestIndex={saleItemGuestIndex}
                                />
                            </div>
                        );
                    })}

                    {saleItem.additionalGuests?.map((saleItemGuest, saleItemGuestIndex) => {
                        return (
                            <div
                                key={saleItemGuestIndex}
                                style={{
                                    marginBottom: 12,
                                    borderBottom: '2px solid #ccc',
                                    padding: 12,
                                }}>
                                <TourReservationEditGuestEditPanel
                                    saleItem={saleItemGuest.saleItem}
                                    saleItemIndex={saleItemIndex}
                                    saleItemGuest={saleItemGuest}
                                    formName="additionalGuests"
                                    normalGuests={saleItem.saleItemGuests}
                                    saleItemGuestIndex={saleItemGuestIndex}
                                />
                            </div>
                        );
                    })}

                    {saleItem.additionalListFullMessage?.map((item, index) => (
                        <div key={index} style={{ padding: 12 }}>
                            {item}
                        </div>
                    ))}
                </CardContent>
            )}
        </Card>
    );
};

const TourReservationEditPageInfoPanel = ({
    stepId,
    sale,
    saleOfferFormId,
    toggle,
    isExpanded,
    onToggle,
}) => {
    const { t } = useTranslation();
    const showMessage = useMessage();
    const methods = useForm();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState([]);

    useEffect(() => {
        if (sale && Array.isArray(sale.saleItems)) {
            const { saleItems } = sale;
            const _data = saleItems.filter(item => !item.saleItemReferenceGuid) || [];

            _data.forEach(saleItem => {
                const additionalList =
                    saleItems.filter(item => saleItem.saleItemGuid === item.saleItemReferenceGuid) ||
                    [];

                const additionalMap = Map.groupBy(additionalList, item => item.productGroupId);

                const additionalListFullMessage = [];

                for (const [key, value] of additionalMap) {
                    if (Array.isArray(value)) {
                        const isEmpty = value.every(item => !item.saleItemGuests?.length);
                        if (isEmpty) {
                            const groupType = Object.values(ProductGroupTypes).find(
                                item => item.id === parseInt(key),
                            );
                            additionalListFullMessage.push(
                                `Not: Tüm misafirler için ${groupType?.overrideLabel || groupType?.label} seçildi!`,
                            );
                        }
                    }
                }

                const additionalSaleItemGuests = [];
                additionalList.forEach(item => {
                    item.saleItemGuests.forEach(guest => {
                        additionalSaleItemGuests.push({
                            ...guest,
                            saleItem: item,
                        });
                    });
                });

                saleItem['additionalGuests'] = additionalSaleItemGuests;
                saleItem['additionalListFullMessage'] = additionalListFullMessage;
            });

            methods.reset({
                vendorSaleCode: sale.vendorSaleCode,
                saleItems: _data,
            });

            setData(_data);
        } else {
            setData([]);
        }
    }, [sale]);

    const onSubmit = async data => {
        if (loading) return false;
        try {
            setLoading(true);

            const saleItems = [];

            data.saleItems.forEach(saleItem => {
                saleItems.push({
                    saleItemId: saleItem.saleItemId,
                    saleItemGuests: [...saleItem.saleItemGuests],
                });

                const additionalGuestsMap = Map.groupBy(
                    saleItem.additionalGuests,
                    item => item.saleItemId,
                );

                for (const [key, value] of additionalGuestsMap) {
                    if (Array.isArray(value)) {
                        saleItems.push({
                            saleItemId: parseInt(key),
                            saleItemGuests: [...value],
                        });
                    }
                }
            });

            const requestData = {
                vendorSaleCode: data.vendorSaleCode,
                saleItems: saleItems,
            };

            const { isSuccess, resultObject } = await saleService.updateSale({
                saleOfferFormId: sale.saleId,
                data: requestData,
            });
            if (isSuccess) {
                showMessage(t('message.updated'));
                onToggle(stepId);
            }
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    return (
        <FormProvider {...methods}>
            <form id="requestForm" onSubmit={methods.handleSubmit(onSubmit)}>
                <Card>
                    <CardHeader
                        title="Rezervasyon Bilgileri"
                        titleTypographyProps={{ variant: 'subtitle1' }}
                        action={
                            <>
                                {isExpanded(stepId) && (
                                    <Button color="secondary" type="submit">
                                        KAYDET
                                    </Button>
                                )}
                                <Button color="secondary" onClick={() => toggle(stepId)}>
                                    {isExpanded(stepId) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </Button>
                            </>
                        }
                    />
                    {isExpanded(stepId) && (
                        <CardContent>
                            <Grid container spacing={1} style={{ marginBottom: 12 }}>
                                <Grid item xs={3}>
                                    <Input
                                        // type="number"
                                        label="Tedarikçi Kodu"
                                        {...methods.register(`vendorSaleCode`)}
                                    />
                                </Grid>
                            </Grid>

                            {data.map((saleItem, saleItemIndex) => {
                                return (
                                    <SalesItemPanel
                                        key={saleItemIndex}
                                        saleItem={saleItem}
                                        saleItemIndex={saleItemIndex}
                                    />
                                );
                            })}
                        </CardContent>
                    )}
                    {isExpanded(stepId) && (
                        <CardActions>
                            <Button color="secondary" sx={{ marginLeft: 'auto' }} type="submit">
                                KAYDET
                            </Button>
                        </CardActions>
                    )}
                </Card>
            </form>
        </FormProvider>
    );
};

export default TourReservationEditPageInfoPanel;
