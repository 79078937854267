import axios from 'axios';
import Config from '../Config';

class FileUploadService {
    upload(file, filename, sessionId, fileName, folderName, onUploadProgress) {
        const formData = new FormData();
        formData.append('file', file, filename);
        formData.append('fileName', fileName);
        formData.append('folderName', folderName);
        //formData.append("sessionId", sessionId);

        return axios.post(`${Config.API_URL}/media/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress,
        });
    }
}

const fileUploadService = new FileUploadService();
export default fileUploadService;
