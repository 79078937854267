import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import SubmitButton from '../../ui/SubmitButton';
import { MessageType } from '../../util/Enums';
import useMessage from '../../util/useMessage';
import commentService from '../../service/CommentService';

const CommentDeleteDialog = ({ comment = null, open, onClose, onDoneCallback }) => {
    const { t } = useTranslation();
    const showMessage = useMessage();

    const [loading, setLoading] = useState(false);
    const { handleSubmit, reset } = useForm();

    useEffect(() => {
        if (open) {
            reset();
            setLoading(false);
        }
    }, [open, reset]);

    const onSubmit = async data => {
        if (loading) return false;
        try {
            setLoading(true);
            await commentService.delete(comment?.commentId, { ...data, isDeleted: true });
            showMessage(t('message.deleted'));
            onClose && onClose();
            onDoneCallback && onDoneCallback();
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} fullWidth maxWidth="sm" scroll="paper">
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>{t('title.deleteCommentDialog')}</DialogTitle>
                <DialogContent dividers={true}>
                    <div style={{ padding: '6px 0' }}>
                        {t('message.areYouSureDelete', { value: comment?.commentTitle || '' })}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary" disabled={loading}>
                        {t('button.cancel')}
                    </Button>
                    <SubmitButton loading={loading} startIcon={<DeleteIcon />}>
                        {t('button.delete')}
                    </SubmitButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default React.memo(CommentDeleteDialog);
