import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import SubmitButton from '../ui/SubmitButton';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from '../components/DataTable';
import usePagination from '../util/usePagination';
import receiptService from '../service/ReceiptService';
import { findEnumById, PaymentType, BankTypes, CurrencyTypes, PosWayTypes } from '../util/Enums';
import MinMaxSearchCellForecast from '../components/datatable/MinMaxSearchCellForecast';
import DateFilterCell from '../components/datatable/DateFilterCell';
import { format } from 'date-fns';
import { formatPrice } from '../util/CurrencyUtil';

const ReceiptAddDialog = ({ open, onClose, onAddCallback, contactId, disabledItems }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState([]);
    useEffect(() => {
        if (open) {
            setSelected([]);
        }
    }, [open]);
    const PaymentTypeOptions = () =>
        Object.values(PaymentType).map(item => (
            <option key={item.id} value={item.id}>
                {t(item.label)}
            </option>
        ));

    const BankTypesOptions = () =>
        Object.values(BankTypes).map(item => (
            <option key={item.id} value={item.id}>
                {t(item.label)}
            </option>
        ));
    
    const CurrencyTypesOptions = () =>
        Object.values(CurrencyTypes).map(item => (
            <option key={item.id} value={item.id}>
                {t(item.label)}
            </option>
        ));

    const PosWayTypesOptions = () =>
        Object.values(PosWayTypes).map(item => (
            <option key={item.id} value={item.id}>
                {t(item.label)}
            </option>
        ));
    const columns = [
        {
            name: 'id',
            label: 'ID',
            width: 75,
            searchable: true,
            type: Number,
        },
        {
            name: 'receiptCode',
            label: "Dekont No.",
            searchable: true,
        },
        {
            name: 'provisionCode',
            label: t('label.provisionNo'),
            searchable: true,
        },
        {
            name: 'receiptDate',
            label: "Provizyon Tarihi",
            execute: item => (item ? format(new Date(item), 'dd.MM.yyyy') : ''),
            searchable: true,
            SearchCellComponent: DateFilterCell,
        },
        {
            name: 'maturityDate',
            label: "Vade Tarihi",
            execute: item => (item ? format(new Date(item), 'dd.MM.yyyy') : ''),
            searchable: true,
            SearchCellComponent: DateFilterCell,
        },
        {
            name: 'paymentTypeId',
            label: "Ödeme Tipi",
            execute: item => t(findEnumById(PaymentType, item)?.label),
            width: 125,
            type: Number,
            searchable: true,
            sortable: false,
            searchType: 'select',
            selectOptions: <PaymentTypeOptions />,
        },
        {
            name: 'posWayTypeId',
            label: "Ödeme Yolu",
            execute: item => t(findEnumById(PosWayTypes, item)?.label),
            width: 125,
            type: Number,
            searchable: true,
            sortable: false,
            searchType: 'select',
            selectOptions: <PosWayTypesOptions />,
        },
        {
            name: 'bankId',
            label: t('label.bankType'),
            execute: item => t(findEnumById(BankTypes, item)?.label),
            width: 125,
            type: Number,
            searchable: true,
            sortable: false,
            searchType: 'select',
            selectOptions: <BankTypesOptions />,
        },
        {
            name: 'contactName',
            label: "Ad Soyad",
            searchable: true,
        },
        {
            name: 'total',
            label: t('label.total'),
            searchable: true,
            type: Number,
            SearchCellComponent: MinMaxSearchCellForecast,
            execute: item => formatPrice(item),
        },
        {
            name: 'currencyTypeId',
            label: t('label.currencyType'),
            execute: item => t(findEnumById(CurrencyTypes, item)?.label),
            width: 125,
            type: Number,
            searchable: true,
            sortable: false,
            searchType: 'select',
            selectOptions: <CurrencyTypesOptions />,
        }
    ];
    const receipts = usePagination({
        service: receiptService,
        defaultOrderBy: { name: 'id', orderType: 'desc' },
        defaultSearchData: {
            contactId,
            isAvailable: true
        },
    });
    return (
        <>
            <Dialog open={open} fullWidth maxWidth="lg" scroll="paper">
                <DialogTitle>{t('title.chooseReceiptDialog')}</DialogTitle>
                <DialogContent dividers={true}>
                    <DataTable
                        title={t('label.receiptList')}
                        columns={columns}
                        {...receipts}
                        {...{ selected, setSelected, disabledItems}}
                        multiSelect={false}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary" disabled={loading}>
                        {t('button.cancel')}
                    </Button>
                    <SubmitButton
                        type="button"
                        loading={loading}
                        startIcon={<AddIcon />}
                        onClick={() => {
                            if(onAddCallback) {
                                if(selected.length > 0) {
                                    onAddCallback(selected[0]);
                                } else {
                                    onAddCallback(null);
                                }
                            }
                        }}>
                        {t('button.add')}
                    </SubmitButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default ReceiptAddDialog;
