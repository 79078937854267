import BaseService from './BaseService';
import { easyPost } from '../util/Fetch';

class TourSearchService extends BaseService {
    constructor() {
        super('/tours/forecast');
    }

    async generateOffer(data) {
        return await easyPost(`/offer/offerForm`, data);
    }

    async addOffer(data) {
        return await easyPost(`/offer/addTour`, data);
    }

    async share({ data }) {
        return await easyPost(`/tours/forecast/share`, data);
    }
}

const tourSearchService = new TourSearchService();

export default tourSearchService;
