import Page from '../../layout/Page';

import { useTranslation } from 'react-i18next';
import usePagination from '../../util/usePagination';
import pageService from '../../service/PageService';
import { DataTable } from '../../components/DataTable';
import Container from '@mui/material/Container';
import ListPageActions from '../../components/ListPageActions';
import { useHistory, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import PageDeleteDialog from './PageDeleteDialog';
import { findEnumById, PageStatus, PageType } from '../../util/Enums';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { getDeletedColumn } from '../../components/YesNoTypeOptions';
import PreviewButton from '../../components/PreviewButton';

const PageListPage = ({ title, ...props }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const pageType = PageType[location.pathname.split('/')[2]];

    const [selected, setSelected] = useState(null);
    const [openPageDeleteDialog, setOpenPageDeleteDialog] = useState(false);

    const PageTypeOptions = () =>
        Object.values(PageType)
            .filter(item => item.autogen)
            .map(item => (
                <option key={item.id} value={item.id}>
                    {t(item.label)}
                </option>
            ));

    const PageStatusOptions = () =>
        Object.values(PageStatus).map(item => (
            <option key={item.id} value={item.id}>
                {t(item.label)}
            </option>
        ));

    const columns = [
        {
            name: 'id',
            label: 'ID',
            width: 50,
            searchable: true,
            type: Number,
        },
        {
            name: 'title',
            label: t('label.title'),
            searchable: true,
        },
        ...(pageType?.region
            ? [
                  {
                      name: 'region',
                      label: t('label.region'),
                      execute: item => item?.name,
                      searchable: true,
                      searchField: 'regionName',
                  },
              ]
            : []),
        ...(pageType?.tag
            ? [
                  {
                      name: 'tag',
                      label: t('label.tag'),
                      execute: item => item?.name,
                      searchable: true,
                      searchField: 'tagName',
                  },
              ]
            : []),
        ...(pageType?.category
            ? [
                  {
                      name: 'category',
                      label: t('label.category'),
                      execute: item => item?.name,
                      searchable: true,
                      searchField: 'categoryName',
                  },
              ]
            : []),
        {
            name: 'url',
            label: t('label.url'),
            width: 120,
            execute: url => (url ? <PreviewButton url={url} /> : ''),
        },
        {
            name: 'volume',
            label: t('label.searchVolume'),
            searchable: true,
            type: Number,
        },
        {
            name: 'status',
            label: t('label.status'),
            searchable: true,
            type: Number,
            searchType: 'select',
            sortable: false,
            selectOptions: <PageStatusOptions />,
            execute: item => t(findEnumById(PageStatus, item)?.label),
        },
        { ...getDeletedColumn(t) },
    ];

    const pages = usePagination({
        service: pageService,
        defaultSearchData: {
            pageType: pageType.id,
        },
    });

    const ActionButtons = () => (
        <ListPageActions>
            <Button
                variant="contained"
                color="secondary"
                aria-haspopup="true"
                startIcon={<AddIcon />}
                onClick={() => {
                    history.push(`/pages/create?pageType=${pageType?.id}`);
                }}>
                {t('button.addNew')}
            </Button>
        </ListPageActions>
    );

    return (
        <>
            <Page title={title}>
                <Container maxWidth="xl">
                    <ActionButtons />
                    <DataTable
                        title={t(pageType?.label)}
                        columns={columns}
                        {...pages}
                        onEdit={item => {
                            history.push(`/pages/edit/${item.id}?pageType=${item.pageType}`);
                        }}
                        onDelete={item => {
                            setSelected(item);
                            setOpenPageDeleteDialog(true);
                        }}
                    />
                    <ActionButtons />
                </Container>

                <PageDeleteDialog
                    page={selected}
                    open={openPageDeleteDialog}
                    onClose={() => {
                        setOpenPageDeleteDialog(false);
                        setSelected(null);
                    }}
                    onDoneCallback={pages.search}
                />
            </Page>
        </>
    );
};

export default PageListPage;
