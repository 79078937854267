import Page from '../../../layout/Page';

import { useTranslation } from 'react-i18next';
import usePagination from '../../../util/usePagination';
import foodService from '../../../service/FoodService';
import { DataTable } from '../../../components/DataTable';
import Container from '@mui/material/Container';
import ListPageActions from '../../../components/ListPageActions';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import FoodDeleteDialog from './FoodDeleteDialog';
import { getDeletedColumn } from '../../../components/YesNoTypeOptions';

const FoodListPage = ({ title, ...props }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [selected, setSelected] = useState(null);
    const [openFoodDeleteDialog, setOpenFoodDeleteDialog] = useState(false);

    const columns = [
        {
            name: 'id',
            label: 'ID',
            width: 50,
            searchable: true,
            type: Number,
        },
        {
            name: 'name',
            label: t('label.name'),
            searchable: true,
        },
        {
            name: 'region',
            label: t('label.region'),
            execute: item => item?.name,
            searchable: true,
            searchField: 'regionName',
        },
        {
            name: 'volume',
            label: t('label.searchVolume'),
            width: 150,
            searchable: true,
            type: Number,
        },
        { ...getDeletedColumn(t) },
    ];

    const foods = usePagination({
        service: foodService,
    });

    const ActionButtons = () => (
        <ListPageActions>
            <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={() => {
                    history.push(`/settings/foods/create`);
                }}>
                {t('button.addNew')}
            </Button>
        </ListPageActions>
    );

    return (
        <Page title={title}>
            <Container maxWidth="xl">
                <ActionButtons />
                <DataTable
                    title={t('label.foodList')}
                    columns={columns}
                    {...foods}
                    onEdit={item => {
                        history.push(`/settings/foods/edit/${item.id}`);
                    }}
                    onDelete={item => {
                        setSelected(item);
                        setOpenFoodDeleteDialog(true);
                    }}
                />
                <ActionButtons />
            </Container>

            <FoodDeleteDialog
                food={selected}
                open={openFoodDeleteDialog}
                onClose={() => {
                    setOpenFoodDeleteDialog(false);
                    setSelected(null);
                }}
                onDoneCallback={foods.search}
            />
        </Page>
    );
};

export default FoodListPage;
