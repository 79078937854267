import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import DoneButton from '../../../ui/DoneButton';
import Grid from '@mui/material/Grid';
import Input from '../../../ui/Input';
import { DataTable } from '../../../components/DataTable';
// import YachtInput from '../../../ui/YachtInput';

const YachtForecastOfferAddDialog = ({ open, onClose, onAddAction, selected }) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        control,
        formState: { errors },
        reset,
    } = useForm();

    useEffect(() => {
        if (!open) {
            reset();
        }
    }, [open, reset]);

    const onSubmit = async data => {
        setLoading(true);
        const obj = {
            ...data,
        };
        onAddAction && onAddAction(obj);
        setLoading(false);
    };

    return (
        <Dialog open={open} fullWidth maxWidth="sm" scroll="paper">
            <DialogTitle>{t('operation.addBoatToOffer')}</DialogTitle>
            <DialogContent dividers={true}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {/* <YachtInput
                            name="yachts"
                            label={t('operation.boats')}
                            multiple={false}
                            control={control}
                            program={true}
                            error={errors?.yachts}
                            {...register("yachts", {
                                required: t('validation.required')
                            })}
                        /> */}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" disabled={loading}>
                    {t('button.cancel')}
                </Button>
                <DoneButton onClick={handleSubmit(onSubmit)} loading={loading}>
                    {t('button.add')}
                </DoneButton>
            </DialogActions>
        </Dialog>
    );
};

export default YachtForecastOfferAddDialog;
