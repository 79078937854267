import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import TextField from '@mui/material/TextField';
import { format, isValid, parse } from 'date-fns';
import DateRangePickerPopup from '../components/datatable/DateRangePickerPopup';
import { Box } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { formatDateForApi } from '../util/DateUtil';

const DateRangeInput = ({ startProps, endProps }) => {
    const dateFormat = 'dd/MM/yyyy';
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dates, setDates] = useState([null, null]);

    const [startDateStr, setStartDateStr] = useState('');
    const [endDateStr, setEndDateStr] = useState('');

    const { control, setValue } = useFormContext();

    useEffect(() => {
        setStartDateStr(startProps.defaultValue || '');
        setEndDateStr(endProps.defaultValue || '');
    }, [startProps, endProps]);

    useEffect(() => {
        const refDate = new Date();
        const parsedStartDate = parse(startDateStr, dateFormat, refDate);
        const parsedEndDate = parse(endDateStr, dateFormat, refDate);
        const _dates = [
            isValid(parsedStartDate) ? parsedStartDate : null,
            isValid(parsedEndDate) ? parsedEndDate : null,
        ];
        setDates(_dates);
    }, [startDateStr, endDateStr]);

    useEffect(() => {
        setValue(startProps.name, formatDateForApi(dates[0]));
        setValue(endProps.name, formatDateForApi(dates[1]));
    }, [dates]);

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const onClear = () => {
        setDates([null, null]);
        setStartDateStr('');
        setEndDateStr('');
    };

    const onPickerChange = dates => {
        const [start, end] = dates;
        if (isValid(start)) {
            setStartDateStr(format(start, dateFormat));
        } else {
            setStartDateStr('');
        }
        if (isValid(end)) {
            setEndDateStr(format(end, dateFormat));
        } else {
            setEndDateStr('');
        }

        setDates(dates);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                marginBottom: '5px',
                marginTop: '5px',
            }}>
            <Controller
                {...startProps}
                control={control}
                render={({ field }) => (
                    <InputMask
                        mask="99/99/9999"
                        value={startDateStr}
                        onChange={event => {
                            setStartDateStr(event.target.value);
                            field.onChange(event.target.value);
                        }}
                        maskChar="_">
                        {inputProps => (
                            <TextField
                                {...inputProps}
                                onClick={handleClick}
                                label="Başlangıç Tarihi"
                            />
                        )}
                    </InputMask>
                )}
            />

            <Box>-</Box>

            <Controller
                {...endProps}
                control={control}
                render={({ field }) => (
                    <InputMask
                        mask="99/99/9999"
                        value={endDateStr}
                        onChange={event => {
                            setEndDateStr(event.target.value);
                            field.onChange(event.target.value);
                        }}
                        maskChar="_">
                        {inputProps => (
                            <TextField {...inputProps} onClick={handleClick} label="Bitiş Tarihi" />
                        )}
                    </InputMask>
                )}
            />

            <DateRangePickerPopup
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                value={dates}
                setValue={onPickerChange}
                onClear={onClear}
            />
        </Box>
    );
};

export default DateRangeInput;
