import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import tagService from '../service/TagService';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import RenderTags from './RenderTags';

const TagInput = ({
    name,
    label,
    defaultValue,
    rules,
    error,
    multiple = true,
    disabled,
    tagType = 0,
    control,
}) => {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        let active = true;
        setLoading(true);
        if (inputValue === '') {
            if (active) {
                setOptions([]);
                setLoading(false);
            }
            return undefined;
        }
        (async () => {
            try {
                const { resultObject } = await tagService.search({
                    start: 0,
                    data: { name: inputValue, tagType },
                    max: 50,
                });
                const result = resultObject.map(item => ({ id: item.id, name: item.name }));
                if (active) {
                    setOptions(result || []);
                    setLoading(false);
                }
            } catch (e) {}
        })();
        return () => {
            active = false;
        };
    }, [inputValue, tagType]);

    const handleChange = event => {
        setInputValue(event.target.value);
    };

    return (
        <Controller
            render={({ field }) => (
                <Autocomplete
                    {...field}
                    multiple={multiple}
                    getOptionLabel={tag => tag.name || ''}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={options}
                    filterOptions={x => x}
                    loading={loading}
                    disabled={disabled}
                    renderTags={RenderTags}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label={label || (multiple ? t('label.tags') : t('label.tag'))}
                            error={error && true}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            autoComplete="off"
                            helperText={error?.message}
                            multiline={multiple}
                            onChange={handleChange}
                        />
                    )}
                    onChange={(_, data) => field.onChange(data)}
                />
            )}
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
        />
    );
};

export default TagInput;
