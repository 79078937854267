import BaseService from './BaseService';
import { easyPost } from '../util/Fetch';

class PaymentService extends BaseService {
    constructor() {
        super('/finance/payments');
    }

    async updateList(data) {
        return await easyPost(`${this.path}/update`, data);
    }
}

const paymentService = new PaymentService();

export default paymentService;
