import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import SaveIcon from '@mui/icons-material/Save';
import SubmitButton from '../../../ui/SubmitButton';
import { findEnumById, MessageType, SalePaymentStatusType } from '../../../util/Enums';
import useMessage from '../../../util/useMessage';
import TourPaymentUpdateTable from './TourPaymentUpdateTable';
import paymentService from '../../../service/PaymentService';

const TourPaymentUpdateDialog = ({
    salePaymentStatusTypeId,
    paymentList = [],
    open,
    onClose,
    onDoneCallback,
}) => {
    const { t } = useTranslation();
    const showMessage = useMessage();

    const [loading, setLoading] = useState(false);
    const methods = useForm();

    const { handleSubmit, reset } = methods;

    const [isWaiting, setIsWaiting] = useState(false);

    useEffect(() => {
        const $isWaiting = salePaymentStatusTypeId - 1 === SalePaymentStatusType.Waiting.id;
        setIsWaiting($isWaiting);

        if (open && $isWaiting) {
            console.log(paymentList);
            reset({
                payment: paymentList,
            });
        }
        setLoading(false);
    }, [open, salePaymentStatusTypeId, reset, paymentList]);

    const onSubmit = data => {
        if (loading) return false;
        setLoading(true);
        const updatedSalePayments = [];
        const idList = paymentList.map(item => item.salePaymentId);
        const request = {
            salePaymentIdList: idList,
            paymentStatusTypeId: salePaymentStatusTypeId,
        };
        if (isWaiting) {
            const { payment } = data;
            payment.forEach(item => {
                updatedSalePayments.push({
                    salePaymentId: item.salePaymentId,
                    bankId: parseInt(item.bankId),
                    commissionRate: item.commissionRate,
                    incomingAmount: item.incomingAmount,
                });
            });
        }

        request['updatedSalePayments'] = updatedSalePayments;
        paymentService
            .updateList(request)
            .then(() => {
                onClose && onClose();
                onDoneCallback && onDoneCallback();
            })
            .catch(({ code, message }) => {
                showMessage(`${code} - ${message}`, MessageType.ERROR);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    if (!open) {
        return null;
    }

    return (
        <Dialog open={open} fullWidth maxWidth="lg" scroll="paper">
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle>{t('title.updateTourPaymentDialog')}</DialogTitle>
                    <DialogContent dividers={true} style={{ padding: 0 }}>
                        {!isWaiting && (
                            <div style={{ padding: 24 }}>
                                Seçilen tahsilatların durumlarını{' '}
                                <strong>
                                    {
                                        findEnumById(SalePaymentStatusType, salePaymentStatusTypeId)
                                            ?.label
                                    }
                                </strong>{' '}
                                olarak güncellemek istediğinizden emin misiniz?
                            </div>
                        )}

                        {isWaiting && <TourPaymentUpdateTable data={paymentList} />}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary" disabled={loading}>
                            {t('button.cancel')}
                        </Button>
                        <SubmitButton loading={loading} startIcon={<SaveIcon />}>
                            {t('button.update')}
                        </SubmitButton>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
};

export default TourPaymentUpdateDialog;
