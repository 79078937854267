import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
} from '@mui/material';
import DoneButton from '../../../ui/DoneButton';
import Input from '../../../ui/Input';
import RegionInput from '../../../ui/RegionInput';
import TourCatalogInput from '../../../ui/TourCatalogInput';
import FilterListOffIcon from '@mui/icons-material/Close';

const ForecastFilterDialog = ({ item, open, onClose, onAddAction, defaultDestinations }) => {
    const { t } = useTranslation();
    const {
        handleSubmit,
        setValue,
        control,
        register,
        reset,
        formState: { errors },
    } = useForm();
    useEffect(() => {
        if (defaultDestinations?.length > 0) {
            setValue('destinations', defaultDestinations);
        }
    }, []);
    useEffect(() => {
        if(open) {
            Object.entries(item).forEach(([key, value]) => {
                if (value === null) {
                    if(key === "destinations" || key === "accommodations") {
                        setValue(key, []);
                    } else {
                        setValue(key, null);
                    }
                }
            });
        }
    }, [open]);
    const getArrayIds = array => {
        const arrayList = [];
        array.forEach(reg => {
            arrayList.push(reg.id);
        });
        return arrayList;
    };
    const onSubmit = async data => {
        const obj = {
            regionId: data.regionId ? data.regionId.id : null,
            tourCatalogId: data.tourCatalogId ? data.tourCatalogId.id : null,
            departureRoute: data.departureRoute || null,
            destinations: data.destinations.length > 0 ? getArrayIds(data.destinations) : null,
            accommodations:
                data.accommodations.length > 0 ? getArrayIds(data.accommodations) : null,
            linkRegionId: data.linkRegionId ? data.linkRegionId.id : null,
        };
        onAddAction && onAddAction(obj);
    };

    return (
        <Dialog open={open} fullWidth maxWidth="md" scroll="paper">
            <DialogTitle>Filters</DialogTitle>
            {Object.entries(item).filter(([key, value]) => value !== null && key !== "personCounts").length > 0 && (
                <Button
                    onClick={() => {
                        reset({
                            regionId: null,
                            tourCatalogId: null,
                            departureRoute: null,
                            destinations: [],
                            accommodations: [],
                            linkRegionId: null,
                        });
                        onAddAction &&
                            onAddAction({
                                regionId: null,
                                tourCatalogId: null,
                                departureRoute: null,
                                destinations: null,
                                accommodations: null,
                                linkRegionId: null,
                            });
                    }}
                    color="error"
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}>
                    Filtreleri Temizle
                </Button>
            )}
            <DialogContent dividers={true}>
                <form>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <RegionInput
                                name="regionId"
                                label={t('forecast.region')}
                                multiple={false}
                                control={control}
                                defaultValue={item?.regionId || null}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TourCatalogInput
                                name="tourCatalogId"
                                multiple={false}
                                defaultValue={item?.tourCatalogId || null}
                                control={control}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Input
                                defaultValue={item?.departureRoute || null}
                                label={t('forecast.departureRoute')}
                                {...register('departureRoute')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <RegionInput
                                name="destinations"
                                label={t('forecast.destinations')}
                                multiple={true}
                                control={control}
                                defaultValue={item?.destinations || []}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <RegionInput
                                name="accommodations"
                                label={t('forecast.accommodations')}
                                multiple={true}
                                control={control}
                                defaultValue={item?.accommodations || []}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <RegionInput
                                name="linkRegionId"
                                label={t('forecast.linkRegion')}
                                multiple={false}
                                control={control}
                                defaultValue={item?.linkRegionId || null}
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t('button.cancel')}
                </Button>
                <DoneButton onClick={handleSubmit(onSubmit)}>{t('button.done')}</DoneButton>
            </DialogActions>
        </Dialog>
    );
};

export default ForecastFilterDialog;
