import Grid from '@mui/material/Grid';
import Panel from '../../../ui/Panel';
import RegionInput from '../../../ui/RegionInput';
import MatchRegionInput from '../../../ui/MatchRegionInput';
import Input from '../../../ui/Input';
import Select from '../../../ui/Select';
import MenuItem from '@mui/material/MenuItem';
import CoverImageSelectionCard from '../../../components/CoverImageSelectionCard';
import React, { useCallback, useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TagInput from '../../../ui/TagInput';
import CheckBox from '../../../ui/CheckBox';
import { TagType, TourType } from '../../../util/Enums';
import ImageGalleryCard from '../../../components/ImageGalleryCard';
import Link from '@mui/material/Link';

const RegionEditInputPanel = ({
    region,
    name,
    images,
    setImages,
    flag,
    setFlag,
    fileNameParam,
    folderName,
    added = false,
    tourPrograms,
    regionId = null,
}) => {
    const { t } = useTranslation();
    const [fileName, setFileName] = useState(fileNameParam);
    const {
        register,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useFormContext();

    const watchTourType = watch('tourType');
    const watchParent = watch('parent');
    const watchFileName = watch('title');
    const watchIsRegion = watch('isRegion');
    const aspect = 720 / 480;

    useEffect(() => {
        if (region && !region.parent) {
            setValue('name', region.name || '');
            setValue('title', region.title || '');
        }
    }, []);

    useEffect(() => {
        setFileName(watchFileName || '');
    }, [watchFileName]);

    const getNameInputLabel = useCallback(() => {
        if (watchTourType === TourType.ABROAD.id || watchTourType === TourType.SHIP.id) {
            const names = ['', 'continent', 'country', 'state', 'city'];
            if (watchParent) {
                return names[watchParent.level];
            } else {
                return names[1];
            }
        } else if (watchTourType === TourType.CULTURE.id) {
            const names = ['', 'domesticRegion', 'domesticState', 'domesticCity'];
            if (watchParent) {
                return names[watchParent.level + 1];
            } else {
                return names[1];
            }
        } else {
            return 'name';
        }
    }, [watchTourType, watchParent]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} md={added ? 12 : 4} lg={added ? 12 : 4}>
                <Panel title={t('title.regionInfos')}>
                    <Select
                        name="tourType"
                        rules={{ required: t('validation.required') }}
                        error={errors?.tourType}
                        label={t('label.tourType')}
                        disabled={region && !region.parent}
                        control={control}>
                        {Object.keys(TourType).map(key => {
                            const item = TourType[key];
                            return (
                                <MenuItem key={item.id} value={item.id}>
                                    {t(item.label)}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    {watchTourType && (
                        <>
                            <RegionInput
                                name="parent"
                                multiple={false}
                                tourType={watchTourType}
                                defaultValue={region?.parent}
                                label={t('label.parentRegion')}
                                control={control}
                                error={errors?.parent}
                                disabled={region && !region.parent}
                            />
                            <Input
                                name="name"
                                defaultValue={region?.name || name || ''}
                                label={t(`label.${getNameInputLabel()}`)}
                                error={errors?.name}
                                disabled={region && !region.parent}
                                {...register('name', {
                                    required:
                                        region && !region.parent ? false : t('validation.required'),
                                    minLength: {
                                        value: 3,
                                        message: t('validation.minLength', { val: 3 }),
                                    },
                                })}
                            />
                            <Input
                                label={t('label.visibleName')}
                                defaultValue={region?.title}
                                error={errors?.title}
                                disabled={region && !region.parent}
                                {...register('title', {
                                    minLength: {
                                        value: 3,
                                        message: t('validation.minLength', { val: 3 }),
                                    },
                                })}
                            />
                        </>
                    )}
                    <CheckBox
                        defaultChecked={region?.isRegion}
                        label={t('label.domesticRegion')}
                        {...register('isRegion')}
                    />
                    <CheckBox
                        defaultChecked={region?.isCreateStaticPage || false}
                        label={t('label.createLink')}
                        {...register('isCreateStaticPage')}
                    />
                </Panel>
            </Grid>
            <Grid item xs={12} md={added ? 12 : 4} lg={added ? 12 : 4}>
                <Panel title="SEO">
                    <Input
                        type="number"
                        name="volume"
                        defaultValue={region?.volume}
                        label={t('label.searchVolume')}
                        error={errors?.volume}
                        {...register('volume')}
                    />
                    <TagInput
                        name="tags"
                        control={control}
                        defaultValue={region?.tags || []}
                        error={errors?.tags}
                        tagType={TagType.LOCATION.id}
                    />
                </Panel>
            </Grid>
            <Grid item xs={12} md={added ? 12 : 4} lg={added ? 12 : 4}>
                <CoverImageSelectionCard
                    aspect={1}
                    title={t('title.flag')}
                    image={flag}
                    setImage={setFlag}
                    canBeCropped={false}
                    {...{ fileName: `${fileName}-flag`, folderName }}
                />
            </Grid>
            <Grid item xs={12}>
                <ImageGalleryCard
                    {...{ aspect, images, setImages, fileName, folderName, isCover: true }}
                />
            </Grid>
            {watchIsRegion && (
                <Grid item xs={12}>
                    <Panel title={t('label.matchingRegion')}>
                        <MatchRegionInput
                            name="matchingRegion"
                            multiple={true}
                            defaultValue={region?.matchingRegion || []}
                            error={errors?.matchingRegion}
                            label={t('label.regions')}
                            control={control}
                            regionId={regionId}
                        />
                    </Panel>
                </Grid>
            )}
            <Grid item xs={12} md={12} lg={12}>
                <Panel title={t('label.otherInfo')}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} lg={4}>
                            <Input
                                name="currency"
                                defaultValue={region?.currency}
                                label={t('label.currency')}
                                error={errors?.currency}
                                {...register('currency')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Input
                                name="population"
                                defaultValue={region?.population}
                                label={t('label.population')}
                                error={errors?.population}
                                {...register('population')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Input
                                name="languageName"
                                defaultValue={region?.languageName}
                                label={t('label.languageName')}
                                error={errors?.languageName}
                                {...register('languageName')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Input
                                name="timeDifference"
                                defaultValue={region?.timeDifference}
                                label={t('label.timeDifference')}
                                error={errors?.timeDifference}
                                {...register('timeDifference')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Input
                                name="capitalCity"
                                defaultValue={region?.capitalCity}
                                label={t('label.capitalCity')}
                                error={errors?.capitalCity}
                                {...register('capitalCity')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Input
                                name="electricity"
                                defaultValue={region?.electricity}
                                label={t('label.electricity')}
                                error={errors?.electricity}
                                {...register('electricity')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Input
                                name="springDegree"
                                defaultValue={region?.springDegree}
                                label={t('label.springDegree')}
                                error={errors?.springDegree}
                                {...register('springDegree', {
                                    pattern: {
                                        value: /^-?\d*\.?\d*$/,
                                        message: t('validation.invalidNumber'),
                                    },
                                })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Input
                                name="summerDegree"
                                defaultValue={region?.summerDegree}
                                label={t('label.summerDegree')}
                                error={errors?.summerDegree}
                                {...register('summerDegree', {
                                    pattern: {
                                        value: /^-?\d*\.?\d*$/,
                                        message: t('validation.invalidNumber'),
                                    },
                                })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Input
                                name="autumnDegree"
                                defaultValue={region?.autumnDegree}
                                label={t('label.autumnDegree')}
                                error={errors?.autumnDegree}
                                {...register('autumnDegree', {
                                    pattern: {
                                        value: /^-?\d*\.?\d*$/,
                                        message: t('validation.invalidNumber'),
                                    },
                                })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <Input
                                name="winterDegree"
                                defaultValue={region?.winterDegree}
                                label={t('label.winterDegree')}
                                error={errors?.winterDegree}
                                {...register('winterDegree', {
                                    pattern: {
                                        value: /^-?\d*\.?\d*$/,
                                        message: t('validation.invalidNumber'),
                                    },
                                })}
                            />
                        </Grid>
                    </Grid>
                </Panel>
            </Grid>
            {tourPrograms && tourPrograms.length > 0 && (
                <Grid item xs={12} md={12}>
                    <Panel title={t('label.tourPrograms')}>
                        <ul>
                            {tourPrograms.map((item, index) => (
                                <li key={index}>
                                    <Link href={`/tours/programs/edit/${item.id}`} target="_blank">
                                        {item.title}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </Panel>
                </Grid>
            )}
        </Grid>
    );
};

export default RegionEditInputPanel;
