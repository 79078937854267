import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { DateUtils } from 'react-day-picker';
import CalendarInput from '../../components/datatable/CalendarInput';

const TourPeriodDateEditDialog = ({ dates = [], open, onClose, onDoneCallback }) => {
    const { t } = useTranslation();

    const { handleSubmit } = useForm();
    const [values, setValues] = useState([]);

    const today = new Date();

    useEffect(() => {
        if (open) {
            const converted = dates.map(item => item.date);
            setValues(converted || []);
        }
    }, [open, dates]);

    const onSubmit = async () => {
        onDoneCallback && onDoneCallback(values);
    };

    const handleDayClick = (day, { selected }) => {
        const selectedDays = values.concat();
        if (selected) {
            const selectedIndex = selectedDays.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day),
            );
            selectedDays.splice(selectedIndex, 1);
        } else {
            selectedDays.push(day);
        }
        setValues(selectedDays);
    };

    return (
        <Dialog open={open} fullWidth maxWidth="md" scroll="paper">
            <DialogTitle>{t('title.periodDatesEdit')}</DialogTitle>
            <DialogContent dividers={true}>
                <div style={{ textAlign: 'center' }}>
                    <CalendarInput values={values} handleDayClick={handleDayClick} today={today} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t('button.cancel')}
                </Button>
                <Button onClick={handleSubmit(onSubmit)}>{t('button.done')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default React.memo(TourPeriodDateEditDialog);
