import MaterialSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import useMessage from '../../../util/useMessage';
import { MessageType } from '../../../util/Enums';
import { useTranslation } from 'react-i18next';

const TourPriceEditHotelChangeInput = ({
    hotels,
    selectedHotel,
    setSelectedHotel,
    isValidForms,
    priceGridRef,
}) => {
    const { t } = useTranslation();
    const showMessage = useMessage();

    const [id, setId] = useState('-1');

    useEffect(() => {
        if (selectedHotel) {
            setId(selectedHotel.hotelId);
        } else {
            setId('-1');
        }
    }, [selectedHotel]);

    const handleOnChange = async e => {
        const isValid = await isValidForms();
        if (!isValid) {
            showMessage(t('message.invalidatePriceForm'), MessageType.ERROR);
        } else {
            if (e.target.value !== '-1') {
                if (priceGridRef.current && priceGridRef.current.isEdited()) {
                    await priceGridRef.current.apply(function () {
                        setId(e.target.value);
                        const hotelId = parseInt(e.target.value);
                        setSelectedHotel(hotels.find(item => item.hotelId === hotelId));
                    });
                } else {
                    setId(e.target.value);
                    const hotelId = parseInt(e.target.value);
                    setSelectedHotel(hotels.find(item => item.hotelId === hotelId));
                }
            } else {
                setSelectedHotel(null);
            }
        }
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <div style={{ marginBottom: 10 }}>
                <strong>
                    Fiyatını düzenlemek istediğiniz <br /> oteli seçiniz:
                </strong>
            </div>
            <MaterialSelect
                margin="dense"
                variant="outlined"
                size={'small'}
                value={id}
                onChange={handleOnChange}
                style={{ backgroundColor: 'white' }}>
                <MenuItem value={'-1'} disabled={true}>
                    Lütfen Seçiniz
                </MenuItem>
                {hotels.map((item, index) => (
                    <MenuItem key={item.hotelId} value={item.hotelId}>
                        {item.name}
                    </MenuItem>
                ))}
                ;
            </MaterialSelect>
        </div>
    );
};

export default TourPriceEditHotelChangeInput;
