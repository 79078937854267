import { useTranslation } from 'react-i18next';

const useDatePickerLocale = () => {
    const { t } = useTranslation();

    const _months =
        'January_February_March_April_May_June_July_August_September_October_November_December'.split(
            '_',
        );
    const _weekdays = 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_');
    const _shorts = 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_');

    const months = _months.map(item => t(`calendar.${item}`));
    const weekdaysLong = _weekdays.map(item => t(`calendar.${item}`));
    const weekdaysShort = _shorts.map(item => t(`calendar.${item}`));

    return { months, weekdaysLong, weekdaysShort };
};

export default useDatePickerLocale;
