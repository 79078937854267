import { useForm } from 'react-hook-form';
import Panel from '../../../ui/Panel';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from '../../../ui/Select';
import MenuItem from '@mui/material/MenuItem';
import ExchangeRangeInput from '../../../ui/ExchangeRangeInput';
import exchangeRateService from '../../../service/ExchangeRateService';
import Grid from '@mui/material/Grid';

export const EXCHANGE_FIELDS = [
    { name: 'purchaseCurrencyType', defaultValue: 'Eur' },
    // {name: "purchaseCurrencyGroup", defaultValue: undefined},
    { name: 'saleCurrencyType', defaultValue: 'Try' },
    // {name: "salesCurrencyGroup", defaultValue: undefined},
];

const TourPriceEditExchangePanel = React.forwardRef(({ hotel, hotels, setHotels }, ref) => {
    const { t } = useTranslation();
    const {
        control,
        setValue,
        watch,
        trigger,
        getValues,
        formState: { errors, isValid },
    } = useForm({ mode: 'onChange' });
    const watchpurchaseCurrencyType = watch('purchaseCurrencyType');
    const watchsaleCurrencyType = watch('saleCurrencyType');

    const [currencyTypes, setCurrencyTypes] = useState([]);

    useEffect(() => {
        (async () => {
            const { resultObject } = await exchangeRateService.getCurrencyTypes();
            setCurrencyTypes(resultObject);
        })();
    }, []);

    useEffect(() => {
        EXCHANGE_FIELDS.forEach(field => {
            setValue(field.name, hotel[field.name] || field.defaultValue || '');
        });
    }, [hotel]);

    // useEffect(() => {
    //     const selectHotelIndex = hotels.findIndex(item => item.hotelId === hotel.hotelId);
    //     const selectHotels = hotels;
    //     selectHotels[selectHotelIndex] = {
    //         ...selectHotels[selectHotelIndex],
    //         purchaseCurrencyType: watchpurchaseCurrencyType,
    //         saleCurrencyType: watchsaleCurrencyType
    //     }
    //     setHotels(selectHotels);
    // }, [watchpurchaseCurrencyType, watchsaleCurrencyType]);

    useImperativeHandle(ref, () => ({
        isValid: async () => {
            await trigger();
            return isValid;
        },
        getData: async () => {
            return getValues();
        },
    }));

    return (
        <Panel title={t('title.currencies')}>
            <Grid container spacing={1}>
                <Grid item md={6}>
                    <Select
                        name="purchaseCurrencyType"
                        defaultValue={hotel?.purchaseCurrencyType || 'EUR'}
                        rules={{ required: t('validation.required') }}
                        error={errors?.purchaseCurrencyType}
                        label={t('label.purchaseCurrencyType')}
                        control={control}>
                        {currencyTypes.map(item => (
                            <MenuItem key={item} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                    {/* <ExchangeRangeInput
                        name="purchaseCurrencyGroup"
                        label={t("label.purchaseCurrencyGroup")}
                        defaultValue={hotel?.purchaseCurrencyGroup}
                        rules={{required: t("validation.required")}}
                        error={errors?.purchaseCurrencyGroup}
                        control={control}
                        multiple={false}
                    /> */}
                </Grid>
                <Grid item md={6}>
                    <Select
                        name="saleCurrencyType"
                        defaultValue={hotel?.saleCurrencyType || 'TRY'}
                        rules={{ required: t('validation.required') }}
                        error={errors?.saleCurrencyType}
                        label={t('label.saleCurrencyType')}
                        control={control}>
                        {currencyTypes.map(item => (
                            <MenuItem key={item} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                    {/* <ExchangeRangeInput
                        name="salesCurrencyGroup"
                        label={t("label.salesCurrencyGroup")}
                        defaultValue={hotel?.salesCurrencyGroup}
                        rules={{required: t("validation.required")}}
                        error={errors?.salesCurrencyGroup}
                        control={control}
                        multiple={false}
                    /> */}
                </Grid>
            </Grid>
        </Panel>
    );
});

export default TourPriceEditExchangePanel;
