import React, { useState } from 'react';
import Page from '../../layout/Page';
import { useTranslation } from 'react-i18next';
import usePagination from '../../util/usePagination';
import tourPriceService from '../../service/TourPriceService';
import { DataTable } from '../../components/DataTable';
import Container from '@mui/material/Container';
import ListPageActions from '../../components/ListPageActions';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { useHistory } from 'react-router-dom';
import { getDeletedColumn, YesNoTypeOptions } from '../../components/YesNoTypeOptions';
import MinMaxSearchCell from '../../components/datatable/MinMaxSearchCell';
import { parseAndFormatDateString } from '../../util/DateUtil';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import Input from '../../ui/Input';
import Tooltip from '@mui/material/Tooltip';
import CheckBox from '../../ui/CheckBox';
import VendorSearch from '../../components/datatable/VendorSearch';
import DateInput from '../../ui/DateInput';
import { formatDate } from '../../util/DateUtil';
import { format } from 'date-fns';
import DateFilterCell from '../../components/datatable/DateFilterCell';

const TourPriceListPage = ({ title, ...props }) => {
    const { t } = useTranslation();
    const { register, handleSubmit, control } = useForm();
    const history = useHistory();
    const [expand, setExpand] = useState(false);

    const columnsMin = [
        {
            name: 'id',
            label: 'ID',
            searchable: true,
            type: Number,
            width: 75,
        },
        {
            name: 'operationTitle',
            label: 'Operasyon',
            searchable: true,
        },
        {
            name: 'contact',
            label: t('forecast.vendor'),
            searchable: true,
            searchField: 'contactId',
            type: Number,
            SearchCellComponent: VendorSearch,
            execute: item =>
                item?.brandName && (
                    <Tooltip title={<p>{item.brandName}</p>}>
                        <div
                            style={{
                                whiteSpace: 'nowrap',
                                maxWidth: '160px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {item.brandName}
                        </div>
                    </Tooltip>
                ),
        },
        {
            name: 'catalogs',
            label: t('label.catalog'),
            searchable: true,
            searchField: 'catalogTitle',
            sortable: false,
            // execute: (items) => items?.map(item => item?.title).join(", "),
            execute: item =>
                item &&
                (item.length > 0 ? (
                    <Tooltip
                        title={
                            <React.Fragment>
                                {item.map(tourCatalog => (
                                    <p>{tourCatalog.title || ''}</p>
                                ))}
                            </React.Fragment>
                        }>
                        <span>{item.length}</span>
                    </Tooltip>
                ) : (
                    <>0</>
                )),
        },
        {
            name: 'title',
            label: t('label.title'),
            searchable: true,
            execute: item =>
                item && (
                    <Tooltip title={<p>{item}</p>}>
                        <div
                            style={{
                                whiteSpace: 'break-spaces',
                                wordBreak: 'break-all',
                                width: '400px',
                                // maxWidth: '400px',
                                // overflow: 'hidden',
                                // textOverflow: 'ellipsis',
                            }}>
                            {item}
                        </div>
                    </Tooltip>
                ),
        },
        {
            name: 'transportCompany',
            label: "Ulaşım",
            searchable: true,
            searchField: 'transportCompanyValue',
            execute: item =>
                item && (
                    <Tooltip title={<p>{item.name || ""}</p>}>
                        <div style={{
                                whiteSpace: 'nowrap',
                                maxWidth: '70px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>{item.value || ""}</div>
                    </Tooltip>
                ),
        },
        {
            name: 'startPeriodDate',
            label: t('label.startPeriodDate'),
            width: 110,
            execute: item => (item ? format(new Date(item), 'dd.MM.yyyy') : ''),
            searchable: true,
            SearchCellComponent: DateFilterCell,
        },
        {
            name: 'endPeriodDate',
            label: t('label.endPeriodDate'),
            width: 110,
            execute: item => (item ? format(new Date(item), 'dd.MM.yyyy') : ''),
            searchable: true,
            SearchCellComponent: DateFilterCell,
        },
        {
            name: 'availability',
            label: t('label.availability'),
            type: Boolean,
            searchType: 'select',
            sortable: false,
            searchable: true,
            selectOptions: <YesNoTypeOptions />,
            width: 100,
            execute: availability => t(`label.${availability ? 'yes' : 'no'}`),
        },
        { ...getDeletedColumn(t) },
    ];
    const columnsMax = [
        {
            name: 'id',
            label: 'ID',
            searchable: true,
            type: Number,
            width: 75,
        },
        {
            name: 'operationTitle',
            label: 'Operasyon',
            searchable: true,
        },
        {
            name: 'contact',
            label: t('forecast.vendor'),
            searchable: true,
            searchField: 'contactId',
            type: Number,
            SearchCellComponent: VendorSearch,
            execute: item =>
                item?.brandName && (
                    <Tooltip title={<p>{item.brandName}</p>}>
                        <div
                            style={{
                                whiteSpace: 'nowrap',
                                maxWidth: '160px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {item.brandName}
                        </div>
                    </Tooltip>
                ),
        },
        {
            name: 'catalogs',
            label: t('label.catalog'),
            searchable: true,
            searchField: 'catalogTitle',
            sortable: false,
            // execute: (items) => items?.map(item => item?.title).join(", "),
            execute: item =>
                item &&
                (item.length > 0 ? (
                    <Tooltip
                        title={
                            <React.Fragment>
                                {item.map(tourCatalog => (
                                    <p>{tourCatalog.title || ''}</p>
                                ))}
                            </React.Fragment>
                        }>
                        <span>{item.length}</span>
                    </Tooltip>
                ) : (
                    <>0</>
                )),
        },
        {
            name: 'title',
            label: t('label.title'),
            searchable: true,
            execute: item =>
                item && (
                    <Tooltip title={<p>{item}</p>}>
                        <div
                            style={{
                                whiteSpace: 'break-spaces',
                                wordBreak: 'break-all',
                                width: '400px',
                                // maxWidth: '400px',
                                // overflow: 'hidden',
                                // textOverflow: 'ellipsis',
                            }}>
                            {item}
                        </div>
                    </Tooltip>
                ),
        },
        {
            name: 'transportCompany',
            label: "Ulaşım",
            searchable: true,
            searchField: 'transportCompanyValue',
            execute: item =>
                item && (
                    <Tooltip title={<p>{item.name || ""}</p>}>
                        <div style={{
                                whiteSpace: 'nowrap',
                                maxWidth: '70px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>{item.value || ""}</div>
                    </Tooltip>
                ),
        },
        {
            name: 'night',
            label: t('label.night'),
            searchable: true,
            SearchCellComponent: MinMaxSearchCell,
        },
        {
            name: 'price',
            label: t('label.price'),
            searchable: true,
            SearchCellComponent: MinMaxSearchCell,
        },
        {
            name: 'commissionRate',
            label: t('label.commissionRate'),
            searchable: true,
            SearchCellComponent: MinMaxSearchCell,
        },
        {
            name: 'defaultDiscountRate',
            label: t('label.discountRate'),
            searchable: true,
            SearchCellComponent: MinMaxSearchCell,
        },
        {
            name: 'stopCount',
            label: t('label.stopCount'),
            searchable: true,
            SearchCellComponent: MinMaxSearchCell,
        },
        {
            name: 'periodCount',
            label: t('label.periodCount'),
            searchable: true,
            SearchCellComponent: MinMaxSearchCell,
        },
        {
            name: 'averageQuota',
            label: t('label.averageQuota'),
            searchable: true,
            SearchCellComponent: MinMaxSearchCell,
        },
        {
            name: 'startPeriodDate',
            label: t('label.startPeriodDate'),
            width: 110,
            execute: item => (item ? format(new Date(item), 'dd.MM.yyyy') : ''),
            searchable: true,
            SearchCellComponent: DateFilterCell,
        },
        {
            name: 'endPeriodDate',
            label: t('label.endPeriodDate'),
            width: 110,
            execute: item => (item ? format(new Date(item), 'dd.MM.yyyy') : ''),
            searchable: true,
            SearchCellComponent: DateFilterCell,
        },
        {
            name: 'availability',
            label: t('label.availability'),
            type: Boolean,
            searchType: 'select',
            sortable: false,
            searchable: true,
            selectOptions: <YesNoTypeOptions />,
            width: 100,
            execute: availability => t(`label.${availability ? 'yes' : 'no'}`),
        },
        { ...getDeletedColumn(t) },
    ];
    const [columns, setColumns] = useState(columnsMin);

    const prices = usePagination({
        service: tourPriceService,
        defaultOrderBy: { name: 'id', orderType: 'desc' },
    });

    const onSubmit = async data => {
        if (prices.loading) return false;
        const obj = {
            ...prices.searchData,
            containingDate:
                data.containingDate && data.containingDate !== '' ? data.containingDate : null,
            isStopTourHide: data.isStopTourHide || false,
        };
        prices.setSearchData({ ...obj });
    };

    const ActionButtons = () => (
        <ListPageActions>
            <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={() => {
                    history.push(`/tours/prices/create`);
                }}>
                {t('button.addNew')}
            </Button>
        </ListPageActions>
    );
    const ExpandButtons = () => (
        <ListPageActions>
            <Button
                variant="contained"
                color="secondary"
                startIcon={!expand ? <OpenInFullIcon /> : <CloseFullscreenIcon />}
                onClick={() => {
                    if (!expand) {
                        setColumns(columnsMax);
                        setExpand(true);
                    } else {
                        setColumns(columnsMin);
                        setExpand(false);
                    }
                }}>
                {!expand ? t('button.expand') : t('button.collapse')}
            </Button>
        </ListPageActions>
    );

    return (
        <Page title={title}>
            <Container maxWidth={false} sx={{ maxWidth: !expand ? '1712px' : '2539px' }}>
                <ExpandButtons />
                <div className="priceTableWrap">
                    <DataTable
                        title={t('label.tourPriceList')}
                        columns={columns}
                        {...prices}
                        headerNoWrap
                        onManage={(item, index, event) => {
                            event.preventDefault();
                            if (event.ctrlKey || event.metaKey) {
                                window.open(`/tours/prices/edit/${item.id}`);
                            } else {
                                history.push(`/tours/prices/edit/${item.id}`);
                            }
                        }}
                        action={
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container>
                                    <Grid item xs={12} md={2} lg={7} sx={{ paddingTop: '8px' }}>
                                        <CheckBox
                                            defaultChecked={false}
                                            label="Stop olan turları gösterme"
                                            {...register('isStopTourHide')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2} lg={4}>
                                        <DateInput
                                            name="containingDate"
                                            label={t('label.containingDate')}
                                            defaultValue={null}
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4} lg={1}>
                                        <IconButton
                                            aria-label="Search"
                                            size="medium"
                                            type="submit"
                                            sx={{ marginTop: '8px' }}>
                                            <SearchIcon fontSize="medium" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </form>
                        }
                    />
                </div>
            </Container>
        </Page>
    );
};

export default TourPriceListPage;
