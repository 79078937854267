import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../../../ui/Input';
import { useForm } from 'react-hook-form';
import useMessage from '../../../util/useMessage';
import { MessageType, Months } from '../../../util/Enums';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '../../../ui/Select';
import ImageGalleryCard from '../../../components/ImageGalleryCard';
import CategoryInput from '../../../ui/CategoryInput';
import TourCatalogInput from '../../../ui/TourCatalogInput';

const HomeDeparturesEditDialog = ({ open, selectedIndex, items, onEditDone, onClose }) => {
    const { t } = useTranslation();
    const showMessage = useMessage();

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        reset,
        control,
        formState: { errors },
    } = useForm();

    const watchDeparture = watch('departure');

    useEffect(() => {
        if (open) {
            reset();
            setValue('tours', items[selectedIndex]?.tours || []);
            setValue('departure', items[selectedIndex]?.departure || null);
        }
    }, [open, selectedIndex, items]);

    useEffect(() => {
        if (!watchDeparture) {
            setValue('tours', []);
        }
    }, [watchDeparture]);

    const onSubmit = data => {
        if (onEditDone) {
            const obj = {
                ...data,
                homeDepartureId: parseInt(data.homeDepartureId),
            };
            onEditDone(obj, selectedIndex);
        }
    };

    return (
        <Dialog open={open} fullWidth maxWidth={'sm'} scroll="paper">
            <DialogTitle>{t('title.departureEdit')}</DialogTitle>
            <DialogContent dividers>
                <input
                    type="hidden"
                    {...register(`homeDepartureId`)}
                    defaultValue={items[selectedIndex]?.homeDepartureId || 0}
                />
                <CategoryInput
                    name="departure"
                    control={control}
                    error={errors?.departure}
                    categoryType={2}
                    label={t('label.departure')}
                    multiple={false}
                    rules={{ required: t('validation.required') }}
                    defaultValue={items[selectedIndex]?.departure}
                />
                <TourCatalogInput
                    name="tours"
                    multiple={true}
                    defaultValue={items[selectedIndex]?.tours}
                    control={control}
                    error={errors?.tours}
                    rules={{ required: t('validation.required') }}
                    categoryId={watchDeparture?.id}
                    disabled={watchDeparture ? false : true}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t('button.cancel')}
                </Button>

                <Button onClick={handleSubmit(onSubmit)} color="secondary">
                    {t('button.done')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default HomeDeparturesEditDialog;
