import React, { useEffect, useState } from 'react';
import {
    FormTypePlatformTypes,
    MessageType,
    PassportType,
    TitleTypes,
    YesNoType,
} from '../../../util/Enums';
import offerService from '../../../service/OfferService';
import { Button, Card, CardActions, CardContent, CardHeader, Grid, MenuItem } from '@mui/material';
import Input from '../../../ui/Input';
import { useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useMessage from '../../../util/useMessage';
import Select from '../../../ui/Select';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CategoryInput from '../../../ui/CategoryInput';
import ParameterInput from '../../../ui/ParameterInput';
import VisaTypeInput from '../../../ui/VisaTypeInput';
import DateRangeInput from '../../../ui/DateRangeInput';
import DateInput from '../../../ui/DateInput';
import { formatDate } from '../../../util/DateUtil';

const TourReservationEditPageRequestPanel = ({
    stepId,
    note,
    sale,
    saleOfferItems,
    saleOfferTourPrograms,
    saleOfferFormId,
    formPlatformTypeId,
    genderTypeId,
    name,
    surname,
    phone,
    email,
    startDate,
    endDate,
    departure,
    visaModeId,
    visaExpirationDate,
    visaType,
    passportTypeId,
    toggle,
    next,
    isExpanded,
    onToggle,
}) => {
    const history = useHistory();
    const { t } = useTranslation();
    const showMessage = useMessage();

    const methods = useForm();
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        control,
        formState: { errors }
    } = methods;

    const [loading, setLoading] = useState(false);
    const [roomCount, setRoomCount] = useState(1);
    const [defaultTours, setDefaultTours] = useState([]);
    useEffect(() => {
        if (saleOfferItems?.length > 0) {
            setRoomCount(saleOfferItems.length);
            setValue('saleOfferItems', saleOfferItems);
        }
        if (saleOfferTourPrograms?.length > 0) {
            const defaultTourList = [];
            saleOfferTourPrograms.forEach(item => defaultTourList.push(item.tourProgramId));
            setDefaultTours(defaultTourList);
        }
    }, []);

    const onSearch = () => {
        // handleSubmit(onSubmit)();
        history.push({
            pathname: '/tours/search',
            state: {
                saleOfferFormId: saleOfferFormId || null,
                defaultSearchData: {
                    selectedTourProgramIds: defaultTours,
                    startDate:
                        getValues('startDate') && getValues('startDate') !== ''
                            ? getValues('startDate')
                            : null,
                    endDate:
                        getValues('endDate') && getValues('endDate') !== ''
                            ? getValues('endDate')
                            : null,
                    personCounts:
                        roomCount !== '' && roomCount !== null
                            ? getValues('saleOfferItems')
                            : [
                                  {
                                      adultCount: 2,
                                      childCount: 0,
                                      infantCount: 0,
                                  },
                              ],
                },
            },
        });
    };

    const onSubmit = async data => {
        if (loading) return false;

        try {
            setLoading(true);
            const obj = {
                ...data,
                saleOfferItems:
                    data.saleOfferItems?.length > 0 ? data.saleOfferItems.splice(0, roomCount) : [],
                startDate: data.startDate && data.startDate !== '' ? data.startDate : null,
                endDate: data.endDate && data.endDate !== '' ? data.endDate : null,
                saleOfferFormId: saleOfferFormId || 0,
                visaExpirationDate:
                    data.visaExpirationDate && data.visaExpirationDate !== ''
                        ? data.visaExpirationDate
                        : null,
            };
            const { isSuccess, resultObject } = await offerService.reservationForm(obj);
            if (isSuccess && resultObject) {
                if (saleOfferFormId) {
                    showMessage(t('message.updated'));
                    history.push(`/tours/reservations/edit/${resultObject.saleOfferFormId}`);
                    // if(saleOfferTourPrograms?.length > 0) {
                    //     onToggle(stepId);
                    // }
                } else {
                    showMessage(t('message.created'));
                    history.push(`/tours/reservations/edit/${resultObject.saleOfferFormId}`);
                }
            }
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    return (
        <FormProvider {...methods}>
            <form id="requestForm" onSubmit={handleSubmit(onSubmit)}>
                <Card>
                    <CardHeader
                        title="Talep Bilgileri"
                        titleTypographyProps={{ variant: 'subtitle1' }}
                        action={
                            <>
                                {isExpanded(stepId) && (
                                    <>
                                        {saleOfferFormId && (
                                            <Button color="secondary" onClick={onSearch}>
                                                TEKLİF OLUŞTUR
                                            </Button>
                                        )}
                                        <Button color="secondary" type="submit">
                                            {saleOfferFormId && saleOfferTourPrograms?.length > 0
                                                ? 'KAYDET VE İLERLE'
                                                : 'KAYDET'}
                                        </Button>
                                    </>
                                )}
                                <Button color="secondary" onClick={() => toggle(stepId)}>
                                    {isExpanded(stepId) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </Button>
                            </>
                        }
                    />
                    {isExpanded(stepId) && (
                        <CardContent>
                            <Grid container spacing={1}>
                                <Grid item xs={3}>
                                    <fieldset style={{ height: '96%' }}>
                                        <legend>Müşteri bilgileri:</legend>
                                        <Select
                                            name="formPlatformTypeId"
                                            defaultValue={formPlatformTypeId || null}
                                            label={t('reservation.Communication')}
                                            error={errors?.formPlatformTypeId}
                                            rules={{ required: t('validation.required') }}
                                            control={control}>
                                            {Object.values(FormTypePlatformTypes).map(item => {
                                                return (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {t(item.label)}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        <Input
                                            name="name"
                                            label="Ad"
                                            defaultValue={name || null}
                                            control={control}
                                            error={errors?.name}
                                            {...register('name', {
                                                required: t('validation.required'),
                                            })}
                                        />
                                        <Input
                                            name="surname"
                                            label="Soyad"
                                            defaultValue={surname || null}
                                            control={control}
                                            error={errors?.surname}
                                            {...register('surname')}
                                        />
                                        <Select
                                            name="genderTypeId"
                                            defaultValue={genderTypeId || null}
                                            label={t('reservation.title')}
                                            error={errors?.genderTypeId}
                                            control={control}
                                            rules={{ required: t('validation.required') }}>
                                            {Object.values(TitleTypes).map(item => {
                                                return (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {t(item.label)}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        <Input
                                            name="phone"
                                            label="Telefon"
                                            defaultValue={phone || null}
                                            control={control}
                                            error={errors?.phone}
                                            {...register('phone')}
                                            onChange={e => {
                                                e.preventDefault();
                                                const val = e.target.value;
                                                setValue(
                                                    'phone',
                                                    val.replace(/ /g, '').replace(/-/g, ''),
                                                );
                                            }}
                                        />
                                        <Input
                                            name="email"
                                            label="Email"
                                            defaultValue={email || null}
                                            control={control}
                                            error={errors?.email}
                                            {...register('email')}
                                        />
                                    </fieldset>
                                </Grid>

                                <Grid item xs={3}>
                                    <fieldset style={{ height: '96%' }}>
                                        <legend>Vize:</legend>
                                        <Select
                                            name="passportTypeId"
                                            label={t('reservation.passportType')}
                                            defaultValue={passportTypeId}
                                            error={errors?.passportTypeId}
                                            control={control}>
                                            {Object.values(PassportType).map(item => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {t(item.label)}
                                                </MenuItem>
                                            ))}
                                        </Select>

                                        <VisaTypeInput
                                            name="visaType"
                                            label={t('reservation.visa')}
                                            control={control}
                                            error={errors?.visaType}
                                            defaultValue={visaType}
                                            multiple={false}
                                        />

                                        <DateInput
                                            name="visaExpirationDate"
                                            label={t('reservation.visaExpirationDate')}
                                            defaultValue={visaExpirationDate || null}
                                            error={errors?.visaExpirationDate}
                                            // rules={{ required: t('validation.required') }}
                                            control={control}
                                        />

                                        <Select
                                            name="visaModeId"
                                            label={t('reservation.visaType')}
                                            defaultValue={visaModeId || null}
                                            error={errors?.visaModeId}
                                            control={control}>
                                                <MenuItem value={1}>
                                                    Tekli
                                                </MenuItem>
                                                <MenuItem value={2}>
                                                    Çoklu
                                                </MenuItem>
                                        </Select>
                                    </fieldset>
                                </Grid>

                                <Grid item xs={3}>
                                    <fieldset style={{ height: '96%' }}>
                                        <legend>Tur Arama:</legend>

                                        <DateRangeInput
                                            control={control}
                                            startProps={{
                                                name: 'startDate',
                                                error: errors?.startDate,
                                                defaultValue:
                                                    formatDate(startDate, 'dd/MM/yyyy') || null,
                                            }}
                                            endProps={{
                                                name: 'endDate',
                                                error: errors?.endDate,
                                                defaultValue:
                                                    formatDate(endDate, 'dd/MM/yyyy') || null,
                                            }}
                                        />
                                        <CategoryInput
                                            name="departure"
                                            control={control}
                                            error={errors?.departure}
                                            categoryType={2}
                                            label={t('label.departure')}
                                            multiple={false}
                                            defaultValue={departure || null}
                                        />
                                    </fieldset>
                                </Grid>

                                <Grid item xs={3}>
                                    <fieldset style={{ height: '96%' }}>
                                        <legend>Oda Sayısı:</legend>
                                        <Input
                                            type="number"
                                            label="Oda sayısı"
                                            value={roomCount || null}
                                            onChange={e => {
                                                if (
                                                    e.target.value === '' ||
                                                    e.target.value === null
                                                ) {
                                                    setRoomCount(e.target.value);
                                                } else if (
                                                    !isNaN(parseInt(e.target.value)) &&
                                                    parseInt(e.target.value) < 6
                                                ) {
                                                    setRoomCount(e.target.value);
                                                }
                                            }}
                                            // disabled={!!sale}
                                        />
                                        <Grid container spacing={1}>
                                            {roomCount &&
                                                roomCount !== null &&
                                                [...Array(parseInt(roomCount)).keys()].map(n => (
                                                    <Grid key={n} item xs={4}>
                                                        <Input
                                                            type="number"
                                                            name={`saleOfferItems[${n}].adultCount`}
                                                            label={`${n + 1}. Oda Yetişkin sayısı`}
                                                            defaultValue={
                                                                saleOfferItems
                                                                    ? saleOfferItems[n]
                                                                          ?.adultCount || 2
                                                                    : 2
                                                            }
                                                            control={control}
                                                            error={
                                                                errors?.saleOfferItems &&
                                                                errors.saleOfferItems[n]?.adultCount
                                                            }
                                                            {...register(
                                                                `saleOfferItems[${n}].adultCount`,
                                                                {
                                                                    min: 1,
                                                                    max: 3,
                                                                },
                                                            )}
                                                            // disabled={sale ? true : false}
                                                        />

                                                        <Input
                                                            type="number"
                                                            name={`saleOfferItems[${n}].childCount`}
                                                            label={`${n + 1}. Oda Çocuk sayısı`}
                                                            defaultValue={
                                                                saleOfferItems
                                                                    ? saleOfferItems[n]
                                                                          ?.childCount || 0
                                                                    : 0
                                                            }
                                                            control={control}
                                                            error={
                                                                errors?.saleOfferItems &&
                                                                errors.saleOfferItems[n]?.childCount
                                                            }
                                                            {...register(
                                                                `saleOfferItems[${n}].childCount`,
                                                                {
                                                                    max: 2,
                                                                },
                                                            )}
                                                            // disabled={sale ? true : false}
                                                        />

                                                        <Input
                                                            type="number"
                                                            name={`saleOfferItems[${n}].infantCount`}
                                                            label={`${n + 1}. Oda Bebek sayısı`}
                                                            defaultValue={
                                                                saleOfferItems
                                                                    ? saleOfferItems[n]
                                                                          ?.infantCount || 0
                                                                    : 0
                                                            }
                                                            control={control}
                                                            error={
                                                                errors?.saleOfferItems &&
                                                                errors.saleOfferItems[n]
                                                                    ?.infantCount
                                                            }
                                                            {...register(
                                                                `saleOfferItems[${n}].infantCount`,
                                                                {
                                                                    max: 2,
                                                                },
                                                            )}
                                                            // disabled={sale ? true : false}
                                                        />
                                                    </Grid>
                                                ))}
                                        </Grid>
                                    </fieldset>
                                </Grid>

                                <Grid item xs={12}>
                                    <Input
                                        name="note"
                                        label={t('reservation.note')}
                                        multiline
                                        rows={2}
                                        defaultValue={note || null}
                                        control={control}
                                        error={errors?.note}
                                        {...register('note')}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    )}
                    {isExpanded(stepId) && (
                        <CardActions>
                            <Button color="secondary" sx={{ marginLeft: 'auto' }} type="submit">
                                {saleOfferFormId && saleOfferTourPrograms?.length > 0
                                    ? 'KAYDET VE İLERLE'
                                    : 'KAYDET'}
                            </Button>
                        </CardActions>
                    )}
                </Card>
            </form>
        </FormProvider>
    );
};

export default TourReservationEditPageRequestPanel;
