import BaseService from './BaseService';
import { easyPost } from '../util/Fetch';

class TourProgramService extends BaseService {
    constructor() {
        super('/tours/programs');
    }
}

const tourProgramService = new TourProgramService();

export default tourProgramService;
