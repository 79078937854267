import BaseService from './BaseService';

class HotelService extends BaseService {
    constructor() {
        super('/common/hotels');
    }
}

const hotelService = new HotelService();

export default hotelService;
