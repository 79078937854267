import { YesNoType } from '../util/Enums';
import { useTranslation } from 'react-i18next';

const YesNoTypeOptions = () => {
    const { t } = useTranslation();

    return Object.values(YesNoType).map(item => (
        <option key={item.id} value={item.id}>
            {t(item.label)}
        </option>
    ));
};

const IsDeletedColumn = {
    name: 'isDeleted',
    width: 50,
    searchable: true,
    searchType: 'select',
    defaultValue: '0',
    selectOptions: <YesNoTypeOptions />,
};

const getDeletedColumn = t => {
    return { ...IsDeletedColumn, label: t('label.deleted'), execute: item => t(`label.${item}`) };
};

export { IsDeletedColumn, getDeletedColumn, YesNoTypeOptions };
