import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ImageUploadProgressTable = ({ images }) => {
    const { t } = useTranslation();

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell width={25}>#</TableCell>
                    <TableCell>{t('label.fileName')}</TableCell>
                    <TableCell />
                    <TableCell>{t('label.status')}</TableCell>
                    <TableCell />
                </TableRow>
            </TableHead>
            <TableBody>
                {images?.map((item, index) => (
                    <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{item.file.name}</TableCell>
                        <TableCell>{`%${item.percentage}`}</TableCell>
                        <TableCell>
                            <div>{t(item.error ? 'status.error' : 'status.success')}</div>
                        </TableCell>
                        <TableCell>
                            <div style={{ color: 'red' }}>{item.message}</div>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default ImageUploadProgressTable;
