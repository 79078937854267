import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { eachDayOfInterval, format } from 'date-fns';
import Input from '../../ui/Input';
import DayOfTheWeekNameInput from '../../ui/DayOfTheWeekNameInput';
import DaysOfTheMonth from '../../ui/DaysOfTheMonth';
import MenuItem from '@mui/material/MenuItem';
import Select from '../../ui/Select';
import DateInput from '../../ui/DateInput';
import { formatDate } from '../../util/DateUtil';

const GenerationTypes = [
    { name: 'dayOfTheWeekNames', format: 'cccc' },
    { name: 'daysOfTheMonth', format: 'd' },
];

const TourPeriodDateWithDayEditDialog = ({ periodDates, open, onClose, onDoneCallback }) => {
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        control,
        reset,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: { generationType: GenerationTypes[0].name },
    });

    const generationType = watch('generationType', 'dayOfTheWeekNames');

    useEffect(() => {
        if (open) {
            reset();
        }
    }, [open]);

    const onSubmit = async data => {
        const values = [];
        const startDate = new Date(data.startDate);
        const endDate = new Date(data.endDate);
        const dates = eachDayOfInterval({ start: startDate, end: endDate });

        const items = periodDates.map(item => item.date);
        const convertedList = items.map(item => format(item, 'dd.MM.yyyy'));

        const formatString = GenerationTypes.find(
            item => item.name === data.generationType,
        )?.format;

        if (formatString) {
            dates.forEach(date => {
                const convert = format(date, 'dd.MM.yyyy');
                if (!convertedList.includes(convert)) {
                    const name = format(date, formatString);
                    const check = data.days.find(item => item.value === name);
                    if (check) {
                        values.push(date);
                    }
                }
            });
        }

        onDoneCallback && onDoneCallback([...items, ...values]);
    };

    return (
        <Dialog open={open} maxWidth={'sm'} scroll="paper">
            <DialogTitle>{t('title.quickDayGenerate')}</DialogTitle>
            <DialogContent dividers={true}>
                <div style={{ textAlign: 'center' }}>
                    <DateInput
                        name="startDate"
                        label={t('label.startDate')}
                        defaultValue={null}
                        error={errors?.startDate}
                        rules={{ required: t('validation.required') }}
                        control={control}
                    />
                    <DateInput
                        name="endDate"
                        label={t('label.endDate')}
                        defaultValue={null}
                        error={errors?.endDate}
                        rules={{ required: t('validation.required') }}
                        control={control}
                    />

                    <Select
                        name="generationType"
                        rules={{ required: t('validation.required') }}
                        error={errors?.generationType}
                        label={t('label.generationType')}
                        control={control}>
                        {GenerationTypes.map((item, index) => {
                            return (
                                <MenuItem key={index} value={item.name}>
                                    {t(`label.${item.name}`)}
                                </MenuItem>
                            );
                        })}
                    </Select>

                    {generationType === 'dayOfTheWeekNames' && (
                        <DayOfTheWeekNameInput
                            name="days"
                            control={control}
                            error={errors?.days}
                            rules={{ required: t('validation.required') }}
                        />
                    )}

                    {generationType === 'daysOfTheMonth' && (
                        <DaysOfTheMonth
                            name="days"
                            control={control}
                            error={errors?.days}
                            rules={{ required: t('validation.required') }}
                        />
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t('button.cancel')}
                </Button>
                <Button onClick={handleSubmit(onSubmit)}>{t('button.generate')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default React.memo(TourPeriodDateWithDayEditDialog);
