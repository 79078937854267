import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import SubmitButton from '../ui/SubmitButton';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from '../components/DataTable';
import usePagination from '../util/usePagination';
import vendorService from '../service/VendorService';
import ContactCreateDialog from './ContactCreateDialog';

const ContactAddDialog = ({ open, onClose, onAddCallback, selected, setSelected }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [openVendorCreateDialog, setOpenVendorCreateDialog] = useState(false);
    const columns = [
        {
            name: 'id',
            label: 'ID',
            searchable: true,
            width: 100,
            type: Number,
        },
        {
            name: 'name',
            label: t('label.name'),
            searchable: true,
        },
        {
            name: 'surname',
            label: t('label.surname'),
            searchable: true,
        },
        {
            name: 'email',
            label: t('label.email'),
            searchable: true,
        },
        {
            name: 'telephone1',
            label: t('label.telephone1'),
            searchable: true,
        },
    ];
    const vendors = usePagination({
        service: vendorService,
        defaultSearchData: {
            contactTypeId: 2,
        },
    });
    // useEffect(() => {
    //     if (open) {
    //         // setSelected([contact] || []);
    //     }
    // }, [open]);
    return (
        <>
            <Dialog open={open} fullWidth maxWidth="lg" scroll="paper">
                <DialogTitle>{t('title.chooseContactDialog')}</DialogTitle>
                <DialogContent dividers={true}>
                    <DataTable
                        title={t('label.contactList')}
                        columns={columns}
                        {...vendors}
                        {...{ selected, setSelected}}
                        multiSelect={false}
                        // action={
                        //     <Button
                        //         color={'secondary'}
                        //         startIcon={<AddIcon />}
                        //         onClick={() => setOpenVendorCreateDialog(true)}
                        //     >
                        //         MÜŞTERİ EKLE
                        //     </Button>
                        // }
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary" disabled={loading}>
                        {t('button.cancel')}
                    </Button>
                    <SubmitButton
                        type="button"
                        loading={loading}
                        startIcon={<AddIcon />}
                        onClick={() => {
                            if(onAddCallback) {
                                if(selected.length > 0) {
                                    onAddCallback(selected[0]);
                                } else {
                                    onAddCallback(null);
                                }
                            }
                        }}>
                        {t('button.add')}
                    </SubmitButton>
                </DialogActions>
            </Dialog>
            <ContactCreateDialog
                open={openVendorCreateDialog}
                onClose={() => setOpenVendorCreateDialog(false)}
                onAddCallback={data => {
                    setSelected(data);
                    setOpenVendorCreateDialog(false);
                }}
            />
        </>
    );
};
export default ContactAddDialog;
