import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import SaveIcon from '@mui/icons-material/Save';
import SubmitButton from '../../../ui/SubmitButton';
import { findEnumById, MessageType, SaleItemPurchaseType } from '../../../util/Enums';
import useMessage from '../../../util/useMessage';
import TourPurchaseUpdateTable from './TourPurchaseUpdateTable';
import purchaseService from '../../../service/PurchaseService';

const TourPurchaseUpdateDialog = ({
    purchasePaymentStatusTypeId,
    purchaseList = null,
    open,
    onClose,
    onDoneCallback,
}) => {
    const { t } = useTranslation();
    const showMessage = useMessage();

    const [loading, setLoading] = useState(false);
    const methods = useForm();
    const { handleSubmit, reset } = methods;

    const [purchaseMap, setPurchaseMap] = useState(new Map());

    const [isBuyForeignCurrency, setIsBuyForeignCurrency] = useState(false);

    useEffect(() => {
        const $isBuyForeignCurrency =
            purchasePaymentStatusTypeId - 1 === SaleItemPurchaseType.BuyForeignCurrency.id;
        setIsBuyForeignCurrency($isBuyForeignCurrency);

        if (open && $isBuyForeignCurrency) {
            const groupBy = Map.groupBy(purchaseList, ({ currencyTypeId }) => currencyTypeId || 0);
            setPurchaseMap(groupBy);

            const obj = {
                currency: {},
            };
            groupBy.keys().forEach(key => {
                obj['currency'][key] = [];
                const values = groupBy.get(key);
                values.forEach(item => {
                    obj['currency'][key].push(item);
                });
            });
            reset(obj);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }, [open, purchasePaymentStatusTypeId, reset, purchaseList]);

    const onSubmit = async data => {
        if (loading) return false;

        const idList = purchaseList.map(item => item.saleItemPurchasePaymentId);

        const request = {
            saleItemPurchasePaymentIdList: idList,
            purchasePaymentStatusTypeId: purchasePaymentStatusTypeId,
        };

        const updatedPurchasePayments = [];

        if (isBuyForeignCurrency) {
            const { currency } = data;
            Object.values(currency).forEach(cType => {
                Object.values(cType).forEach(item => {
                    updatedPurchasePayments.push({
                        saleItemPurchasePaymentId: item.saleItemPurchasePaymentId,
                        currencyTypeId: item.currencyTypeId,
                        bankId: item.bankId,
                        exchangeBuyingRate: item.exchangeBuyingRate,
                        exchangeBuyTL: item.exchangeBuyTL,
                    });
                });
            });
        }

        request['updatedPurchasePayments'] = updatedPurchasePayments;

        setLoading(true);
        purchaseService
            .updateList(request)
            .then(() => {
                onClose && onClose();
                onDoneCallback && onDoneCallback();
            })
            .catch(({ code, message }) => {
                showMessage(`${code} - ${message}`, MessageType.ERROR);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    if (!open) {
        return null;
    }

    return (
        <Dialog open={open} fullWidth maxWidth="lg" scroll="paper">
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle>
                        {t(
                            isBuyForeignCurrency
                                ? 'title.buyForeignCurrencyTourPurchaseDialog'
                                : 'title.updateTourPurchaseDialog',
                        )}
                    </DialogTitle>
                    <DialogContent dividers={true} style={{ padding: 0 }}>
                        {!isBuyForeignCurrency && (
                            <div style={{ padding: 24 }}>
                                Seçilen satınalmaların durumlarını{' '}
                                <strong>
                                    {
                                        findEnumById(
                                            SaleItemPurchaseType,
                                            purchasePaymentStatusTypeId,
                                        )?.label
                                    }
                                </strong>{' '}
                                olarak güncellemek istediğinizden emin misiniz?
                            </div>
                        )}

                        {isBuyForeignCurrency &&
                            [...purchaseMap.keys()].map((currencyType, index) => {
                                return (
                                    <TourPurchaseUpdateTable
                                        key={index}
                                        currencyType={currencyType}
                                        data={purchaseMap}
                                    />
                                );
                            })}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary" disabled={loading}>
                            {t('button.cancel')}
                        </Button>
                        <SubmitButton loading={loading} startIcon={<SaveIcon />}>
                            {t('button.update')}
                        </SubmitButton>
                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog>
    );
};

export default TourPurchaseUpdateDialog;
