import SettingsIcon from '@mui/icons-material/Settings';
import RoomIcon from '@mui/icons-material/Room';
import RegionListPage from './pages/common/regions/RegionListPage';
import RegionEditPage from './pages/common/regions/RegionEditPage';
import TagListPage from './pages/common/tags/TagListPage';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import TagEditPage from './pages/common/tags/TagEditPage';
import CategoryListPage from './pages/common/categories/CategoryListPage';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CategoryEditPage from './pages/common/categories/CategoryEditPage';
import EmojiFoodBeverageIcon from '@mui/icons-material/EmojiFoodBeverage';
import FoodListPage from './pages/common/foods/FoodListPage';
import FoodEditPage from './pages/common/foods/FoodEditPage';
import EventIcon from '@mui/icons-material/Event';
import SpecialDayListPage from './pages/common/specialDays/SpecialDayListPage';
import SpecialDayEditPage from './pages/common/specialDays/SpecialDayEditPage';
import DescriptionIcon from '@mui/icons-material/Description';
import PostListPage from './pages/posts/PostListPage';
import PostEditPage from './pages/posts/PostEditPage';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import PageListPage from './pages/combinations/PageListPage';
import PageEditPage from './pages/combinations/PageEditPage';
import PlaceIcon from '@mui/icons-material/Place';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import ExploreIcon from '@mui/icons-material/Explore';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import MapIcon from '@mui/icons-material/Map';
import CategoryIcon from '@mui/icons-material/Category';
import BallotIcon from '@mui/icons-material/Ballot';
import TourCatalogListPage from './pages/tours/TourCatalogListPage';
import TourCatalogEditPage from './pages/tours/TourCatalogEditPage';
import LockIcon from '@mui/icons-material/Lock';
import RoleListPage from './pages/auths/RoleListPage';
import RoleEditPage from './pages/auths/RoleEditPage';
import UserListPage from './pages/auths/UserListPage';
import UserEditPage from './pages/auths/UserEditPage';
import VendorListPage from './pages/common/vendors/VendorListPage';
import VendorEditPage from './pages/common/vendors/VendorEditPage';
import ExtraTourListPage from './pages/common/extraTours/ExtraTourListPage';
import ExtraTourEditPage from './pages/common/extraTours/ExtraTourEditPage';
import ExchangeRateListPage from './pages/common/exchangeRates/ExchangeRateListPage';
import ExchangeRateEditPage from './pages/common/exchangeRates/ExchangeRateEditPage';
import TourProgramListPage from './pages/tours/TourProgramListPage';
import TourProgramEditPage from './pages/tours/TourProgramEditPage';
import { ViewQuiltOutlined } from '@mui/icons-material';
import OpportunityEditPage from './pages/campaigns/OpportunityEditPage';
import AdditionalServicesListPage from './pages/common/additionalServices/AdditionalServicesListPage';
import AdditionalServicesEditPage from './pages/common/additionalServices/AdditionalServicesEditPage';
import ParameterListPage from './pages/common/parameters/ParameterListPage';
import ParameterEditPage from './pages/common/parameters/ParameterEditPage';
import TourPriceListPage from './pages/tours/TourPriceListPage';
import TourPriceEditPage from './pages/tours/TourPriceEditPage';
import HotelListPage from './pages/common/hotels/HotelListPage';
import HotelEditPage from './pages/common/hotels/HotelEditPage';
import VisaTypeListPage from './pages/common/visaTypes/VisaTypeListPage';
import VisaTypeEditPage from './pages/common/visaTypes/VisaTypeEditPage';
import GeneralConditionEditPage from './pages/common/generalConditions/GeneralConditionEditPage';
import MessageIcon from '@mui/icons-material/Message';
import CommentListPage from './pages/comments/CommentListPage';
import HomeEditPage from './pages/common/home/HomeEditPage';
import ForecastPage from './pages/tours/forecast/ForecastPage';
import TourReservationListPage from './pages/tours/reservations/TourReservationListPage';
import TourReservationEditPage from './pages/tours/reservations/TourReservationEditPage';
import TourPurchaseListPage from './pages/tours/purchases/TourPurchaseListPage';
import TourPaymentListPage from './pages/tours/payments/TourPaymentListPage';
import SaleCampaignListPage from './pages/campaigns/saleCampaigns/SaleCampaignListPage';
import SaleCampaignEditPage from './pages/campaigns/saleCampaigns/SaleCampaignEditPage';
import CalculateIcon from '@mui/icons-material/Calculate';
import AddCardIcon from '@mui/icons-material/AddCard';
import ReceiptListPage from './pages/finance/receipts/ReceiptListPage';
import ReceiptEditPage from './pages/finance/receipts/ReceiptEditPage';

const Routers = [
    {
        title: 'menu.posts',
        path: '/posts',
        icon: <DescriptionIcon />,
        component: PostListPage,
        privilege: 'post.list',
    },
    {
        title: 'title.createPosts',
        path: '/posts/create',
        component: PostEditPage,
        privilege: 'post.create',
        excludeMenu: true,
    },
    {
        title: 'title.editPosts',
        path: '/posts/edit/:id',
        component: PostEditPage,
        privilege: 'post.edit',
        excludeMenu: true,
    },
    {
        title: 'menu.pages',
        icon: <ViewQuiltIcon />,
        privilege: 'pages',
        isGroup: true,
        items: [
            {
                title: 'title.createPages',
                path: '/pages/create',
                component: PageEditPage,
                privilege: 'pages.create',
                excludeMenu: true,
            },
            {
                title: 'title.editPages',
                path: '/pages/edit/:id',
                component: PageEditPage,
                privilege: 'pages.edit',
                excludeMenu: true,
            },
            {
                title: 'menu.placePosts',
                subtitle: 'title.region',
                path: '/pages/201',
                icon: <PlaceIcon />,
                component: PageListPage,
                privilege: 'page.regionPlaces.list',
            },
            {
                title: 'menu.placePosts',
                subtitle: 'title.tag',
                path: '/pages/202',
                icon: <LocalOfferIcon />,
                component: PageListPage,
                privilege: 'page.tagPlaces.list',
            },
            {
                title: 'menu.placePosts',
                subfztitle: 'title.regionTagCombination',
                path: '/pages/203',
                icon: <ArtTrackIcon />,
                component: PageListPage,
                privilege: 'page.combinationPlaces.list',
            },
            {
                title: 'menu.foodList',
                subtitle: 'title.region',
                path: '/pages/301',
                icon: <EmojiFoodBeverageIcon />,
                component: PageListPage,
                privilege: 'page.regionFoods.list',
            },
            {
                title: 'menu.foodList',
                subtitle: 'title.regionTagCombination',
                path: '/pages/302',
                icon: <FastfoodIcon />,
                component: PageListPage,
                privilege: 'page.combinationFoods.list',
            },
            {
                title: 'menu.guideBooks',
                path: '/pages/401',
                icon: <ExploreIcon />,
                component: PageListPage,
                privilege: 'page.guideBooks.list',
            },
            {
                title: 'menu.guideBookList',
                path: '/pages/402',
                icon: <ViewStreamIcon />,
                component: PageListPage,
                privilege: 'page.guideBooksList.list',
            },
            {
                title: 'menu.tourCatalogs',
                subtitle: 'title.region',
                path: '/pages/601',
                icon: <MapIcon />,
                component: PageListPage,
                privilege: 'page.regionTourCatalogs.list',
            },
            {
                title: 'menu.tourCatalogs',
                subtitle: 'title.category',
                path: '/pages/602',
                icon: <CategoryIcon />,
                component: PageListPage,
                privilege: 'page.categoryTourCatalogs.list',
            },
            {
                title: 'menu.tourCatalogs',
                subtitle: 'title.regionCategoryCombination',
                path: '/pages/603',
                icon: <BallotIcon />,
                component: PageListPage,
                privilege: 'page.combinationTourCatalogs.list',
            },
        ],
    },
    {
        title: 'menu.tours',
        path: '/tours',
        icon: <MapIcon />,
        privilege: 'tours',
        isGroup: true,
        items: [
            {
                title: 'menu.catalogs',
                path: '/tours/catalogs',
                component: TourCatalogListPage,
                privilege: 'tours.catalog.list',
            },
            {
                title: 'title.createCatalogs',
                path: '/tours/catalogs/create',
                component: TourCatalogEditPage,
                privilege: 'tours.catalog.create',
                excludeMenu: true,
            },
            {
                title: 'title.editCatalogs',
                path: '/tours/catalogs/edit/:id',
                component: TourCatalogEditPage,
                privilege: 'tours.catalog.edit',
                excludeMenu: true,
            },
            {
                title: 'menu.programs',
                path: '/tours/programs',
                component: TourProgramListPage,
                privilege: 'tours.program.list',
            },
            {
                title: 'title.createPrograms',
                path: '/tours/programs/create',
                component: TourProgramEditPage,
                privilege: 'tours.programs.create',
                excludeMenu: true,
            },
            {
                title: 'title.editPrograms',
                path: '/tours/programs/edit/:id',
                component: TourProgramEditPage,
                privilege: 'tours.programs.edit',
                excludeMenu: true,
            },
            {
                title: 'menu.prices',
                path: '/tours/prices',
                component: TourPriceListPage,
                privilege: 'tours.prices.list',
            },
            {
                title: 'title.createPrices',
                path: '/tours/prices/create',
                component: TourPriceEditPage,
                privilege: 'tours.prices.create',
                excludeMenu: true,
            },
            {
                title: 'title.editPrices',
                path: '/tours/prices/edit/:id',
                component: TourPriceEditPage,
                privilege: 'tours.prices.edit',
                excludeMenu: true,
            },
            {
                title: 'menu.search',
                path: '/tours/search',
                component: ForecastPage,
                privilege: 'tours.search.list',
            },
            {
                title: 'menu.reservations',
                path: '/tours/reservations',
                component: TourReservationListPage,
                privilege: 'tours.reservations.list',
            },
            {
                title: 'title.createReservations',
                path: '/tours/reservations/create',
                component: TourReservationEditPage,
                privilege: 'tours.reservations.create',
                excludeMenu: true,
            },
            {
                title: 'title.editReservations',
                path: '/tours/reservations/edit/:id',
                component: TourReservationEditPage,
                privilege: 'tours.reservations.edit',
                excludeMenu: true,
            }
        ],
    },
    {
        title: 'menu.finance',
        path: '/finance',
        icon: <CalculateIcon />,
        privilege: 'finance',
        isGroup: true,
        items: [
            {
                title: 'menu.receipts',
                path: '/finance/receipts',
                icon: <AddCardIcon />,
                component: ReceiptListPage,
                privilege: 'finance.receipts.list',
            },
            {
                title: 'title.createReceipt',
                path: '/finance/receipts/create',
                component: ReceiptEditPage,
                privilege: 'finance.receipts.create',
                excludeMenu: true,
            },
            {
                title: 'title.editReceipt',
                path: '/finance/receipts/edit/:id',
                component: ReceiptEditPage,
                privilege: 'finance.receipts.edit',
                excludeMenu: true,
            },
            {
                title: 'menu.purchases',
                path: '/tours/purchases',
                component: TourPurchaseListPage,
                privilege: 'tours.purchases.list',
            },
            {
                title: 'menu.payments',
                path: '/tours/payments',
                component: TourPaymentListPage,
                privilege: 'tours.payments.list',
            },
        ],
    },
    {
        title: 'menu.campaigns',
        path: '/campaigns',
        icon: <LocalOfferIcon />,
        privilege: 'campaigns',
        isGroup: true,
        items: [
            {
                title: 'menu.opportunity',
                path: '/campaigns/opportunity',
                component: OpportunityEditPage,
                privilege: 'campaigns.opportunity',
            },
            {
                title: 'menu.saleCampaigns',
                path: '/campaigns/saleCampaigns',
                component: SaleCampaignListPage,
                privilege: 'campaigns.saleCampaign.list',
            },
            {
                title: 'title.createSaleCampaign',
                path: '/campaigns/saleCampaigns/create',
                component: SaleCampaignEditPage,
                privilege: 'campaigns.saleCampaign.create',
                excludeMenu: true,
            },
            {
                title: 'title.editSaleCampaign',
                path: '/campaigns/saleCampaigns/edit/:id',
                component: SaleCampaignEditPage,
                privilege: 'campaigns.saleCampaign.edit',
                excludeMenu: true,
            },
        ],
    },
    {
        title: 'menu.definitions',
        path: '/settings',
        icon: <SettingsIcon />,
        privilege: 'settings',
        isGroup: true,
        items: [
            {
                title: 'menu.regions',
                path: '/settings/regions',
                icon: <RoomIcon />,
                component: RegionListPage,
                privilege: 'common.regions.list',
            },
            {
                title: 'title.createRegion',
                path: '/settings/regions/create',
                component: RegionEditPage,
                privilege: 'common.regions.create',
                excludeMenu: true,
            },
            {
                title: 'title.editRegion',
                path: '/settings/regions/edit/:id',
                component: RegionEditPage,
                privilege: 'common.regions.edit',
                excludeMenu: true,
            },
            {
                title: 'menu.tags',
                path: '/settings/tags',
                icon: <LocalOfferIcon />,
                component: TagListPage,
                privilege: 'common.tags.list',
            },
            {
                title: 'title.createTags',
                path: '/settings/tags/create',
                component: TagEditPage,
                privilege: 'common.tags.create',
                excludeMenu: true,
            },
            {
                title: 'title.editTags',
                path: '/settings/tags/edit/:id',
                component: TagEditPage,
                privilege: 'common.tags.edit',
                excludeMenu: true,
            },
            {
                title: 'menu.categories',
                path: '/settings/categories',
                icon: <AccountTreeIcon />,
                component: CategoryListPage,
                privilege: 'common.categories.list',
            },
            {
                title: 'title.createCategories',
                path: '/settings/categories/create',
                component: CategoryEditPage,
                privilege: 'common.categories.create',
                excludeMenu: true,
            },
            {
                title: 'title.editCategories',
                path: '/settings/categories/edit/:id',
                component: CategoryEditPage,
                privilege: 'common.categories.edit',
                excludeMenu: true,
            },
            {
                title: 'menu.foods',
                path: '/settings/foods',
                icon: <EmojiFoodBeverageIcon />,
                component: FoodListPage,
                privilege: 'common.foods.list',
            },
            {
                title: 'title.createFoods',
                path: '/settings/foods/create',
                component: FoodEditPage,
                privilege: 'common.foods.create',
                excludeMenu: true,
            },
            {
                title: 'title.editFoods',
                path: '/settings/foods/edit/:id',
                component: FoodEditPage,
                privilege: 'common.foods.edit',
                excludeMenu: true,
            },
            {
                title: 'menu.specialDays',
                path: '/settings/specialDays',
                icon: <EventIcon />,
                component: SpecialDayListPage,
                privilege: 'common.specialDays.list',
            },
            {
                title: 'title.createSpecialDays',
                path: '/settings/specialDays/create',
                component: SpecialDayEditPage,
                privilege: 'common.specialDays.create',
                excludeMenu: true,
            },
            {
                title: 'title.editSpecialDays',
                path: '/settings/specialDays/edit/:id',
                component: SpecialDayEditPage,
                privilege: 'common.specialDays.edit',
                excludeMenu: true,
            },
            {
                title: 'menu.vendors',
                path: '/settings/vendors',
                component: VendorListPage,
                privilege: 'common.vendors.list',
            },
            {
                title: 'title.createVendors',
                path: '/settings/vendors/create',
                component: VendorEditPage,
                privilege: 'common.vendors.create',
                excludeMenu: true,
            },
            {
                title: 'title.editVendors',
                path: '/settings/vendors/edit/:id',
                component: VendorEditPage,
                privilege: 'common.vendors.edit',
                excludeMenu: true,
            },
            {
                title: 'menu.extraTours',
                path: '/settings/extraTours',
                component: ExtraTourListPage,
                privilege: 'common.extraTours.list',
            },
            {
                title: 'title.createExtraTours',
                path: '/settings/extraTours/create',
                component: ExtraTourEditPage,
                privilege: 'common.extraTours.create',
                excludeMenu: true,
            },
            {
                title: 'title.editExtraTours',
                path: '/settings/extraTours/edit/:id',
                component: ExtraTourEditPage,
                privilege: 'common.extraTours.edit',
                excludeMenu: true,
            },
            {
                title: 'menu.additionalServices',
                path: '/settings/additionalServices',
                component: AdditionalServicesListPage,
                privilege: 'common.additionalServices.list',
            },
            {
                title: 'title.createAdditionalService',
                path: '/settings/additionalServices/create',
                component: AdditionalServicesEditPage,
                privilege: 'common.additionalServices.create',
                excludeMenu: true,
            },
            {
                title: 'title.editAdditionalService',
                path: '/settings/additionalServices/edit/:id',
                component: AdditionalServicesEditPage,
                privilege: 'common.additionalServices.edit',
                excludeMenu: true,
            },
            {
                title: 'menu.exchangeRates',
                path: '/settings/exchangeRates',
                component: ExchangeRateListPage,
                privilege: 'common.exchangeRates.list',
            },
            {
                title: 'title.createExchangeRates',
                path: '/settings/exchangeRates/create',
                component: ExchangeRateEditPage,
                privilege: 'common.exchangeRates.create',
                excludeMenu: true,
            },
            {
                title: 'title.editExchangeRates',
                path: '/settings/exchangeRates/edit/:id',
                component: ExchangeRateEditPage,
                privilege: 'common.exchangeRates.edit',
                excludeMenu: true,
            },
            {
                title: 'title.generalParameters',
                path: '/settings/parameters',
                component: ParameterListPage,
                privilege: 'common.generalParameters.list',
            },
            {
                title: 'title.createGeneralParameters',
                path: '/settings/parameters/create',
                component: ParameterEditPage,
                privilege: 'common.generalParameters.create',
                excludeMenu: true,
            },
            {
                title: 'title.editGeneralParameters',
                path: '/settings/parameters/edit/:id',
                component: ParameterEditPage,
                privilege: 'common.generalParameters.edit',
                excludeMenu: true,
            },
            {
                title: 'title.hotels',
                path: '/settings/hotels',
                component: HotelListPage,
                privilege: 'common.hotels.list',
            },
            {
                title: 'title.createHotels',
                path: '/settings/hotels/create',
                component: HotelEditPage,
                privilege: 'common.hotels.create',
                excludeMenu: true,
            },
            {
                title: 'title.editHotels',
                path: '/settings/hotels/edit/:id',
                component: HotelEditPage,
                privilege: 'common.hotels.edit',
                excludeMenu: true,
            },
            {
                title: 'title.visaTypes',
                path: '/settings/visaTypes',
                component: VisaTypeListPage,
                privilege: 'common.hotels.list',
            },
            {
                title: 'title.createVisaTypes',
                path: '/settings/visaTypes/create',
                component: VisaTypeEditPage,
                privilege: 'common.visaTypes.create',
                excludeMenu: true,
            },
            {
                title: 'title.editVisaTypes',
                path: '/settings/visaTypes/edit/:id',
                component: VisaTypeEditPage,
                privilege: 'common.visaTypes.edit',
                excludeMenu: true,
            },
            {
                title: 'title.generalConditions',
                path: '/settings/generalConditions',
                component: GeneralConditionEditPage,
                privilege: 'common.generalConditions.edit',
            },
            {
                title: 'title.home',
                path: '/settings/home',
                component: HomeEditPage,
                privilege: 'common.home.edit',
            },
        ],
    },
    {
        title: 'menu.staticPages',
        path: '/pages/702',
        icon: <ViewQuiltOutlined />,
        component: PageListPage,
        privilege: 'menu.staticPages.list',
    },
    {
        title: 'menu.comments',
        path: '/comments',
        icon: <MessageIcon />,
        component: CommentListPage,
        privilege: 'menu.comments.list',
    },
    {
        title: 'menu.auths',
        path: '/auths',
        icon: <LockIcon />,
        privilege: 'auths',
        isGroup: true,
        items: [
            {
                title: 'menu.roles',
                path: '/auths/roles',
                component: RoleListPage,
                privilege: 'auths.roles.list',
            },
            {
                title: 'title.createRoles',
                path: '/auths/roles/create',
                component: RoleEditPage,
                privilege: 'auths.roles.create',
                excludeMenu: true,
            },
            {
                title: 'title.editRoles',
                path: '/auths/roles/edit/:id',
                component: RoleEditPage,
                privilege: 'auths.roles.edit',
                excludeMenu: true,
            },

            {
                title: 'menu.users',
                path: '/auths/users',
                component: UserListPage,
                privilege: 'auths.users.list',
            },
            {
                title: 'title.createUsers',
                path: '/auths/users/create',
                component: UserEditPage,
                privilege: 'auths.users.create',
                excludeMenu: true,
            },
            {
                title: 'title.editUsers',
                path: '/auths/users/edit/:id',
                component: UserEditPage,
                privilege: 'auths.users.edit',
                excludeMenu: true,
            },
        ],
    },
];

export default Routers;
