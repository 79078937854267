import { useTranslation } from 'react-i18next';
import { Button, Card, CardContent, Grid, MenuItem, Select } from '@mui/material';
import { MessageType, SaleStatusTypes, SaleTypes } from '../../../util/Enums';
import Input from '../../../ui/Input';
import React, { useEffect, useRef, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import offerService from '../../../service/OfferService';
import useMessage from '../../../util/useMessage';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useHistory } from 'react-router-dom';
import TourReservationReceiptDialog from './TourReservationReceiptDialog';
import TourReservationVoucherDialog from './TourReservationVoucherDialog';

const TourReservationEditHeadPanel = ({
    saleOfferFormId,
    createdDate,
    saleTypeId,
    saleStatusTypeId,
    url,
    token,
    sale,
    saleGuid,
    isDeleted,
}) => {
    const isMount = useRef(false);
    const { t } = useTranslation();
    const showMessage = useMessage();
    const [selectedSaleTypeId, setSelectedSaleTypeId] = useState('');
    const [selectedStatusTypeId, setSelectedStatusTypeId] = useState('');

    const [saleStatusTypeList, setSaleStatusTypeList] = useState([]);
    const [openReceiptPdfDialog, setOpenReceiptPdfDialog] = useState(false);
    // const [openVoucherPdfDialog, setOpenVoucherPdfDialog] = useState(false);

    const history = useHistory();

    useEffect(() => {
        isMount.current = true;
        const listOfSaleStatusType = Object.values(SaleStatusTypes).filter(
            item => item.saleTypeId === saleTypeId,
        );
        setSelectedSaleTypeId(saleTypeId);
        setSelectedStatusTypeId(saleStatusTypeId);
        setSaleStatusTypeList(listOfSaleStatusType);

        return () => {
            isMount.current = false;
        };
    }, [saleTypeId, saleStatusTypeId]);

    const onSaleTypeIdChange = e => {
        const _saleTypeId = e.target.value;
        if (saleTypeId === 3 && (_saleTypeId === 1 || _saleTypeId === 2)) {
            e.preventDefault();
            showMessage(t('message.noChangeReservation'), MessageType.ERROR);
        } else if (
            _saleTypeId === 3 &&
            sale?.salePaymentSummary?.paidAmount !== sale?.salePaymentSummary?.totalSalePrice
        ) {
            e.preventDefault();
            showMessage(t('message.notDecont'), MessageType.ERROR);
        } else {
            const listOfSaleStatusType = Object.values(SaleStatusTypes).filter(
                item => item.saleTypeId === _saleTypeId,
            );

            setSelectedSaleTypeId(_saleTypeId);
            setSelectedStatusTypeId('');
            setSaleStatusTypeList(listOfSaleStatusType);
        }
    };

    const onStatusTypeIdChange = e => {
        setSelectedStatusTypeId(e.target.value);

        offerService
            .updateStatus({
                saleOfferFormId,
                data: {
                    saleTypeId: selectedSaleTypeId,
                    saleStatusTypeId: e.target.value,
                },
            })
            .then(() => {
                showMessage(t('message.updated'));
            })
            .catch(({ code, message }) => {
                showMessage(`${code} - ${message}`, MessageType.ERROR);
            });
    };

    const onDeletedChange = e => {
        offerService
            .delete(saleOfferFormId, {
                isDeleted: e.target.checked,
            })
            .then(() => {
                showMessage(t('message.updated'));
            })
            .catch(({ code, message }) => {
                showMessage(`${code} - ${message}`, MessageType.ERROR);
            });
    };

    return (
        <React.Fragment>
            <Card>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid container item spacing={1} xs={9}>
                            {saleOfferFormId && (
                                <Grid item xs={1}>
                                    <Input
                                        label="Teklif No"
                                        defaultValue={saleOfferFormId || null}
                                        disabled
                                        readOnly
                                    />
                                </Grid>
                            )}
                            {createdDate && (
                                <Grid item xs={2}>
                                    <Input
                                        type="date"
                                        label={t('reservation.offerDate')}
                                        defaultValue={createdDate || null}
                                        disabled
                                        readOnly
                                    />
                                </Grid>
                            )}
                            {sale?.date && (
                                <Grid item xs={2}>
                                    <Input
                                        type="date"
                                        label={t('reservation.reservationDate')}
                                        defaultValue={sale?.date || null}
                                        disabled
                                        readOnly
                                    />
                                </Grid>
                            )}
                            {sale?.saleCode && (
                                <Grid item xs={2}>
                                    <Input
                                        label={t('reservation.saleCode')}
                                        defaultValue={sale.saleCode || null}
                                        disabled
                                        readOnly
                                    />
                                </Grid>
                            )}
                            <Grid item xs={2}>
                                <FormControl fullWidth sx={{ marginTop: 1 }}>
                                    <InputLabel id="saleTypeId-select-label">
                                        {t('reservation.saleTypeId')}
                                    </InputLabel>
                                    <Select
                                        margin="dense"
                                        size="small"
                                        variant="outlined"
                                        labelId="saleTypeId-select-label"
                                        id="saleTypeId-select"
                                        value={selectedSaleTypeId}
                                        label={t('reservation.saleTypeId')}
                                        InputLabelProps={{
                                            shrink: false,
                                        }}
                                        onChange={onSaleTypeIdChange}>
                                        {Object.values(SaleTypes).map(item => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl fullWidth sx={{ marginTop: 1 }}>
                                    <InputLabel id="onStatusTypeId-select-label">
                                        {t('reservation.saleStatusTypeId')}
                                    </InputLabel>
                                    <Select
                                        margin="dense"
                                        size="small"
                                        variant="outlined"
                                        labelId="onStatusTypeId-select-label"
                                        id="onStatusTypeId-select"
                                        value={selectedStatusTypeId}
                                        label={t('reservation.saleTypeId')}
                                        InputLabelProps={{
                                            shrink: false,
                                        }}
                                        onChange={onStatusTypeIdChange}>
                                        {saleStatusTypeList.map(item => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={3} sx={{ display: 'flex', marginLeft: 'auto' }}>
                            <FormControlLabel
                                label="Silindi İşaretle"
                                control={
                                    <Checkbox
                                        defaultChecked={isDeleted}
                                        onChange={onDeletedChange}
                                    />
                                }
                            />

                            {sale?.salePayments?.length > 0 &&
                                sale?.saleCode !== undefined &&
                                token !== undefined && (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                        onClick={() => {
                                            setOpenReceiptPdfDialog(true);
                                        }}
                                        sx={{ margin: '8px 0 0 auto', height: '40px' }}>
                                        VOUCHER/TAHSİLAT
                                    </Button>
                            )}
                            {saleStatusTypeId &&
                                saleStatusTypeId <= 5 &&
                                url !== undefined &&
                                url !== '' && (
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        size="small"
                                        onClick={() =>
                                            window
                                                .open(
                                                    `${import.meta.env.VITE_B2C_URL}${url}`,
                                                    '_blank',
                                                )
                                                .focus()
                                        }
                                        sx={{ margin: '8px 0 0 auto', height: '40px' }}>
                                        {t('button.offer')}
                                    </Button>
                                )}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <TourReservationReceiptDialog
                token={token}
                saleCode={sale?.saleCode}
                saleGuid={saleGuid}
                open={openReceiptPdfDialog}
                onClose={() => setOpenReceiptPdfDialog(false)}
            />

            {/* <TourReservationVoucherDialog
                token={token}
                saleCode={sale?.saleCode}
                open={openVoucherPdfDialog}
                onClose={() => setOpenVoucherPdfDialog(false)}
            /> */}
        </React.Fragment>
    );
};

export default TourReservationEditHeadPanel;
