import React from 'react';
import { TableCell, TableRow } from '@mui/material';

const NoResultFound = React.memo(({ colSpan, text }) => (
    <TableRow>
        <TableCell colSpan={colSpan + 2} style={{ textAlign: 'center' }}>
            <div style={{ fontWeight: 'bold', padding: 12 }}>{text}</div>
        </TableCell>
    </TableRow>
));

export default NoResultFound;
