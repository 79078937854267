import React, { useEffect, useState } from 'react';
import Page from '../../../layout/Page';
import { useHistory, useParams } from 'react-router-dom';
import exchangeRateService from '../../../service/ExchangeRateService';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Panel from '../../../ui/Panel';
import Input from '../../../ui/Input';
import Select from '../../../ui/Select';
import MenuItem from '@mui/material/MenuItem';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import PageActions from '../../../components/PageActions';
import { useForm } from 'react-hook-form';
import { MessageType, ExchangeRatesTypes } from '../../../util/Enums';
import useMessage from '../../../util/useMessage';
import SubmitButton from '../../../ui/SubmitButton';
import { DataTable } from '../../../components/DataTable';
import AddIcon from '@mui/icons-material/Add';
import ExchangeItemEditDialog from './ExchangeItemEditDialog';

let MOUNT = true;

const ExchangeRateEditPage = ({ title, ...props }) => {
    const param = useParams();
    const exchangeRateId = param?.id;

    const { t } = useTranslation();
    const history = useHistory();
    const showMessage = useMessage();

    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [items, setItems] = useState([]);
    const [exchangeRate, setExchangeRate] = useState(null);

    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    const [openEditDialog, setOpenEditDialog] = useState(false);

    const {
        register,
        handleSubmit,
        watch,
        control,
        formState: { errors },
    } = useForm();

    const watchAmountAdded = watch('amountAdded');
    const watchAmountAddedTypeOf = watch('amountAddedTypeOf');

    const columns = [
        {
            name: 'fromCurrencyType',
            label: t('label.from'),
        },
        {
            name: 'toCurrencyType',
            label: t('label.to'),
        },
        {
            name: 'isManualControl',
            label: 'Durum',
            execute: item => t(item === true ? 'Düzenlenmiş' : 'Otomatik'),
        },
        {
            name: 'liveCurrencyValue',
            label: 'Sistemden Gelen Doviz Kuru',
            width: 120,
        },
        {
            name: 'liveCurrencyUpdateDate',
            label: 'Sistemden Gelen Doviz Kuru Saati',
            width: 120,
        },
        {
            name: 'manualValue',
            label: 'Düzenlenmiş Doviz Kuru',
            width: 120,
            execute: item => (item === 0 ? '' : item),
        },
        {
            name: 'value',
            label: t('label.value'),
        },
    ];

    // useEffect(() => {
    //     const changeItems = items;
    //     changeItems.forEach(item => {
    //         item.value = watchAmountAddedTypeOf === ExchangeRatesTypes.PERCENT.id ?
    //         (item.isManualControl ? parseFloat(item.manualValue)+(item.manualValue*watchAmountAdded/100) : parseFloat(item.liveCurrencyValue)+(item.liveCurrencyValue*watchAmountAdded/100)).toFixed(4) :
    //         (item.isManualControl ? parseFloat(item.manualValue)+parseFloat(watchAmountAdded) : parseFloat(item.liveCurrencyValue)+parseFloat(watchAmountAdded)).toFixed(4)
    //     });
    // }, [watchAmountAdded, watchAmountAddedTypeOf]);

    useEffect(() => {
        if (exchangeRateId) {
            (async () => {
                setPageLoading(true);
                try {
                    const { resultObject } = await exchangeRateService.get(exchangeRateId);
                    initPageValues(resultObject);
                } catch (e) {}
                setPageLoading(false);
            })();
        } else {
            setPageLoading(false);
        }
        return () => {
            MOUNT = false;
        };
    }, [exchangeRateId]);

    const initPageValues = _exchangeRate => {
        setExchangeRate(_exchangeRate);
        setItems(_exchangeRate.items);
    };

    const onSubmit = async data => {
        if (loading) return false;
        try {
            setLoading(true);
            const obj = {
                ...data,
                amountAddedTypeOf: parseInt(data.amountAddedTypeOf),
                amountAdded: parseFloat(data.amountAdded),
                items,
            };
            const { resultObject } = await exchangeRateService.saveOrUpdate(exchangeRateId, obj);
            showMessage(t(exchangeRateId ? 'message.updated' : 'message.created'));

            history.push(
                `/settings/exchangeRates/edit/${resultObject.exchangeRateId || resultObject.id}`,
            );
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const ActionPanel = () => (
        <PageActions>
            <Button
                variant="contained"
                disabled={loading}
                startIcon={loading ? null : <KeyboardBackspaceIcon />}
                onClick={() => {
                    history.goBack();
                }}>
                {t('button.back')}
            </Button>
            <SubmitButton loading={loading} startIcon={<SaveIcon />}>
                {t('button.save')}
            </SubmitButton>
        </PageActions>
    );

    return (
        <Page title={title} loading={pageLoading}>
            <Container maxWidth="lg">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ActionPanel />
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Panel title={t('title.exchangeRateInfos')}>
                                <Input
                                    defaultValue={exchangeRate?.name}
                                    label={t('label.name')}
                                    error={errors?.name}
                                    {...register('name', {
                                        required: t('validation.required'),
                                    })}
                                />

                                <Select
                                    name="amountAddedTypeOf"
                                    error={errors?.amountAddedTypeOf}
                                    label={t('label.unit')}
                                    defaultValue={exchangeRate?.amountAddedTypeOf}
                                    control={control}
                                    rules={{ required: t('validation.required') }}>
                                    {Object.values(ExchangeRatesTypes).map(item => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {t(item.label)}
                                        </MenuItem>
                                    ))}
                                </Select>

                                <Input
                                    type="text"
                                    name="amountAdded"
                                    defaultValue={exchangeRate?.amountAdded}
                                    label={t('label.ratio')}
                                    error={errors?.amountAdded}
                                    {...register('amountAdded', {
                                        required: t('validation.required'),
                                        pattern: {
                                            value: /^\d*(\.\d{0,2})?$/,
                                            message: t('validation.invalidNumber'),
                                        },
                                    })}
                                />

                                <Input
                                    type="text"
                                    name="percentageChangeForUpdatingValue"
                                    defaultValue={exchangeRate?.percentageChangeForUpdatingValue}
                                    label="Maksimum Kur Güncelleme Farkı"
                                    error={errors?.percentageChangeForUpdatingValue}
                                    tooltip="Belirlenen oranın 2 katından fazlay"
                                    {...register('percentageChangeForUpdatingValue', {
                                        pattern: {
                                            value: /^\d*(\.\d{0,2})?$/,
                                            message: t('validation.invalidNumber'),
                                        },
                                    })}
                                />
                                <Input
                                    type="text"
                                    name="percentageChangeForInformationValue"
                                    defaultValue={exchangeRate?.percentageChangeForInformationValue}
                                    label="Maksimum Kur Bilgilendirme Farkı"
                                    error={errors?.percentageChangeForInformationValue}
                                    {...register('percentageChangeForInformationValue', {
                                        pattern: {
                                            value: /^\d*(\.\d{0,2})?$/,
                                            message: t('validation.invalidNumber'),
                                        },
                                    })}
                                />
                            </Panel>
                        </Grid>

                        <Grid item xs={12}>
                            <Panel noPadding={true}>
                                <DataTable
                                    title={t('title.items')}
                                    columns={columns}
                                    action={
                                        <Button
                                            color={'secondary'}
                                            startIcon={<AddIcon />}
                                            onClick={() => {
                                                setSelectedItemIndex(null);
                                                setOpenEditDialog(true);
                                            }}>
                                            {t('button.add')}
                                        </Button>
                                    }
                                    onEdit={(item, index) => {
                                        setSelectedItemIndex(index);
                                        setOpenEditDialog(true);
                                    }}
                                    onDelete={(item, index) => {
                                        setItems(items.filter((item, idx) => idx !== index));
                                    }}
                                    data={items?.map((item, index) => ({ id: index, ...item }))}
                                />
                                <div style={{ textAlign: 'right', padding: '10px' }}>
                                    {items
                                        .filter(
                                            item =>
                                                item.lastLiveCurrencyValue &&
                                                item.lastLiveCurrencyValue !== 0,
                                        )
                                        .map((item, index) => (
                                            <p style={{ fontSize: '12px', margin: '0' }}>
                                                Doviz Cinsi:{' '}
                                                <strong>
                                                    {item.fromCurrencyType} - {item.toCurrencyType}
                                                </strong>{' '}
                                                Saat:{' '}
                                                <strong>{item.lastLiveCurrencyUpdateDate}</strong>{' '}
                                                Döviz kuru:{' '}
                                                <strong>{item.lastLiveCurrencyValue}</strong>
                                            </p>
                                        ))}
                                </div>
                            </Panel>
                        </Grid>
                    </Grid>
                    <ActionPanel />
                </form>
            </Container>

            <ExchangeItemEditDialog
                open={openEditDialog}
                onClose={() => setOpenEditDialog(false)}
                item={items[selectedItemIndex]}
                amountAdded={watchAmountAdded}
                amountAddedTypeOf={watchAmountAddedTypeOf}
                onDoneCallback={data => {
                    for (let i = 0; i < items.length; i++) {
                        if (
                            data.fromCurrencyType === items[i].fromCurrencyType &&
                            data.toCurrencyType === items[i].toCurrencyType
                        ) {
                            if (selectedItemIndex === null || selectedItemIndex !== i) {
                                showMessage(t('validation.duplicateRecord'), MessageType.ERROR);
                                return;
                            }
                        }
                    }
                    if (selectedItemIndex === null) {
                        setItems([...items, data]);
                    } else {
                        const temps = [...items];
                        temps[selectedItemIndex] = data;
                        setItems(temps);
                    }
                    setSelectedItemIndex(null);
                    setOpenEditDialog(false);
                }}
            />
        </Page>
    );
};

export default ExchangeRateEditPage;
