import Page from '../../../layout/Page';

import { useTranslation } from 'react-i18next';
import usePagination from '../../../util/usePagination';
import categoryService from '../../../service/CategoryService';
import { DataTable } from '../../../components/DataTable';
import Container from '@mui/material/Container';
import ListPageActions from '../../../components/ListPageActions';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import CategoryDeleteDialog from './CategoryDeleteDialog';
import { CategoryType, findEnumById } from '../../../util/Enums';
import { getDeletedColumn } from '../../../components/YesNoTypeOptions';

const CategoryListPage = ({ title, ...props }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [selected, setSelected] = useState(null);
    const [openCategoryDeleteDialog, setOpenCategoryDeleteDialog] = useState(false);

    const CategoryTypeOptions = () =>
        Object.values(CategoryType).map(item => (
            <option key={item.id} value={item.id}>
                {t(item.label)}
            </option>
        ));

    const columns = [
        {
            name: 'id',
            label: 'ID',
            width: 75,
            searchable: true,
            type: Number,
        },
        {
            name: 'categoryType',
            label: t('label.categoryType'),
            execute: item => t(findEnumById(CategoryType, item)?.label),
            width: 125,
            type: Number,
            searchable: true,
            searchType: 'select',
            sortable: false,
            selectOptions: <CategoryTypeOptions />,
        },
        {
            name: 'name',
            label: t('label.name'),
            searchable: true,
        },
        {
            name: 'volume',
            label: t('label.searchVolume'),
            width: 125,
            type: Number,
            searchable: true,
        },
        { ...getDeletedColumn(t) },
    ];

    const categories = usePagination({
        service: categoryService,
    });

    const ActionButtons = () => (
        <ListPageActions>
            <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={() => {
                    history.push(`/settings/categories/create`);
                }}>
                {t('button.addNew')}
            </Button>
        </ListPageActions>
    );

    return (
        <Page title={title}>
            <Container maxWidth="xl">
                <ActionButtons />
                <DataTable
                    title={t('label.categoryList')}
                    columns={columns}
                    {...categories}
                    onEdit={item => {
                        history.push(`/settings/categories/edit/${item.id}`);
                    }}
                    onDelete={item => {
                        setSelected(item);
                        setOpenCategoryDeleteDialog(true);
                    }}
                />
                <ActionButtons />
            </Container>

            <CategoryDeleteDialog
                category={selected}
                open={openCategoryDeleteDialog}
                onClose={() => {
                    setOpenCategoryDeleteDialog(false);
                    setSelected(null);
                }}
                onDoneCallback={categories.search}
            />
        </Page>
    );
};

export default CategoryListPage;
