import React, { useEffect, useState } from 'react';
import Page from '../../../layout/Page';
import { useHistory, useParams } from 'react-router-dom';
import vendorService from '../../../service/VendorService';
import Container from '@mui/material/Container';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import PageActions from '../../../components/PageActions';
import { FormProvider, useForm } from 'react-hook-form';
import { MessageType } from '../../../util/Enums';
import useMessage from '../../../util/useMessage';
import SubmitButton from '../../../ui/SubmitButton';
import VendorEditInputs from './VendorEditInputs';

const VendorEditPage = ({ title, ...props }) => {
    const param = useParams();
    const contactId = param?.id;

    const { t } = useTranslation();
    const history = useHistory();
    const showMessage = useMessage();

    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [vendor, setVendor] = useState(null);

    const methods = useForm();

    useEffect(() => {
        if (contactId) {
            (async () => {
                setPageLoading(true);
                try {
                    const { resultObject } = await vendorService.get(contactId);
                    initPageValues(resultObject);
                } catch (e) {}
                setPageLoading(false);
            })();
        } else {
            setPageLoading(false);
        }
    }, [contactId]);

    const initPageValues = _vendor => {
        setVendor(_vendor);
    };

    const onSubmit = async data => {
        if (loading) return false;
        try {
            setLoading(true);
            const obj = {
                ...data,
                contactTypeId: 3
            };
            const { resultObject } = await vendorService.saveOrUpdate(contactId, obj);
            showMessage(t(contactId ? 'message.updated' : 'message.created'));

            history.push(`/settings/vendors/edit/${resultObject.contactId || resultObject.id}`);
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const ActionPanel = () => (
        <PageActions>
            <Button
                variant="contained"
                disabled={loading}
                startIcon={loading ? null : <KeyboardBackspaceIcon />}
                onClick={() => {
                    history.goBack();
                }}>
                {t('button.back')}
            </Button>

            <SubmitButton loading={loading} startIcon={<SaveIcon />}>
                {t('button.save')}
            </SubmitButton>
        </PageActions>
    );

    return (
        <Page title={title} loading={pageLoading}>
            <Container maxWidth={'md'}>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <ActionPanel />
                        <VendorEditInputs vendor={vendor} />
                        <ActionPanel />
                    </form>
                </FormProvider>
            </Container>
        </Page>
    );
};

export default VendorEditPage;
