import React, { useEffect, useState } from 'react';
import Page from '../../../layout/Page';
import { useTranslation } from 'react-i18next';
import usePagination from '../../../util/usePagination';
import offerService from '../../../service/OfferService';
import { DataTable } from '../../../components/DataTable';
import { Container, Grid, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { findEnumById, ProductGroupTypes, SaleStatusTypes, SaleTypes } from '../../../util/Enums';
import { format } from 'date-fns';
import ListPageActions from '../../../components/ListPageActions';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import TourReservationListPageFilterDialog from './TourReservationListPageFilterDialog';
import DateFilterCell from '../../../components/datatable/DateFilterCell';
import MinMaxSearchCellForecast from '../../../components/datatable/MinMaxSearchCellForecast';
import VendorSearch from '../../../components/datatable/VendorSearch';

const TourReservationListPage = ({ title, ...props }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [init, setInit] = useState(false);
    const [openFilterDialog, setOpenFilterDialog] = useState(false);
    const [filters, setFilters] = useState({});

    const SaleStatusOptions = () =>
        Object.values(SaleStatusTypes).map(item => (
            <option key={item.id} value={item.id}>
                {t(item.label)}
            </option>
        ));

    const SaleOptions = () =>
        Object.values(SaleTypes).map(item => (
            <option key={item.id} value={item.id}>
                {t(item.label)}
            </option>
        ));

    const ProductGroupOptions = () =>
        Object.values(ProductGroupTypes).map(item => (
            <option key={item.id} value={item.id}>
                {t(item.label)}
            </option>
        ));

    const columns = [
        {
            name: 'saleOfferFormId',
            label: 'Teklif No',
            width: 10,
            searchable: true,
            type: Number,
        },
        {
            name: 'saleStatusTypeId',
            label: 'Detay',
            searchable: true,
            type: Number,
            searchType: 'select',
            sortable: false,
            selectOptions: <SaleStatusOptions />,
            execute: item => (
                <span style={{ whiteSpace: 'nowrap' }}>
                    {findEnumById(SaleStatusTypes, item)?.label}
                </span>
            ),
        },
        {
            name: 'saleTypeId',
            label: 'Statü',
            searchable: true,
            type: Number,
            searchType: 'select',
            sortable: false,
            selectOptions: <SaleOptions />,
            execute: item => (
                <span style={{ whiteSpace: 'nowrap' }}>{findEnumById(SaleTypes, item)?.label}</span>
            ),
        },
        {
            name: 'createdDate',
            label: 'Tarih',
            execute: item => (item ? format(new Date(item), 'dd.MM.yyyy') : ''),
            searchable: true,
            SearchCellComponent: DateFilterCell,
        },
        {
            name: 'phone',
            label: t('label.phone'),
            searchable: true,
        },
        {
            name: 'saleCode',
            label: 'Rez. No',
            searchable: true,
        },
        {
            name: 'productGroupId',
            label: 'Ürün',
            searchable: true,
            type: Number,
            searchType: 'select',
            sortable: false,
            selectOptions: <ProductGroupOptions />,
            execute: item => findEnumById(ProductGroupTypes, item)?.label,
        },
        {
            name: 'title',
            label: 'Ürün Adı',
            searchable: true,
            execute: item =>
                item && (
                    <Tooltip title={item}>
                        <div
                            style={{
                                whiteSpace: 'nowrap',
                                maxWidth: '300px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {item}
                        </div>
                    </Tooltip>
                ),
        },
        {
            name: 'salePrice',
            label: 'Satış TL',
            searchable: true,
            type: Number,
            SearchCellComponent: MinMaxSearchCellForecast,
            searchField: 'salePrice',
        },
        {
            name: 'checkInDate',
            label: 'Checkin',
            execute: item => (item ? format(new Date(item), 'dd.MM.yyyy') : ''),
            searchable: true,
            SearchCellComponent: DateFilterCell,
        },
        {
            name: 'contact',
            label: t('forecast.vendor'),
            searchable: true,
            searchField: 'contactId',
            type: Number,
            SearchCellComponent: VendorSearch,
            execute: item =>
                item?.brandName && (
                    <Tooltip title={<p>{item.brandName}</p>}>
                        <div
                            style={{
                                whiteSpace: 'nowrap',
                                maxWidth: '160px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {item.brandName}
                        </div>
                    </Tooltip>
                ),
        },
    ];

    const reservations = usePagination({
        service: offerService,
        defaultOrderBy: { name: 'saleOfferFormId', orderType: 'desc' },
    });

    useEffect(() => {
        if (init) {
            console.log('filters', filters);
            reservations.setSearchData({ ...reservations.searchData, ...filters });
        } else {
            setInit(true);
        }
    }, [filters]);

    const onFilterAddAction = value => {
        setFilters(value);
        setOpenFilterDialog(false);
    };

    const ActionButtons = () => (
        <ListPageActions>
            <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={() => {
                    history.push(`/tours/reservations/create`);
                }}>
                {t('button.addNew')}
            </Button>
        </ListPageActions>
    );

    return (
        <Page title={title}>
            <Container maxWidth={false} sx={{ maxWidth: '1642px' }}>
                <ActionButtons />
                <DataTable
                    title={t('label.reservationList')}
                    columns={columns}
                    {...reservations}
                    headerNoWrap
                    onEdit={(item, index, e) => {
                        e.preventDefault();
                        if (e.ctrlKey || e.metaKey) {
                            window.open(`/tours/reservations/edit/${item.saleOfferFormId}`);
                        } else {
                            history.push(`/tours/reservations/edit/${item.saleOfferFormId}`);
                        }
                    }}
                    action={
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={1} lg={1}>
                                <IconButton
                                    aria-label="Filter"
                                    size="small"
                                    sx={{ marginTop: '8px' }}
                                    onClick={() => {
                                        setOpenFilterDialog(true);
                                    }}>
                                    <FilterListIcon fontSize="medium" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    }
                />
            </Container>
            <TourReservationListPageFilterDialog
                open={openFilterDialog}
                onAddAction={onFilterAddAction}
                item={filters}
                onClose={() => {
                    setOpenFilterDialog(false);
                }}
            />
        </Page>
    );
};

export default TourReservationListPage;
