import Grid from '@mui/material/Grid';
import Panel from '../../../ui/Panel';
import Input from '../../../ui/Input';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Editor from '../../../ui/Editor';

const VisaTypeEditInputPanel = ({ visaType, name, description, setDescription }) => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors },
    } = useFormContext();

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Panel title={t('title.visaTypeInfo')}>
                    <Input
                        name="name"
                        defaultValue={visaType?.name || name || ''}
                        label={t('label.name')}
                        error={errors?.name}
                        {...register('name', {
                            required: t('validation.required'),
                        })}
                    />
                    <Input
                        type="number"
                        name="volume"
                        defaultValue={visaType?.volume}
                        label={t('label.searchVolume')}
                        error={errors?.volume}
                        {...register('volume')}
                    />
                </Panel>
            </Grid>
            <Grid item xs={12}>
                <Editor
                    title={t('label.description')}
                    value={description}
                    onChange={val => {
                        setDescription(val);
                    }}
                />
            </Grid>
        </Grid>
    );
};

export default VisaTypeEditInputPanel;
