import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Input from '../ui/Input';
import NativeEditor from '../ui/NativeEditor';
import DoneButton from '../ui/DoneButton';

const FaqEditDialog = ({ item = null, open, onClose, onAddAction, onEditAction }) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm();

    const [content, setContent] = useState('');

    useEffect(() => {
        if (!open) {
            reset();
            setContent('');
        } else if (open && item) {
            setContent(item.content);
        }
    }, [open, item, reset]);

    const onSubmit = async data => {
        setLoading(true);
        const obj = { ...data, content: content };
        if (item) {
            onEditAction && onEditAction(obj);
        } else {
            onAddAction && onAddAction(obj);
        }
        setLoading(false);
    };

    return (
        <Dialog open={open} fullWidth maxWidth="sm" scroll="paper">
            <DialogTitle>{t('title.editFaqDialog')}</DialogTitle>
            <DialogContent dividers={true}>
                {open && (
                    <form>
                        <Input
                            autoFocus
                            label={t('label.title')}
                            defaultValue={item?.title}
                            error={errors?.title}
                            {...register('title', {
                                required: t('validation.required'),
                            })}
                        />
                        <div style={{ border: '1px solid #c4c4c4', borderRadius: 4 }}>
                            <NativeEditor value={content} onChange={setContent} />
                        </div>
                    </form>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" disabled={loading}>
                    {t('button.cancel')}
                </Button>
                <DoneButton onClick={handleSubmit(onSubmit)} loading={loading}>
                    {t('button.done')}
                </DoneButton>
            </DialogActions>
        </Dialog>
    );
};

export default FaqEditDialog;
