import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Input from '../../../ui/Input';
import DeleteIcon from '@mui/icons-material/Delete';
import SubmitButton from '../../../ui/SubmitButton';
import DateInput from '../../../ui/DateInput';

const TourReservationPaymentDialog = ({ open, onClose, onDoneCallback, remainingAmount = null }) => {
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control
    } = useForm();

    useEffect(() => {
        if (open) {
            reset();
        }
    }, [open, reset]);

    const onSubmit = async data => {
        onClose && onClose();
        onDoneCallback && onDoneCallback(data);
    };

    return (
        <Dialog open={open} fullWidth maxWidth="sm" scroll="paper">
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>Parçalı Ödeme Al</DialogTitle>
                <DialogContent dividers={true}>
                    <Input
                        autoFocus
                        label="Tutar"
                        error={errors?.totalPrice}
                        defaultValue={remainingAmount || null}
                        {...register('totalPrice', {
                            required: t('validation.required'),
                            max: remainingAmount === null ? false : {
                              value: remainingAmount,
                              message: "Kalan Tutardan fazla olamaz."
                            },
                        })}
                    />
                    {/* <DateInput
                        name="paymentMaturityDate"
                        label={t('purchase.maturityDate')}
                        defaultValue={null}
                        control={control}
                    /> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        {t('button.cancel')}
                    </Button>
                    <SubmitButton>
                        OLUŞTUR
                    </SubmitButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default React.memo(TourReservationPaymentDialog);
