import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const CheckBox = React.forwardRef(({ error, ...props }, ref) => {
    return (
        <>
            <FormControlLabel
                control={<Checkbox {...props} inputRef={ref} />}
                label={props.label}
            />
        </>
    );
});

export default CheckBox;
