import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Card, CardContent, CardHeader } from '@mui/material';

const useStyles = makeStyles(theme => ({
    card: {
        height: '100%',
    },
    title: {
        fontSize: '1.2rem',
    },
}));

const Panel = ({ title = null, subtitle, action = null, noPadding, elevation = 1, ...props }) => {
    const classes = useStyles();

    return (
        <Card
            className={props?.defaultClass ? props.defaultClass.card : classes.card}
            elevation={elevation}>
            {(title !== null || action !== null) && (
                <CardHeader
                    title={title}
                    subheader={subtitle}
                    classes={{ title: classes.title }}
                    action={action}
                />
            )}

            {!noPadding && <CardContent>{props.children}</CardContent>}
            {noPadding && props.children}
        </Card>
    );
};

export default Panel;
