import React, { useCallback, useEffect, useState } from 'react';
import { Box, Card, CardActions, CardContent, CardHeader, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Input from '../../../ui/Input';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { DislikeTypes, findEnumById, MessageType } from '../../../util/Enums';
import useMessage from '../../../util/useMessage';
import offerService from '../../../service/OfferService';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import TourReservationAddTourDialog from './TourReservationAddTourDialog';
import { useHistory } from 'react-router-dom';
// import VoyageInput from "../../../ui/VoyageInput";
import TourReservationOrderDialog from './TourReservationOrderDialog';
import ViewListIcon from '@mui/icons-material/ViewList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Editor from '../../../ui/Editor';
import TourReservationEditToursPanelTable from './TourReservationEditToursPanelTable';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && <Box sx={{ padding: '24px 0 0 0' }}>{children}</Box>}
        </div>
    );
}

const TourReservationEditPageToursPanel = ({
    stepId,
    offerDescription,
    saleOfferFormId,
    sale,
    saleOfferTourPrograms,
    toggle,
    isExpanded,
    onToggle,
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [tourPrograms, setTourPrograms] = useState(saleOfferTourPrograms || []);
    const [tabValue, setTabValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const [openTourOperationAddTourDialog, setOpenTourOperationAddTourDialog] = useState(false);

    const [openTourOperationOrderDialog, setOpenTourOperationOrderDialog] = useState(false);
    const [reservationDescription, setReservationDescription] = useState(null);

    const showMessage = useMessage();

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        setReservationDescription(offerDescription || null);
        if (saleOfferTourPrograms?.length > 0) {
            setTourPrograms(saleOfferTourPrograms);
            console.log('saleOfferTourPrograms: %', saleOfferTourPrograms);
        }
    }, []);

    const onAddTourAction = async values => {
        if (loading) return false;
        try {
            setLoading(true);
            const obj = {
                saleOfferFormId,
                saleOfferTourPrograms: [
                    {
                        tourProgramId: values.saleOfferTourPrograms.tourProgramId,
                        hotelId: values.saleOfferTourPrograms.hotelId,
                    },
                ],
            };
            const { resultObject } = await offerService.addTour(obj);
            history.push(window.location.pathname);
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
            setOpenTourOperationAddTourDialog(false);
        } finally {
            setLoading(false);
        }
    };

    const onSubmit = async () => {
        if (loading) return false;
        try {
            setLoading(true);
            const { isSuccess } = await offerService.update(saleOfferFormId, {
                saleOfferTourPrograms: tourPrograms,
                saleOfferFormId,
                offerDescription: reservationDescription,
            });
            if (isSuccess) {
                showMessage('Kaydedildi.');
                onToggle(stepId);
            }
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const onClickConfirm = async saleOfferTourProgramPeriodId => {
        if (loading) return false;
        try {
            setLoading(true);
            const { isSuccess } = await offerService.confirmProgram({
                saleOfferTourProgramPeriodId,
                saleOfferFormId,
            });
            if (isSuccess) {
                showMessage('Periyot Seçildi.');
                onToggle(stepId);
            }
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const onPriceCalculateCallback = ({ tourProgramIndex, itemIndex, data }) => {
        const instance = JSON.parse(JSON.stringify(tourPrograms));
        const item = instance[tourProgramIndex].saleOfferTourProgramPeriods[itemIndex];
        instance[tourProgramIndex].saleOfferTourProgramPeriods[itemIndex] = {
            ...item,
            ...data,
        };
        setTourPrograms(instance);
    };

    const isSelectedOfferTab = useCallback(
        saleOfferTourProgramId => {
            const offer = tourPrograms.find(
                item => item.saleOfferTourProgramId === saleOfferTourProgramId,
            );
            if (offer) {
                return offer.saleOfferTourProgramPeriods?.some(item => item.isSelected);
            } else {
                return false;
            }
        },
        [tourPrograms],
    );

    return (
        <form>
            <Card>
                <CardHeader
                    title="Teklif Bilgileri"
                    titleTypographyProps={{ variant: 'subtitle1' }}
                    action={
                        <>
                            {isExpanded(stepId) && (
                                <Button color="secondary" onClick={onSubmit}>
                                    KAYDET VE İLERLE
                                </Button>
                            )}
                            <Button color="secondary" onClick={() => toggle(stepId)}>
                                {isExpanded(stepId) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </Button>
                        </>
                    }
                />
                {isExpanded(stepId) && (
                    <CardContent>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton
                                aria-label="Search"
                                size="medium"
                                sx={{ marginTop: '2px' }}
                                onClick={() => {
                                    setOpenTourOperationOrderDialog(true);
                                }}>
                                <ViewListIcon fontSize="large" />
                            </IconButton>
                            <Tabs
                                value={tabValue}
                                onChange={handleTabChange}
                                aria-label="basic tabs example"
                                sx={{ marginTop: '2px' }}>
                                {tourPrograms?.map((item, index) => {
                                    const isSelected = isSelectedOfferTab(
                                        item.saleOfferTourProgramId,
                                    );
                                    return (
                                        <Tab
                                            label={
                                                <>
                                                    {item.operationTitle}
                                                    <br />
                                                    <span style={{ fontSize: '10px' }}>
                                                        {item.hotel.name}
                                                    </span>
                                                </>
                                            }
                                            key={index}
                                            sx={{
                                                fontSize: '20px',
                                                display: 'block',
                                                textTransform: 'none',
                                                lineHeight: '1',
                                                backgroundColor: isSelected ? '#dfffec' : '#FFF',
                                            }}
                                            icon={
                                                !sale && (
                                                    <CancelIcon
                                                        color="error"
                                                        sx={{
                                                            fontSize: '20px',
                                                            marginLeft: '10px',
                                                        }}
                                                        onClick={() => {
                                                            if (index === tabValue) {
                                                                const check = window.confirm(
                                                                    t(
                                                                        'reservation.AreYouSureYouWantToDelete',
                                                                    ),
                                                                );
                                                                if (check) {
                                                                    const newValueIndex =
                                                                        index ===
                                                                        tourPrograms.length - 1
                                                                            ? index - 1
                                                                            : index;
                                                                    setTabValue(newValueIndex);
                                                                    const removeList =
                                                                        tourPrograms.filter(
                                                                            (_, removeIndex) =>
                                                                                removeIndex !==
                                                                                index,
                                                                        );
                                                                    setTourPrograms(removeList);
                                                                }
                                                            }
                                                        }}
                                                    />
                                                )
                                            }
                                            iconPosition="right"
                                        />
                                    );
                                })}
                            </Tabs>
                        </div>
                        {tourPrograms?.map((item, index) => (
                            <TabPanel value={tabValue} index={index} key={index}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <div style={{ display: 'flex' }}>
                                            {item.tourName && item.tourName}
                                            <div style={{ marginLeft: 'auto' }}>
                                                {item.tourProgramId !== undefined && (
                                                    <a
                                                        href={`/tours/programs/edit/${item.tourProgramId}`}
                                                        target="_blank"
                                                        style={{
                                                            marginLeft: '5px',
                                                            color: '#1976d2',
                                                        }}>
                                                        Program
                                                    </a>
                                                )}
                                                {item.tourPriceId !== undefined && (
                                                    <a
                                                        href={`/tours/prices/edit/${item.tourPriceId}`}
                                                        target="_blank"
                                                        style={{
                                                            marginLeft: '5px',
                                                            color: '#1976d2',
                                                        }}>
                                                        Fiyat
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                        {item.departure?.name && item.departure.name}
                                        {item.transportation?.name &&
                                            ' - ' + item.transportation.name}
                                        {item.transportCompany?.name &&
                                            ' - ' + item.transportCompany.name}
                                        {item.night?.name && ' - ' + item.night.name}
                                        {item.departureRoute && ' - ' + item.departureRoute}
                                        {item.arrivalRoute && item.departureRoute && ', '}
                                        {!item.departureRoute && ' - '}
                                        {item.arrivalRoute && item.arrivalRoute}
                                        {item.cancellationDayCount &&
                                            ' - ' + item.cancellationDayCount + ' İade'}
                                        {item.visaType?.length > 0 &&
                                            ' - ' +
                                                item.visaType?.map(visa => visa.name).join(', ')}
                                    </Grid>
                                    {item.isRemoveByCustomer !== undefined &&
                                        item.isRemoveByCustomer === true && (
                                            <Grid item xs={12}>
                                                <p style={{ color: 'red', fontWeigth: 'bold' }}>
                                                    Removed
                                                    {item.reservationTourCatalogFeedBackByCustomer &&
                                                        item
                                                            .reservationTourCatalogFeedBackByCustomer
                                                            .dislikeTypeId && (
                                                            <span>
                                                                , reason:{' '}
                                                                {t(
                                                                    findEnumById(
                                                                        DislikeTypes,
                                                                        item
                                                                            .reservationTourCatalogFeedBackByCustomer
                                                                            .dislikeTypeId,
                                                                    )?.label,
                                                                )}
                                                            </span>
                                                        )}
                                                </p>
                                            </Grid>
                                        )}
                                    <Grid container spacing={1} item xs={12}>
                                        <Grid item xs={2}>
                                            <Input
                                                label="Tedarikçi Marka"
                                                value={item.contact.brandName}
                                                disabled
                                                readOnly
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Input
                                                label="Tedarikçi Adı"
                                                value={item.contact.name}
                                                disabled
                                                readOnly
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Input
                                                label="Tedarikçi Telefon"
                                                value={item.contact.telephone1}
                                                disabled
                                                readOnly
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Input
                                                label="Tedarikçi E-Posta"
                                                value={item.contact.email}
                                                disabled
                                                readOnly
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TourReservationEditToursPanelTable
                                            tourProgramIndex={index}
                                            saleOfferTourProgramPeriods={
                                                item.saleOfferTourProgramPeriods
                                            }
                                            onClickConfirm={onClickConfirm}
                                            onPriceCalculateCallback={onPriceCalculateCallback}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        ))}
                        <Editor
                            title={t('reservation.offerDescription')}
                            value={reservationDescription}
                            onChange={val => {
                                setReservationDescription(val);
                            }}
                            expanded={
                                reservationDescription && reservationDescription !== null
                                    ? true
                                    : false
                            }
                        />
                    </CardContent>
                )}
                {isExpanded(stepId) && sale && (
                    <CardActions>
                        <Button color="secondary" sx={{ marginLeft: 'auto' }} onClick={onSubmit}>
                            KAYDET VE İLERLE
                        </Button>
                    </CardActions>
                )}
            </Card>
            <TourReservationAddTourDialog
                open={openTourOperationAddTourDialog}
                onAddAction={onAddTourAction}
                onClose={() => {
                    setOpenTourOperationAddTourDialog(false);
                }}
            />
            <TourReservationOrderDialog
                saleOfferTourPrograms={saleOfferTourPrograms}
                open={openTourOperationOrderDialog}
                onOrderChange={items => {
                    setTourPrograms(items);
                }}
                onClose={() => {
                    setOpenTourOperationOrderDialog(false);
                }}
            />
        </form>
    );
};

export default TourReservationEditPageToursPanel;
