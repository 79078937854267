import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import categoryService from '../service/CategoryService';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import RenderTags from './RenderTags';

const CategoryInput = ({
    name,
    label,
    defaultValue,
    categoryType,
    limitTags,
    rules,
    error,
    disabled,
    multiple = true,
    control,
    max,
}) => {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        let active = true;
        setLoading(true);
        if (inputValue === '') {
            if (active) {
                setOptions([]);
                setLoading(false);
            }
            return undefined;
        }
        (async () => {
            try {
                const { resultObject } = await categoryService.search({
                    start: 0,
                    data: {
                        name: inputValue,
                        ...(categoryType ? { categoryType: categoryType } : {}),
                    },
                    max: 50,
                });
                const result = resultObject.map(item => ({
                    id: item.id,
                    name: item.name,
                    categoryType: item.categoryType,
                    numberValue: item.numberValue,
                    periodStartDate: item.periodStartDate || null,
                    periodEndDate: item.periodEndDate || null,
                }));
                if (active) {
                    setOptions(result || []);
                    setLoading(false);
                }
            } catch (e) {}
        })();
        return () => {
            active = false;
        };
    }, [inputValue, categoryType]);

    const handleChange = event => {
        setInputValue(event.target.value);
    };

    return (
        <Controller
            render={({ field }) => (
                <Autocomplete
                    {...field}
                    multiple={multiple}
                    getOptionLabel={category => category.name || ''}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={options}
                    filterOptions={x => x}
                    loading={loading}
                    limitTags={limitTags}
                    renderTags={RenderTags}
                    disabled={disabled}
                    renderInput={params => (
                        <TextField
                            {...params}
                            fullWidth
                            label={
                                label || (multiple ? t('label.categories') : t('label.category'))
                            }
                            error={error && true}
                            margin="dense"
                            variant="outlined"
                            autoComplete="off"
                            helperText={error?.message}
                            placeholder={t('label.category')}
                            multiline={multiple}
                            onChange={handleChange}
                        />
                    )}
                    onChange={(_, data) => {
                        if (multiple && max) {
                            const selections = data?.reverse().filter((item, index) => index < max);
                            data = selections.reverse();
                            field.onChange(data);
                        } else {
                            field.onChange(data);
                        }
                    }}
                />
            )}
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
        />
    );
};

export default CategoryInput;
