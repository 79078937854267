import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import i18n from '../i18n';
import { ActionType } from '../store/ActionType';

export default function LangMenu() {
    const dispatch = useDispatch();
    const panelLang = useSelector(state => state.auth.panelLang);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = lang => {
        setAnchorEl(null);

        if (lang instanceof Object) {
            return;
        }

        dispatch({
            type: ActionType.CHANGE_LANG,
            lang: lang,
        });

        i18n.changeLanguage(lang);
    };

    return (
        <div>
            <Button aria-controls="lang-menu" aria-haspopup="true" onClick={handleClick}>
                {panelLang}
            </Button>
            <Menu
                id="lang-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <MenuItem
                    onClick={() => {
                        handleClose('tr');
                    }}>
                    Türkçe
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose('en');
                    }}>
                    English
                </MenuItem>
            </Menu>
        </div>
    );
}
