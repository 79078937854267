import { Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import RenderTags from './RenderTags';
import { useTranslation } from 'react-i18next';
import extraTourService from '../service/ExtraTourService';
import Button from '@mui/material/Button';
import ExtraTourAddDialog from '../components/ExtraTourAddDialog';

const ExtraTourInput = ({
    name,
    label,
    defaultValue,
    rules,
    error,
    multiple = true,
    disabled,
    control,
    addNewCallback,
}) => {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const [openExtraTourDialog, setOpenExtraTourDialog] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        let active = true;
        setLoading(true);
        (async () => {
            try {
                const { resultObject } = await extraTourService.search({
                    start: 0,
                    data: { name: inputValue },
                    max: 50,
                });
                const result = resultObject.map(item => ({ id: item.id, name: item.name }));
                if (active) {
                    setOptions(result || []);
                    setLoading(false);
                }
            } catch (e) {}
        })();
        return () => {
            active = false;
        };
    }, [inputValue]);

    const handleChange = event => {
        setInputValue(event.target.value);
    };

    return (
        <>
            <Controller
                render={({ field }) => (
                    <Autocomplete
                        {...field}
                        multiple={multiple}
                        getOptionLabel={extraTour => extraTour.name || ''}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        options={options}
                        filterOptions={x => x}
                        loading={loading}
                        disabled={disabled}
                        renderTags={RenderTags}
                        noOptionsText={
                            <Button
                                variant={'contained'}
                                color="primary"
                                size={'small'}
                                onClick={() => {
                                    setOpenExtraTourDialog(true);
                                }}>
                                {t('button.addNewExtraTour')}
                            </Button>
                        }
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={
                                    label ||
                                    (multiple ? t('label.extraTours') : t('label.extraTour'))
                                }
                                error={error && true}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                autoComplete="off"
                                helperText={error?.message}
                                multiline={multiple}
                                onChange={handleChange}
                            />
                        )}
                        onChange={(_, data) => field.onChange(data)}
                    />
                )}
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={rules}
            />

            <ExtraTourAddDialog
                open={openExtraTourDialog}
                inputValue={inputValue}
                onAddCallback={data => {
                    addNewCallback && addNewCallback(data);
                    setOpenExtraTourDialog(false);
                }}
                onClose={() => setOpenExtraTourDialog(false)}
            />
        </>
    );
};

export default ExtraTourInput;
