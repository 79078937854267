import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import SideMenu from '../menu/SideMenu';
import Dashboard from './Dashboard';
import LogoutPage from './LogoutPage';
import Routers from '../routers';
import { useTranslation } from 'react-i18next';

const HomePage = props => {
    const { t } = useTranslation();

    const onMenuToggle = pinned => {
        const content = document.getElementById('content');
        // const toolbar = document.getElementById('toolbar');
        if (pinned) {
            content.classList.add('expanded');
            //   toolbar.classList.add("expanded");
        } else {
            content.classList.remove('expanded');
            // toolbar.classList.remove("expanded");
        }
    };

    const RenderPages = () => {
        return Routers.map((item, key) => {
            return item.isGroup === true ? (
                item.items.map((page, key) => {
                    return (
                        <Route exact path={page.path} key={key}>
                            <page.component title={t(page.title)} />
                        </Route>
                    );
                })
            ) : (
                <Route exact path={item.path} key={key}>
                    <item.component title={t(item.title)} />
                </Route>
            );
        });
    };

    return (
        <div className="App">
            <SideMenu onToggle={onMenuToggle} />
            <div id="content" className="content">
                <Switch>
                    <Route exact path="/">
                        <Dashboard />
                    </Route>
                    <Route exact path="/logout">
                        <LogoutPage />
                    </Route>
                    <RenderPages />
                </Switch>
            </div>
        </div>
    );
};

export default withRouter(HomePage);
