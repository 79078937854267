import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import RenderTags from './RenderTags';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const options = [
    { id: 1, name: 'Pazartesi', value: 'Monday' },
    { id: 2, name: 'Salı', value: 'Tuesday' },
    { id: 3, name: 'Çarşamba', value: 'Wednesday' },
    { id: 4, name: 'Perşembe', value: 'Thursday' },
    { id: 5, name: 'Cuma', value: 'Friday' },
    { id: 6, name: 'Cumartesi', value: 'Saturday' },
    { id: 7, name: 'Pazar', value: 'Sunday' },
];

const DayOfTheWeekNameInput = ({
    name,
    defaultValue,
    rules,
    error,
    multiple = true,
    disabled,
    control,
}) => {
    const { t } = useTranslation();

    return (
        <Controller
            render={({ field }) => (
                <Autocomplete
                    {...field}
                    multiple={multiple}
                    getOptionLabel={data => data.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={options}
                    disabled={disabled}
                    renderTags={RenderTags}
                    disableCloseOnSelect={true}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.name}
                        </li>
                    )}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label={t('label.dayOfTheWeekNames')}
                            error={error && true}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            autoComplete="off"
                            helperText={error?.message}
                            multiline={multiple}
                        />
                    )}
                    onChange={(_, data) => field.onChange(data)}
                />
            )}
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
        />
    );
};

export default DayOfTheWeekNameInput;
