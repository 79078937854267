import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

const PreviewButton = ({ url }) => {
    const { t } = useTranslation();

    return (
        url && (
            <Button
                color="secondary"
                size="small"
                style={{ padding: 0, minWidth: '70px' }}
                href={`${import.meta.env.VITE_B2C_URL}${url}`}
                target="_blank">
                {t('button.preview')}
            </Button>
        )
    );
};
export default PreviewButton;
