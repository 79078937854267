import Page from '../../layout/Page';

import { useTranslation } from 'react-i18next';
import usePagination from '../../util/usePagination';
import postService from '../../service/PostService';
import { DataTable } from '../../components/DataTable';
import Container from '@mui/material/Container';
import ListPageActions from '../../components/ListPageActions';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import PostDeleteDialog from './PostDeleteDialog';
import { ArticleType, findEnumById, PostType } from '../../util/Enums';
import { getDeletedColumn } from '../../components/YesNoTypeOptions';
import PreviewButton from '../../components/PreviewButton';

const PostListPage = ({ title, ...props }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [selected, setSelected] = useState(null);
    const [openPostDeleteDialog, setOpenPostDeleteDialog] = useState(false);

    const PostTypeOptions = () =>
        Object.values(PostType).map(item => (
            <option key={item.id} value={item.id}>
                {t(item.label)}
            </option>
        ));

    const ArticleTypeOptions = () =>
        Object.values(ArticleType).map(item => (
            <option key={item.id} value={item.id}>
                {t(item.label)}
            </option>
        ));

    const columns = [
        {
            name: 'id',
            label: 'ID',
            width: 50,
            searchable: true,
            type: Number,
        },
        {
            name: 'region',
            label: t('label.region'),
            execute: item => item?.name,
            searchable: true,
            searchField: 'regionName',
            width: 150,
        },
        {
            name: 'postType',
            label: t('label.postType'),
            execute: item => t(findEnumById(PostType, item)?.label),
            width: 150,
            type: Number,
            searchable: true,
            searchType: 'select',
            sortable: false,
            selectOptions: <PostTypeOptions />,
        },
        {
            name: 'articleType',
            label: t('label.articleType'),
            execute: item => t(findEnumById(ArticleType, item)?.label),
            width: 150,
            type: Number,
            searchable: true,
            searchType: 'select',
            sortable: false,
            selectOptions: <ArticleTypeOptions />,
        },
        {
            name: 'title',
            label: t('label.title'),
            searchable: true,
        },
        {
            name: 'url',
            label: t('label.url'),
            width: 120,
            execute: url => (url ? <PreviewButton url={url} /> : ''),
        },
        {
            name: 'volume',
            label: t('label.searchVolume'),
            width: 150,
            searchable: true,
            type: Number,
        },
        { ...getDeletedColumn(t) },
    ];

    const posts = usePagination({
        service: postService,
    });

    const ActionButtons = () => (
        <ListPageActions>
            <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={() => {
                    history.push(`/posts/create`);
                }}>
                {t('button.addNew')}
            </Button>
        </ListPageActions>
    );

    return (
        <Page title={title}>
            <Container maxWidth="xl">
                <ActionButtons />
                <DataTable
                    title={t('label.postList')}
                    columns={columns}
                    {...posts}
                    onEdit={item => {
                        history.push(`/posts/edit/${item.id}`);
                    }}
                    link="/posts/edit/"
                    onDelete={item => {
                        setSelected(item);
                        setOpenPostDeleteDialog(true);
                    }}
                />
                <ActionButtons />
            </Container>

            <PostDeleteDialog
                post={selected}
                open={openPostDeleteDialog}
                onClose={() => {
                    setOpenPostDeleteDialog(false);
                    setSelected(null);
                }}
                onDoneCallback={posts.search}
            />
        </Page>
    );
};

export default PostListPage;
