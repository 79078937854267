import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import SubmitButton from '../ui/SubmitButton';
import AddIcon from '@mui/icons-material/Add';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ExtraTourEditInputPanel from '../pages/common/extraTours/ExtraTourEditInputPanel';
import extraTourService from '../service/ExtraTourService';
import { MessageType } from '../util/Enums';
import useMessage from '../util/useMessage';

const ExtraTourAddDialog = ({ open, inputValue, onClose, onAddCallback }) => {
    const { t } = useTranslation();
    const methods = useForm();

    const [loading, setLoading] = useState(false);

    const [shortDesc, setShortDesc] = useState('');
    const [image, setImage] = useState(null);
    const [fileNameParam, setFileNameParam] = useState('');
    const [folderName] = useState('extratour');

    const showMessage = useMessage();

    useEffect(() => {
        if (open) {
            methods.reset();
            setImage(null);
            setFileNameParam('');
            setShortDesc('');
        }
    }, [open]);

    const onSubmit = async data => {
        if (loading) return false;
        try {
            setLoading(true);

            const obj = {
                ...data,
                image,
                fileNameParam,
                folderName,
                shortDesc: shortDesc,
            };
            delete obj['extraTourType'];

            const { resultObject } = await extraTourService.saveOrUpdate(undefined, obj);
            showMessage(t('message.created'));

            onAddCallback && onAddCallback(resultObject);
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} fullWidth maxWidth="sm" scroll="paper">
            <DialogTitle>{t('title.addExtraTourDialog')}</DialogTitle>
            <DialogContent dividers={true}>
                <FormProvider {...methods}>
                    <ExtraTourEditInputPanel
                        elevation={1}
                        {...{
                            name: inputValue,
                            image,
                            setImage,
                            fileNameParam,
                            folderName,
                            shortDesc,
                            setShortDesc,
                        }}
                    />
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" disabled={loading}>
                    {t('button.cancel')}
                </Button>
                <SubmitButton
                    type="button"
                    loading={loading}
                    startIcon={<AddIcon />}
                    onClick={methods.handleSubmit(onSubmit)}>
                    {t('button.add')}
                </SubmitButton>
            </DialogActions>
        </Dialog>
    );
};

export default ExtraTourAddDialog;
