import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMessage from './useMessage';
import { ActionType } from '../store/ActionType';

const ReduxNotificationHandler = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(state => state.notification.notifications);
    const [messages, setMessages] = useState([]);

    const showMessage = useMessage();

    useEffect(() => {
        if (notifications.length > 0) {
            const newList =
                messages.length === 0
                    ? notifications
                    : notifications.filter(item => messages.some(m => m.id === item.id));

            newList.forEach(item => {
                setTimeout(() => {
                    showMessage(item.message, item.type);
                }, 300);
                dispatch({ type: ActionType.REMOVE_NOTIFICATION, id: item.id });
            });
        }
        setMessages(notifications);
    }, [notifications, dispatch, showMessage, messages]);

    return null;
};

export default ReduxNotificationHandler;
