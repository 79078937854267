import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NativeEditor from '../ui/NativeEditor';
import Input from '../ui/Input';
import { useForm } from 'react-hook-form';
import ExtraTourInput from '../ui/ExtraTourInput';
import useMessage from '../util/useMessage';
import { MessageType, AccommodationTypes } from '../util/Enums';
import Grid from '@mui/material/Grid';
import RegionInput from '../ui/RegionInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '../ui/Select';

const ProgramDayEditDialog = ({ open, selectedIndex, items, night, onEditDone, onClose }) => {
    const { t } = useTranslation();
    const showMessage = useMessage();

    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        watch,
        reset,
        control,
        formState: { errors },
    } = useForm();

    const others = items.filter((item, index) => index !== selectedIndex);
    const days = [];

    others?.forEach(item => item.days && days.push(...item.days));

    const [description, setDescription] = useState(null);
    const [dayRequired, setDayRequired] = useState(false);
    const watchExtraTours = watch('extraTours', []);
    const watchDestinations = watch('destinations', []);
    const watchAccommodations = watch('accommodations', []);
    const watchDays = watch('days', []);

    useEffect(() => {
        if (open) {
            reset();
            setValue('extraTours', items[selectedIndex]?.extraTours || []);

            setValue('destinations', items[selectedIndex]?.destinations || []);
            setValue('accommodations', items[selectedIndex]?.accommodations || []);
            setValue('days', items[selectedIndex]?.days || []);
        }
        setDescription(items[selectedIndex]?.description || "");
    }, [open, selectedIndex, items]);

    useEffect(() => {
        const checkFirstDate = watchDays?.includes(1);
        const checkLastDate = watchDays?.includes(night + 1);
        const checkLastDateStr = watchDays?.includes((night + 1).toString());
        if (checkFirstDate || checkLastDate || checkLastDateStr) {
            setDayRequired(true);
        } else {
            setDayRequired(false);
        }
    }, [watchDays]);

    const onNewExtraTourAddCallback = data => {
        const tours = [...watchExtraTours, data];
        setValue('extraTours', tours);
    };

    const onNewDestinationsCallback = data => {
        const newDestinations = [...watchDestinations, data];
        setValue('destinations', newDestinations);
    };

    const onNewAccommodationsCallback = data => {
        const newAccommodations = [...watchAccommodations, data];
        setValue('accommodations', newAccommodations);
    };

    const onSubmit = data => {
        if (onEditDone) {
            if (!description) {
                showMessage(t('message.descriptionNotEmpty'), MessageType.ERROR);
                return;
            }
            const obj = {
                ...data,
                tourProgramDayId: parseInt(data.tourProgramDayId),
                days: Array.isArray(data.days) ? data.days.map(i => parseInt(i)) : [1],
                description: description,
            };
            onEditDone(obj, selectedIndex);
        }
    };

    return (
        <Dialog open={open} fullWidth maxWidth={'md'} scroll="paper">
            <DialogTitle>{t('title.programDayEdit')}</DialogTitle>
            <DialogContent dividers>
                <input
                    type="hidden"
                    {...register(`tourProgramDayId`)}
                    defaultValue={items[selectedIndex]?.tourProgramDayId || 0}
                />
                <Input
                    defaultValue={items[selectedIndex]?.title}
                    label={t('label.title')}
                    error={errors?.title}
                    {...register('title', {
                        required: t('validation.required'),
                    })}
                />

                <ExtraTourInput
                    name="extraTours"
                    control={control}
                    addNewCallback={onNewExtraTourAddCallback}
                    defaultValue={items[selectedIndex]?.extraTours || []}
                />

                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} lg={6}>
                        <RegionInput
                            name="destinations"
                            label={t('label.destinationLocations')}
                            multiple={true}
                            control={control}
                            defaultValue={items[selectedIndex]?.destinations || []}
                            rules={{ required: !dayRequired ? t('validation.required') : false }}
                            error={errors?.destinations}
                            addNewCallback={onNewDestinationsCallback}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6}>
                        <RegionInput
                            name="accommodations"
                            label={t('label.stayLocations')}
                            multiple={true}
                            control={control}
                            defaultValue={items[selectedIndex]?.accommodations || []}
                            error={errors?.accommodations}
                            addNewCallback={onNewAccommodationsCallback}
                        />
                    </Grid>
                </Grid>

                <div style={{ marginTop: 10, marginBottom: 10 }}>
                    {[...Array(night + 1).keys()].map(n => (
                        <label
                            key={n}
                            htmlFor={`${n}-day-checkbox`}
                            style={{ marginRight: 10, cursor: 'pointer' }}>
                            <input
                                id={`${n}-day-checkbox`}
                                type="checkbox"
                                value={n + 1}
                                defaultChecked={items[selectedIndex]?.days?.includes(n + 1)}
                                {...register('days', {
                                    required: t('validation.required'),
                                })}
                                disabled={days?.includes(n + 1)}
                            />
                            {n + 1}. Gün
                        </label>
                    ))}
                    {errors?.days && (
                        <div
                            style={{
                                color: '#f44336',
                                fontSize: '0.75rem',
                                marginLeft: 14,
                            }}>
                            {t('validation.required')}
                        </div>
                    )}
                </div>

                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} lg={6}>
                        <Select
                            name="accommodationTypeId"
                            error={errors?.accommodationTypeId}
                            label={t('label.accommodationType')}
                            defaultValue={items[selectedIndex]?.accommodationTypeId || 3}
                            control={control}>
                            {Object.values(AccommodationTypes).map(item => (
                                <MenuItem key={item.id} value={item.id}>
                                    {t(item.label)}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>

                <div
                    style={{
                        border: '1px solid #ccc',
                        borderRadius: 6,
                        padding: 1,
                        marginTop: '10px',
                    }}>
                    <NativeEditor value={description} onChange={val => setDescription(val)} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t('button.cancel')}
                </Button>

                <Button onClick={handleSubmit(onSubmit)} color="secondary">
                    {t('button.done')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProgramDayEditDialog;
