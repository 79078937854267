import { Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import RenderTags from './RenderTags';
import { useTranslation } from 'react-i18next';
import hotelService from '../service/HotelService';
import Button from '@mui/material/Button';
import HotelAddDialog from '../components/HotelAddDialog';

const HotelInput = ({
    name,
    label,
    defaultValue,
    rules,
    error,
    multiple = true,
    disabled,
    control,
    addNewCallback,
    selectedItems,
}) => {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const [openHotelDialog, setOpenHotelDialog] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        let active = true;
        setLoading(true);
        (async () => {
            try {
                const { resultObject } = await hotelService.search({
                    start: 0,
                    data: { name: inputValue },
                    max: 50,
                });
                const result = resultObject
                    .filter(
                        item =>
                            item.id &&
                            !selectedItems.some(selectedItem => selectedItem.hotelId === item.id),
                    )
                    .map(item => ({ ...item }));
                if (active) {
                    setOptions(result || []);
                    setLoading(false);
                }
            } catch (e) {}
        })();
        return () => {
            active = false;
        };
    }, [inputValue]);

    const handleChange = event => {
        setInputValue(event.target.value);
    };

    return (
        <>
            <Controller
                render={({ field }) => (
                    <Autocomplete
                        {...field}
                        multiple={multiple}
                        getOptionLabel={hotel => hotel.name || ''}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        options={options}
                        filterOptions={x => x}
                        loading={loading}
                        disabled={disabled}
                        renderTags={RenderTags}
                        limitTags={2}
                        noOptionsText={
                            addNewCallback ? (
                                <Button
                                    variant={'contained'}
                                    color="primary"
                                    size={'small'}
                                    onClick={() => {
                                        setOpenHotelDialog(true);
                                    }}>
                                    {t('button.addNewHotel')}
                                </Button>
                            ) : (
                                'Seçenek Yok'
                            )
                        }
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={label || (multiple ? t('label.hotels') : t('label.hotel'))}
                                error={error && true}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                autoComplete="off"
                                helperText={error?.message}
                                multiline={multiple}
                                onChange={handleChange}
                            />
                        )}
                        onChange={(_, data) => field.onChange(data)}
                    />
                )}
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={rules}
            />
            <HotelAddDialog
                open={openHotelDialog}
                inputValue={inputValue}
                onAddCallback={data => {
                    addNewCallback && addNewCallback(data);
                    setOpenHotelDialog(false);
                }}
                onClose={() => setOpenHotelDialog(false)}
            />
        </>
    );
};

export default HotelInput;
