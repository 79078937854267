import Page from '../../../layout/Page';
import { useTranslation } from 'react-i18next';
import usePagination from '../../../util/usePagination';
import { DataTable } from '../../../components/DataTable';
import Container from '@mui/material/Container';
import ListPageActions from '../../../components/ListPageActions';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import parameterService from '../../../service/ParameterService';
import { findEnumById, ParameterTypes } from '../../../util/Enums';
import ParameterDeleteDialog from './ParameterDeleteDialog';
import { useState } from 'react';
import { getDeletedColumn, YesNoTypeOptions } from '../../../components/YesNoTypeOptions';

const ParameterListPage = ({ title, ...props }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [selected, setSelected] = useState(null);
    const [openParameterDeleteDialog, setOpenParameterDeleteDialog] = useState(false);

    const ParameterTypesOptions = () =>
        Object.values(ParameterTypes).map(item => (
            <option key={item.id} value={item.id}>
                {t(item.label)}
            </option>
        ));

    const columns = [
        {
            name: 'id',
            label: 'ID',
            width: 10,
            searchable: true,
            type: Number,
        },
        {
            name: 'type',
            label: t('label.generalParameterType'),
            execute: item => t(findEnumById(ParameterTypes, Number(item))?.label),
            width: 125,
            type: Number,
            searchable: true,
            sortable: false,
            searchType: 'select',
            selectOptions: <ParameterTypesOptions />,
        },
        {
            name: 'name',
            label: t('label.name'),
            searchable: true,
        },
        {
            name: 'isDefault',
            label: t('label.isDefault'),
            type: Boolean,
            searchable: true,
            searchType: 'select',
            sortable: false,
            execute: item => t(`label.${item === true ? 'yes' : 'no'}`),
            selectOptions: <YesNoTypeOptions />,
        },
        getDeletedColumn(t),
    ];

    const parameters = usePagination({
        service: parameterService,
    });

    const ActionButtons = () => (
        <ListPageActions>
            <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={() => {
                    history.push(`/settings/parameters/create`);
                }}>
                {t('button.addNew')}
            </Button>
        </ListPageActions>
    );

    return (
        <Page title={title}>
            <Container maxWidth="xl">
                <ActionButtons />
                <DataTable
                    title={t('label.generalParameters')}
                    columns={columns}
                    {...parameters}
                    onEdit={item => {
                        history.push(`/settings/parameters/edit/${item.id}`);
                    }}
                    onDelete={item => {
                        setSelected(item);
                        setOpenParameterDeleteDialog(true);
                    }}
                />
                <ActionButtons />
            </Container>

            <ParameterDeleteDialog
                parameter={selected}
                open={openParameterDeleteDialog}
                onClose={() => {
                    setOpenParameterDeleteDialog(false);
                    setSelected(null);
                }}
                onDoneCallback={parameters.search}
            />
        </Page>
    );
};

export default ParameterListPage;
