import React, { useRef, useState } from 'react';
import { TableCell } from '@mui/material';
import SearchInput from './SearchInput';

const SearchCell = React.memo(({ column, onSearch }) => {
    const [min, setMin] = useState('');
    const [max, setMax] = useState('');
    const process = useRef(null);

    const onKeyPress = e => {
        if (e.charCode === 13 && onSearch) {
            setSearchData(min, max);
        }
    };

    const setSearchData = (min, max) => {
        if (process.current) {
            clearTimeout(process.current);
        }

        process.current = setTimeout(() => {
            const obj = {};
            obj[`${column.name}Min`] = min ? Number(min) : null;
            obj[`${column.name}Max`] = max ? Number(max) : null;
            onSearch(obj);
        }, 250);
    };

    return (
        <TableCell
            style={{
                backgroundColor: '#f9f6f3',
                margin: 0,
                padding: '2px 2px 0px 8px',
                width: 110,
            }}>
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <SearchInput
                    value={min}
                    onChange={e => setMin(e.target.value)}
                    onKeyPress={onKeyPress}
                    autoComplete="off"
                    placeholder={`Min...`}
                    type={column.type === Number ? 'number' : 'text'}
                />
                <SearchInput
                    value={max}
                    onChange={e => setMax(e.target.value)}
                    onKeyPress={onKeyPress}
                    autoComplete="off"
                    placeholder={`Max...`}
                    style={{ borderLeft: '1px solid #e0e0e0' }}
                    type={column.type === Number ? 'number' : 'text'}
                />
            </div>
        </TableCell>
    );
});

export default SearchCell;
