import React, { useEffect, useRef, useState } from 'react';
import Page from '../../layout/Page';
import { useHistory, useParams } from 'react-router-dom';
import tourPriceService from '../../service/TourPriceService';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import PageActions from '../../components/PageActions';
import { FormProvider, useForm } from 'react-hook-form';
import { MessageType } from '../../util/Enums';
import useMessage from '../../util/useMessage';
import SubmitButton from '../../ui/SubmitButton';

import TourPriceEditProgramInputs from './prices/TourPriceEditProgramInputs';
import TourPriceEditHotelListPanel from './prices/TourPriceEditHotelListPanel';
import TourPriceEditHotelUnitPricePanel from './prices/TourPriceEditHotelUnitPricePanel';
import TourPriceEditHotelChangeInput from './prices/TourPriceEditHotelChangeInput';
import TourPriceEditExchangePanel from './prices/TourPriceEditExchangePanel';
import TourPriceEditChildRulesPanel from './prices/TourPriceEditChildRulesPanel';
import { parseAndFormatDateString } from '../../util/DateUtil';
import TourPriceEditGrid from './prices/TourPriceEditGrid';

const TourPriceEditPage = ({ title, ...props }) => {
    const param = useParams();
    const tourPriceId = param?.id;

    const { t } = useTranslation();
    const history = useHistory();
    const showMessage = useMessage();

    const unitPriceRef = useRef(null);
    const exchangeRef = useRef(null);
    const childRulesRef = useRef(null);
    const priceGridRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const [tourPrice, setTourPrice] = useState(true);

    const methods = useForm();
    const [hotels, setHotels] = useState([]);
    const [defaultHotel, setDefaultHotel] = useState(null);
    const [selectedHotel, setSelectedHotel] = useState(null);

    const watchVendor = methods.watch('contact', null);

    const [periodMap, setPeriodMap] = useState(new Map());

    const [hasPrice, setHasPrice] = useState(false);
    const [isPriceGenerateActive, setPriceGenerateActive] = useState(false);

    useEffect(() => {
        if (tourPriceId) {
            (async () => {
                setPageLoading(true);
                try {
                    const { resultObject } = await tourPriceService.get(tourPriceId);
                    initPageValues(resultObject);
                } catch ({ code, message }) {
                    showMessage(`${code} - ${message}`, MessageType.ERROR);
                }
                setPageLoading(false);
            })();
        } else {
            setPageLoading(false);
        }
    }, [tourPriceId]);

    const initPageValues = _tourPrice => {
        setTourPrice(_tourPrice);
        setSelectedHotel(Array.isArray(_tourPrice.hotels) ? _tourPrice.hotels[0] : null);
        methods.setValue('contact', _tourPrice.contact);
        setHotels(_tourPrice.hotels);
        if (_tourPrice.hotels && _tourPrice.hotels.length > 0) {
            setDefaultHotel({ ..._tourPrice.hotels[0] });
        }
    };

    useEffect(() => {
        if (selectedHotel) {
            const data = new Map();
            let _hasPrice = false;
            if (selectedHotel.periodDates) {
                selectedHotel?.periodDates.forEach(item => {
                    const key = parseAndFormatDateString(item.startDate, 'MMMM yyyy');
                    const month = data.get(key) || [];
                    month.push(item);
                    data.set(key, month);
                });

                setPeriodMap(data);
                _hasPrice = selectedHotel?.periodDates.some(
                    period =>
                        Array.isArray(period.accommodations) && period.accommodations.length > 0,
                );
            }

            setHasPrice(_hasPrice);
            setPriceGenerateActive(!_hasPrice);
        } else {
            setPeriodMap(new Map());
        }
    }, [selectedHotel]);

    const isValidForms = async (validateExchange = true) => {
        if (selectedHotel) {
            const promiseList = [];

            if (validateExchange) {
                promiseList.push(exchangeRef.current.isValid());
            }

            if (isPriceGenerateActive) {
                promiseList.push(unitPriceRef.current.isValid());
                promiseList.push(childRulesRef.current.isValid());
            } else {
                promiseList.push(priceGridRef.current.isValid());
            }

            const validations = await Promise.all(promiseList);
            const isNotValid = validations.some(item => item === false);
            return isNotValid !== true;
        } else {
            return true;
        }
    };

    const applyGridFormData = data => {
        const index = hotels.indexOf(selectedHotel);
        const _hotel = { ...selectedHotel };
        selectedHotel.periodDates.forEach((period, _index) => {
            data.forEach(item => {
                if (item.startDate === period.startDate) {
                    _hotel.periodDates[_index] = {
                        ...period,
                        ...item,
                    };
                }
            });
        });

        const _hotels = [...hotels];
        _hotels[index] = _hotel;

        setHotels(_hotels);
        setSelectedHotel(_hotel);
    };

    const onSubmit = async data => {
        if (loading) return false;
        try {
            const isValid = await isValidForms();
            if (!isValid) {
                showMessage(t('message.invalidatePriceForm'), MessageType.ERROR);
                return false;
            }

            const exchange = exchangeRef.current.getData();

            const object = {
                ...data,
                ...exchange,
                hotels,
                tourProgram: tourPrice.tourProgram || null,
            };

            const { resultObject } = await tourPriceService.saveOrUpdate(tourPrice.id, object);
            showMessage(t(tourPriceId ? 'message.updated' : 'message.created'));
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const onPriceGenerateRequest = async () => {
        try {
            setLoading(true);
            const isValid = await isValidForms();
            if (!isValid) {
                showMessage(t('message.invalidatePriceForm'), MessageType.ERROR);
                return;
            }

            const formData = await Promise.all([
                unitPriceRef.current.getData(),
                childRulesRef.current.getData(),
                exchangeRef.current.getData(),
            ]);

            const data = Object.assign({}, ...formData);
            const { contact } = tourPrice;
            const { commissionCalculationType, singleRoomDifference } = contact;
            const { resultObject } = await tourPriceService.priceGenerate({
                ...data,
                commissionCalculationType,
                singleRoomDifference,
            });

            const index = hotels.indexOf(selectedHotel);
            const _hotel = { ...selectedHotel, ...data };
            console.log('selectedHotel', selectedHotel);
            // perPersonPrice, singleRoomPrice, childPrice1, childPrice2 ,additionalPriceAdt, additionalPriceChd, additionalPriceInf

            const childPrice1 = data.childRules[0].price;
            const additionalPriceInf = data.childRules[0].additionalPrice;

            const childPrice2 = data.childRules[1].price;
            const additionalPriceChd = data.childRules[1].additionalPrice;

            _hotel.periodDates = defaultHotel.periodDates.map((item, itemIndex) => ({
                ...item,
                id:
                    selectedHotel !== null &&
                    selectedHotel.periodDates &&
                    selectedHotel.periodDates.length > 0
                        ? item.id
                        : 0,
                perPersonPrice: data.perPersonPrice,
                singleRoomPrice: data.singleRoomPrice,
                additionalPriceAdt: data.additionalPrice,
                childPrice1: childPrice1,
                additionalPriceInf: additionalPriceInf,
                childPrice2: childPrice2,
                additionalPriceChd: additionalPriceChd,
                accommodations: [],
                periodDateDiscountRate: data.defaultDiscountRate,
                periodDateQuota: data.defaultQuota,
                periodDateDiscountCurrency: 1,
            }));

            _hotel.periodDates.forEach(period => {
                if (period.accommodations.length > 0) {
                    const accommodations = [];
                    defaultHotel.periodDates?.map((per, idx) => {
                        if (period.id === per.id) {
                            defaultHotel.periodDates[idx].accommodations.map((acc, i) => {
                                resultObject.map((res, resi) => {
                                    if (
                                        acc.adultCount === res.adultCount &&
                                        acc.childCount === res.childCount &&
                                        acc.infantCount === res.infantCount
                                    ) {
                                        accommodations.push({ id: acc.id, ...res });
                                    }
                                });
                            });
                        }
                    });
                    period.accommodations = accommodations;
                } else {
                    period.accommodations = [...resultObject];
                }
            });
            const _hotels = [...hotels];
            _hotels[index] = _hotel;

            setHotels(_hotels);
            setSelectedHotel(_hotel);

            showMessage(t('message.pricesSuccessfullyGenerated'));
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setLoading(false);
        }
    };

    const ActionPanel = () => (
        <PageActions>
            {!isPriceGenerateActive && (
                <>
                    <Button
                        variant="contained"
                        disabled={loading}
                        startIcon={loading ? null : <KeyboardBackspaceIcon />}
                        onClick={() => {
                            history.goBack();
                        }}>
                        {t('button.back')}
                    </Button>
                    <SubmitButton
                        type="button"
                        loading={loading}
                        startIcon={<SaveIcon />}
                        onClick={async () => {
                            if (priceGridRef.current.isEdited()) {
                                priceGridRef.current.submit();
                            } else {
                                methods.handleSubmit(onSubmit)();
                            }
                        }}>
                        {t('button.save')}
                    </SubmitButton>
                </>
            )}
        </PageActions>
    );

    return (
        <Page title={title} loading={pageLoading}>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Container maxWidth={'md'}>
                        <ActionPanel />
                        <Grid container spacing={2}>
                            <Grid item md={12}>
                                <TourPriceEditProgramInputs {...{ tourPrice, tourPriceId }} />
                            </Grid>

                            <Grid item md={12}>
                                <TourPriceEditHotelListPanel
                                    hotels={hotels}
                                    setHotels={setHotels}
                                    activeHotel={selectedHotel}
                                    setSelectedHotel={setSelectedHotel}
                                    isValidForms={isValidForms}
                                    defaultHotel={defaultHotel}
                                />
                            </Grid>

                            {hotels.length > 0 && (
                                <Grid item md={12}>
                                    <TourPriceEditHotelChangeInput
                                        {...{
                                            hotels,
                                            selectedHotel,
                                            setSelectedHotel,
                                            isValidForms,
                                            priceGridRef,
                                        }}
                                    />
                                </Grid>
                            )}

                            {selectedHotel && (
                                <>
                                    <Grid item md={12}>
                                        <TourPriceEditExchangePanel
                                            ref={exchangeRef}
                                            {...{ hotel: selectedHotel, hotels, setHotels }}
                                        />
                                    </Grid>
                                    {isPriceGenerateActive && (
                                        <>
                                            <Grid item md={12}>
                                                <TourPriceEditHotelUnitPricePanel
                                                    ref={unitPriceRef}
                                                    vendor={watchVendor}
                                                    hotel={selectedHotel}
                                                />
                                            </Grid>
                                            <Grid item md={12}>
                                                <TourPriceEditChildRulesPanel
                                                    ref={childRulesRef}
                                                    hotel={selectedHotel}
                                                />
                                            </Grid>
                                            <Grid item md={12} style={{ textAlign: 'center' }}>
                                                {hasPrice && (
                                                    <Button
                                                        variant="contained"
                                                        color={'secondary'}
                                                        style={{ marginRight: 10 }}
                                                        onClick={() => {
                                                            setPriceGenerateActive(false);
                                                        }}>
                                                        {t('button.cancel')}
                                                    </Button>
                                                )}

                                                <Button
                                                    variant="contained"
                                                    color={'info'}
                                                    onClick={onPriceGenerateRequest}>
                                                    {t('title.createPrices')}
                                                </Button>
                                            </Grid>
                                        </>
                                    )}

                                    {!isPriceGenerateActive && (
                                        <Grid item md={12} style={{ textAlign: 'center' }}>
                                            <Button
                                                variant="contained"
                                                color={'warning'}
                                                onClick={() => {
                                                    setPriceGenerateActive(true);
                                                }}>
                                                {t('button.regenPrice')}
                                            </Button>
                                            <p
                                                style={{ fontStyle: 'italic', color: '#ccc' }}
                                                dangerouslySetInnerHTML={{
                                                    __html: t('message.regenPrice'),
                                                }}
                                            />
                                        </Grid>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Container>
                    <Container maxWidth={'lg'}>
                        {!isPriceGenerateActive && selectedHotel && (
                            <Grid container spacing={2} style={{ marginTop: 14 }}>
                                <TourPriceEditGrid
                                    ref={priceGridRef}
                                    periodMap={periodMap}
                                    selectedHotel={selectedHotel}
                                    setPeriodMap={setPeriodMap}
                                    applyFormData={applyGridFormData}
                                    onSubmit={methods.handleSubmit(onSubmit)}
                                    commissionCalculationType={
                                        tourPrice.contact.commissionCalculationType
                                    }
                                    singleRoomDifference={tourPrice.contact.singleRoomDifference}
                                    editLoading={loading}
                                />
                            </Grid>
                        )}
                        <ActionPanel />
                    </Container>
                </form>
            </FormProvider>
        </Page>
    );
};

export default TourPriceEditPage;
