import BaseService from './BaseService';

class VisaTypeService extends BaseService {
    constructor() {
        super('/common/visaTypes');
    }
}

const visaTypeService = new VisaTypeService();

export default visaTypeService;
