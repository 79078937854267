import Page from '../../layout/Page';
import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { DataTable } from '../../components/DataTable';
import usePagination from '../../util/usePagination';
import campaignOpportunityService from '../../service/CampaignOpportunityService';
import AddIcon from '@mui/icons-material/Add';
import ExtraOpportunityEditDialog from '../../components/ExtraOpportunityEditDialog';
import { MessageType } from '../../util/Enums';
import useMessage from '../../util/useMessage';
import OpportunityDeleteDialog from './OpportunityDeleteDialog';

const OpportunityEditPage = ({ title, ...props }) => {
    const { t } = useTranslation();
    const showMessage = useMessage();

    const [openDialog, setOpenDialog] = useState(false);
    const [openOpportunityDeleteDialog, setOpenOpportunityDeleteDialog] = useState(false);

    const [pageLoading, setPageLoading] = useState(false);

    const [selectedIndex, setSelectedIndex] = useState(null);

    const columns = [
        {
            name: 'id',
            label: 'ID',
            width: 50,
            type: Number,
            searchable: true,
        },
        {
            name: 'catalog',
            label: t('label.title'),
            searchable: true,
            execute: item => item?.title,
            searchField: 'catalogTitle',
        },
        {
            name: 'sequence',
            label: t('label.sortOrder'),
        },
    ];

    const opportunities = usePagination({
        service: campaignOpportunityService,
    });

    const addNewExtraOpportunity = () => {
        setOpenDialog(true);
        setSelectedIndex(null);
    };

    const handleOnEditDone = async data => {
        setPageLoading(true);

        try {
            const obj = {
                ...data,
            };

            await campaignOpportunityService.saveOrUpdate(obj.id, obj);
            showMessage(t(obj.id ? 'message.updated' : 'message.created'));
        } catch ({ code, message }) {
            showMessage(`${code} - ${message}`, MessageType.ERROR);
        } finally {
            setPageLoading(false);
        }

        setOpenDialog(false);
        setSelectedIndex(null);

        await opportunities.search();
    };

    return (
        <Page title={title} loading={pageLoading}>
            <Container maxWidth="xl">
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <DataTable
                            columns={columns}
                            {...opportunities}
                            title={t('title.opportunity')}
                            action={
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    startIcon={<AddIcon />}
                                    size={'small'}
                                    onClick={addNewExtraOpportunity}>
                                    {t('button.add')}
                                </Button>
                            }
                            onEdit={(item, index) => {
                                setSelectedIndex(index);
                                setOpenDialog(true);
                            }}
                            onDelete={(item, index) => {
                                setSelectedIndex(index);
                                setOpenOpportunityDeleteDialog(true);
                            }}
                        />
                    </Grid>
                </Grid>
            </Container>

            <ExtraOpportunityEditDialog
                open={openDialog}
                items={opportunities.data}
                selectedIndex={selectedIndex}
                onClose={() => setOpenDialog(false)}
                onEditDone={data => {
                    handleOnEditDone(data);
                }}
            />

            <OpportunityDeleteDialog
                opportunity={opportunities.data[selectedIndex]}
                open={openOpportunityDeleteDialog}
                onClose={() => {
                    setOpenOpportunityDeleteDialog(false);
                    setSelectedIndex(null);
                }}
                onDoneCallback={opportunities.search}
            />
        </Page>
    );
};

export default OpportunityEditPage;
