import Page from '../../../layout/Page';
import { useTranslation } from 'react-i18next';
import usePagination from '../../../util/usePagination';
import campaignService from '../../../service/CampaignService';
import { DataTable } from '../../../components/DataTable';
import Container from '@mui/material/Container';
import ListPageActions from '../../../components/ListPageActions';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import SaleCampaignDeleteDialog from './SaleCampaignDeleteDialog';
import { getDeletedColumn, YesNoTypeOptions } from '../../../components/YesNoTypeOptions';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import Input from '../../../ui/Input';
import Tooltip from '@mui/material/Tooltip';
import VendorSearch from '../../../components/datatable/VendorSearch';
import DateFilterCell from '../../../components/datatable/DateFilterCell';
import { format } from 'date-fns';
import { MessageType, PaymentType, ProductGroupTypes } from '../../../util/Enums';
import Select from '../../../ui/Select';
import MenuItem from '@mui/material/MenuItem';
import DateInput from '../../../ui/DateInput';
import { formatDate } from '../../../util/DateUtil';

const CampaignListPage = ({ title, ...props }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { register, control, handleSubmit } = useForm();
    const [deleted, setDeleted] = useState(null);
    const [openCampaignDeleteDialog, setOpenCampaignDeleteDialog] = useState(false);

    const columns = [
        {
            name: 'saleCampaignId',
            label: 'ID',
            width: 10,
            searchable: true,
            type: Number,
        },
        {
            name: 'name',
            label: t('label.name'),
            searchable: true,
            execute: item =>
                item && (
                    <Tooltip title={<p>{item}</p>}>
                        <div
                            style={{
                                whiteSpace: 'nowrap',
                                maxWidth: '300px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {item}
                        </div>
                    </Tooltip>
                ),
        },
        {
            name: 'startDate',
            label: t('label.campaignStartDate'),
            execute: item => (item ? format(new Date(item), 'dd.MM.yyyy') : ''),
            searchable: true,
            SearchCellComponent: DateFilterCell,
        },
        {
            name: 'endDate',
            label: t('label.campaignEndDate'),
            execute: item => (item ? format(new Date(item), 'dd.MM.yyyy') : ''),
            searchable: true,
            SearchCellComponent: DateFilterCell,
        },
        {
            name: 'contact',
            label: t('label.vendor'),
            searchable: true,
            searchField: 'contactId',
            type: Number,
            SearchCellComponent: VendorSearch,
            execute: item =>
                item?.brandName && (
                    <Tooltip title={<p>{item.brandName}</p>}>
                        <div
                            style={{
                                whiteSpace: 'nowrap',
                                maxWidth: '160px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>
                            {item.brandName}
                        </div>
                    </Tooltip>
                ),
        },
        {
            name: 'isActive',
            label: t('label.active'),
            type: Boolean,
            searchable: true,
            searchType: 'select',
            sortable: false,
            execute: item => t(`label.${item === true ? 'yes' : 'no'}`),
            selectOptions: <YesNoTypeOptions />,
        },
        { ...getDeletedColumn(t) },
    ];
    const campaigns = usePagination({
        service: campaignService,
        defaultOrderBy: { name: 'saleCampaignId', orderType: 'desc' },
    });
    const onSubmit = async data => {
        if (campaigns.loading) return false;
        const obj = {
            ...campaigns.searchData,
            checkInDate: data.checkInDate && data.checkInDate !== '' ? data.checkInDate : null,
            checkOutDate: data.checkOutDate && data.checkOutDate !== '' ? data.checkOutDate : null,
            saleStartDate:
                data.saleStartDate && data.saleStartDate !== '' ? data.saleStartDate : null,
            saleEndDate: data.saleEndDate && data.saleEndDate !== '' ? data.saleEndDate : null,
            paymentTypeId: data.paymentTypeId ? data.paymentTypeId : null,
            productGroupId: data.productGroupId ? data.productGroupId : null,
        };
        campaigns.setSearchData({ ...obj });
    };
    const ActionButtons = () => (
        <ListPageActions>
            <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={() => {
                    history.push(`/campaigns/saleCampaigns/create`);
                }}>
                {t('button.addNew')}
            </Button>
        </ListPageActions>
    );
    return (
        <Page title={title}>
            <Container maxWidth={false} sx={{ maxWidth: '1642px' }}>
                <ActionButtons />
                <DataTable
                    title={t('label.campaignList')}
                    columns={columns}
                    {...campaigns}
                    headerNoWrap
                    buttons
                    onEdit={(item, index, e) => {
                        e.preventDefault();
                        if (e.ctrlKey || e.metaKey) {
                            window.open(`/campaigns/saleCampaigns/edit/${item.saleCampaignId}`);
                        } else {
                            history.push(`/campaigns/saleCampaigns/edit/${item.saleCampaignId}`);
                        }
                    }}
                    onDelete={item => {
                        setDeleted(item);
                        setOpenCampaignDeleteDialog(true);
                    }}
                    action={
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={1}>
                                <Grid container spacing={1} item xs={11}>
                                    <Grid item xs={4} md={4} lg={2}>
                                        <DateInput
                                            name="checkInDate"
                                            label={t('label.checkInDate')}
                                            defaultValue={null}
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid item xs={4} md={4} lg={2}>
                                        <DateInput
                                            name="checkOutDate"
                                            label={t('label.checkOutDate')}
                                            defaultValue={null}
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid item xs={4} md={4} lg={2}>
                                        <DateInput
                                            name="saleStartDate"
                                            label={t('label.saleStartDate')}
                                            defaultValue={null}
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid item xs={4} md={4} lg={2}>
                                        <DateInput
                                            name="saleEndDate"
                                            label={t('label.saleEndDate')}
                                            defaultValue={null}
                                            control={control}
                                        />
                                    </Grid>
                                    <Grid item xs={4} md={4} lg={2}>
                                        <Select
                                            name="paymentTypeId"
                                            label={t('label.paymentType')}
                                            control={control}>
                                            <MenuItem value={null}>{t('label.all')}</MenuItem>
                                            {Object.values(PaymentType).map(item => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {t(item.label)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={4} md={4} lg={2}>
                                        <Select
                                            name="productGroupId"
                                            label={t('label.productGroup')}
                                            control={control}>
                                            <MenuItem value={null}>{t('label.all')}</MenuItem>
                                            {Object.values(ProductGroupTypes).map(item => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {t(item.label)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={1}>
                                    <IconButton
                                        aria-label="Search"
                                        size="medium"
                                        type="submit"
                                        sx={{ marginTop: '8px' }}>
                                        <SearchIcon fontSize="medium" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </form>
                    }
                />
                <ActionButtons />
            </Container>
            <SaleCampaignDeleteDialog
                campaign={deleted}
                open={openCampaignDeleteDialog}
                onClose={() => {
                    setOpenCampaignDeleteDialog(false);
                    setDeleted(null);
                }}
                onDoneCallback={campaigns.search}
            />
        </Page>
    );
};

export default CampaignListPage;
