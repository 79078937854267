import {
    easyDelete,
    easyGet,
    easyPost,
    easyPut,
    easySearch,
    easyCustomSearch,
} from '../util/Fetch';
import Config from '../Config';

class BaseService {
    constructor(path) {
        this.path = path;
    }

    async search({ start = 0, data, max = Config.MAX_RESULT, orderBy = null }) {
        return await easySearch(`${this.path}/search`, start, data, max, orderBy);
    }

    async customSearch(data) {
        const { start = 0, max = Config.MAX_RESULT, orderBy = null } = data;
        return await easyCustomSearch(`${this.path}/search`, start, data, max, orderBy);
    }

    async get(id) {
        return await easyGet(`${this.path}/${id}`);
    }

    async getStatic() {
        return await easyGet(`${this.path}`);
    }

    async save(data) {
        return await easyPost(this.path, data);
    }

    async update(id, data) {
        return await easyPut(`${this.path}/${id}`, data);
    }

    async saveOrUpdate(id, data) {
        return id ? await this.update(id, data) : await this.save(data);
    }

    async delete(id, data) {
        return await easyDelete(`${this.path}/${id}`, data);
    }

    async rollback(id) {
        return await easyDelete(`${this.path}/${id}/rollback`, {});
    }
}

export default BaseService;
