import { useEffect, useRef } from 'react';

const useOutsideClick = onClick => {
    const ref = useRef(null);
    const handleClick = e => {
        if (ref.current?.contains(e.target)) {
            return false;
        }
        onClick && onClick(e);
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.addEventListener('mousedown', handleClick);
        };
    }, [ref]);
    return ref;
};

export default useOutsideClick;
