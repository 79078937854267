import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageEditorPanel from './ImageEditorPanel';
import fileUploadService from '../util/FileUploadService';
import ImageUploadProgressTable from './ImageUploadProgressTable';

const ImageUploadDialog = ({
    open,
    sessionId,
    aspect = 3 / 2,
    canBeCropped = true,
    multiple = true,
    onUploadDone,
    onClose,
    fileName,
    folderName,
    savedImages = [],
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [isDone, setDone] = useState(false);

    const [images, setImages] = useState([]);

    useEffect(() => {
        if (open) {
            setImages([]);
            setLoading(false);
            setDone(false);
        }
    }, [open]);

    useEffect(() => {
        if (images.length > 0) {
            const done = !images?.some(item => !item.done);
            if (done === true) {
                setDone(true);
            }
        }
    }, [images]);

    const onFileChange = async e => {
        // if (e.target.files && e.target.files.length > 5) {
        //     alert(t('message.maxFileCountAlert', {count: 4}));
        //     return;
        // }

        if (e.target.files && e.target.files.length > 0) {
            const items = Object.values(e.target.files).map(item => ({
                file: item,
                url: URL.createObjectURL(item),
                percentage: 0,
                done: false,
                error: false,
                cropData: undefined,
            }));
            setImages(items);
        }
    };

    const onUploadProgressCallback = useCallback(
        (event, index) => {
            const _items = [...images];
            _items[index].percentage = Math.round((100 * event.loaded) / event.total);
            setImages(_items);
        },
        [images],
    );

    const onUploadDoneCallback = useCallback(
        (response, index) => {
            const _items = [...images];
            _items[index].done = true;
            _items[index].data = response?.data?.resultObject;
            setImages(_items);
        },
        [images],
    );

    const onErrorCallback = useCallback(
        (err, index) => {
            const _items = [...images];
            _items[index].done = true;
            _items[index].error = true;
            _items[index].message = err?.response?.data?.error?.message || err.message;
            setImages(_items);
        },
        [images],
    );

    const onSubmit = useCallback(async () => {
        const check = images.some(item => !item.cropImage);
        if (canBeCropped && check) {
            alert(t('message.pleaseCropAllImageAlert'));
            return false;
        }

        setLoading(true);

        const savedImagesNumber = [];
        if (savedImages !== null && savedImages?.length > 0) {
            savedImages.forEach(img => {
                savedImagesNumber.push(
                    parseInt(
                        img.path.substring(
                            img.path.lastIndexOf('-') + 1,
                            img.path.lastIndexOf('.'),
                        ),
                    ),
                );
            });
        }
        images.forEach((item, index) => {
            let fileNames = '';
            let number = index + 1;
            if (savedImages?.length > 0) {
                for (var i = 0; i < images.length + savedImagesNumber.length; i++) {
                    if (savedImagesNumber.some(num => num === number)) {
                        number++;
                    } else {
                        fileNames = `${fileName} ${number}`;
                        savedImagesNumber.push(number);
                        break;
                    }
                }
            } else {
                fileNames = multiple ? `${fileName} ${number}` : fileName;
            }
            const upload = canBeCropped ? item.cropImage.file : item.file;
            fileUploadService
                .upload(upload, item.file?.name, sessionId, fileNames, folderName, event => {
                    onUploadProgressCallback(event, index);
                })
                .then(response => {
                    onUploadDoneCallback(response, index);
                })
                .catch(err => {
                    onErrorCallback(err, index);
                });
        });
    }, [images]);

    const onDoneButtonClick = () => {
        const items = images
            .filter(item => !item.error)
            .map(item => ({ ...item.data, desc: item.desc }));
        onUploadDone && onUploadDone(items);
        onClose && onClose();
    };

    return (
        <Dialog open={open} fullWidth maxWidth={'md'} scroll="paper">
            <DialogTitle>{t('title.imageUploadDialog')}</DialogTitle>

            <DialogContent dividers>
                {!images.length && (
                    <div style={{ textAlign: 'center' }}>
                        <input
                            id="fileInput"
                            type="file"
                            onChange={onFileChange}
                            multiple={multiple}
                            accept="image/*"
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="fileInput" style={{ cursor: 'pointer' }}>
                            {t('button.browseImage')}
                        </label>
                    </div>
                )}
                {!loading && (
                    <ImageEditorPanel aspect={aspect} {...{ canBeCropped, images, setImages }} />
                )}

                {loading && <ImageUploadProgressTable images={images} />}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary" disabled={loading}>
                    {t('button.cancel')}
                </Button>
                {!isDone && images.length > 0 && (
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={onSubmit}
                        disabled={loading}>
                        {t('button.upload')}
                    </Button>
                )}

                {loading && isDone && (
                    <Button onClick={onDoneButtonClick}>{t('button.done')}</Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ImageUploadDialog;
