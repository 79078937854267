import Page from '../../../layout/Page';
import { useTranslation } from 'react-i18next';
import usePagination from '../../../util/usePagination';
import receiptService from '../../../service/ReceiptService';
import { DataTable } from '../../../components/DataTable';
import Container from '@mui/material/Container';
import ListPageActions from '../../../components/ListPageActions';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import ReceiptDeleteDialog from './ReceiptDeleteDialog';
import { findEnumById, PaymentType, BankTypes, CurrencyTypes, PosWayTypes } from '../../../util/Enums';
import { getDeletedColumn } from '../../../components/YesNoTypeOptions';
import Grid from '@mui/material/Grid';
import { useForm } from 'react-hook-form';
import ContactInput from '../../../ui/ContactInput';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import MinMaxSearchCellForecast from '../../../components/datatable/MinMaxSearchCellForecast';
import DateFilterCell from '../../../components/datatable/DateFilterCell';
import { format } from 'date-fns';
import { formatPrice } from '../../../util/CurrencyUtil';

const ReceiptListPage = ({ title, ...props }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { register, handleSubmit, control, formState: { errors } } = useForm();
    const [selected, setSelected] = useState(null);
    const [openReceiptDeleteDialog, setOpenReceiptDeleteDialog] = useState(false);

    const PaymentTypeOptions = () =>
        Object.values(PaymentType).map(item => (
            <option key={item.id} value={item.id}>
                {t(item.label)}
            </option>
        ));

    const BankTypesOptions = () =>
        Object.values(BankTypes).map(item => (
            <option key={item.id} value={item.id}>
                {t(item.label)}
            </option>
        ));
    
    const CurrencyTypesOptions = () =>
        Object.values(CurrencyTypes).map(item => (
            <option key={item.id} value={item.id}>
                {t(item.label)}
            </option>
        ));

    const PosWayTypesOptions = () =>
        Object.values(PosWayTypes).map(item => (
            <option key={item.id} value={item.id}>
                {t(item.label)}
            </option>
        ));

    const columns = [
        {
            name: 'id',
            label: 'ID',
            width: 75,
            searchable: true,
            type: Number,
        },
        {
            name: 'receiptCode',
            label: "Dekont No.",
            searchable: true,
        },
        {
            name: 'provisionCode',
            label: t('label.provisionNo'),
            searchable: true,
        },
        {
            name: 'receiptDate',
            label: "Provizyon Tarihi",
            execute: item => (item ? format(new Date(item), 'dd.MM.yyyy') : ''),
            searchable: true,
            SearchCellComponent: DateFilterCell,
        },
        {
            name: 'maturityDate',
            label: "Vade Tarihi",
            execute: item => (item ? format(new Date(item), 'dd.MM.yyyy') : ''),
            searchable: true,
            SearchCellComponent: DateFilterCell,
        },
        {
            name: 'paymentTypeId',
            label: "Ödeme Tipi",
            execute: item => t(findEnumById(PaymentType, item)?.label),
            width: 125,
            type: Number,
            searchable: true,
            sortable: false,
            searchType: 'select',
            selectOptions: <PaymentTypeOptions />,
        },
        {
            name: 'posWayTypeId',
            label: "Ödeme Yolu",
            execute: item => t(findEnumById(PosWayTypes, item)?.label),
            width: 125,
            type: Number,
            searchable: true,
            sortable: false,
            searchType: 'select',
            selectOptions: <PosWayTypesOptions />,
        },
        {
            name: 'bankId',
            label: t('label.bankType'),
            execute: item => t(findEnumById(BankTypes, item)?.label),
            width: 125,
            type: Number,
            searchable: true,
            sortable: false,
            searchType: 'select',
            selectOptions: <BankTypesOptions />,
        },
        {
            name: 'contactName',
            label: "Ad Soyad",
            searchable: false,
            sortable: false,
        },
        {
            name: 'total',
            label: t('label.total'),
            searchable: true,
            type: Number,
            SearchCellComponent: MinMaxSearchCellForecast,
            execute: item => formatPrice(item),
        },
        {
            name: 'currencyTypeId',
            label: t('label.currencyType'),
            execute: item => t(findEnumById(CurrencyTypes, item)?.label),
            width: 125,
            type: Number,
            searchable: true,
            sortable: false,
            searchType: 'select',
            selectOptions: <CurrencyTypesOptions />,
        },
        { ...getDeletedColumn(t) },
    ];

    const receipts = usePagination({
        service: receiptService,
        defaultOrderBy: { name: 'id', orderType: 'desc' }
    });

    const onSubmit = async data => {
        if (receipts.loading) return false;
        const obj = {
            ...receipts.searchData,
            contactId: data?.contact?.id ? data.contact.id : null
        };
        receipts.setSearchData({ ...obj });
    };

    const ActionButtons = () => (
        <ListPageActions>
            <Button
                variant="contained"
                color="secondary"
                startIcon={<AddIcon />}
                onClick={() => {
                    history.push(`/finance/receipts/create`);
                }}>
                {t('button.addNew')}
            </Button>
        </ListPageActions>
    );

    return (
        <Page title={title}>
            <Container maxWidth="xl">
                <Grid container spacing={0}>
                    <Grid item xs={4}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={1}>
                                <Grid item xs={10}>
                                    <ContactInput
                                        name="contact"
                                        label={t('label.contact')}
                                        // defaultValue={receipt?.contact}
                                        error={errors?.contact}
                                        // rules={{ required: t('validation.required') }}
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <IconButton
                                        aria-label="Search"
                                        size="medium"
                                        type="submit"
                                        sx={{ marginTop: '8px' }}>
                                        <SearchIcon fontSize="medium" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Grid item xs={8}>
                        <ActionButtons />
                    </Grid>
                </Grid>
                <DataTable
                    title={t('label.receiptList')}
                    columns={columns}
                    headerNoWrap
                    {...receipts}
                    onEdit={item => {
                        history.push(`/finance/receipts/edit/${item.id}`);
                    }}
                    onDelete={item => {
                        setSelected(item);
                        setOpenReceiptDeleteDialog(true);
                    }}
                />
                <ActionButtons />
            </Container>
            <ReceiptDeleteDialog
                receipt={selected}
                open={openReceiptDeleteDialog}
                onClose={() => {
                    setOpenReceiptDeleteDialog(false);
                    setSelected(null);
                }}
                onDoneCallback={receipts.search}
            />
        </Page>
    );
};

export default ReceiptListPage;
